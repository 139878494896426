"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _vuex = require("vuex");
var _index = require("@/model/exchange/index");
var _order = require("@/model/currencyBasedContract/order");
var _settings = require("@/model/settings");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _message = require("@/components/dialog/message");
var _SocketDispatcher = _interopRequireDefault(require("@/assets/js/SocketDispatcher"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    InputNumber: _InputNumber.default
  },
  mixins: [_commonMixin.default],
  props: {
    assetsInfoList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    assetsInfo: {
      type: Object,
      default: function () {
        return {
          remain: {
            all_u_valuation: {
              usdt: "--"
            },
            list: []
          }
        };
      }
    },
    swap_symbol_id: {
      type: [String, Number],
      default: function () {
        1010;
      }
    },
    from_symbol: {
      type: [String, Number]
    },
    to_symbol: {
      type: [String, Number]
    },
    from_item_id: {
      type: [String, Number]
    },
    to_item_id: {
      type: [String, Number]
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      userInfo: "user/userInfo"
    }),
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (JSON.stringify(this.assetsInfo) != "{}" && this.assetsInfo.remain.list.length > 0) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (JSON.stringify(this.apiAssetsInfo) != "{}" && this.apiAssetsInfo.remain.list.length > 0) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "0";
      }
      return tempObj;
    },
    // 市场现在交易价格
    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    }
  },
  watch: {
    swap_symbol_id() {
      if (this.swap_symbol_id == "") {
        return;
      }
      let tempMultipleNum = JSON.parse(localStorage.getItem("delivery-multiple-num"));
      for (const key in tempMultipleNum) {
        if (key == this.swap_symbol_id) {
          this.multipleNumBtn = true;
          this.multipleNum = Number(tempMultipleNum[key]);
          setTimeout(() => {
            this.multipleNumBtn = false;
          }, 1000);
        }
      }
      if (this.multipleNumBtn == false) {
        this.multipleNum = 20;
      }
      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyPriceSelf = "";
      this.buyAmountSelf = "";
      this.buyAmount = "";
      this.sellNumSelf = "";
      this.tradingVolume = "";
      this.tradingVolumeSelf = "";
      this.sellSliderValue = 0;
      this.sellPrice = "";
      this.sellNum = "";
      this.number_precision = this.coinInfoMap[this.swap_symbol_id].number_precision;
      this.open_position_rate = this.coinInfoMap[this.swap_symbol_id].open_position_rate;
      this.price_precision = this.coinInfoMap[this.swap_symbol_id].price_precision;
      this.contract_value = this.coinInfoMap[this.swap_symbol_id].contract_value;
      this.fee_rate = this.coinInfoMap[this.swap_symbol_id].fee_rate;
      this.settlement_precision = this.coinInfoMap[this.swap_symbol_id].settlement_precision;
    }
  },
  data() {
    return {
      coinInfoMap: {},
      multipleNumBtn: false,
      currChainValue: "1",
      buySliderValue: 0,
      multipleNum: 20,
      sellSliderValue: 0,
      marketPrice: "",
      buyPrice: "",
      buyPriceSelf: "",
      sellNumSelf: "",
      sellPrice: "",
      buyNum: "",
      buyAmount: "",
      //
      buyAmountSelf: "",
      //现价买入金额 U
      sellNum: "",
      buyTriggerPrice: "",
      sellTriggerPrice: "",
      number_precision: "",
      settlement_precision: "",
      open_position_rate: "",
      price_precision: "",
      contract_value: "",
      fee_rate: "",
      buyBtnLoading: false,
      sellBtnLoading: false,
      tradingVolume: "",
      // 买入交易量
      tradingVolumeSelf: "",
      // 限价买入交易数量
      transactionAmount: "",
      // 买入交易额
      sellTransactionAmount: "",
      // 卖出交易额
      sellTransactionAmountSelf: "",
      // 限价卖出交易额
      // from_item_id: "",
      // to_item_id: "",
      marks: {
        25: "25%",
        50: "50%",
        75: "75%"
      },
      apiAssetsInfo: {},
      coinInfoList: [],
      tabsOptions: [{
        label: "市价",
        value: 1
      }, {
        label: "限价",
        value: 2
      }
      // {
      //   label: "止盈止损",
      // },
      ],

      inputControl: false
    };
  },
  async created() {
    await this.getStaticTableInfo(58);
    await this.getAssets();
    _SocketDispatcher.default.addEventListener("onPriceClick", this.onPriceClick, this);
  },
  mounted() {},
  methods: {
    routeSplicing: _routeSplicing.default,
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onMultipleNumClick() {
      this.$emit("onMultipleNumClick", this.multipleNum);
    },
    onPriceClick(row) {
      if (this.currChainValue == "2") {
        this.buyPriceSelf = row[0];
        this.onBuyChangeV2("1");
        this.sellPrice = row[0];
        this.onSellNumChange();
      }
    },
    setMultipleNum(value) {
      this.multipleNum = value;
      this.onBuyChangeV2("2");
      this.onSellNumChangeMarket();
      this.onBuyAmountChange(this.buyAmount);
      this.onSellNumChange();
    },
    // 买入滑块变化
    onBuySliderChange(value) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      if (this.currChainValue == "1") {
        if (value && !this.inputControl) {
          // 买入交易额
          this.buyAmount = (value / 100 * this.assetsInfoShow).toFixed(this.settlement_precision);
          let tempMultipleNum = new _bignumber.default(this.multipleNum);
          let tempInput = new _bignumber.default(this.buyAmount);
          let tempContractValue = new _bignumber.default(this.contract_value);
          let tempFeeRate = new _bignumber.default(this.fee_rate);
          let one = new _bignumber.default(1);
          let tenThousand = new _bignumber.default(10000);

          // 开仓手续费 = 保证金 × 杠杆倍数 × 开仓手续费率 open_position_rate

          let bigMarketPrice = new _bignumber.default(JSON.parse(JSON.stringify(this.marketPrice)));
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数

          let tempValV2 = bigMarketPrice.times(tempInput).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
          let tempVal3 = new _bignumber.default(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempVolume = tempVal3.times(tempContractValue).div(bigMarketPrice).toString();
          if (this.settlement_precision > 0) {
            this.tradingVolume = tempVolume.substring(0, tempVolume.indexOf(".") + this.settlement_precision + 1 // +1 保留小数点位数
            );
          } else {
            this.tradingVolume = tempVolume.substring(0, tempVolume.indexOf("."));
          }
        } else if (value == 0 && !this.inputControl) {
          this.buyAmount = "";
          this.tradingVolume = "";
        }
      } else if (this.currChainValue == "2") {
        // buyPriceSelf  限价买入价3 buyAmountSelf 现价买入金额4   tradingVolumeSelf// 现价买入数量6

        if (this.buyPriceSelf != "" && this.buyPriceSelf != 0 && !this.inputControl) {
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数

          // 买入交易额
          let tempPrice = new _bignumber.default(this.buyPriceSelf); // 自定义买入价格
          this.buyAmountSelf = (value / 100 * this.assetsInfoShow).toFixed(this.settlement_precision);
          let tempInput = new _bignumber.default(this.buyAmountSelf);
          let tempFeeRate = new _bignumber.default(this.fee_rate);
          let one = new _bignumber.default(1);
          let tenThousand = new _bignumber.default(10000);
          let tempMultipleNum = new _bignumber.default(this.multipleNum);
          let tempContractValue = new _bignumber.default(this.contract_value);
          let tempValV2 = tempPrice.times(tempInput).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
          let tempVal3 = new _bignumber.default(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempVolumeSelf = tempVal3.times(tempContractValue).div(tempPrice).toString();
          if (this.settlement_precision > 0) {
            this.tradingVolumeSelf = tempVolumeSelf.substring(0, tempVolumeSelf.indexOf(".") + this.settlement_precision + 1); // +1 保留小数点位数
          } else {
            this.tradingVolumeSelf = tempVolumeSelf.substring(0, tempVolumeSelf.indexOf("."));
          }
        }
      }
    },
    // 卖出滑块变化
    onSellSliderChange(value) {
      // sellNum  卖出金额9  sellTransactionAmount  卖出数量10
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      if (this.currChainValue == "1") {
        let tempPrice = new _bignumber.default(this.marketPrice);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        if (value && !this.inputControl) {
          this.sellNum = (value * 0.01 * this.assetsInfoShow).toFixed(this.settlement_precision);
          let tempInput = new _bignumber.default(this.sellNum);
          let tempFeeRate = new _bignumber.default(this.fee_rate);
          let one = new _bignumber.default(1);
          let tenThousand = new _bignumber.default(10000);
          let tempContractValue = new _bignumber.default(this.contract_value);
          let tempValV2 = tempPrice.times(tempInput).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
          let tempVal3 = new _bignumber.default(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempSellAmount = tempVal3.times(tempContractValue).div(tempPrice).toString();
          if (this.settlement_precision > 0) {
            this.sellTransactionAmount = tempSellAmount.substring(0, tempSellAmount.indexOf(".") + this.settlement_precision + 1);
          } else {
            this.sellTransactionAmount = tempSellAmount.substring(0, tempSellAmount.indexOf("."));
          }
        } else if (value == 0 && !this.inputControl) {
          this.sellNum = "";
          this.sellTransactionAmount = "";
        }
      } else if (this.currChainValue == "2") {
        //  sellPrice 卖出价格8 sellNumSelf 卖出金额12  sellTransactionAmountSelf  卖出数量11
        if (this.sellPrice != "" && this.sellPrice != 0) {
          if (value && !this.inputControl) {
            let tempSellPrice = new _bignumber.default(this.sellPrice);
            let tempValue = new _bignumber.default(value);
            let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
            let zeroone = new _bignumber.default(0.01);
            this.sellNumSelf = tempValue.times(zeroone).times(tempAssetsInfoShow).toFixed(this.number_precision);
            // console.log(this.sellNumSelf);
            // this.sellNumSelf = value * 0.01 * this.assetsInfoShow;
            let tempMultipleNum = new _bignumber.default(this.multipleNum);
            let tempInput = new _bignumber.default(this.sellNumSelf);
            let tempFeeRate = new _bignumber.default(this.fee_rate);
            let one = new _bignumber.default(1);
            let tenThousand = new _bignumber.default(10000);
            let tempContractValue = new _bignumber.default(this.contract_value);
            let tempValV2 = tempSellPrice.times(tempInput).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
            let tempVal3 = new _bignumber.default(tempValV2.toNumber());
            // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
            let sellAmountSelf = tempVal3.times(tempContractValue).div(tempSellPrice).toString();
            if (this.settlement_precision > 0) {
              this.sellTransactionAmountSelf = sellAmountSelf.substring(0, sellAmountSelf.indexOf(".") + this.settlement_precision + 1);
            } else {
              this.sellTransactionAmountSelf = sellAmountSelf.substring(0, sellAmountSelf.indexOf("."));
            }
          } else if (value == 0 && !this.inputControl) {
            this.sellNumSelf = "";
            this.sellTransactionAmountSelf = "";
          }
        }
      }
    },
    // 市价交易额变化
    onBuyAmountChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;
      let tempInput = new _bignumber.default(val);
      let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
      let tempVal = tempInput.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      // 买入滑块
      this.buySliderValue = Number(tempVal2.toFixed(0));
      // 买入 预开仓数量
      let tempValV2 = "0";
      let bigMarketPrice = new _bignumber.default(JSON.parse(JSON.stringify(this.marketPrice)));
      if (this.buyAmount == "0") {
        tempValV2 = "0";
      } else if (this.buyAmount != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new _bignumber.default(this.buyAmount);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        let tempContractValue = new _bignumber.default(this.contract_value);
        let tempFeeRate = new _bignumber.default(this.fee_rate);
        let tenThousand = new _bignumber.default(10000);
        let one = new _bignumber.default(1);
        // let tempOpenPositionRate = new BigNumber(
        //   this.open_position_rate / 10000
        // );
        // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
        // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

        tempValV2 = bigMarketPrice.times(tempInput).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
        let tempVal3 = new _bignumber.default(tempValV2.toNumber());
        // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
        let tempVolume = tempVal3.times(tempContractValue).div(bigMarketPrice).toString();
        if (this.number_precision > 0) {
          this.tradingVolume = tempVolume.substring(0, tempVolume.indexOf(".") + this.number_precision + 1 // +1 保留小数点位数
          );
        } else {
          this.tradingVolume = tempVolume.substring(0, tempVolume.indexOf("."));
        }
      } else {
        tempValV2 = "0";
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 市价买入数量变化
    onBuyTotalChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;
      //  开仓本金 = 预计开仓数量 ×  (1 + 手续费率 ×杠杆倍数) / 杠杆倍数

      let tempValV2 = "0";
      if (this.tradingVolume == "0") {
        tempValV2 = "0";
      } else if (this.tradingVolume != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new _bignumber.default(this.tradingVolume);
        let bigMarketInfoShow = new _bignumber.default(JSON.parse(JSON.stringify(this.marketPrice)));
        let tempInput = new _bignumber.default(val);
        let one = new _bignumber.default(1);
        let tenThousand = new _bignumber.default(10000);
        let tempFeeRate = new _bignumber.default(this.fee_rate);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        tempValV2 = tempInput.times(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempMultipleNum);
      } else {
        tempValV2 = "0";
      }
      this.buyAmount = tempValV2.toNumber();
      let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
      let tempBuyAmount = new _bignumber.default(this.buyAmount);
      let tempVal = tempBuyAmount.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(0));

      // 买入滑块
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 限价买入量变化
    onBuyChangeV2(type) {
      // buyPriceSelf  限价买入价3 buyAmountSelf 现价买入金额4   tradingVolumeSelf// 现价买入数量6
      if (this.buyPriceSelf == "" || this.buyPriceSelf == 0 || this.buyAmountSelf == "" || this.buyAmountSelf == 0) {
        this.tradingVolumeSelf = "";
        return;
      }
      if (this.userInfo) {
        this.inputControl = true;
        let tempPrice = new _bignumber.default(this.buyPriceSelf); // 自定义买入价格
        let tempInput = new _bignumber.default(this.buyAmountSelf); // 自定义买入数量
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        let tempValue = tempInput.div(tempAssetsInfoShow).toNumber() * 100;
        this.buySliderValue = Number(tempValue.toFixed(2));
        if (this.buyPriceSelf != "" && this.buyAmountSelf != "") {
          let tempMultipleNum = new _bignumber.default(this.multipleNum);
          let tempContractValue = new _bignumber.default(this.contract_value);
          let tempFeeRate = new _bignumber.default(this.fee_rate);
          let tenThousand = new _bignumber.default(10000);
          let one = new _bignumber.default(1);
          // let tempOpenPositionRate = new BigNumber(
          //   this.open_position_rate / 10000
          // );
          // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
          // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

          let tempValV2 = tempPrice.times(tempInput).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
          let tempVal3 = new _bignumber.default(tempValV2.toNumber());
          // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
          let tempVolumeSelf = tempVal3.times(tempContractValue).div(tempPrice).toString();
          if (this.settlement_precision > 0) {
            this.tradingVolumeSelf = tempVolumeSelf.substring(0, tempVolumeSelf.indexOf(".") + this.settlement_precision + 1); // +1 保留小数点位数
          } else {
            this.tradingVolumeSelf = tempVolumeSelf.substring(0, tempVolumeSelf.indexOf("."));
          }
        }
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onBuyChangeV3() {
      // buyPriceSelf  限价买入价3 buyAmountSelf 现价买入金额4   tradingVolumeSelf// 现价买入数量6
      if (this.tradingVolumeSelf == "" || Number(this.tradingVolumeSelf) == 0) {
        this.inputControl = true;
        this.buySliderValue = 0;
        this.buyAmountSelf = "";
        this.tradingVolumeSelf = "";
        return;
      }
      this.inputControl = true;
      if (this.buyPriceSelf != "") {
        let tempPrice = new _bignumber.default(this.buyPriceSelf); // 自定义买入价格
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow); // 资产

        let tempInput = new _bignumber.default(this.tradingVolumeSelf); // 现价买入数量6
        let one = new _bignumber.default(1);
        let tenThousand = new _bignumber.default(10000);
        let tempFeeRate = new _bignumber.default(this.fee_rate);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        let tempValue = tempInput.times(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempMultipleNum);
        let tempValueV2 = tempValue.div(tempAssetsInfoShow).toNumber() * 100;
        this.buySliderValue = Number(tempValueV2.toFixed(2));
        this.buyAmountSelf = tempValue.toFixed(this.price_precision);
      } else {
        this.buySliderValue = 0;
        this.buyAmountSelf = "";
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 9
    onSellNumChangeMarket() {
      // sellNum  卖出金额9  sellTransactionAmount  卖出数量10
      if (this.userInfo) {
        if (this.sellNum == "") {
          this.sellSliderValue = 0;
          this.sellTransactionAmount = "";
        }
        this.inputControl = true;
        this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempMarketPrice = new _bignumber.default(this.marketPrice);
        let tempSellNum = new _bignumber.default(this.sellNum);
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        let tempContractValue = new _bignumber.default(this.contract_value);
        let tempFeeRate = new _bignumber.default(this.fee_rate);
        let tenThousand = new _bignumber.default(10000);
        let one = new _bignumber.default(1);
        // let tempOpenPositionRate = new BigNumber(
        //   this.open_position_rate / 10000
        // );
        // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
        // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

        let tempValV2 = tempMarketPrice.times(tempSellNum).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
        let tempVal3 = new _bignumber.default(tempValV2.toNumber());
        // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
        let tempSellAmount = tempVal3.times(tempContractValue).div(tempMarketPrice).toString();
        if (this.settlement_precision > 0) {
          this.sellTransactionAmount = tempSellAmount.substring(0, tempSellAmount.indexOf(".") + this.settlement_precision + 1);
        } else {
          this.sellTransactionAmount = tempSellAmount.substring(0, tempSellAmount.indexOf("."));
        }
        let tempValue = tempSellNum.div(tempAssetsInfoShow).toNumber() * 100;
        this.sellSliderValue = Number(tempValue.toFixed(0));
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 8 12
    onSellNumChange() {
      //  sellPrice 卖出价格8 sellNumSelf 卖出金额12  sellTransactionAmountSelf  卖出数量11
      if (this.sellPrice == "" || this.sellPrice == 0 || this.sellNumSelf == "" || this.sellNumSelf == 0) {
        this.sellTransactionAmountSelf = "";
        return;
      }
      if (this.userInfo) {
        this.inputControl = true;
        // this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempMarketPrice = new _bignumber.default(this.sellPrice);
        let tempSellNumSelf = new _bignumber.default(this.sellNumSelf);
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        let tempContractValue = new _bignumber.default(this.contract_value);
        let tempFeeRate = new _bignumber.default(this.fee_rate);
        let tenThousand = new _bignumber.default(10000);
        let one = new _bignumber.default(1);
        // let tempOpenPositionRate = new BigNumber(
        //   this.open_position_rate / 10000
        // );
        // 持仓张数 = 开仓价 × 开仓本金 / (1 + 手续费率 ×杠杆倍数)/单张仓位价值 * 杠杆倍数
        // 持仓张数 = 49504 × 1 / (1 + 10 *20)/100 * 20 =

        let tempValV2 = tempMarketPrice.times(tempSellNumSelf).div(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempContractValue).times(tempMultipleNum);
        let tempVal3 = new _bignumber.default(tempValV2.toNumber());
        // 预计开仓数量  = 持仓张数 * 单张仓位价值 / 开仓价
        let sellAmountSelf = tempVal3.times(tempContractValue).div(tempMarketPrice).toString();
        if (this.settlement_precision > 0) {
          this.sellTransactionAmountSelf = sellAmountSelf.substring(0, sellAmountSelf.indexOf(".") + this.settlement_precision + 1);
        } else {
          this.sellTransactionAmountSelf = sellAmountSelf.substring(0, sellAmountSelf.indexOf("."));
        }
        let tempValue = tempSellNumSelf.div(tempAssetsInfoShow).toNumber() * 100;
        this.sellSliderValue = Number(tempValue.toFixed(0));
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 卖出金额变化  卖出数量11
    onSellNumChangeSelf(val) {
      //  sellPrice 卖出价格8 sellNumSelf 卖出金额12  sellTransactionAmountSelf  卖出数量11
      if (this.sellPrice == "") {
        this.sellNumSelf = "";
        return;
      }
      if (this.userInfo) {
        this.inputControl = true;
        this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempPrice = new _bignumber.default(this.sellPrice);
        let tempInput = new _bignumber.default(val);
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        let tempFeeRate = new _bignumber.default(this.fee_rate);
        let one = new _bignumber.default(1);
        let tenThousand = new _bignumber.default(10000);
        if (val != "") {
          this.sellNumSelf = tempInput.times(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempMultipleNum).toFixed(this.price_precision);
          let tempSellNumSelf = new _bignumber.default(this.sellNumSelf);
          this.sellSliderValue = tempSellNumSelf.div(tempAssetsInfoShow).toNumber() * 100;
        } else {
          this.sellNumSelf = "";
          this.sellSliderValue = 0;
        }
        setTimeout(() => {
          this.inputControl = false;
        }, 1000);
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 卖出金额变化 10
    onSellTotalChange(val) {
      // sellNum  卖出金额9  sellTransactionAmount  卖出数量10
      if (this.userInfo) {
        this.inputControl = true;
        this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let tempMarketPrice = new _bignumber.default(this.marketPrice);
        let tempInput = new _bignumber.default(val);
        let tempMultipleNum = new _bignumber.default(this.multipleNum);
        let one = new _bignumber.default(1);
        let tenThousand = new _bignumber.default(10000);
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
        if (val != "") {
          this.sellNum = tempInput.times(one.plus(tempFeeRate.div(tenThousand).times(tempMultipleNum))).div(tempMultipleNum).toFixed(this.settlement_precision);
          let tempSellNum = new _bignumber.default(this.sellNum);
          this.sellSliderValue = tempSellNum.div(tempAssetsInfoShow).toNumber() * 100;
        } else {
          this.sellNum = "";
          this.sellSliderValue = 0;
        }
        setTimeout(() => {
          this.inputControl = false;
        }, 1000);
      }
    },
    formatTooltip(val) {
      return val + "%";
    },
    onTrade(type) {
      // 1买入 2卖出
      if (type == 1) {
        if (this.currChainValue == "1") {
          if (this.buyAmount == "" || Number(this.buyAmount == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入交易额"),
              type: "error"
            });
            return;
          }
          if (Number(this.buyAmount) > Number(this.assetsInfoShow)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.buyBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "1",
            //开仓方向 1 买涨 2 买跌
            consumer_costs: this.buyAmount,
            // 购买数量
            market_price: this.marketInfoShow.last // 购买数量
          };

          (0, _order.apiOpenPosition)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.buyBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "1");
            } else {
              this.buyBtnLoading = false;
            }
          });
        } else if (this.currChainValue == "2") {
          if (this.buyPriceSelf == "" || Number(this.buyPriceSelf == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入买入价格"),
              type: "error"
            });
            return;
          }
          if (this.buyAmountSelf == "" || Number(this.buyAmountSelf == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入买入量"),
              type: "error"
            });
            return;
          }
          if (Number(this.buyAmountSelf) > Number(this.assetsInfoShow)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.buyBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "1",
            //开仓方向 1 买涨 2 买跌
            consumer_costs: this.buyAmountSelf,
            // 购买数量
            trigger_price: this.buyPriceSelf
          };
          (0, _order.apiPlanOrder)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.buyBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "2");
            } else {
              this.buyBtnLoading = false;
            }
          });
        }
      } else {
        // 2 卖出
        if (this.currChainValue == "1") {
          if (this.sellNum == "" || Number(this.sellNum == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入卖出量"),
              type: "error"
            });
            return;
          }
          if (Number(this.sellNum) > Number(this.assetsInfoShow)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.sellBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "2",
            //开仓方向 1 买涨 2 买跌
            bail_number: this.sellNum,
            // 购买数量
            consumer_costs: this.sellNum,
            market_price: this.marketInfoShow.last // 购买数量
          };

          (0, _order.apiOpenPosition)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.sellBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "1");
            } else {
              this.sellBtnLoading = false;
            }
          });
        } else if (this.currChainValue == "2") {
          if (this.sellPrice == "" || Number(this.sellPrice == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入卖出价"),
              type: "error"
            });
            return;
          }
          if (this.sellNumSelf == "" || Number(this.sellNumSelf == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入卖出量"),
              type: "error"
            });
            return;
          }
          if (Number(this.sellNumSelf) > Number(this.assetsInfoShow)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.sellBtnLoading = true;
          let params = {
            leverage: this.multipleNum,
            symbol_id: this.swap_symbol_id,
            open_side: "2",
            //开仓方向 1 买涨 2 买跌
            bail_number: this.sellNumSelf,
            // 购买数量
            consumer_costs: this.sellNumSelf,
            trigger_price: this.sellPrice
          };
          (0, _order.apiPlanOrder)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.sellBtnLoading = false;
              this.reSetData();
              this.$emit("on-trade-success", "2");
            } else {
              this.sellBtnLoading = false;
            }
          });
        }
      }
    },
    goLogin(type) {
      if (type == 1) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      } else {
        this.$router.push(this.routeSplicing(this.language, "register"));
      }
    },
    onChainChange(value) {
      this.inputControl = true;
      this.currChainValue = value;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPriceSelf = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellTransactionAmountSelf = "";
      this.sellNumSelf = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.tradingVolume = "";
      this.tradingVolumeSelf = "";
      this.transactionAmount = "";
      this.sellTransactionAmount = "";
      this.buyAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    reSetData() {
      this.inputControl = true;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.buyNum = "";
      this.sellTransactionAmountSelf = "";
      this.buyPriceSelf = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellNumSelf = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.tradingVolume = "";
      this.tradingVolumeSelf = "";
      this.transactionAmount = "";
      this.sellTransactionAmount = "";
      this.buyAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    async getStaticTableInfo(sv_id) {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(sv_id);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
        if (this.swap_symbol_id) {
          this.number_precision = this.coinInfoMap[this.swap_symbol_id].number_precision;
          this.open_position_rate = this.coinInfoMap[this.swap_symbol_id].open_position_rate;
          this.price_precision = this.coinInfoMap[this.swap_symbol_id].price_precision;
          this.contract_value = this.coinInfoMap[this.swap_symbol_id].contract_value;
          this.fee_rate = this.coinInfoMap[this.swap_symbol_id].fee_rate;
          this.settlement_precision = this.coinInfoMap[this.swap_symbol_id].settlement_precision;
        }
      }
    }
  }
};
exports.default = _default;