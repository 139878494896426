"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _getTime = require("@/assets/js/getTime");
var _SocketDispatcher = _interopRequireDefault(require("@/assets/js/SocketDispatcher"));
var _settings = require("@/model/settings");
var _keymap = require("@/assets/js/keymap.js");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _vuex = require("vuex");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: {
    depthList: {
      type: Object,
      default: function () {
        return {
          a: [],
          b: []
        };
      }
    },
    swap_symbol_id: {
      type: [String, Number]
    },
    showTitle: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    from_symbol: {
      type: [String, Number],
      default: function () {
        return "--";
      }
    },
    to_symbol: {
      type: [String, Number],
      default: function () {
        return "--";
      }
    }
  },
  data() {
    return {
      systemCurrencyMaps: _keymap.systemCurrencyMaps,
      pageLoading: false,
      showBg: false,
      colorClass: "",
      price_precision: 2,
      precisionValue: "0.01",
      activeItem: 1,
      deptLength: 13,
      options: [],
      tradePair: {}
    };
  },
  async created() {
    await this.getTradePairList();
    let value = sessionStorage.getItem("dept-item-active");
    if (value) {
      this.onItemClick(value);
    }
    for (const key in this.tradePair) {
      if (this.tradePair[key].symbol_id == this.swap_symbol_id) {
        this.price_precision = this.tradePair[key].price_precision;
      }
    }
    this.options = [{
      value: new _bignumber.default(1).div(new _bignumber.default(Math.pow(10, this.price_precision))).toNumber()
    }, {
      value: 1 / Math.pow(10, this.price_precision) * 10
    }, {
      value: 1 / Math.pow(10, this.price_precision) * 100
    }];
  },
  mounted() {},
  watch: {
    swap_symbol_id() {
      this.showBg = false;
      for (const key in this.tradePair) {
        if (this.tradePair[key].symbol_id == this.swap_symbol_id) {
          this.price_precision = this.tradePair[key].price_precision;
        }
      }
      setTimeout(() => {
        this.showBg = true;
      }, 300);
      // this.tradePair.forEach((ele) => {
      //   if (ele.swap_symbol_id == this.swap_symbol_id) {
      //     this.price_precision = ele.price_precision;
      //   }
      // });
    },

    marketInfoShow: {
      handler(newValue, oldValue) {
        if (Number(newValue.last) - Number(oldValue.last) > 0) {
          this.colorClass = "green";
        } else if (Number(newValue.last) - Number(oldValue.last) < 0) {
          this.colorClass = "red";
        } else {
          this.colorClass = "";
        }
      },
      deep: true
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    getNowTime: _getTime.getNowTime,
    onMoreClick() {
      //   this.$router.push({
      //     // path: "/delivery-orderbook/",
      //     path: this.routeSplicing(this.language, "delivery-orderbook"),
      //     query: {
      //       type: "exchange",
      //       symbol: `${this.from_symbol}${this.to_symbol}`,
      //     },
      //   });
      let routeUrl = this.$router.resolve({
        path: "/delivery-orderbook/",
        query: {
          type: "exchange",
          symbol: `${this.from_symbol}${this.to_symbol}`
        }
      });
      window.location.href = routeUrl.href;
    },
    async getTradePairList() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(58);
      if (status == 200) {
        this.tradePair = data;
      }
    },
    ceilNum(d, prex = 2) {
      if (parseInt(d) == d) return d;
      let len = 1;
      let i = 0;
      while (parseInt(d) != d) {
        d = d * 10;
        len *= 10;
        i++;
      }
      let last = Math.abs(i <= prex ? 0 : d % Math.pow(10, i - prex));
      let d1 = d - last;
      if (last > 0) {
        d1 = d1 + Math.pow(10, i - prex);
      }
      d1 = d1 / len;
      return d1;
    },
    onPrecisionChange(value) {
      this.precisionValue = value;
    },
    onItemClick(value) {
      this.activeItem = value;
      sessionStorage.setItem("dept-item-active", value);
      if (value == 2) {
        this.deptLength = 26;
      } else {
        this.deptLength = 13;
      }
    },
    onRowClick(row) {
      _SocketDispatcher.default.dispatchEvent("onPriceClick", row);
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    sellContentClass() {
      let className = "";
      if (this.activeItem == 1) {
        className = "";
      } else if (this.activeItem == 2) {
        className = "all";
      } else if (this.activeItem == 3) {
        className = "none";
      }
      return className;
    },
    buyContentClass() {
      let className = "";
      if (this.activeItem == 1) {
        className = "";
      } else if (this.activeItem == 2) {
        className = "none";
      } else if (this.activeItem == 3) {
        className = "all";
      }
      return className;
    },
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    loading() {
      let val = 1;
      if (this.depthList instanceof Array && this.depthList.a[0][0] == "--") {
        val = 1;
      } else {
        val = 0;
      }
      return val;
    },
    bigBuy() {
      let tempList = this.depthList.a.slice(0, this.deptLength);
      let temp = Number(this.depthList.a[0][1]);
      for (let i = 0; i < tempList.length - 1; i++) {
        temp = temp < Number(tempList[i + 1][1]) ? Number(tempList[i + 1][1]) : temp;
      }
      return temp;
    },
    bigSell() {
      let tempList = this.depthList.b.slice(0, this.deptLength);
      let temp = Number(this.depthList.b[0][1]);
      for (let i = 0; i < tempList.length - 1; i++) {
        temp = temp < Number(tempList[i + 1][1]) ? Number(tempList[i + 1][1]) : temp;
      }
      return temp;
    },
    totleBuy() {
      let temp = 0;
      this.depthList.a.slice(0, this.deptLength).forEach(ele => {
        temp += Number(ele[1]);
      });
      return temp;
    },
    totleSell() {
      let temp = 0;
      this.depthList.b.slice(0, this.deptLength).forEach(ele => {
        temp += Number(ele[1]);
      });
      return temp;
    },
    // a卖  b买
    showListSell() {
      let array = this.depthList.a.slice(0, this.deptLength);
      for (let i = 0; i < array.length / 2; i++) {
        let temp = array[i];
        array[i] = array[array.length - 1 - i];
        array[array.length - 1 - i] = temp;
      }
      return array;
    },
    showListBuy() {
      return this.depthList.b;
    },
    marketInfoShow() {
      let marketInfo = {
        last: "--",
        currency: {
          cny: "--"
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.price_change_percent < 0) {
        className = "red";
      }
      return className;
    }
  }
};
exports.default = _default;