"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _elButton = _interopRequireDefault(require("./elButton"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["title", "desc", "isMobile", "btnText", "btnTColor", "btnRight", "btnBColor", "fieldVal", "disabled", "googleVerifyStatus"],
  methods: {
    settingBtnClick() {
      this.$emit("settingBtnClick");
    },
    btnRightClick() {
      this.$emit("btnRightClick");
    }
  },
  computed: {
    /* email() {
      if (this.accountInfo) {
        let { email } = this.accountInfo;
        return email;
      }
    },
    mobile() {
      if (this.accountInfo) {
        let { mobile } = this.accountInfo;
        return mobile;
      }
    } */
  },
  components: {
    GButton: _elButton.default
  }
};
exports.default = _default;