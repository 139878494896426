"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiMarketTrend = apiMarketTrend;
exports.apiMetaverseExpress = apiMetaverseExpress;
exports.apiMetaverseTrend = apiMetaverseTrend;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
//元宇宙市场趋势
function apiMarketTrend(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/Metaverse/marketTrend`,
    data
  });
}
//元宇宙元宇宙趋势
function apiMetaverseTrend(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/Metaverse/trend`,
    data
  });
}
//元宇宙元宇宙趋势
function apiMetaverseExpress(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/Metaverse/express`,
    data
  });
}