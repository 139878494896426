"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _i18n = require("@/assets/js/i18n");
var _vuex = require("vuex");
var _finance = require("@/model/finance/finance");
var _index = require("@/model/exchange/index");
var _keymap = require("@/assets/js/keymap.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _formatDate = require("@/assets/js/formatDate");
var _financialDialog = _interopRequireDefault(require("./components/financial-management/financial-dialog"));
var _confirmDialog = _interopRequireDefault(require("./components/financial-management/confirm-dialog.vue"));
var _stationSetting = require("@/assets/js/stationSetting");
var _countdown = _interopRequireDefault(require("@/components/common/countdown.vue"));
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _digital = require("@/assets/js/digital");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Countdown2 from "@choujiaojiao/vue2-countdown";
// import Countdown from "vue2-countdown";
// import countdown from "@components/common/countdown.vue";
var _default = {
  mixins: [_commonMixin.default],
  name: "finances-index",
  components: {
    NoData: _NoData.default,
    // Countdown,
    // Countdown2,
    Countdown: _countdown.default,
    FinancialDialog: _financialDialog.default,
    ConfirmDialogContent: _confirmDialog.default
  },
  data() {
    return {
      quantTagMaps: _keymap.quantTagMaps,
      financialStatusMaps: _keymap.financialStatusMaps,
      financialStatusMapsTwo: _keymap.financialStatusMapsTwo,
      buyNumber: "",
      voucherInterval: null,
      rightForm: {},
      dataList: [],
      productTypeList: [],
      confirmDialogTitle: this.translateTitle(`量化理财`),
      listType: [{
        text: "全部",
        val: 0
      }
      // { text: "量化理财", val: 3 },
      // { text: "活期", val: 2 },
      // { text: "定期", val: 1 },
      // { text: "汇信池", val: 4 },
      ],

      active: 0,
      rate1: 1,
      rate2: 1,
      currTab: 1,
      pageLoading: true,
      financialDialogVisible: false,
      confirmDialogVisible: false,
      successDialogVisible: false,
      productId: "",
      apiAssetsInfo: "",
      productInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 4,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "--",
        max_fluctuation_annual_rate: "--",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 0,
        stop_time: 0,
        lock_period: "--",
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: "--",
        max_amount: "--",
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url: "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang: "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33"
        },
        item_name: "USDT",
        item_url: require("../../assets/img/holder-coin-ico.png"),
        to_item_url: require("../../assets/img/holder-coin-ico.png"),
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    }),
    heightClass() {
      return "active" + this.active;
    }
  },
  async created() {
    await this.getProductlist(1);
    await this.getAssets();
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  async mounted() {
    if (this.$route.query.relation_id && this.$route.query.relation_id != 0) {
      let params = {
        product_id: this.$route.query.relation_id
      };
      const {
        status,
        data
      } = await (0, _finance.apiGetProductInfo)(params);
      if (status == 200) {
        this.onTypeChange(data.productInfo.product_type);
        this.productId = this.$route.query.relation_id;
        this.financialDialogVisible = true;
      }

      // apiGetProductInfo(params).then((res) => {
      //   if (res.status == 200) {
      //     // this.onTypeChange(res.data.productInfo.product_type);
      //     // this.productId = this.$route.query.relation_id;
      //     // this.financialDialogVisible = true;
      //   }
      // });
    }

    var meta = null;
    var title = null;
    if (_stationSetting.VUE_UNION_STATION == "bitda") {
      title = document.getElementsByTagName("title");
      title[0].innerHTML = "金融理財|幣達交易所";
      meta = document.getElementsByTagName("meta");
      $("meta[property='og:description']").attr("content", "幣達加密貨幣金融理財產品，其中包括風險低、利率固定、期限靈活、也有年化13.6%的高收益理財商品，令您的投資更得心應手，是您收益的好夥伴，不容錯過！");
      meta["title"].setAttribute("content", "金融理財|幣達交易所");
      meta["description"].setAttribute("content", "幣達加密貨幣金融理財產品，其中包括風險低、利率固定、期限靈活、也有年化13.6%的高收益理財商品，令您的投資更得心應手，是您收益的好夥伴，不容錯過！");
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    timestampToDate: _formatDate.timestampToDate,
    translateTitle: _i18n.translateTitle,
    remainingPercent(remaining, amount) {
      let tempVal = (0, _digital.floorNumber)(remaining * 100 / amount, 2);
      return tempVal;
    },
    onLockPeriodChange(prod, item) {
      item.active_lock_period_id = prod.product_id;
      item.active_annual_rate = prod.annual_rate;
    },
    goToMyHolder() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      } else {
        this.$router.push(this.routeSplicing(this.language, "ucenter/finanManagement"));
      }
    },
    annualRateComputed(annual_rate) {
      let tempRate = new _bignumber.default(annual_rate);
      let time = new _bignumber.default(100);
      return tempRate.times(time).toString();
    },
    onCountDownEnd() {
      this.getProductlist();
      // setTimeout(() => {
      //   this.getProductlist();
      // }, 1500);
    },

    onProductInfoChange(productInfo) {
      this.productInfo = productInfo;
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "remain"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        let tempDate = data.remain.list;
        tempDate.forEach(ele => {
          if (ele.item_id == 10007) {
            this.apiAssetsInfo = ele.over;
          }
        });
      }
    },
    onDialogClose() {
      this.financialDialogVisible = false;
    },
    onConfirm() {
      this.confirmDialogVisible = true;
    },
    onConfirmCancel() {
      this.confirmDialogVisible = false;
    },
    onSuccess() {
      this.successDialogVisible = false;
      this.getProductlist();
    },
    onConfirmOK() {
      this.confirmDialogVisible = false;
      this.financialDialogVisible = false;
      this.successDialogVisible = true;
    },
    onClickAll() {
      this.buyNumber = this.apiAssetsInfo;
    },
    // 选项卡切换
    onTypeChange(val) {
      this.active = val;
      if (val == 3) {
        this.confirmDialogTitle = this.translateTitle(`量化理财`);
      } else if (val == 2) {
        this.confirmDialogTitle = this.translateTitle(`活期存入`);
      } else if (val == 1) {
        this.confirmDialogTitle = this.translateTitle(`定期存入`);
      } else if (val == 4) {
        if (_stationSetting.VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w" || _stationSetting.VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T") {
          // 汇信
          this.confirmDialogTitle = this.translateTitle(`汇信池`);
        } else {
          this.confirmDialogTitle = "Launchpad";
        }
      }
      this.getProductlist();
    },
    async goToBuy(item) {
      if (item.union_info.length > 0) {
        this.productId = item.active_lock_period_id;
      } else {
        this.productId = item.product_id;
      }
      this.financialDialogVisible = true;
    },
    goToUseCenter() {
      this.$router.push({
        path: this.routeSplicing(this.language, "ucenter/finanManagement")
      });
    },
    getProductlist(timer) {
      let tempProductType = 0;
      if (this.active == 3) {
        tempProductType = 3;
      } else if (this.active == 2) {
        tempProductType = 2;
      } else if (this.active == 1) {
        tempProductType = 1;
      } else if (this.active == 4) {
        tempProductType = 4;
      }
      let params = {
        item_type: 1,
        //货币类型，1为数字货币，2为法币
        product_type: tempProductType,
        //产品类型，1定期 2活期 3量化 4汇信池，-1为全部产品列表
        page: 1,
        page_size: 100
      };
      this.pageLoading = true;
      this.dataList = [];
      (0, _finance.apiGetProductListV2)(params).then(res => {
        if (res.status == 200) {
          let tempList = res.data.list;
          const now = Date.parse(new Date());
          tempList.forEach(ele => {
            this.productTypeList.push(ele.product_type);
            ele.to_item_id_price = Number(ele.to_item_id_price);
            if (ele.to_item_url == "") {
              ele.to_item_url = require("../../assets/img/holder-coin-ico.png");
            }
            if (ele.stop_time != 0 && now < ele.stop_time * 1000 && ele.stop_time * 1000 - now < 86400000) {
              ele.remain_end_time = ele.stop_time * 1000 - now;
            }
            if (ele.start_time != 0) {
              ele.remain_time = ele.start_time * 1000 - now;
            } else {
              ele.timeCount = 0;
            }
            if (ele.union_info.length > 0) {
              ele.active_lock_period_id = ele.union_info[0].product_id;
              ele.active_annual_rate = ele.union_info[0].annual_rate;
            }
          });
          if (timer) {
            if (this.productTypeList.indexOf(3) > -1) {
              this.listType.push({
                text: "量化理财",
                val: 3
              });
            }
            if (this.productTypeList.indexOf(2) > -1) {
              this.listType.push({
                text: "活期",
                val: 2
              });
            }
            if (this.productTypeList.indexOf(1) > -1) {
              this.listType.push({
                text: "定期",
                val: 1
              });
            }
            if (this.productTypeList.indexOf(4) > -1) {
              if (_stationSetting.VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w" || _stationSetting.VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T") {
                // 汇信
                this.listType.push({
                  text: "汇信池",
                  val: 4
                });
              } else {
                this.listType.push({
                  text: "Launchpad",
                  val: 4
                });
              }
            }
          }
          this.dataList = tempList;
        }
        this.pageLoading = false;
      });
    }
  }
};
exports.default = _default;