"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _couponCenter = require("@/model/ucenter/couponCenter");
var _i18n = require("@/assets/js/i18n");
var _settings = require("@/model/settings");
var _vuex = require("vuex");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _formatDate = require("@/assets/js/formatDate");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    NoData: _NoData.default
  },
  data() {
    return {
      currIndex: 1,
      tableLoading: true,
      dataList: [],
      coinInfoMap: {},
      tabList: [{
        value: "1",
        label: "全部"
      }, {
        value: "2",
        label: "理财体验劵"
      }, {
        value: "3",
        label: "已失效"
      }]
    };
  },
  async created() {
    await this.getCoinInfoMap();
    this.getUserVoucherList();
  },
  computed: {
    dataListComputed() {
      let tempArr = [];
      if (this.currIndex == 1) {
        // tempArr = this.dataList;
        tempArr = this.dataList.filter(ele => {
          return ele.voucher_type == 1;
        });
      } else if (this.currIndex == 2) {
        tempArr = this.dataList.filter(ele => {
          return ele.status == 1 && ele.voucher_type == 1;
        });
      } else if (this.currIndex == 3) {
        tempArr = this.dataList.filter(ele => {
          return (ele.status == 2 || ele.status == 3) && ele.voucher_type == 1;
        });
      }
      return tempArr;
    },
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    timestamp: _formatDate.timestamp,
    translateTitle: _i18n.translateTitle,
    goToUse(item) {
      this.$router.push({
        // path: "/finances",
        path: this.routeSplicing(this.language, `finances`),
        query: {
          relation_id: item.relation_id
        }
      });
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    async getCoinInfoMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.coinInfoMap = data;
      }
    },
    getUserVoucherList() {
      let params = {
        voucher_type: 0
      };
      this.tableLoading = true;
      (0, _couponCenter.apiGetUserVoucherList)().then(res => {
        if (res.status == 200) {
          let tempArr = res.data.list;
          tempArr.forEach(ele => {
            for (const key in this.coinInfoMap) {
              if (ele.item_id == key) {
                ele.item_name = this.coinInfoMap[key].item_name.toUpperCase();
              }
            }
          });
          this.tableLoading = false;
          this.dataList = tempArr;
        }
      });
    }
  }
};
exports.default = _default;