"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _vueAwesomeSwiper = require("vue-awesome-swiper");
var _settings = require("@/model/settings");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _index = require("@/model/metaverse/index");
var _vuex = require("vuex");
var _index2 = require("@/model/exchange/index");
var _market = require("@/model/contractExchange/market");
var _keymap = require("@/assets/js/keymap.js");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _stationSetting = require("@/assets/js/stationSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var vm = null;
var _default = {
  mixins: [_commonMixin.default],
  components: {
    swiper: _vueAwesomeSwiper.swiper,
    swiperSlide: _vueAwesomeSwiper.swiperSlide,
    NoData: _NoData.default
  },
  data() {
    return {
      tt: {},
      //WS延时触发器
      lockReconnect: false,
      //避免重复连接
      wsContentTime: 0,
      detailsUrl: "",
      systemCurrencyMaps: _keymap.systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      // realIndex:0,
      isHuiXin: false,
      is8V: false,
      isUeasy: false,
      isS6: false,
      sideLen: 3,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      showBanner: true,
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",
      headerForm: [],
      metaCoinMaps: {},
      metaCoinMapsV2: {},
      firstArticles: {},
      secondArticles: {},
      marketTrendObj: {
        usd_market_cap: "--",
        usd_market_cap_change_24h: "--",
        usd_trading_volume: "--",
        usd_trading_volume_change_24h: "--",
        gainer_amount: "1",
        loser_amount: "1"
      },
      swiperOption: {
        initialSlide: 0,
        // //循环
        loop: true,
        // slidesPerView: "auto",
        slidesPerView: 3,
        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev"
        },
        on: {
          click: function () {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this); // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            const realIndex = this.realIndex;
            // vm.handleClickSlide(realIndex);
          }
        },

        spaceBetween: 24,
        loopedSlides: 3,
        slidesPerGroup: 3,
        loopFillGroupWithBlank: true,
        infinite: 1,
        // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000
        },
        autoplayDisableOnInteraction: false

        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },

      imgList: [],
      tempMarkList: [],
      articlesList: [],
      // tableDataComputed: [],
      exchangeCoinMap: {},
      metaExchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      tableData: [],
      contractTableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      timer: null
    };
  },
  async created() {
    vm = this;
    this.initURL();
    this.getMetaverseTrend();
    await this.getStaticTableInfo();
    await this.getExchangeCoinMap();
    await this.getExchangeCoinMapV2();
    this.getMarketTrend();
    this.getMetaverseExpress();
    this.getTradePairList();
    this.getContractList();
    this.getMetaverseExpress();
    this.initWS();
  },
  mounted() {
    let me = this;
    if (this.language == "zh_CN" || this.language == "zh_HK") {
      let titleTag = document.getElementById("pageTitle");
      titleTag.innerHTML = "元界 - 数字加密货币全球数字资产交易所";
      let metaTag = document.getElementById("description");
      metaTag.name = "description";
      metaTag.content = "元界 - 数字加密货币全球数字资产交易所";
      let metaTag2 = document.getElementById("keywords");
      metaTag2.name = "keywords";
      metaTag2.content = "加密货币,加密数字货币,数字加密货币";
    } else {
      let titleTag = document.getElementById("pageTitle");
      titleTag.innerHTML = "Metaverse - Cryptocurrency global digital asset exchange";
      let metaTag = document.getElementById("description");
      metaTag.name = "description";
      metaTag.content = "Metaverse - Cryptocurrency global digital asset exchange";
      let metaTag2 = document.getElementById("keywords");
      metaTag2.name = "keywords";
      metaTag2.content = "cryptocurrency, encrypted digital currency, digital cryptocurrency";
    }
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = _jsCookie.default.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    tableDataComputed() {
      let tempArr = this.tableData;
      if (this.activeName == 1) {
        tempArr = this.tableData;
      } else {
        tempArr = this.contractTableData;
      }
      return tempArr;
    }
  },
  watch: {
    // activeName(newValue) {
    //   if (newValue == 1) {
    //     this.tableDataComputed = this.tableData;
    //   } else {
    //     this.tableDataComputed = this.contractTableData;
    //   }
    // },
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    mounted() {
      window.USDT_WS.sub({
        action: "Topic.sub",
        data: {
          type: "contractM",
          app_id: _stationSetting.VUE_APP_ID
        }
      });
    },
    initWS() {
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: _stationSetting.VUE_APP_ID
          }
        },
        callback: this.onResponse,
        mounted: this.mounted,
        onConnect
      };
      window.USDT_WS = this.ws = new _socket.default(option).getInstance();
      me.global.setWs(this.ws);
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
    },
    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;
        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach(ele => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name.split("_")[0].toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name.split("_")[1].toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        this.tableData = this.tableData.filter(ele => {
          return ele.from_symbol != undefined;
        });
      } else if (data.action == "Pushdata.contractM") {
        let tempArr = data.data;
        let tempMarkList = [];
        tempArr.forEach(ele => {
          ele.lastV2 = Number(ele.last);
          ele.currency.usdV2 = Number(ele.currency.usd);
          ele.price_change_percentV2 = Number(ele.price_change_percent);
          for (const key in this.metaExchangeCoinMap) {
            if (ele.symbol_id == key) {
              ele["from_symbol"] = this.metaExchangeCoinMap[key].name.split("_")[0].toUpperCase();
              ele["to_symbol"] = this.metaExchangeCoinMap[key].name.split("_")[1].toUpperCase();
              ele["info"] = this.metaExchangeCoinMap[key];
              ele["web_recommend"] = this.metaExchangeCoinMap[key].web_recommend;
              tempMarkList.push(ele);
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        this.tableLoading = false;
        this.contractTableData = tempMarkList.filter(ele => {
          return ele.from_symbol != undefined;
        });
      }
    },
    handleClick(tab, event) {
      console.log(tab);
    },
    className(key) {
      let className = "";
      if (!this.headerForm[key].usd_price_change_24h) {
        className = "";
      } else if (this.headerForm[key].usd_price_change_24h > 0) {
        className = "green";
      } else if (this.headerForm[key].usd_price_change_24h < 0) {
        className = "red";
      }
      return className;
    },
    getMarketTrend() {
      (0, _index.apiMarketTrend)().then(res => {
        if (res.status == 200) {
          this.marketTrendObj = res.data;
        }
      });
    },
    getMetaverseTrend() {
      (0, _index.apiMetaverseTrend)().then(res => {
        if (res.status == 200) {
          this.headerForm = res.data;
        }
      });
    },
    jumpToUrl(url) {},
    jumpToUrlV2(item) {
      window.open(item.content_rul);
    },
    async getExchangeCoinMap() {
      await (0, _settings.apiGetStaticTableInfo)(69).then(({
        data
      }) => {
        this.metaCoinMaps = data;
      });
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(55);
      if (status == 200) {
        let tempObj = {};
        let tempObjV2 = {};
        for (const key in data) {
          if (data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        for (const key in tempObj) {
          for (const index in this.metaCoinMaps) {
            if (tempObj[key].symbol_id == this.metaCoinMaps[index].symbol_id) {
              tempObjV2[key] = tempObj[key];
            }
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObjV2));
      }
    },
    async getExchangeCoinMapV2() {
      await (0, _settings.apiGetStaticTableInfo)(70).then(({
        data
      }) => {
        this.metaCoinMapsV2 = data;
      });
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(49);
      if (status == 200) {
        let tempObj = {};
        let tempObjV2 = {};
        for (const key in data) {
          if (data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        for (const key in tempObj) {
          for (const index in this.metaCoinMapsV2) {
            if (tempObj[key].symbol_id == this.metaCoinMapsV2[index].symbol_id) {
              tempObjV2[key] = tempObj[key];
            }
          }
        }
        this.metaExchangeCoinMap = JSON.parse(JSON.stringify(tempObjV2));
      }
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
      };
      const {
        status,
        data
      } = await (0, _index2.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    getTradePairList() {
      (0, _index2.apiGetSpotList)().then(res => {
        if (res.status == 200) {
          let tempArr = res.data;
          let tempMarkList = [];
          tempArr.forEach(ele => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name.split("_")[0].toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name.split("_")[1].toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          this.tableData = tempMarkList.filter(ele => {
            return ele.from_symbol != undefined;
          });
          // this.tableDataComputed = this.tableData;
        }
      });
    },

    getContractList() {
      (0, _market.apiGetContractList)().then(res => {
        if (res.status == 200) {
          let tempArr = res.data;
          let tempMarkList = [];
          tempArr.forEach(ele => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.metaExchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.metaExchangeCoinMap[key].name.split("_")[0].toUpperCase();
                ele["to_symbol"] = this.metaExchangeCoinMap[key].name.split("_")[1].toUpperCase();
                ele["info"] = this.metaExchangeCoinMap[key];
                ele["web_recommend"] = this.metaExchangeCoinMap[key].web_recommend;
                tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          this.contractTableData = tempMarkList.filter(ele => {
            return ele.from_symbol != undefined;
          });
          // this.contractTableData = [...this.tableDataComputed, ...temp];
        }
      });
    },

    onSymbolBlockClick(from_symbol, to_symbol) {
      let tempUrl = this.routeSplicing(this.language, `exchange/${from_symbol.toLowerCase()}_${to_symbol.toLowerCase()}`);
      window.open(tempUrl);
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onLookMarket() {
      this.$router.push(this.routeSplicing(this.language, `markets`));
    },
    async getMetaverseExpress() {
      const {
        status,
        data
      } = await (0, _index.apiMetaverseExpress)();
      if (status == 200) {
        let len = data.length;
        // if (len <= 4) {
        //   data.forEach((ele, index) => {
        //     this.oldImgList[index] = ele;
        //   });
        // } else {
        //   }
        this.imgList = data;
        this.showBanner = true;
        this.sideLen = len >= 3 ? 3 : len;
      }
    },
    async getStaticTableInfo() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(1);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },
    onSymbolClick(row) {
      let tempUrl = "";
      if (this.activeName == 1) {
        tempUrl = `/exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`;
      } else {
        tempUrl = `/contract-exchange/${row.from_symbol.toLowerCase()}${row.to_symbol.toLowerCase()}`;
      }
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      let tempUrl = "";
      if (this.activeName == 1) {
        tempUrl = `/exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`;
      } else {
        tempUrl = `/contract-exchange/${row.from_symbol.toLowerCase()}${row.to_symbol.toLowerCase()}`;
      }
      window.open(tempUrl);
    }
  }
};
exports.default = _default;