<template>
  <section class="web-first-section">
    <div class="background-image">
      <!-- Replace with your actual background image -->
      <div class="image-container">
        <img
          src="@/assets/img/maskexcard/2d884caa633c469ca3256071ebbd674f.png"
          class="background-image"
          alt="Background Image"
        />
        <img
          src="@/assets/img/maskexcard/408ff219b420be869f5c045bf3aa4438.png"
          class="overlay-image1"
          alt="Overlay Image"
        />
        <img
          src="@/assets/img/maskexcard/471ef7cbe3a373d87c8f96500ddf1cb1.png"
          class="overlay-image2"
          alt="Overlay Image"
        />
        <img
          src="@/assets/img/maskexcard/e0bef3dd18ad091ac85413f98a0eec1c.png"
          class="overlay-image3"
          alt="Overlay Image"
        />
        <img
          src="@/assets/img/maskexcard/a277e2e773b04321380845a86503eb75.png"
          class="overlay-image4"
          alt="Overlay Image"
        />
      </div>
    </div>
    <div class="content">
      <div class="labels">
        <div class="label">
          <h2 class="label-text1">MaskEX Virtual Card</h2>
        </div>
        <div class="label">
          <h2 class="label-text2">Crypto in the Fast Lane</h2>
        </div>
        <div class="label">
          <h2 class="label-text3">
            Kick-start your Crypto Adventure with the MaskEX Virtual Card — your
            go-to and handy aid for spending crypto.
          </h2>
        </div>
      </div>
      <button class="section-button">
        <div class="section-button-text">Register Now</div>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  // First section component logic
};
</script>

<style scoped>
/* First section styles */
.web-first-section {
  width: 100%;
  height: 720px;
  position: relative;
}

/* .background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
.image-container {
  position: relative;
  width: 100%;
  height: 720px;
}
.background-image {
  width: 100%;
  height: 720px;
  flex-shrink: 0;
  /* object-fit: cover; */
}

.overlay-image1 {
  position: absolute;
  width: 625.425px;
  height: 604.552px;
  transform: rotate(0deg);
  flex-shrink: 0;
  top: 116px;
  left: 860px;
}

.overlay-image2 {
  position: absolute;
  width: 84.613px;
  height: 96.283px;
  transform: rotate(93.639deg);
  flex-shrink: 0;
  top: 180px;
  left: 1073px;
  transform: rotate(93.639deg);
}

.overlay-image3 {
  position: absolute;
  width: 109px;
  height: 107px;
  flex-shrink: 0;
  top: 450px;
  left: 1079px;
}

.overlay-image4 {
  position: absolute;
  width: 101.715px;
  height: 74.711px;
  flex-shrink: 0;
  top: 340px;
  left: 1439px;
  transform: rotate(-43.639deg);
}

.overlay-yellow-coin {
  position: absolute;
  width: 109px;
  height: 107px;
  flex-shrink: 0;
  transform: rotate(-3.022deg);
  top: 10px;
  left: 20px;
}

.overlay-blue-coin {
  position: absolute;
  width: 101.715px;
  height: 74.711px;
  flex-shrink: 0;
  transform: rotate(-3.022deg);
  top: 10px;
  left: 20px;
}

.content {
  position: absolute;
  top: 250px;
  left: 357px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.labels {
  display: flex;
  flex-direction: column;
}

.label {
  margin-bottom: 20px;
}

.label-text1 {
  color: #fff;
  font-family: "DIN Black";
  font-size: 62px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  width: 682px;
  height: 89px;
  flex-shrink: 0;
}

.label-text2 {
  color: #fff;
  font-family: "DIN Pro";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 486px;
  height: 41px;
  flex-shrink: 0;
}

.label-text3 {
  color: #fff;
  font-family: "DIN Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 486px;
  height: 86px;
  flex-shrink: 0;
}

.section-button {
  background-color: #0084d6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 259px;
  height: 56px;
  justify-content: center;
  align-items: center;
  vertical-align: center;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 8px;
  border-radius: 8px;
  background: var(
    --button-gradient,
    linear-gradient(311deg, #0084d6 0%, #00c691 50.32%, #00cf88 100%)
  );
}

.section-button-text {
  color: #fff;
  text-align: center;
  font-family: DIN Pro;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 200% */
}
</style>
