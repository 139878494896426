"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _vueAwesomeSwiper = require("vue-awesome-swiper");
var _settings = require("@/model/settings");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/model/home/index");
var _vuex = require("vuex");
var _index2 = require("@/model/exchange/index");
var _keymap = require("@/assets/js/keymap.js");
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
var _axios = _interopRequireDefault(require("axios"));
var _stationSetting = require("@/assets/js/stationSetting");
var _finance = require("@/model/finance/finance");
var _countdown = _interopRequireDefault(require("@/components/common/countdown.vue"));
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _digital = require("@/assets/js/digital");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getCoinList } from "@/model/assets/index";

var vm = null;
var _default = {
  mixins: [_commonMixin.default],
  components: {
    swiper: _vueAwesomeSwiper.swiper,
    swiperSlide: _vueAwesomeSwiper.swiperSlide,
    // QrcodeVue,
    Countdown: _countdown.default,
    NoData: _NoData.default
  },
  data() {
    return {
      detailsUrl: "",
      systemCurrencyMaps: _keymap.systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      // realIndex:0,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      android_url: "",
      appstore_url: "",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",
      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,
        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev"
        },
        // on: {
        //   click: function() {
        //     // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
        //     // console.log(this); // -> Swiper
        //     // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
        //     const realIndex = this.realIndex;
        //     vm.handleClickSlide(realIndex);
        //   }
        // },
        on: {
          click: function (e) {
            window.open(e.target.dataset.href);
          }
        },
        // on: {
        //   click: function() {
        //     // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
        //     // console.log(this); // -> Swiper
        //     // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
        //     const realIndex = this.realIndex;
        //     vm.handleClickSlide(realIndex);
        //   }
        // },

        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,
        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1,
        // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 4000
        },
        autoplayDisableOnInteraction: false,
        // //循环
        loop: true
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },

      oldImgList: [{
        language: {
          img_url_ar: require("../../assets/img/home/banner-1.png"),
          img_url_en: require("../../assets/img/home/banner-1.png"),
          img_url_ja: require("../../assets/img/home/banner-1.png"),
          img_url_ko: require("../../assets/img/home/banner-1.png"),
          img_url_zh_CN: require("../../assets/img/home/banner-1.png"),
          img_url_zh_HK: require("../../assets/img/home/banner-1.png")
        },
        param: {
          url: ""
        }
      }, {
        language: {
          img_url_ar: require("../../assets/img/home/banner-2.png"),
          img_url_en: require("../../assets/img/home/banner-2.png"),
          img_url_ja: require("../../assets/img/home/banner-2.png"),
          img_url_ko: require("../../assets/img/home/banner-2.png"),
          img_url_zh_CN: require("../../assets/img/home/banner-2.png"),
          img_url_zh_HK: require("../../assets/img/home/banner-2.png")
        },
        param: {
          url: ""
        }
      }, {
        language: {
          img_url_ar: require("../../assets/img/home/banner-3.png"),
          img_url_en: require("../../assets/img/home/banner-3.png"),
          img_url_ja: require("../../assets/img/home/banner-3.png"),
          img_url_ko: require("../../assets/img/home/banner-3.png"),
          img_url_zh_CN: require("../../assets/img/home/banner-3.png"),
          img_url_zh_HK: require("../../assets/img/home/banner-3.png")
        },
        param: {
          url: ""
        }
      }, {
        language: {
          img_url_ar: require("../../assets/img/home/banner-4.png"),
          img_url_en: require("../../assets/img/home/banner-4.png"),
          img_url_ja: require("../../assets/img/home/banner-4.png"),
          img_url_ko: require("../../assets/img/home/banner-4.png"),
          img_url_zh_CN: require("../../assets/img/home/banner-4.png"),
          img_url_zh_HK: require("../../assets/img/home/banner-4.png")
        },
        param: {
          url: ""
        }
      }],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      tableDataComputed6: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      tableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      tgUrl: "",
      rateUrl: "",
      timer: null,
      voucherIntervalV2: null,
      tempMarkList: [],
      ///////////////////////////////增加/////////////////////////////////
      swiperOptionTop: {
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      active: 0,
      productTypeList: [],
      listType: [{
        text: "全部",
        val: 0
      }
      // { text: "量化理财", val: 3 },
      // { text: "活期", val: 2 },
      // { text: "定期", val: 1 },
      // { text: "汇信池", val: 4 },
      ],

      dataList: [],
      quantTagMaps: _keymap.quantTagMaps,
      financialStatusMapsTwo: _keymap.financialStatusMapsTwo,
      financialStatusMaps: _keymap.financialStatusMaps,
      pageLoading: false,
      guide: false,
      guideSwiperOption: {
        autoplay: {
          disableOnInteraction: false,
          delay: 2500
        },
        pagination: {
          el: ".swiper-pagination"
        }
      },
      faq1: "",
      faq2: "",
      faq3: "",
      faq4: "",
      faq5: "",
      faq6: "",
      flag: ""
    };
  },
  mounted() {
    this.faqLink();
  },
  async created() {
    let userMsg = JSON.parse(sessionStorage.getItem("userInfo"));
    if (userMsg && !localStorage.getItem("a" + userMsg.user_id.toString())) {
      this.guide = true;
      localStorage.setItem("a" + userMsg.user_id.toString(), 1);
    }
    await this.getExchangeCoinMap();

    // if (this.language == "zh_CN" || this.language == "zh_HK") {
    //   let titleTag = document.getElementById("pageTitle");
    //   titleTag.innerHTML =
    //     "8v交易所_比特币交易平台_元宇宙虚拟币交易平台 - 8v官网";
    //   let metaTag = document.getElementById("description");
    //   metaTag.name = "description";
    //   metaTag.content =
    //     "8v交易所是专注数字加密货币交易、理财货币交易、比特币交易平台、元宇宙、虚拟币交易网站。以最安全，最高效的数字理财平台；即刻交易BTC、ETH等上百种数字资产、可信赖安全有保障。";
    //   let metaTag2 = document.getElementById("keywords");
    //   metaTag2.name = "keywords";
    //   metaTag2.content =
    //     "8v交易所,8v官网,比特币交易,比特币交易平台,比特币交易网站,比特币交易所,元宇宙,虚拟币,虚拟币交易,虚拟币平台,虚拟币交易平台,虚拟币交易所";
    // } else {
    //   let titleTag = document.getElementById("pageTitle");
    //   titleTag.innerHTML =
    //     "8v Exchange_Bitcoin Trading Platform_Metaverse Virtual Currency Trading Platform - 8v Official Website";
    //   let metaTag = document.getElementById("description");
    //   metaTag.name = "description";
    //   metaTag.content =
    //     "8v Exchange is a website focusing on digital cryptocurrency trading, wealth management currency trading, Bitcoin trading platform, Metaverse, and virtual currency trading. With the safest and most efficient digital wealth management platform; instantly trade hundreds of digital assets such as BTC, ETH, etc., reliable and safe.";
    //   let metaTag2 = document.getElementById("keywords");
    //   metaTag2.name = "keywords";
    //   metaTag2.content =
    //     "8V exchange,8v official website,bitcoin,bitcoin trading,bitcoin price,dogecoin,crypto,crypto trading,NFT,exchange blockchain,trade coin binance,exchange binance";
    // }

    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;
    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
      this.detailsUrl = zendesk_notice.language[`articles_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
      this.detailsUrl = zendesk_notice.articles_url;
    }
    let othersList = JSON.parse(sessionStorage.getItem("others"));
    this.rateUrl = othersList[0].url;
    let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    this.tgUrl = qrcode.ori_url;
    this.getBannerData();
    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = _jsCookie.default.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.initWS();
    } else {
      this.token = _jsCookie.default.get("topcredit_exchange_t");
      await this.initURL();
      this.initWS();
    }
    vm = this;
    // await this.getItemList();
    this.getTradePairList();
    this.voucherInterval = setInterval(() => {
      this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
    /////////////////////////////////////////新增/////////////////////
    this.getProductlist(1);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.voucherIntervalV2);
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = _jsCookie.default.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    on_bot_enter() {
      this.$refs.topSwiper.swiper.autoplay.stop();
    },
    on_bot_leave() {
      this.$refs.topSwiper.swiper.autoplay.start();
    },
    jumpToUrl(url) {
      if (url != "") {
        window.open(url);
      }
    },
    jumpToUrlV2(item) {
      if (item.language) {
        window.open(item.language[`url_${this.language}`]);
      } else {
        window.open(item.param.url);
      }
    },
    faqLink() {
      switch (this.language) {
        case "zh_HK":
          this.faq1 = "https://info.8v.com/hc/zh-tw/categories/4413699059737";
          this.faq2 = "https://info.8v.com/hc/zh-tw/articles/4409499411353-8V 理財產品介紹";
          this.faq3 = "https://info.8v.com/hc/zh-tw/articles/4409499292313-如何使提高賬戶安全性";
          this.faq4 = "https://info.8v.com/hc/zh-tw/articles/4409499187737-如何註冊 8V 賬戶";
          this.faq5 = "https://info.8v.com/hc/zh-tw/articles/4409499312537-如何使用 8V 在線客服系統";
          this.faq6 = "https://info.8v.com/hc/zh-tw/articles/4409485729049-充值-收款數字貨幣";
          break;
        case "zh_CN":
          this.faq1 = "https://info.8v.com/hc/zh-cn/articles/4409490980249-现货交易新手指引";
          this.faq2 = "https://info.8v.com/hc/zh-cn/articles/4409499411353-8V 理财产品介绍";
          this.faq3 = "https://info.8v.com/hc/zh-cn/articles/4409499292313-如何使提高账户安全性";
          this.faq4 = "https://info.8v.com/hc/zh-cn/articles/4409499187737-如何注册 8V 账户";
          this.faq5 = "https://info.8v.com/hc/zh-cn/articles/4409499312537-如何使用 8V 在线客服系统";
          this.faq6 = "https://info.8v.com/hc/zh-cn/articles/4409485729049-充值-收款数字货币";
          break;
        default:
          this.faq1 = "https://info.8v.com/hc/en-001/articles/4409490980249-How-to-Trade-Spot-on-8V";
          this.faq2 = "https://info.8v.com/hc/en-001/articles/4409499411353-8V-Financial-Products";
          this.faq3 = "https://info.8v.com/hc/en-001/articles/4409499292313-How-to-Improve-Account-Security";
          this.faq4 = "https://info.8v.com/hc/en-001/articles/4409499187737-How-to-Register-8V-APP";
          this.faq5 = "https://info.8v.com/hc/en-001/articles/4409499312537-24-7-Live-Chat-Support";
          this.faq6 = "https://info.8v.com/hc/en-001/articles/4409485729049-Crypto-Deposit-Withdrawal";
          break;
      }
    },
    closeGuide() {
      this.guide = false;
    },
    goAboutUs() {
      window.open(`/aboutus/${this.language}`);
      // this.$router.push(`/aboutus/${this.language}`);
    },

    async getExchangeCoinMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };

      const {
        status,
        data
      } = await (0, _index2.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
        this.android_url = data.android_url;
        this.appstore_url = data.appstore_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal
        }
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
    onLookMarket() {
      window.open(this.routeSplicing(this.language, "markets"));
      // this.$router.push("/markets" + "/" + this.language);
    },

    onLookFinances() {
      window.open(this.routeSplicing(this.language, `finances`));
      // this.$router.push("/finances" + "/" + this.language);
    },

    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
    async getBannerData() {
      const req = {
        type: 1,
        title: "main"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetBannerList)(req);
      if (status == 200) {
        let len = data.length;
        if (len <= 4) {
          data.forEach((ele, index) => {
            this.oldImgList[index] = ele;
          });
        } else {
          this.imgList = data;
        }
        this.sideLen = len >= 4 ? 4 : len;
      }
    },
    async getStaticTableInfo(sv_id) {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },
    async getDeatilsData() {
      var that = this;
      _axios.default.get(this.detailsUrl).then(function (response) {
        that.articlesList = response.data.articles;
        that.firstArticles = response.data.articles[0];
        that.secondArticles = response.data.articles[1];
      }).catch(function (error) {
        console.log(error);
      });
    },
    getTradePairList() {
      (0, _index2.apiGetSpotList)().then(res => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach(ele => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.exchangeCoinMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.exchangeCoinMap[key].name.split("_")[0].toUpperCase();
                ele["to_symbol"] = this.exchangeCoinMap[key].name.split("_")[1].toUpperCase();
                ele["info"] = this.exchangeCoinMap[key];
                ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
                this.tempMarkList.push(ele);
              }
            }
            for (const key in this.coinInfoList) {
              if (ele.from_symbol) {
                if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
                  ele.coin_url = this.coinInfoList[key].coin_url;
                }
              }
            }
          });
          this.tableLoading = false;
          let tempList = this.tempMarkList.filter(ele => {
            return ele.from_symbol != undefined && ele.web_recommend == 1;
          });
          this.tableDataComputed = tempList.sort(this.compareOrderBy("order_by"));
          this.tableDataComputed6 = this.tableDataComputed.slice(0, 5);
        }
      });
    },
    initWS() {
      this.tableLoading = true;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: _stationSetting.VUE_APP_ID
          }
        },
        callback: this.onResponse
      };
      window.USDT_WS = this.ws = new _socket.default(option).getInstance();
      setTimeout(() => {}, 1200);
      // this.getSubData();
    },

    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;
        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach(ele => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name.split("_")[0].toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name.split("_")[1].toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].web_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        let tempList = this.tableData.filter(ele => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });
        this.tableDataComputed = tempList.sort(this.compareOrderBy("order_by"));
        this.tableDataComputed6 = this.tableDataComputed.slice(0, 4);
      }
    },
    compareOrderBy(property) {
      return function (a, b) {
        if (a && b && a["info"] && b["info"] && a["info"][property] && b["info"][property]) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(this.language, `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`);
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      // let tempUrl = this.routeSplicing(
      //   this.language,
      //   `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`
      // );
      // window.open(tempUrl);
    },
    ////////////////////////////////////新增///////////////////////////
    getProductlist(timer) {
      this.pageLoading = true;
      let tempProductType = 0;
      if (this.active == 3) {
        tempProductType = 3;
      } else if (this.active == 2) {
        tempProductType = 2;
      } else if (this.active == 1) {
        tempProductType = 1;
      } else if (this.active == 4) {
        tempProductType = 4;
      }
      let params = {
        item_type: 1,
        //货币类型，1为数字货币，2为法币
        product_type: tempProductType,
        //产品类型，1定期 2活期 3量化 4汇信池，-1为全部产品列表
        page: 1,
        page_size: 100
      };
      (0, _finance.apiGetProductListV2)(params).then(res => {
        if (res.status == 200) {
          let tempList = res.data.list;
          const now = Date.parse(new Date());
          tempList.forEach(ele => {
            this.productTypeList.push(ele.product_type);
            ele.to_item_id_price = Number(ele.to_item_id_price).toFixed(2);
            if (ele.to_item_url == "") {
              ele.to_item_url = require("../../assets/img/holder-coin-ico.png");
            }
            if (ele.stop_time != 0 && now < ele.stop_time * 1000 && ele.stop_time * 1000 - now < 86400000) {
              ele.remain_end_time = ele.stop_time * 1000 - now;
            }
            if (ele.start_time != 0) {
              ele.remain_time = ele.start_time * 1000 - now;
            } else {
              ele.timeCount = 0;
            }
            if (ele.union_info.length > 0) {
              ele.active_lock_period_id = ele.union_info[0].product_id;
              ele.active_annual_rate = ele.union_info[0].annual_rate;
            }
          });
          if (timer) {
            if (this.productTypeList.indexOf(3) > -1) {
              this.listType.push({
                text: "量化理财",
                val: 3
              });
            }
            if (this.productTypeList.indexOf(2) > -1) {
              this.listType.push({
                text: "活期",
                val: 2
              });
            }
            if (this.productTypeList.indexOf(1) > -1) {
              this.listType.push({
                text: "定期",
                val: 1
              });
            }
            if (this.productTypeList.indexOf(4) > -1) {
              if (_stationSetting.VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w" || _stationSetting.VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T") {
                // 汇信
                this.listType.push({
                  text: "汇信池",
                  val: 4
                });
              } else {
                this.listType.push({
                  text: "Launchpad",
                  val: 4
                });
              }
            }
          }
          this.dataList = tempList.slice(0, 4);
        }
        this.pageLoading = false;
      });
    },
    // 选项卡切换
    onTypeChange(val) {
      this.active = val;
      if (val == 3) {
        this.confirmDialogTitle = this.translateTitle(`量化理财`);
      } else if (val == 2) {
        this.confirmDialogTitle = this.translateTitle(`活期存入`);
      } else if (val == 1) {
        this.confirmDialogTitle = this.translateTitle(`定期存入`);
      } else if (val == 4) {
        if (_stationSetting.VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" || _stationSetting.VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w" || _stationSetting.VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T") {
          // 汇信
          this.confirmDialogTitle = this.translateTitle(`汇信池`);
        } else {
          this.confirmDialogTitle = "Launchpad";
        }
      }
      this.getProductlist();
    },
    goToBuy(item) {
      let productId = 0;
      if (item.union_info.length > 0) {
        productId = item.active_lock_period_id;
      } else {
        productId = item.product_id;
      }
      // window.location.href =
      //   "/finances" + "/" + this.language + "?relation_id=" + productId;
      this.$router.push({
        // path: "/finances",
        path: this.routeSplicing(this.language, `finances`),
        query: {
          relation_id: productId
        }
      });
    },
    onCountDownEnd() {
      this.getProductlist();
    },
    annualRateComputed(annual_rate) {
      let tempRate = new _bignumber.default(annual_rate);
      let time = new _bignumber.default(100);
      return tempRate.times(time).toString();
    },
    onLockPeriodChange(prod, item) {
      item.active_lock_period_id = prod.product_id;
      item.active_annual_rate = prod.annual_rate;
    },
    remainingPercent(remaining, amount) {
      let tempVal = (0, _digital.floorNumber)(remaining * 100 / amount, 2);
      return tempVal;
    },
    joinActive(flag) {
      this.flag = flag;
    }
  }
};
exports.default = _default;