<template>
  <section class="web-second-section">
    <div class="section-part top-part">
      <div class="part-left-pic">
        <img
          src="@/assets/img/maskexcard/2f53846efeacc1ec0255dfa2bc5b2446.png"
          alt="Top Left Image"
        />
      </div>
      <div class="part-right-text">
        <h2 class="part-title">Use Crypto in Your Daily Life Worldwide</h2>
        <p class="part-description">
          Pay anywhere in the world using your MaskEX account funds. No extra
          wallets or accounts required.
        </p>
      </div>
    </div>
    <div class="section-part middle-part">
      <div class="part-left-text">
        <h2 class="part-title">Only Gains, No Pains</h2>
        <p class="part-description">
          Experience the complete benefits of MaskEX Card by taking advantage of
          our generous upper limits of up to $500,000 a day.
        </p>
      </div>
      <div class="part-right-pic">
        <img
          src="@/assets/img/maskexcard/34491fee89cbdecd3670436100144af4.png"
          alt="Middle Right Image"
        />
      </div>
    </div>
    <div class="section-part bottom-part">
      <div class="part-left-pic">
        <img
          src="@/assets/img/maskexcard/ed60f85b7c9b18beb3d54558d7bcdf25.png"
          alt="Bottom Left Image"
        />
      </div>
      <div class="part-right-text">
        <h2 class="part-title">Elite Loyalty Perks</h2>
        <p class="part-description">
          Gain enticing benefits from regular campaigns to elevate your
          experience.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // Second section component logic
};
</script>

<style scoped>
/* Second section styles */
.top-part {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-left: 360px;
  margin-right: 360px;
}
.middle-part {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-left: 360px;
  margin-right: 360px;
}
.bottom-part {
  display: flex;
  align-items: center;
  gap: 100px;
  margin-left: 360px;
  margin-right: 360px;
}
.web-second-section {
  width: 100%;
  height: 1400px;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.section-part {
  display: flex;
  /* width: 100%; */
  height: calc(1400px / 3);
}

.part-left-text {
  /* background: url(<path-to-image>),
    lightgray 31px -36.676px / 86.863% 120.643% no-repeat; */
  width: 500px;
  height: 360px;
  flex-shrink: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 20px;
  /* margin-left: 360px; */
  margin-top: 200px;
  gap: 24px;
}
.part-left-pic {
  /* background: url(<path-to-image>),
    lightgray 31px -36.676px / 86.863% 120.643% no-repeat; */
  width: 500px;
  height: 360px;
  flex-shrink: 0;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 20px;
  /* margin-left: 360px; */
  margin-top: 100px;
}

.part-right-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  margin-top: 100px;
  gap: 24px;
}

.part-right-pic {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
  margin-top: 100px;
  gap: 24px;
}

.part-title {
  color: black;
  font-size: 24px;
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.part-description {
  color: black;
  font-family: "DIN Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
