"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiHistoryData = apiHistoryData;
exports.apiSymbolInfo = apiSymbolInfo;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_APP } = process.env

function apiHistoryData(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Lever/kline`,
    data,
    type: "POST"
  });
}

// 获取kline精度
function apiSymbolInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Lever/symbols`,
    data,
    type: "POST"
  });
}