"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiAllClosePosition = apiAllClosePosition;
exports.apiAppendInterest = apiAppendInterest;
exports.apiCancelOrde = apiCancelOrde;
exports.apiClosePosition = apiClosePosition;
exports.apiGetContract = apiGetContract;
exports.apiGetOpenPositionList = apiGetOpenPositionList;
exports.apiGetOrderList = apiGetOrderList;
exports.apiGetOrderStatistic = apiGetOrderStatistic;
exports.apiGetPlanOrderList = apiGetPlanOrderList;
exports.apiOpenPosition = apiOpenPosition;
exports.apiPlanOrder = apiPlanOrder;
exports.apiRevokeAllPlan = apiRevokeAllPlan;
exports.apiRevokePlan = apiRevokePlan;
exports.apiSetPlanProfitLoss = apiSetPlanProfitLoss;
exports.apiSetPositionProfitLoss = apiSetPositionProfitLoss;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env

function apiGetOrderList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/TradeCoin/getOrderListV2`,
    data,
    type: "POST"
  });
}
function apiCancelOrde(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/TradeCoin/cancelLimitOrder`,
    data,
    type: "POST"
  });
}
// 持仓  平仓记录
function apiGetOpenPositionList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/openPositionList`,
    data,
    type: "POST"
  });
}
// 当前委托  历史委托
function apiGetPlanOrderList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/planOrderList`,
    data,
    type: "POST"
  });
}
// apiRevokePlan
// 合约撤销计划委托
function apiRevokePlan(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/revokePlan`,
    data,
    type: "POST"
  });
}
//

// 合约追加持仓
function apiAppendInterest(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/appendInterest`,
    data,
    type: "POST"
  });
}
// 市价合约下单
function apiOpenPosition(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/openPosition`,
    data,
    type: "POST"
  });
}
// 限价合约下单
function apiPlanOrder(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/planOrder`,
    data,
    type: "POST"
  });
}
// 合约平仓
function apiClosePosition(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/closePosition`,
    data,
    type: "POST"
  });
}
// 合约一键平仓
function apiAllClosePosition(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/allClosePosition`,
    data,
    type: "POST"
  });
}
// 合约仓位止盈止损
function apiSetPositionProfitLoss(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/setPositionProfitLoss`,
    data,
    type: "POST"
  });
}
// 合约委托单止盈止损
function apiSetPlanProfitLoss(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/setPlanProfitLoss`,
    data,
    type: "POST"
  });
}
// 撤销所有计划委托
function apiRevokeAllPlan(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/revokeAllPlan`,
    data,
    type: "POST"
  });
}
// 用户合约统计数据
function apiGetOrderStatistic(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Contract/statistic`,
    data,
    type: "POST"
  });
}

//币本位合约接口整合 undingRate,planOrderList,openPositionList   当前委托  历史委托   持仓  平仓记录
function apiGetContract(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Merge/contract`,
    data,
    type: "POST"
  });
}