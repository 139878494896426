"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _settings = require("@/model/settings");
var _vuex = require("vuex");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _broker = require("@/model/broker");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getStaticCoinList as getCoinList } from "@/model/assets/index";
// import visa from "@/assets/img/quickly-coins/visa.png";
// import mastercard from "@/assets/img/quickly-coins/mastercard.png";
// import moonpay from "@/assets/img/quickly-coins/Moonpay.png";
// import mercuryo from "@/assets/img/quickly-coins/mercuryo_n.png";
// import paxful from "@/assets/img/quickly-coins/Paxful.png";
// import transak from "@/assets/img/quickly-coins/Transak.png";
// import routelay from "@/assets/img/quickly-coins/Routelay.png";
// import itez from "@/assets/img/quickly-coins/itez.png";
// import alchemypay from "@/assets/img/quickly-coins/AlchemyPay.png";
// import wyre from "@/assets/img/quickly-coins/wyre.png";
// import ipay from "@/assets/img/quickly-coins/ipay.png";
// import scpa from "@/assets/img/quickly-coins/scpa.png";
// import choose_a from "@/assets/img/quickly-coins/m-choose-a.png";
// import choose from "@/assets/img/quickly-coins/m-choose.png";
// import * as Account from "@/model/user/account";
// import Recharge from "@/pages/quicklycoins/recharge.vue";
// import CryptoJS from "crypto-js/crypto-js";
// import {
//   apiGetFinanceAddressList,
//   apiWyreUrl,
//   apiFetchOtcUrl,
// } from "@/model/assets/index";
var _default = {
  mixins: [_commonMixin.default],
  // components: { Recharge },
  data() {
    return {
      // visa,
      // mastercard,
      // ipay,
      // scpa,
      // moonpay,
      // mercuryo,
      // paxful,
      // transak,
      // routelay,
      // itez,
      // alchemypay,
      // wyre,
      // active: 1,
      // checked: false,
      // spendValue: "",
      // currencyList: [],
      // thirdPaymentMap: [],
      // otcThirdPaymentMap: [],
      // coinData: {},
      // coinBuyAvailable: [],
      // thirdPaymentArray: [],
      // thirdPaymentShowArray: [],
      // currencyCurr: "",
      // coinCurr: "",
      // radioCurr: "",
      // addressCurr: "",
      // rowCount: 0,
      // choose_a,
      // choose,
      marketing: 0,
      programForm: {
        name: "",
        email: "",
        contact: "",
        uid: "",
        tid: "",
        marketing: "",
        area: ""
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language"
    })
  },
  watch: {
    // currencyCurr(val) {
    //   let tempArray = [];
    //   if (val.logic_name.toUpperCase() != "USD") {
    //     this.thirdPaymentArray.forEach(item => {
    //       if (item.name.toLowerCase() != "wyre") {
    //         tempArray.push(item);
    //       }
    //     });
    //   } else {
    //     tempArray = this.thirdPaymentArray;
    //   }
    //   // tempArray = this.thirdPaymentArray;
    //   this.rowCount = Math.ceil(tempArray.length / 2);
    //   let index = null;
    //   for (let i = 0; i < tempArray.length; i++) {
    //     if (tempArray[i].name.toLowerCase() == "itez") {
    //       index = i;
    //       break;
    //     }
    //   }
    //   if (index !== null) {
    //     tempArray.unshift(...tempArray.splice(index, 1));
    //   }
    //   this.thirdPaymentShowArray = tempArray;
    // },
  },
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    routeSplicing: _routeSplicing.default,
    async submit() {
      let foll = "";
      if (this.marketing == 0) {
        foll = "1000-10000";
      } else if (this.marketing == 1) {
        foll = "10000-100000";
      } else if (this.marketing == 2) {
        foll = "100000-1000000";
      }
      const req = {
        name: this.programForm.name,
        email: this.programForm.email,
        mobile: this.programForm.area + "" + this.programForm.contact,
        telegram: this.programForm.tid,
        marketing_platform: this.programForm.marketing,
        followers: this.foll
      };
      this.btnLoading = true;
      const {
        data,
        status
      } = await (0, _broker.apiAffiliateProgram)(req);
      if (status == 200) {
        this.$message(this.translateTitle("申请成功"), "success");
      }
      this.btnLoading = false;
    },
    chooseMarketing(index) {
      this.marketing = index;
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    }
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     this.coinData = data;
    //   }
    // },
    // //7-static_currency-法币表
    // async getStaticCurrencyMap() {
    //   const { status, data } = await apiGetStaticTableInfo(7);
    //   if (status == 200) {
    //     this.currencyList = Object.values(JSON.parse(JSON.stringify(data)));
    //   }
    // },
    // async getStaticOtcThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(64);
    //   const { status, data } = await apiGetStaticTableInfo(69);
    //   if (status == 200) {
    //     this.otcThirdPaymentMap = Object.values(
    //       JSON.parse(JSON.stringify(data))
    //     );
    //   }
    // },
    // async getStaticThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(65);
    //   const { status, data } = await apiGetStaticTableInfo(68);
    //   if (status == 200) {
    //     this.thirdPaymentMap = Object.values(JSON.parse(JSON.stringify(data)));
    //     this.thirdPaymentMap.forEach(item => {
    //       item["img"] = this.$data[item.name.toLowerCase()];
    //     });
    //   }
    // },
    // changeActive(num) {
    //   this.active = num;
    // },
    // selectChange(val) {
    //   this.currencyCurr = val;
    // },
    // selectChangeCoin(val) {
    //   this.coinCurr = val;

    //   this.getAddress();
    //   let tempPaymentArray = [];
    //   this.coinCurr.payment.split(",").forEach(ele => {
    //     this.thirdPaymentMap.forEach(item => {
    //       if (ele == item.name) {
    //         tempPaymentArray.push(item);
    //       }
    //     });
    //   });
    //   tempPaymentArray.push({ name: "Wyre", img: this.wyre });
    //   this.thirdPaymentArray = tempPaymentArray;
    //   // this.radioCurr = this.thirdPaymentArray[0].name;
    //   this.rowCount = Math.ceil(tempPaymentArray.length / 2);
    //   this.thirdPaymentShowArray = tempPaymentArray;
    //   let index = null;
    //   for (let i = 0; i < this.thirdPaymentShowArray.length; i++) {
    //     if (this.thirdPaymentShowArray[i].name.toLowerCase() == "itez") {
    //       index = i;
    //       break;
    //     }
    //   }
    //   if (index !== null) {
    //     this.thirdPaymentShowArray.unshift(
    //       ...this.thirdPaymentShowArray.splice(index, 1)
    //     );
    //   }
    //   this.radioCurr = this.thirdPaymentShowArray[0].name;
    // },
    // async getAddress() {
    //   let params = {
    //     item_id: this.coinCurr.item_id,
    //     chain_tag_list: this.coinCurr.chain_name.toUpperCase(),
    //   };
    //   if (!this.userInfo) return;
    //   const { status, msg, data } = await apiGetFinanceAddressList(params);
    //   if (status == 200) {
    //     this.addressCurr = data[0]["address"];
    //   }
    // },
    // async doBuy() {
    //   if (!this.spendValue) {
    //     this.$message({
    //       message: this.translateTitle("请输入金额"),
    //       type: "warning",
    //       customClass: "zZindex",
    //     });
    //     return;
    //   }
    //   let url = await this.generateUrl(
    //     this.radioCurr,
    //     this.coinCurr.item_name.toUpperCase(),
    //     this.spendValue,
    //     this.currencyCurr.logic_name.toUpperCase(),
    //     this.addressCurr
    //   );
    //   window.open(url);
    // },
    // async generateUrl(paymentCode, symbol, amount, currency, address) {
    //   let resultUrl = "";
    //   if (paymentCode.toLowerCase() != "wyre") {
    //     const { status, data: dataUrl } = await apiFetchOtcUrl({
    //       payment_code: paymentCode.toLowerCase(),
    //       symbol: symbol,
    //       amount: amount,
    //       currency: currency,
    //       address: address,
    //     });
    //     if (status == 200) {
    //       resultUrl = dataUrl;
    //     }
    //   } else {
    //     const destCurrency =
    //       symbol.toUpperCase() == "BTC"
    //         ? "bitcoin:" + address
    //         : "ethereum:" + address;
    //     const { status, data } = await apiWyreUrl({
    //       sourceAmount: amount,
    //       sourceCurrency: currency,
    //       destCurrency: symbol,
    //       dest: destCurrency,
    //       country: "US",
    //     });
    //     if (status == 200) {
    //       resultUrl = data.url;
    //     }
    //   }
    //   return resultUrl;
    // },
  }
};
exports.default = _default;