"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _vuex = require("vuex");
var _index = require("@/model/exchange/index");
var _settings = require("@/model/settings");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _message = require("@/components/dialog/message");
var _SocketDispatcher = _interopRequireDefault(require("@/assets/js/SocketDispatcher"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    InputNumber: _InputNumber.default
  },
  mixins: [_commonMixin.default],
  props: {
    assetsInfoList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    assetsInfo: {
      type: Object,
      default: function () {
        return {
          remain: {
            all_u_valuation: {
              usdt: "--"
            },
            list: []
          }
        };
      }
    },
    swap_symbol_id: {
      type: Number,
      default: function () {
        22;
      }
    },
    from_symbol: {
      type: [String, Number]
    },
    to_symbol: {
      type: [String, Number]
    },
    from_item_id: {
      type: [String, Number]
    },
    to_item_id: {
      type: [String, Number]
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      userInfo: "user/userInfo"
    }),
    apiAssetsRemain() {
      let tempObj = 0;
      if (JSON.stringify(this.apiAssetsInfo) != "{}" && this.apiAssetsInfo.remain.list.length > 0) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    assetsInfoWs() {
      let tempObj = 0;
      if (JSON.stringify(this.assetsInfo) != "{}" && this.assetsInfo.remain.list.length > 0) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    apiAssetsRemainSell() {
      let tempObj = 0;
      if (JSON.stringify(this.apiAssetsInfo) != "{}" && this.apiAssetsInfo.remain.list.length > 0) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    assetsInfoWsSell() {
      let tempObj = 0;
      if (JSON.stringify(this.assetsInfo) != "{}" && this.assetsInfo.remain.list.length > 0) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = 0;
      }
      return tempObj;
    },
    // 现有U资产
    assetsInfoShow() {
      if (this.apiAssetsBtn) {
        return this.apiAssetsRemain;
      } else {
        return this.assetsInfoWs;
      }
    },
    // 现有币资产
    assetsInfoShowSell() {
      if (this.apiAssetsBtn) {
        return this.apiAssetsRemainSell;
      } else {
        return this.assetsInfoWsSell;
      }
    },
    // 市场现在交易价格
    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    // 买入交易量
    tradingVolume() {
      let tempVal = "0";
      if (this.buyAmount == "0") {
        tempVal = "0";
      } else if (this.buyAmount != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new _bignumber.default(this.buyAmount);
        let bigMarketInfoShow = new _bignumber.default(JSON.parse(JSON.stringify(this.marketPrice)));
        tempVal = bigBuyAmount.div(bigMarketInfoShow).toNumber().toFixed(this.number_precision);
      } else {
        tempVal = "0";
      }
      return tempVal;
    },
    // 买入交易额
    transactionAmount() {
      let tempVal = "0";
      if (this.currChainValue == "1") {
        if (this.buyNum == "0") {
          tempVal = "0";
        } else if (this.buyNum != "" && this.marketInfoShow.last) {
          let bigBuyNum = new _bignumber.default(this.buyNum);
          let bigMarketInfoShow = new _bignumber.default(this.marketInfoShow.last);
          tempVal = bigBuyNum.times(bigMarketInfoShow).toNumber().toFixed(this.price_precision);
        } else {
          tempVal = "0";
        }
      } else if (this.currChainValue == "2") {
        if (this.buyNum == "0") {
          tempVal = "0";
        } else if (this.buyNum != "" && this.buyPrice) {
          let bigBuyNum = new _bignumber.default(this.buyNum);
          let bigMarketInfoShow = new _bignumber.default(this.buyPrice);
          let tempValV2 = bigBuyNum.times(bigMarketInfoShow).toNumber();
          tempVal = this.ceilNumber(tempValV2, this.price_precision);
        } else {
          tempVal = "0";
        }
      }
      return tempVal;
    }
  },
  watch: {
    swap_symbol_id() {
      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellSliderValue = 0;
      this.sellPrice = "";
      this.sellNum = "";
      this.number_precision = this.coinInfoMap[this.swap_symbol_id].number_precision;
      this.price_precision = this.coinInfoMap[this.swap_symbol_id].price_precision;
    }
  },
  data() {
    return {
      coinInfoMap: {},
      apiAssetsInfo: {},
      currChainValue: "1",
      sellTransactionAmountSelf: "",
      sellTransactionAmount: "",
      buyTransactionAmount: "",
      buyTransactionAmountSelf: "",
      buySliderValue: 0,
      sellSliderValue: 0,
      marketPrice: "",
      buyPrice: "",
      sellPrice: "",
      buyNum: "",
      buyAmount: "",
      //
      sellNum: "",
      buyTriggerPrice: "",
      sellTriggerPrice: "",
      number_precision: "",
      price_precision: "",
      // from_item_id: "",
      // to_item_id: "",
      marks: {
        25: "25%",
        50: "50%",
        75: "75%"
      },
      coinInfoList: [],
      tabsOptions: [{
        label: "市价",
        value: 1
      }, {
        label: "限价",
        value: 2
      }
      // {
      //   label: "止盈止损",
      // },
      ],

      inputControl: false,
      buyBtnLoading: false,
      sellBtnLoading: false,
      apiAssetsBtn: false
    };
  },
  async created() {
    await this.getStaticTableInfo(55);
    await this.getAssets();
    _SocketDispatcher.default.addEventListener("onPriceClick", this.onPriceClick, this);
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsBtn = true;
        this.apiAssetsInfo = data;
        setTimeout(() => {
          this.apiAssetsBtn = false;
        }, 15000);
      }
    },
    onPriceClick(row) {
      if (this.currChainValue == "2") {
        this.buyPrice = row[0];
        this.onBuyNumChange();
        this.sellPrice = row[0];
        this.onSellPriceChange();
      }
    },
    // 买入滑块变化
    onBuySliderChange(value) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      if (this.currChainValue == "1") {
        if (value && !this.inputControl) {
          // 买入交易额
          this.buyAmount = (value / 100 * this.assetsInfoShow).toFixed(this.price_precision);
          this.buyTransactionAmount = (value / 100 * this.assetsInfoShow / this.marketPrice).toFixed(this.number_precision);
        } else if (value == 0 && !this.inputControl) {
          this.buyAmount = "";
          this.buyTransactionAmount = "";
        }
      } else if (this.currChainValue == "2") {
        if (this.buyPrice && !this.inputControl) {
          this.buyTransactionAmountSelf = (value / 100 * this.assetsInfoShow).toFixed(this.price_precision);
          this.buyNum = (value / 100 * this.assetsInfoShow / this.buyPrice).toFixed(this.number_precision);
        }
        if (value == 0 && !this.inputControl) {
          this.buyNum = "";
          this.buyTransactionAmountSelf = "";
        }
      }
    },
    // 卖出滑块变化
    onSellSliderChange(value) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      let tempMarketPrice = new _bignumber.default(this.marketPrice);
      if (value == 0) {
        this.sellNum = "";
        this.sellTransactionAmount = "";
        this.sellTransactionAmountSelf = "";
        return;
      }
      if (this.currChainValue == "1") {
        if (value && !this.inputControl) {
          if (value == 100) {
            this.sellNum = this.assetsInfoShowSell.toString().substring(0, this.assetsInfoShowSell.toString().indexOf(".") + this.number_precision + 1);
          } else {
            this.sellNum = (value * 0.01 * this.assetsInfoShowSell).toFixed(this.number_precision);
          }
          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            this.sellNum = this.assetsInfoShowSell;
          }
          let tempSellNum = new _bignumber.default(this.sellNum);
          let tempValue3 = tempSellNum.times(tempMarketPrice).toNumber();
          if (tempValue3.toString().indexOf(".") > 0) {
            this.sellTransactionAmount = tempValue3.toString().substring(0, tempValue3.toString().indexOf(".") + this.price_precision + 1);
          } else {
            this.sellTransactionAmount = tempValue3;
          }
        } else if (value == 0 && !this.inputControl) {
          this.sellNum = "";
          this.sellTransactionAmount = "";
        }
      } else if (this.currChainValue == "2") {
        if (!this.inputControl) {
          if (value == 100) {
            this.sellNum = this.assetsInfoShowSell.toString().substring(0, this.assetsInfoShowSell.toString().indexOf(".") + this.number_precision + 1);
          } else {
            this.sellNum = (value / 100 * this.assetsInfoShowSell).toFixed(this.number_precision);
          }
          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            this.sellNum = this.assetsInfoShowSell;
          }
          let tempSellNum = new _bignumber.default(this.sellNum);
          let tempSellPrice = new _bignumber.default(this.sellPrice);
          let tempValue3 = tempSellNum.times(tempSellPrice);
          if (this.sellPrice) {
            if (tempValue3.toFixed(this.price_precision).indexOf(".") > 0) {
              this.sellTransactionAmountSelf = tempValue3.toFixed(this.price_precision).substring(0, tempValue3.toString().indexOf(".") + this.price_precision + 1);
            } else {
              this.sellTransactionAmountSelf = tempValue3.toFixed(this.price_precision);
            }
          }
        } else {
          // this.sellNum = "";
          // this.sellTransactionAmountSelf = "";
        }
      }
    },
    // 市价交易额变化
    onBuyAmountChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;
      let tempInput = new _bignumber.default(val);
      let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
      let tempVal = tempInput.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(0));
      let tempValV3 = "0";
      if (this.buyAmount == "0") {
        tempValV3 = "0";
      } else if (this.buyAmount != "" && this.marketInfoShow.last) {
        let bigBuyNum = new _bignumber.default(this.buyAmount);
        let bigMarketInfoShow = new _bignumber.default(this.marketInfoShow.last);
        tempValV3 = bigBuyNum.div(bigMarketInfoShow).toNumber().toFixed(this.number_precision);
      } else {
        tempValV3 = "0";
      }
      this.buyTransactionAmount = tempValV3;
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 限价买入量变化
    onBuyNumChange(val) {
      //买入金额6 buyTransactionAmountSelf
      // 买入价2  buyPrice
      if (!this.buyPrice || !this.buyNum) {
        this.buySliderValue = 0;
        this.buyTransactionAmountSelf = "";
        return;
      }
      this.inputControl = true;
      let tempInput = new _bignumber.default(this.buyNum);
      let tempPrice = new _bignumber.default(this.buyPrice);
      let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
      let tempR = tempInput.times(tempPrice);
      let tempAmout = tempR.toNumber();
      this.buyTransactionAmountSelf = this.ceilNumber(tempAmout, this.price_precision);
      let tempValue = tempR.toNumber() / tempAssetsInfoShow * 100;
      this.buySliderValue = Number(tempValue.toFixed(2));
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    // 限价卖出量变化
    onSellNumChange(val) {
      if (!val) {
        this.inputControl = true;
        this.sellSliderValue = 0;
        this.sellTransactionAmount = "";
        this.sellTransactionAmountSelf = "";
        return;
      }

      //卖出金额10 sellTransactionAmount
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      let tempMarketPrice = new _bignumber.default(this.marketPrice);
      let tempInput = new _bignumber.default(val);
      if (this.userInfo) {
        this.inputControl = true;
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShowSell);
        let tempValue = tempInput.div(tempAssetsInfoShow);
        this.sellSliderValue = Number((tempValue.toNumber() * 100).toFixed(0));
      }
      if (this.currChainValue == "1") {
        let tempValue2 = tempInput.times(tempMarketPrice).toNumber();
        if (tempValue2.toString().indexOf(".") > 0) {
          this.sellTransactionAmount = tempValue2.toString().substring(0, tempValue2.toString().indexOf(".") + this.price_precision + 1);
        } else {
          this.sellTransactionAmount = tempValue2;
        }
      } else {
        if (this.sellPrice) {
          let tempSellPrice = new _bignumber.default(this.sellPrice);
          let tempValue3 = tempInput.times(tempSellPrice);
          if (tempValue3.toString().indexOf(".") > 0) {
            this.sellTransactionAmountSelf = tempValue3.toFixed(this.price_precision).substring(0, tempValue3.toFixed(this.price_precision).indexOf(".") + this.price_precision + 1);
          } else {
            this.sellTransactionAmountSelf = tempValue3.toFixed(this.price_precision);
          }
        }
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onSellPriceChange() {
      // 卖出价格 sellPrice   卖出数量 sellNum  卖出金额 sellTransactionAmountSelf
      if (this.sellPrice && this.sellNum) {
        let tempValue = this.sellPrice * this.sellNum;
        if (tempValue.toString().indexOf(".") > 0) {
          this.sellTransactionAmountSelf = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.price_precision + 1);
        } else {
          this.sellTransactionAmountSelf = tempValue;
        }
      }
    },
    // 市价买入数量变化
    onBuyTotalChange(val) {
      this.marketPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
      this.inputControl = true;

      // 买入 预计开仓数量
      let tempValV2 = "0";
      if (this.buyTransactionAmount == "0" || this.buyTransactionAmount == "") {
        tempValV2 = "0";
        this.buyAmount = "";
      } else if (this.buyTransactionAmount != "" && this.marketInfoShow.last) {
        let bigBuyAmount = new _bignumber.default(this.buyTransactionAmount);
        let bigMarketInfoShow = new _bignumber.default(JSON.parse(JSON.stringify(this.marketPrice)));
        tempValV2 = bigBuyAmount.times(bigMarketInfoShow).toNumber();
        this.buyAmount = tempValV2.toFixed(this.price_precision);
      } else {
        tempValV2 = "0";
        this.buyAmount = "";
      }
      let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
      let tempBuyAmount = new _bignumber.default(this.buyAmount);
      let tempVal = tempBuyAmount.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(2));

      // 买入滑块
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onBuyNumChangeSelf() {
      // 限价买入价格2 buyPrice
      //限价买入金额6 buyTransactionAmountSelf
      let tempValV3 = "0";
      this.inputControl = true;
      let bigBuyNum = new _bignumber.default(this.buyTransactionAmountSelf);
      if (this.buyTransactionAmountSelf == "0") {
        tempValV3 = "0";
      } else if (this.buyTransactionAmountSelf != "" && this.buyPrice) {
        let bigBuyPrice = new _bignumber.default(this.buyPrice);
        let tempValV4 = bigBuyNum.div(bigBuyPrice).toNumber();
        tempValV3 = this.ceilNumber(tempValV4, this.number_precision);
      } else {
        tempValV3 = "0";
      }
      let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShow);
      let tempVal = bigBuyNum.div(tempAssetsInfoShow).toNumber();
      let tempVal2 = JSON.parse(JSON.stringify(tempVal)) * 100;
      if (tempVal2 > 100) {
        tempVal2 = 100;
      }
      this.buySliderValue = Number(tempVal2.toFixed(2));
      this.buyNum = tempValV3;

      // 买入滑块
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onSellTransactionAmountChange(val) {
      // 卖出数量9 sellNum
      //卖出金额10 sellTransactionAmount
      //可用数量 assetsInfoShowSell
      let tempVal = "0";
      this.inputControl = true;
      if (this.sellTransactionAmount == "" || this.sellTransactionAmount == 0) {
        tempVal = "0";
      } else {
        let tempPrice = JSON.parse(JSON.stringify(this.marketInfoShow.last));
        let bigMarketInfoShow = new _bignumber.default(tempPrice);
        let tempSellTransactionAmount = new _bignumber.default(this.sellTransactionAmount);
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShowSell);
        tempVal = tempSellTransactionAmount.div(bigMarketInfoShow).toNumber();
        let tempVal2 = tempSellTransactionAmount.div(bigMarketInfoShow).div(tempAssetsInfoShow);
        this.sellNum = tempVal.toString().substring(0, tempVal.toString().indexOf(".") + this.number_precision + 1);
        this.sellSliderValue = Number((tempVal2 * 100).toFixed(2));
      }
      setTimeout(() => {
        this.inputControl = false;
      }, 1000);
    },
    onSellAmoutSelfChange(val) {
      let tempVal = "0";
      this.inputControl = true;
      if (this.sellTransactionAmountSelf == "" || this.sellTransactionAmountSelf == 0 || this.sellPrice == "" || this.sellPrice == 0) {
        tempVal = "0";
      } else {
        let tempPrice = JSON.parse(JSON.stringify(this.sellPrice));
        let bigMarketInfoShow = new _bignumber.default(tempPrice);
        let tempSellTransactionAmount = new _bignumber.default(this.sellTransactionAmountSelf);
        let tempAssetsInfoShow = new _bignumber.default(this.assetsInfoShowSell);
        tempVal = tempSellTransactionAmount.div(bigMarketInfoShow).toNumber();
        let tempVal2 = tempSellTransactionAmount.div(bigMarketInfoShow).div(tempAssetsInfoShow);
        this.sellNum = tempVal.toString().substring(0, tempVal.toString().indexOf(".") + this.number_precision + 1);
        this.sellSliderValue = Number((tempVal2 * 100).toFixed(2));
      }
    },
    formatTooltip(val) {
      return val + "%";
    },
    onTrade(type) {
      // 1买入 2卖出
      if (type == 1) {
        if (this.currChainValue == "1") {
          if (this.buyAmount == "" || Number(this.buyAmount == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入交易额"),
              type: "error"
            });
            return;
          }
          if (Number(this.buyAmount) > Number(this.assetsInfoShow)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.buyBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "buy",
            trust_type: "1",
            // 1-普通|2-立即取消
            assets_type: "1",
            // 1-币币|2-杠杆|3-杠杆爆仓
            type: "2",
            //  1-限价单|2-市价单
            total: this.buyAmount // 购买数量
          };

          (0, _index.apiTrustStore)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.buyBtnLoading = false;
              this.reSetData();
              this.getAssets();
              this.$emit("on-trade-success", "2");
            } else {
              this.buyBtnLoading = false;
            }
          });
        } else if (this.currChainValue == "2") {
          if (this.buyPrice == "" || Number(this.buyPrice == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入买入价格"),
              type: "error"
            });
            return;
          }
          if (this.buyNum == "" || Number(this.buyNum == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入买入量"),
              type: "error"
            });
            return;
          }
          if (Number(this.buyTransactionAmountSelf) > Number(this.assetsInfoShow)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.buyBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "buy",
            trust_type: "1",
            // 1-普通|2-立即取消
            assets_type: "1",
            // 1-币币|2-杠杆|3-杠杆爆仓
            type: "1",
            //  1-限价单|2-市价单
            num: this.buyNum,
            // 购买数量
            price: this.buyPrice
          };
          (0, _index.apiTrustStore)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.buyBtnLoading = false;
              this.getAssets();
              this.reSetData();
              this.$emit("on-trade-success", "1");
            } else {
              this.buyBtnLoading = false;
            }
          });
        }
      } else {
        // 2 卖出

        if (this.currChainValue == "1") {
          if (this.sellNum == "" || Number(this.sellNum == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入卖出量"),
              type: "error"
            });
            return;
          }
          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.sellBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "sell",
            trust_type: "1",
            // 1-普通|2-立即取消
            assets_type: "1",
            // 1-币币|2-杠杆|3-杠杆爆仓
            type: "2",
            //  1-限价单|2-市价单
            num: this.sellNum // 购买数量
          };

          (0, _index.apiTrustStore)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.reSetData();
              this.getAssets();
              this.$emit("on-trade-success", "2");
            } else {
              this.sellBtnLoading = false;
            }
            this.sellBtnLoading = false;
          });
        } else if (this.currChainValue == "2") {
          if (this.sellPrice == "" || Number(this.sellPrice == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入卖出价"),
              type: "error"
            });
            return;
          }
          if (this.sellNum == "" || Number(this.sellNum == 0)) {
            (0, _message.showMessage)({
              message: this.translateTitle("请输入卖出量"),
              type: "error"
            });
            return;
          }
          if (Number(this.sellNum) > Number(this.assetsInfoShowSell)) {
            (0, _message.showMessage)({
              message: this.translateTitle("可用余额不足"),
              type: "error"
            });
            return;
          }
          this.sellBtnLoading = true;
          let params = {
            symbol_id: this.swap_symbol_id,
            side: "sell",
            trust_type: "1",
            // 1-普通|2-立即取消
            assets_type: "1",
            // 1-币币|2-杠杆|3-杠杆爆仓
            type: "1",
            //  1-限价单|2-市价单
            num: this.sellNum,
            // 购买数量
            price: this.sellPrice
          };
          (0, _index.apiTrustStore)(params).then(res => {
            if (res.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle("下单成功"),
                type: "success"
              });
              this.sellBtnLoading = false;
              this.reSetData();
              this.getAssets();
              this.$emit("on-trade-success", "1");
            } else {
              this.sellBtnLoading = false;
            }
            this.sellBtnLoading = false;
          });
        }
      }
    },
    goLogin(type) {
      if (type == 1) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      } else {
        this.$router.push(this.routeSplicing(this.language, "register"));
      }
    },
    onChainChange(value) {
      this.inputControl = true;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.currChainValue = value;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.sellTransactionAmountSelf = "";
      this.sellTransactionAmount = "";
      this.buyTransactionAmount = "";
      this.buyTransactionAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    reSetData() {
      this.inputControl = true;
      this.sellSliderValue = 0;
      this.buySliderValue = 0;
      this.buyNum = "";
      this.buyPrice = "";
      this.buyAmount = "";
      this.sellPrice = "";
      this.sellNum = "";
      this.sellTransactionAmountSelf = "";
      this.sellTransactionAmount = "";
      this.buyTransactionAmount = "";
      this.buyTransactionAmountSelf = "";
      setTimeout(() => {
        this.inputControl = false;
      }, 300);
    },
    async getStaticTableInfo(sv_id) {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(sv_id);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
        if (this.swap_symbol_id) {
          this.number_precision = this.coinInfoMap[this.swap_symbol_id].number_precision;
          this.price_precision = this.coinInfoMap[this.swap_symbol_id].price_precision;
        }
        // this.coinInfoList = JSON.parse(JSON.stringify(data));

        // for (const key in this.coinInfoList) {
        //   if (this.coinInfoList[key].swap_symbol_id == this.swap_symbol_id) {
        //     this.from_item_id = this.coinInfoList[key].from_item_id;
        //     this.to_item_id = this.coinInfoList[key].to_item_id;
        //   }
        // }
      }
    }
  }
};
exports.default = _default;