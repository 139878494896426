<template>
  <section class="web-third-section">
    <div class="background-image">
      <!-- Replace with your actual background image -->
      <img
        src="@/assets/img/maskexcard/c10bec8ecf730f0d58c7fc4a94dd2116.png"
        class="background-image"
        alt="Background Image"
      />
    </div>
    <div class="section-part left-part">
      <div class="part-content">
        <h2 class="part-title">How It Works</h2>
        <p class="part-description">
          The MaskEX Virtual Card, powered by MasterCard, grants easy and secure
          access to your funds as per your needs, making it the ultimate
          accessory for your crypto exploration.
        </p>
      </div>
    </div>
    <div class="section-part right-part">
      <img
        src="@/assets/img/maskexcard/986e4dd04f282c78248a3974a3e039d8.png"
        alt="Right Image"
      />
    </div>
  </section>
</template>

<script>
export default {
  // Third section component logic
};
</script>

<style scoped>
/* Third section styles */
.web-third-section {
  position: relative; /* Set the position to relative */
  width: 100%;
  height: 600px;
  display: flex;
  background: #f5f7fa;
}

.section-part {
  flex: 1;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  display: inline-flex;
  /* align-items: center; */
  gap: 84px;
  z-index: 2; /* Set a higher z-index to place it on top of the background image */
}
.background-image {
  position: absolute; /* Set the position to absolute */
  width: 100%;
  height: 600px;
  flex-shrink: 0;
  z-index: 1; /* Set a lower z-index to place it behind the content parts */
  /* object-fit: cover; */
}

.left-part {
  width: 400px;
  height: 360px;
  flex-shrink: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 360px;
  margin-top: 200px;
  margin-right: 84px;
  gap: 100px;
}

.right-part img {
  /* max-height: 100%; */
  width: 516px;
  height: 432px;
  flex-shrink: 0;
  margin-right: 360px;
}

.part-content {
  /* text-align: left; */
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
}

.part-title {
  color: black;
  font-size: 24px;
  font-family: "DIN Pro";
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.part-description {
  color: black;
  font-family: "DIN Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
