"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _message = require("@/components/dialog/message.js");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _settings = require("@/model/settings");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _vuex = require("vuex");
var _getAssetsCoin = require("../ucenter/common/getAssetsCoin");
var _index = require("@/model/assets/index");
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {
    NoData: _NoData.default,
    QrcodeVue: _qrcode.default
  },
  data() {
    return {
      isScroll: false,
      createVisible: false,
      modifyVisible: false,
      delVisible: false,
      modifyLoading: false,
      delLoading: false,
      creatLoading: false,
      pageLoading: true,
      coinData: {},
      addressData: {
        address: "",
        bak: "",
        qrcode_url: "",
        transactions: "",
        chain_tag: "",
        ua_id: ""
      },
      chain_tag: "erc20",
      bak: "",
      new_bak: "",
      addressActive: "",
      coinMaps: {},
      addressList4Tag: {},
      time: "",
      active: "",
      chainMap: {},
      item_id: "10007",
      acitiveChain: "0",
      data: {
        itemDetail: {
          coin_url: ""
        }
      },
      coinInfo: [],
      chainList: [{
        address: "",
        qrcode_url: ""
      }],
      qrcodeList: [],
      addressList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    }),
    confirmNum() {
      if (JSON.stringify(this.data) !== "{}") {
        let {
          confirm_num
        } = this.data.itemDetail;
        return confirm_num;
      } else {
        return "--";
      }
    },
    coinInfoComputed() {
      let tempObj = [];
      for (const key in this.coinInfo) {
        if (this.data.itemDetail.coin_id == this.coinInfo[key].value) {
          tempObj.push({
            key: this.coinInfo[key].value,
            value: this.coinInfo[key].label,
            label: this.coinInfo[key].label.toUpperCase()
          });
        }
      }
      // if (tempObj.length > 0) {
      //   this.chain_tag = tempObj[0].label;
      // } else {
      //   this.chain_tag = this.data.itemDetail.default_chain.toUpperCase();
      // }
      let erc20 = {};
      let trc20 = {};
      tempObj.forEach(ele => {
        if (ele.label === "ERC20") erc20 = ele;
        if (ele.label === "TRC20") trc20 = ele;
      });
      let newtempObj = tempObj.filter(ele => {
        return ele.label != "ERC20" && ele.label != "TRC20";
      });
      if (JSON.stringify(erc20) != "{}") {
        newtempObj.push(erc20);
      }
      if (JSON.stringify(trc20) != "{}") {
        newtempObj.unshift(trc20);
      }
      return newtempObj;
    }
  },
  async created() {
    this.data = await (0, _getAssetsCoin.getCurrentCoin)();
    await this.getStaticChainInfo();
    this.coinData = await (0, _getAssetsCoin.getCoinInfo)();
    for (let item in this.coinData) {
      this.coinData[item].capital_name = this.coinData[item].itemDetail.capital_name;
      // this.coinData[item].language = this.coinData[item].itemDetail.language[
      //   "display_" + this.language
      // ];
      for (const key in this.coinMaps) {
        if (this.coinData[item].item_tag == this.coinMaps[key].coin_id) {
          this.coinData[item].language = this.coinMaps[key].language;
        }
      }
    }
    for (let key in this.coinData) {
      if (this.coinData[key].item_name == "usdt") {
        this.data = this.coinData[key];
      }
    }
    console.log(this.coinData);
    this.selectChange(this.item_id);
  },
  beforeDestroy() {},
  destroyed() {},
  methods: {
    //新增确认
    async onCreateConfirm() {
      if (this.bak) {
        this.creatLoading = true;
        const req = {
          item_id: this.data.item_id,
          bak: this.bak
        };
        // if (!this.data.itemDetail.multi_chain_status) {
        //   this.active = "";
        // } else {
        //   req.chain_tag = this.chain_tag;
        // }
        req.chain_tag = this.chain_tag;
        const {
          status,
          data
        } = await (0, _index.apiCreateFinanceAddress)(req);
        this.creatLoading = false;
        if (status == 200) {
          (0, _message.showMessage)({
            message: this.translateTitle(`生成新充币地址成功`),
            type: "success"
          });
          this.getAddress(this.chain_tag);
          this.bak = "";
          this.createVisible = false;
        }
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`请输入新地址备注名`),
          type: "error"
        });
      }
    },
    scrollAdress() {
      this.isScroll = true;
    },
    getScrollClass() {
      let tempVal = "";
      if (this.addressList.length > 6) {
        tempVal = "scroll-calss";
      } else {
        tempVal = "";
      }
      return tempVal;
    },
    onAddressChange(val) {
      //   this.$refs.scorllRef.scrollTo(0,0)
      // //   window.scrollTo(0,0);   // chrome
      // // document.body.scrollTop = 0
      this.addressActive = val;
      this.addressList.forEach(item => {
        if (val == item.ua_id) {
          this.addressData = item;
          this.ua_id = val;
        }
      });
    },
    onModifyClose() {
      this.new_bak = "";
    },
    onCreate() {
      this.createVisible = true;
      this.time = parseInt(new Date().getTime() / 1000);
      this.bak = this.translateTitle(`新地址`) + this.time;
      this.isModify = false;
    },
    // 选项卡切换
    onTypeChange(val) {
      this.isModify = false;
      this.chain_tag = val;
      // this.getAddress(val);
      this.onChangeTag(val);
    },
    //切换链选项卡
    onChangeTag(tagName) {
      this.active = tagName.toUpperCase();
      this.addressList = this.addressList4Tag[tagName.toLowerCase()];
      // this.addressList = data;  //pangpang注释
      // this.addressOptions = data; //pangpang注释

      if (!this.isModify) {
        if (this.addressList4Tag[tagName.toLowerCase()].length > 0) {
          this.addressData = this.addressList4Tag[tagName.toLowerCase()][0];
          this.ua_id = this.addressData.ua_id;
          this.addressActive = this.addressData.ua_id;
          // this.onAddressChange(this.ua_id);
        } else {
          this.addressList = [];
          this.addressData = [];
        }
      } else {
        this.onAddressChange(this.ua_id);
      }
    },
    onFilterNewBak(val) {
      this.new_bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    onFilterBak(val) {
      this.bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    mainClose() {
      this.isModify = false;
      // this.chain_tag = "erc20";
    },

    //修改确认
    async onModifyConfirm() {
      if (this.new_bak) {
        if (this.new_bak == this.addressData.bak) {
          this.modifyVisible = false;
          return;
        } else {
          const req = {
            ua_id: this.ua_id,
            bak: this.new_bak
          };
          this.modifyLoading = true;
          const {
            status,
            data
          } = await (0, _index.apiSaveFinanceAddress)(req);
          this.modifyLoading = false;
          if (status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle(`修改成功`),
              type: "success"
            });
            this.getAddress(this.chain_tag);
            this.new_bak = "";
            this.isModify = true;
            this.modifyVisible = false;
          }
        }
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`请输入新备注名`),
          type: "error"
        });
      }
    },
    //删除确认
    async onDeleteConfirm() {
      this.delLoading = true;
      const req = {
        ua_id: this.ua_id
      };
      const {
        status,
        data
      } = await (0, _index.apiDelFinanceAddress)(req);
      this.delLoading = false;
      if (status == 200) {
        this.getAddress(this.chain_tag);
        this.delVisible = false;
        (0, _message.showMessage)({
          message: this.translateTitle(`删除成功`),
          type: "success"
        });
      }
    },
    onModify() {
      this.modifyVisible = true;
      // this.selectChange(this.ua_id);
      this.new_bak = this.addressData.bak;
    },
    onDelete() {
      this.delVisible = true;
      this.isModify = false;
    },
    onChainClick(item) {
      this.acitiveChain = item;
    },
    async getStaticChainInfo() {
      this.coinInfo = [];
      await (0, _settings.apiGetStaticTableInfo)(1).then(({
        data
      }) => {
        this.coinMaps = data;
      });
      await (0, _settings.apiGetStaticTableInfo)(56).then(({
        data
      }) => {
        this.chainMap = data;
      });
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      await (0, _settings.apiGetStaticTableInfo)(57).then(({
        data
      }) => {
        for (const key in data) {
          for (let item in this.chainMap) {
            if (data[key].chain.toUpperCase() == this.chainMap[item].chain_tag.toUpperCase() && data[key].status == 1 && this.chainMap[item].status == 1) {
              this.coinInfo.push({
                label: data[key].chain.toUpperCase(),
                value: data[key].coin_id,
                rate_out: data[key].fee,
                is_default: data[key].is_default,
                chain_status: this.chainMap[item].status,
                status: data[key].status,
                minout: data[key].minout,
                maxout: data[key].maxout
              });
            }
          }
        }
      });
    },
    // 复制成功
    copySuccess() {
      this.$message({
        message: this.translateTitle("复制成功"),
        type: "success"
      });
    },
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制失败`)
      });
    },
    // 选择币种
    async selectChange(val) {
      this.item_id = val;
      this.data = this.coinData[val];
      let chain_tag_temp = [];
      let chain_tag_strings = "";
      // if (!val) {
      this.coinInfoComputed.forEach(item => {
        chain_tag_temp.push(item.value);
      });
      chain_tag_strings = chain_tag_temp.join(",");
      const req = {
        item_id: val,
        chain_tag_list: chain_tag_strings
      };
      this.chainList = [];
      this.qrcodeList = [];
      const {
        status,
        data
      } = await (0, _index.apiGetFinanceAddressList)(req);
      if (status == 200) {
        this.pageLoading = false;
        data.forEach(ele => {
          ele.chain_tag = ele.chain_tag.toUpperCase();
          this.chainList.push(ele);
        });
        this.active = this.chain_tag.toUpperCase();
        chain_tag_temp.forEach(tag => {
          this.addressList4Tag[tag.toLowerCase()] = [];
          data.forEach(ele => {
            if (tag == ele.chain_tag) {
              this.addressList4Tag[tag.toLowerCase()].push(ele);
            }
          });
        });
        this.addressList = this.addressList4Tag[this.chain_tag.toLowerCase()];
        if (!this.isModify) {
          if (this.addressList4Tag[this.chain_tag.toLowerCase()].length > 0) {
            this.addressData = this.addressList4Tag[this.chain_tag.toLowerCase()][0];
            this.ua_id = this.addressData.ua_id;
            this.addressActive = this.addressData.ua_id;
            // this.onAddressChange(this.ua_id);
          } else {
            this.addressList = [];
            this.addressData = [];
          }
        } else {
          this.onAddressChange(this.ua_id);
        }
      }
      this.acitiveChain = 0;
    },
    // 获取充币地址
    async getAddress(val) {
      // this.chain_tag = val;  //pangpang注释
      const item_id = this.data.item_id;
      let params = {
        item_id: item_id
      };
      // if (!this.data.itemDetail.multi_chain_status) {
      //   this.active = "";
      // } else {
      //   params.chain_tag_list = this.chain_tag;
      // }
      let chain_tag_temp = [];
      let chain_tag_strings = "";
      // if (!val) {
      this.coinInfoComputed.forEach(item => {
        chain_tag_temp.push(item.value);
      });
      chain_tag_strings = chain_tag_temp.join(",");
      params.chain_tag_list = chain_tag_strings;
      const {
        status,
        msg,
        data
      } = await (0, _index.apiGetFinanceAddressList)(params);
      // apiGetFinanceAddressList(params).then(({ status, msg, data }) => {

      if (status === 200) {
        // if (val) {                          //pangpang 注释
        //   this.active = val.toUpperCase();
        // }
        this.active = this.chain_tag.toUpperCase();
        chain_tag_temp.forEach(tag => {
          this.addressList4Tag[tag.toLowerCase()] = [];
          data.forEach(ele => {
            if (tag.toLowerCase() == ele.chain_tag) {
              this.addressList4Tag[tag.toLowerCase()].push(ele);
            }
          });
        });
        this.addressList = this.addressList4Tag[this.chain_tag.toLowerCase()];
        if (!this.isModify) {
          if (this.addressList4Tag[this.chain_tag.toLowerCase()].length > 0) {
            this.addressData = this.addressList4Tag[this.chain_tag.toLowerCase()][0];
            this.ua_id = this.addressData.ua_id;
            this.addressActive = this.addressData.ua_id;
            // this.onAddressChange(this.ua_id);
          } else {
            this.addressList = [];
            this.addressData = [];
          }
        } else {
          this.onAddressChange(this.ua_id);
        }
        this.dialogVisible = true;
      } else {
        (0, _message.showMessage)({
          message: msg ? msg : status
        });
      }
    }
  }
};
exports.default = _default;