"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _market = require("@/model/exchange/market.js");
var _orderDetails = _interopRequireDefault(require("../../../bill-center/components/orderDetails.vue"));
var _index = require("@/model/exchange/index");
var _order = require("@/model/currencyBasedContract/order.js");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _index2 = require("@/utils/index.js");
var _vuex = require("vuex");
var _message = require("@/components/dialog/message.js");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _settings = require("@/model/settings");
var _keymap = require("@/assets/js/keymap.js");
var _digital = require("@/assets/js/digital");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _i18n = require("@/assets/js/i18n");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _index3 = require("@/model/currencyBasedContract/index");
var _stationSetting = require("@/assets/js/stationSetting");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _index4 = require("@/model/assets/index");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {
    NoData: _NoData.default,
    InputNumber: _InputNumber.default,
    OrderDetails: _orderDetails.default
  },
  data() {
    return {
      tt: {},
      lockReconnect: false,
      recordDeliveryTable1: false,
      planOrderList_create_time: "",
      planOrderList_symbol_id: "",
      planOrderList_open_side: "",
      ctimeAndEtime: [],
      ctimeAndEtime4History: [],
      open_side_array: [{
        value: 1,
        label: "买涨"
      }, {
        value: 2,
        label: "买跌"
      }],
      create_time_array: [{
        value: 86400,
        label: "今天"
      }, {
        value: 7 * 86400,
        label: "7天"
      }, {
        value: 30 * 86400,
        label: "1月"
      }, {
        value: 3 * 30 * 86400,
        label: "3月"
      }],
      coinData: {},
      isDestoryWS: false,
      marketList: [],
      // swap_symbol_id: 1010,
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--"
          },
          list: []
        }
      },
      from_symbol: "",
      to_symbol: "",
      from_item_id: "",
      to_item_id: "",
      closePositionTypeMaps: _keymap.closePositionTypeMaps,
      parseTime: _index2.parseTime,
      ws: null,
      tableData: [],
      confirmMessage: "是否确认平仓此订单？",
      tableDataComputed: [],
      orderLoading: true,
      dialogVisible: false,
      appendMoneydialog: false,
      setProfitPricedialog: false,
      row_swap_symbol_id: this.swap_symbol_id,
      beforeBailNumber: "",
      marketPriceNow: "",
      settlement_precision: "",
      fee_rate: "",
      contract_value: "",
      liquidation_risk_rate: "",
      afterBailNumber: "--",
      inputProfitPrice: "",
      inputLossPrice: "",
      price_precision: "",
      contract_plan_num: 0,
      contract_position_num: 0,
      contract_multiple_num: "",
      beforeLiquidationPrice: "",
      plan_order_id: "",
      afterLiquidationPrice: "--",
      rowData: {},
      hidenCancelChecked: false,
      checked: false,
      layout: "total, prev, pager, next",
      symbolId: "",
      position_order_id: "",
      bail_number: "",
      contractMultipleNum: {},
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 10,
        pageTotal: 1
      },
      apiAssetsInfo: {},
      tabList: [{
        value: "1",
        label: "持仓"
      }, {
        value: "2",
        label: "当前委托"
      }, {
        value: "3",
        label: "历史委托"
      }, {
        value: "4",
        label: "平仓记录"
      }],
      currIndex: "1",
      getSymbolList: false,
      symbolList: {},
      coinInfoMap: {},
      triggerSideMap: {
        1: "大于触发价",
        2: "小于触发价"
      },
      tradeMap: {
        1: "市价",
        2: "限价"
      },
      sideMap: {
        1: "买涨",
        2: "买跌"
      },
      statusMap: {
        0: "未成交",
        1: "待成交",
        2: "完全成交",
        3: "已撤销",
        4: "失败"
      }
    };
  },
  async created() {
    let tempCookies = _jsCookie.default.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      // await this.getToken();
      await this.initURL();
      this.initWs();
    } else {
      this.token = _jsCookie.default.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
    await this.getItemList();
    await this.getCoinInfoMap();
    await this.getTradePairList();
    this.getOrderNumber();
    this.getAssets();
    let {
      userInfo
    } = await Account.getUserInfo();
    if (userInfo) {
      this.initData(this.checked);
      // this.startTimer();
    }
  },

  beforeDestroy() {
    // clearTimeout(timer);
  },
  mounted() {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    rowMarketInfoShow() {
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.row_swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (JSON.stringify(this.assetsInfo) != "{}" && this.assetsInfo.remain.list.length > 0) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (JSON.stringify(this.apiAssetsInfo) != "{}" && this.apiAssetsInfo.remain.list.length > 0) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.from_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    afterLiquidationPriceShow() {
      //强平价 =  1 / ((持仓张数×单张合约价值 × 1/开仓价 × (1 做多| -1做空) + 保证金 × (1 − 强平风险率))/持仓张数/单张合约价值 / (1 做多 | -1做空))
      let tempValue = "";
      let side = this.rowData.open_side == 1 ? 1 : -1;
      if (this.bail_number != "") {
        // tempValV2 持仓张数
        let tempOpenPrice = "";
        if (this.currIndex == 1) {
          tempOpenPrice = new _bignumber.default(this.rowData.open_price);
        } else {
          tempOpenPrice = new _bignumber.default(this.rowData.trigger_price);
        }
        let tempSide = new _bignumber.default(side);
        let bigMarketPrice = new _bignumber.default(this.rowData.open_price);
        let tempMultipleNum = new _bignumber.default(this.rowData.open_leverage);
        // let tempConsumerCosts = new BigNumber(
        //   Number(this.rowData.consumer_costs) + Number(this.bail_number)
        // );
        let tempConsumerCosts = new _bignumber.default(this.afterBailNumberShow);
        let tempLiquidationRiskRate = new _bignumber.default(Number(this.liquidation_risk_rate) / 10000);
        let tempContractValue = new _bignumber.default(this.contract_value);
        let tempQty = new _bignumber.default(this.rowData.qty);
        let one = new _bignumber.default(1);

        // 强平价 =  1 / { 1 / 开仓价 + [持仓方向 × 保证金 × (1 −强平⻛险率)/ 张数 / 单张合约价值] }

        let tempValV2 = one.div(one.div(tempOpenPrice).plus(tempSide.times(tempConsumerCosts).times(one.minus(tempLiquidationRiskRate)).div(tempQty).div(tempContractValue)));
        tempValue = tempValV2.toString();
        if (this.rowData.open_side == 1) {
          //做多 做多的时候小数位用全进
          tempValue = (Math.ceil(tempValue * Math.pow(10, this.price_precision)) / Math.pow(10, this.price_precision)).toFixed(this.price_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.price_precision + 1);
        }
      } else {
        tempValue = this.afterLiquidationPrice;
      }
      if (Number(tempValue) < 0) {
        tempValue = "0";
      }
      return tempValue;
    },
    afterBailNumberShow() {
      return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(this.settlement_precision);
    },
    //  未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)
    expectedProfit() {
      let tempValue = "--";
      let side = this.rowData.open_side == 1 ? 1 : -1;
      let tempOpenPrice = "";
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        if (this.currIndex == 1) {
          tempOpenPrice = new _bignumber.default(this.rowData.open_price);
        } else {
          tempOpenPrice = new _bignumber.default(this.rowData.trigger_price);
        }
        let tempInputProfitPrice = new _bignumber.default(this.inputProfitPrice);
        let tempContractValue = new _bignumber.default(this.contract_value);
        let tempSide = new _bignumber.default(side);
        let one = new _bignumber.default(1);
        let tempQty = new _bignumber.default(this.rowData.qty);
        // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

        tempValue = tempQty.times(tempContractValue).times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice))).times(tempSide).toNumber();
        if (this.rowData.open_side == 1) {
          tempValue = Math.ceil(tempValue * Math.pow(10, this.settlement_precision));
          tempValue = tempValue * 1.0 / Math.pow(10, this.settlement_precision);
          //做多 做多的时候小数位用全进
          // tempValue = (
          //   Math.ceil(tempValue * Math.pow(10, this.settlement_precision)) /
          //   Math.pow(10, this.settlement_precision)
          // ).toFixed(this.settlement_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.settlement_precision + 1);
        }

        // 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

        if (Number(tempValue) <= 0) {
          tempValue = "--";
        }
      }
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }
        tempValue = this.expectedProfit / this.rowData.bail_number * 100;
        tempValue = (0, _digital.floorNumber)(tempValue, 2);
        if (Number(tempValue) <= 0) {
          tempValue = "--";
        } else {
          tempValue = "+" + tempValue;
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏
    expectedLoss() {
      let tempValue = "--";
      let side = this.rowData.open_side == 1 ? 1 : -1;
      let tempOpenPrice = "";
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        if (this.currIndex == 1) {
          tempOpenPrice = new _bignumber.default(this.rowData.open_price);
        } else {
          tempOpenPrice = new _bignumber.default(this.rowData.trigger_price);
        }
        let tempInputProfitPrice = new _bignumber.default(this.inputLossPrice);
        let tempQty = new _bignumber.default(this.rowData.qty);
        let tempContractValue = new _bignumber.default(this.contract_value);
        let tempSide = new _bignumber.default(side);
        let one = new _bignumber.default(1);
        // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

        tempValue = tempQty.times(tempContractValue).times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice))).times(tempSide).toNumber();
        if (this.rowData.open_side == 1) {
          if (Number(tempValue) < 0) {
            //做多 做多的时候小数位用全进
            let tempValueABS = Math.abs(tempValue);
            tempValue = Math.ceil(tempValueABS * Math.pow(10, this.settlement_precision));
            tempValue = tempValue * 1.0 / Math.pow(10, this.settlement_precision);
            tempValue = tempValue * -1;
          }
          // tempValue =
          //   Math.round(tempValue * Math.pow(10, this.settlement_precision)) /
          //   Math.pow(10, this.settlement_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.settlement_precision + 1);
        }
        if (Number(tempValue) > 0) {
          tempValue = "--";
        } else if (Number(tempValue * -1) > this.rowData.bail_number) {
          tempValue = this.rowData.bail_number * -1;
        }
      }
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }
        tempValue = this.expectedLoss / this.rowData.bail_number * 100;
        tempValue = (0, _digital.ceilNumber)(tempValue, 2);
        if (Number(tempValue) < -100) {
          tempValue = -100;
        }
        if (Number(tempValue) > 0) {
          tempValue = "--";
        }
      }
      // inputLossPrice
      return tempValue;
    }
  },
  beforeDestroy() {
    this.isDestoryWS = true;
    this.global.ws.close();
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    onOpenOrderDetails(assets_type, row) {
      let tempRow = {
        order_info: {
          position_order_id: row.position_order_id
        },
        assets_type: assets_type
      };
      this.$refs.orderDetails.liquidationFuturesInfoClick(tempRow);
    },
    searchPlanOrderList() {
      this.initData();
    },
    resetPlanOrderList() {
      this.planOrderList_create_time = "";
      this.planOrderList_symbol_id = "";
      this.planOrderList_open_side = "";
      this.ctimeAndEtime = [];
      this.ctimeAndEtime4History = [];
      this.initData();
    },
    async getItemList() {
      const {
        status,
        data
      } = await (0, _index4.getStaticCoinList)();
      if (status == 200) {
        this.coinData = data;
      }
    },
    onOK() {
      if (this.dialogType == 1) {
        this.onClosePosition();
      } else if (this.dialogType == 2) {
        this.onCancelOrder();
      } else if (this.dialogType == 3) {
        this.onRevokeAllOrder();
      } else if (this.dialogType == 4) {
        this.onRevokePlan();
      }
    },
    goToRecharge() {
      window.open(this.routeSplicing(this.language, "ucenter/assets/recharge/usdt"));
    },
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    reset(type) {
      this.orderLoading = true;
      this.currIndex = type.toString();
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
      this.getOrderNumber();
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onProfitPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
          this.settlement_precision = this.symbolList[key].settlement_precision;
          this.contract_value = this.symbolList[key].contract_value;
          this.fee_rate = this.symbolList[key].fee_rate;
          this.liquidation_risk_rate = this.symbolList[key].liquidation_risk_rate;
        }
      }
      this.marketPriceNow = JSON.parse(JSON.stringify(this.rowMarketInfoShow.last));
      this.setProfitPricedialog = true;
    },
    onLossPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
          this.settlement_precision = this.symbolList[key].settlement_precision;
          this.contract_value = this.symbolList[key].contract_value;
          this.fee_rate = this.symbolList[key].fee_rate;
          this.liquidation_risk_rate = this.symbolList[key].liquidation_risk_rate;
        }
      }
      this.setProfitPricedialog = true;
    },
    async getCoinInfoMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.coinInfoMap = data;
      }
    },
    async getTradePairList() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(58);
      if (status == 200) {
        this.symbolList = data;
        this.getSymbolList = true;
        // this.contract_value = this.symbolList[
        //   this.swap_symbol_id
        // ].contract_value;
        // this.settlement_precision = this.symbolList[
        //   this.swap_symbol_id
        // ].settlement_precision;
      }
    },

    onSymbolChange(item) {
      this.$emit("on-symbol-change", item);
    },
    onSymbolClick(row) {
      let tempName = "";
      if (row.from_symbol_name) {
        tempName = row.from_symbol_name.toLowerCase() + "usd";
      } else {
        for (const key in this.coinInfoMap) {
          if (key == row.open_item_id) {
            tempName = this.coinInfoMap[key].item_name.toLowerCase() + "usd";
          }
        }
      }
      window.open(this.routeSplicing(this.language, "delivery/" + tempName));
    },
    onOpenConfirm(type, id) {
      this.dialogType = type;
      if (type == 1) {
        this.position_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认平仓此订单？");
      } else if (type == 2) {
        this.confirmMessage = this.translateTitle("是否确认全部平仓？");
      } else if (type == 3) {
        this.confirmMessage = this.translateTitle("是否确认全部撤销？");
      } else if (type == 4) {
        this.plan_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认撤销此委托单？");
      }
      this.dialogVisible = true;
    },
    onOpenAppendMoney(row) {
      this.from_item_id = row.open_item_id;
      this.to_item_id = row.settlement_item_id;
      this.from_symbol = this.coinInfoMap[row.open_item_id].item_name.toUpperCase();
      this.to_symbol = this.coinInfoMap[row.settlement_item_id].item_name.toUpperCase();
      this.swap_symbol_id = row.symbol_id;
      this.position_order_id = row.position_order_id;
      this.beforeBailNumber = row.bail_number;
      this.afterBailNumber = row.bail_number;
      this.beforeLiquidationPrice = row.liquidation_price;
      this.afterLiquidationPrice = row.liquidation_price;
      this.rowData = row;
      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
          this.settlement_precision = this.symbolList[key].settlement_precision;
          this.contract_value = this.symbolList[key].contract_value;
          this.fee_rate = this.symbolList[key].fee_rate;
          this.liquidation_risk_rate = this.symbolList[key].liquidation_risk_rate;
        }
      }
      this.appendMoneydialog = true;
    },
    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id
      };
      (0, _order.apiClosePosition)(params).then(res => {
        if (res.status == 200) {
          this.dialogVisible = false;
          (0, _message.showMessage)({
            message: this.translateTitle("平仓成功"),
            type: "success"
            // duration:10000000,
          });

          this.initData(this.checked);
        }
      });
    },
    onAppendMoney() {
      if (Number(this.bail_number) == 0) {
        (0, _message.showMessage)({
          message: this.translateTitle("请输入追加金额"),
          type: "error"
        });
        return;
      }
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number
      };
      (0, _order.apiAppendInterest)(params).then(res => {
        if (res.status == 200) {
          (0, _message.showMessage)({
            message: this.translateTitle("追加成功"),
            type: "success"
          });
          this.appendMoneydialog = false;
          this.initData(this.checked);
        }
      });
    },
    async startTimer() {
      const timer = setTimeout(() => {
        this.initData(this.checked);
        // this.getOrderNumber();
        this.getMultipleNum();
        this.startTimer();
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(timer);
      });
      this.$once("hook:destroyed", () => {
        clearTimeout(timer);
      });
    },
    async onCancelOrder() {
      const {
        status,
        data
      } = await (0, _order.apiAllClosePosition)();
      if (status == 200) {
        this.dialogVisible = false;
        (0, _message.showMessage)({
          message: this.translateTitle("平仓成功"),
          type: "success"
        });
        this.initData(this.checked);
      }
    },
    async onRevokeAllOrder() {
      const {
        status,
        data
      } = await (0, _order.apiRevokeAllPlan)();
      if (status == 200) {
        (0, _message.showMessage)({
          message: this.translateTitle("撤销成功"),
          type: "success"
        });
        this.dialogVisible = false;
        this.initData(this.checked);
      }
    },
    async onRevokePlan() {
      const req = {
        plan_order_id: this.plan_order_id
      };
      const {
        status,
        data
      } = await (0, _order.apiRevokePlan)(req);
      if (status == 200) {
        (0, _message.showMessage)({
          message: this.translateTitle("撤销成功"),
          type: "success"
        });
        this.dialogVisible = false;
        this.initData(this.checked);
      }
    },
    onSetPositionProfitLoss() {
      // if (this.inputLossPrice == "" && this.inputProfitPrice == "") {
      //   showMessage({ message: "请输入止盈价或止损价", type: "error" });
      //   return;
      // }
      if (this.currIndex == 1) {
        let params = {
          position_order_id: this.rowData.position_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: this.inputProfitPrice,
          stop_loss_price: this.inputLossPrice
        };
        (0, _order.apiSetPositionProfitLoss)(params).then(res => {
          if (res.status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle("设置成功"),
              type: "success"
            });
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          }
        });
      } else {
        let params = {
          plan_order_id: this.rowData.plan_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: this.inputProfitPrice,
          stop_loss_price: this.inputLossPrice
        };
        (0, _order.apiSetPlanProfitLoss)(params).then(res => {
          if (res.status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle("设置成功"),
              type: "success"
            });
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          }
        });
      }
    },
    onAreaChange(item) {
      this.orderLoading = true;
      this.tableData = [];
      this.currIndex = item.value;
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    getMultipleNum() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        symbol_id: this.checked ? this.swap_symbol_id : ""
      };
      (0, _order.apiGetOrderStatistic)(params).then(res => {
        if (res.status == 200) {
          this.contract_multiple_num = JSON.stringify(res.data.contract_multiple_num);
          localStorage.setItem("delivery-multiple-num", this.contract_multiple_num);
          this.contract_position_num = res.data.contract_position_info.total_num;
          this.contract_plan_num = res.data.contract_plan_info.total_num;
        }
      });
    },
    getOrderNumber() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        symbol_id: this.checked ? this.swap_symbol_id : ""
      };
      (0, _order.apiGetOrderStatistic)(params).then(res => {
        if (res.status == 200) {
          this.contract_position_num = res.data.contract_position_info.total_num;
          this.contract_plan_num = res.data.contract_plan_info.total_num;
        }
      });
    },
    async initData(checked = false) {
      this.orderLoading = true;
      let {
        userInfo
      } = await Account.getUserInfo();
      if (!userInfo) {
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      let temp_is_hide = 2;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
        temp_is_hide = 1;
      } else {
        temp_symbol_id = "";
        temp_is_hide = 2;
      }
      if (this.currIndex == 1) {
        const req = {
          symbol_id: temp_symbol_id,
          position_status: 1,
          page: this.pageInfo.pageCurrent,
          page_size: 100000
          // page_size: this.pageInfo.pageSize,
          // is_hide: temp_is_hide,
        };

        return (0, _order.apiGetOpenPositionList)(req).then(res => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach(ele => {
              // 存各个币种杠杆倍数

              this.contractMultipleNum[ele.symbol_id] = {
                open_leverage: ele.open_leverage
              };
              for (const key in this.symbolList) {
                if (this.symbolList[key].open_item_id == ele.open_item_id) {
                  ele.settlement_precision = this.symbolList[key].settlement_precision;
                  ele.contract_value = this.symbolList[key].contract_value;
                }
              }
              if (this.marketList.length > 0) {
                this.marketList.forEach(item => {
                  if (item.symbol_id == ele.symbol_id) {
                    ele.last_price = item.last;
                  }
                });
                // 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价)
                let tempContractValue = new _bignumber.default(ele.contract_value);
                let side = ele.open_side == 1 ? 1 : -1;
                let tempSide = new _bignumber.default(side);
                let one = new _bignumber.default(1);
                let oneHundred = new _bignumber.default(100);
                let tempQty = new _bignumber.default(ele.qty);
                let tempOpenPrice = new _bignumber.default(ele.open_price);
                let tempLastPrice = new _bignumber.default(ele.last_price);
                let tempSettlementPrecision = new _bignumber.default(ele.settlement_precision);

                // 未实现盈亏
                ele.position_profit_loss = tempQty.times(tempContractValue).times(one.div(tempOpenPrice).minus(one.div(tempLastPrice))).times(tempSide).toNumber().toFixed(ele.settlement_precision);
                // .toFixed(this.number_precision);
                let tempValue = new _bignumber.default(ele.position_profit_loss);
                ele.position_profit_loss_procent = tempValue.div(ele.bail_number).times(oneHundred).toFixed(2);
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            // this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        const req = {
          symbol_id: temp_symbol_id,
          order_status: 1,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: 100000
          // page_size: this.pageInfo.pageSize,
        };

        return (0, _order.apiGetPlanOrderList)(req).then(res => {
          if (res.status == 200) {
            for (const key in this.coinInfoMap) {
              res.data.list.forEach(ele => {
                this.marketList.forEach(item => {
                  if (item.symbol_id == ele.symbol_id) {
                    ele.last_price = item.last;
                  }
                });
                if (ele.open_item_id == key) {
                  ele.from_symbol_name = ele.symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.list;
            // this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 3) {
        let temp_order_status = "2,3,4";
        if (this.hidenCancelChecked) {
          temp_order_status = "2,4";
        } else {
          temp_order_status = "2,3,4";
        }
        // let temp_create_time = "";
        // if (this.planOrderList_create_time) {
        //   temp_create_time =
        //     Date.parse(new Date()) / 1000 - this.planOrderList_create_time;
        // }
        let create_time = "";
        let end_time = "";
        if (this.ctimeAndEtime4History) {
          const [start, end] = this.ctimeAndEtime4History;
          const end2 = end + 86399000;
          create_time = parseInt(start / 1000);
          end_time = parseInt(end2 / 1000);
        }
        const req = {
          symbol_id: this.planOrderList_symbol_id,
          open_side: this.planOrderList_open_side,
          create_time: create_time,
          end_time: end_time,
          // create_time: temp_create_time,
          order_status: temp_order_status,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize
        };
        return (0, _order.apiGetPlanOrderList)(req).then(res => {
          if (res.status == 200) {
            res.data.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[key].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
                if (key == ele.settlement_item_id) {
                  ele.to_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 4) {
        let create_time = "";
        let end_time = "";
        if (this.ctimeAndEtime) {
          const [start, end] = this.ctimeAndEtime;
          const end2 = end + 86399000;
          create_time = parseInt(start / 1000);
          end_time = parseInt(end2 / 1000);
        }
        const req = {
          symbol_id: this.planOrderList_symbol_id,
          open_side: this.planOrderList_open_side,
          create_time: create_time,
          end_time: end_time,
          position_status: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize
        };
        return (0, _order.apiGetOpenPositionList)(req).then(res => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[key].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },
    onCurrentPageChange() {
      this.initData(this.checked);
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };

      const {
        status,
        data
      } = await (0, _index3.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "futureM",
            app_id: _stationSetting.VUE_APP_ID
          }
        },
        callback,
        onConnect,
        mounted
      };
      let wsNew = new _socket.default(option).getInstance();
      me.global.setWs(wsNew);
      // 重新连接
      function onConnect() {
        if (me.isDestoryWS) return;
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        // window.ws.sub({
        //   action: "Topic.sub",
        //   data: {
        //     type: "depth",
        //     symbol: me.swap_symbol_id,
        //     // resolution: "D",
        //   },
        // });
        // window.ws.sub({
        //   action: "Topic.sub",
        //   data: {
        //     type: "orderbook",
        //     symbol: me.swap_symbol_id,
        //   },
        // });

        // window.ws.sub({
        //   action: "Topic.sub",
        //   data: {
        //     type: "futureM",
        //     app_id: VUE_APP_ID,
        //   },
        // });
        if (_jsCookie.default.get("topcredit_exchange_t")) {
          me.global.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets"
            }
          });
        }
      }
      function callback(data) {
        const {
          action
        } = data;
        if (action == "Pushdata.futureM") {
          if (data.data) {
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }
        // if (action == "Pushdata.kline") {
        //   window.resolveKlineDataV4(data);
        // }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        // if (action == "Pushdata.orderbook") {
        //   if (data.data) {
        //     if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
        //       return;
        //     }
        //     me.orderbookList.unshift(...data.data);
        //     if (me.orderbookList.length > 28) {
        //       me.orderbookList = me.orderbookList.slice(0, 28);
        //     }
        //   }
        // }
        // if (action == "Pushdata.depth") {
        //   if (data.data) {
        //     if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
        //       return;
        //     }
        //     let tempObj = data.data;
        //     if (tempObj.a.length > 0) {
        //       tempObj.c = 0;
        //       tempObj.d = 0;
        //       tempObj.a.forEach((ele) => {
        //         tempObj.c += Number(ele[1]);
        //       });
        //       tempObj.b.forEach((ele) => {
        //         tempObj.d += Number(ele[1]);
        //       });
        //     }
        //     me.depthList = tempObj;
        //   }
        // }
      }
    }
  },

  watch: {
    // swap_symbol_id: {
    //   handler(newVal, oldVal) {
    //     if (oldVal == undefined) {
    //       return;
    //     }
    //     if (newVal != oldVal) {
    //       this.symbolId = newVal;
    //       this.orderLoading = true;
    //       this.pageInfo.pageCurrent = 1;
    //       if (this.getSymbolList) {
    //         this.contract_value = this.symbolList[
    //           this.swap_symbol_id
    //         ].contract_value;
    //         this.settlement_precision = this.symbolList[
    //           this.swap_symbol_id
    //         ].settlement_precision;
    //       }
    //       this.initData(this.checked);
    //     }
    //   },
    // },
    hidenCancelChecked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    checked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    marketList() {
      if (this.currIndex == 1 || this.currIndex == 2) {
        // this.recordDeliveryTable1 = !this.recordDeliveryTable1;
        let tmepData = JSON.parse(JSON.stringify(this.tableData));
        this.tableData = [];
        tmepData.forEach(ele => {
          this.marketList.forEach(item => {
            if (item.symbol_id == ele.symbol_id) {
              ele.last_price = item.last;
            }
          });
          // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价)
          let tempContractValue = new _bignumber.default(ele.contract_value);
          let side = ele.open_side == 1 ? 1 : -1;
          let tempSide = new _bignumber.default(side);
          let one = new _bignumber.default(1);
          let oneHundred = new _bignumber.default(100);
          let tempQty = new _bignumber.default(ele.qty);
          let tempOpenPrice = new _bignumber.default(ele.open_price);
          let tempLastPrice = new _bignumber.default(ele.last_price);
          let tempSettlementPrecision = new _bignumber.default(ele.settlement_precision);

          // 未实现盈亏
          ele.position_profit_loss = tempQty.times(tempContractValue).times(one.div(tempOpenPrice).minus(one.div(tempLastPrice))).times(tempSide).toNumber().toFixed(ele.settlement_precision);
          // .toFixed(this.number_precision);
          let tempValue = new _bignumber.default(ele.position_profit_loss);
          ele.position_profit_loss_procent = tempValue.div(ele.bail_number).times(oneHundred).toFixed(2);
        });
        this.tableData = tmepData;
        // this.$forceUpdate();
      }
    }
  }
};
exports.default = _default;