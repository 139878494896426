import api from "@/assets/js/api";
import global from "@/assets/js/global.js";
// const { VUE_APP_UCENTER } = process.env;
import { VUE_APP_UCENTER } from "@/assets/js/stationSetting";
let Btn = false;
let changeSvId = [];
//静态表设置
export const SETTING_SV_ID = {
  static_coin: 1,
  static_sms_country: 2,
  otc_static_coin: 3,
  static_currency: 7,
  static_item_v2: 13,
  static_exchange_limit: 14,
  sotc_static_payment: 22,
  swap_static_symbol: 26,
  static_assets_type: 27, //资产记录静态配置表
  // static_item_permission: 38, //币种权限表
  sotc_static_guarantee_type: 42, // 用户卖U包赔时间类型
  stock_static_symbol: 46, // 股票
  contract_static_symbol: 49, // U本位合约
  static_symbol: 55, //币币交易对
  static_chain: 56, //区块地址
  static_coin_chain: 57, //币币多链配置表
  futures_static_symbol: 58, //币本位合约
  static_rebate_type: 59, //static_rebate_type表
  sotc_static_item: 63, //匿名OTC数字货币表表
  // static_otc_third_payment: 64, //第三方OTC 支付配置
  static_otc_third_payment_new: 69, //第三方OTC 支付配置 新变更
  // static_third_payment: 65, //第三方 OTC 支付方式静态配置
  static_third_payment: 68, //第三方 OTC 支付方式静态配置 新变更
  static_mateverse_trend_coin_list_future: 69, //元宇宙配置表
  static_mateverse_trend_coin_list_spot: 70, //元宇宙配置表
  main_geetest_captcha_config: 75, //极验证配置数据
};

//获取所有静态表版本号
function getStaticTableList(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Settings/getStaticTableList`,
    data,
  });
}

async function getChangeStaticTable() {
  changeSvId = [];
  const { status, data } = await getStaticTableList();
  if (status == 200 && data) {
    let result = localStorage.getItem("staticTableVersion");
    if (data && result && result != "[object Object]") {
      let resultParse = result && JSON.parse(result);

      for (let key in resultParse) {
        for (let index in data) {
          if (
            resultParse[key].sv_id == data[index].sv_id &&
            data[index].version > resultParse[key].version
          ) {
            changeSvId.push(resultParse[key].sv_id);
          }
        }
      }
    }
    localStorage.setItem("staticTableVersion", JSON.stringify(data));
  }

  return changeSvId;
}

//获取静态表信息
export const apiGetStaticTableInfo_new = async sv_id => {
  let result = await getCacheStaticTableInfo(sv_id);
  return {
    data: result,
    status: 200,
  };
};
//获取静态表信息
export const apiGetStaticTableInfo = async sv_id => {
  let result = await getCacheStaticTableInfo(sv_id);
  return {
    data: result,
    status: 200,
  };
};

const VERSION = 1;
//缓存静态表,防止一个页面多次请求
export const getCacheStaticTableInfo_new = async sv_id => {
  let result = localStorage.getItem("settingList")
    ? JSON.parse(localStorage.getItem("settingList"))
    : "";
  if (!result) {
    localStorage.setItem("settingList", "{}");
  }

  let changeStaticTableID = [];
  if (
    Date.parse(new Date()) / 1000 -
      parseInt(
        localStorage.getItem("changeStaticTableTime")
          ? localStorage.getItem("changeStaticTableTime")
          : 0
      ) >
    60 * 5
  ) {
    localStorage.setItem(
      "changeStaticTableTime",
      Date.parse(new Date()) / 1000
    );
    changeStaticTableID = getChangeStaticTable();
  }

  let settingList = {};
  let sv_ids = [];

  if (!result) {
    sv_ids = Object.values(SETTING_SV_ID);
    sv_ids.push(sv_id);
    sv_ids = Array.from(new Set(sv_ids));
  } else {
    let localIdsTemp = Object.keys(result);
    // let settingIdsTemp = Object.values(SETTING_SV_ID);
    let resultIDS = Array.from(new Set([...[], ...localIdsTemp]));
    if (
      changeStaticTableID.includes(sv_id) ||
      !resultIDS.includes(sv_id + "")
    ) {
      sv_ids.push(sv_id);
    }
  }

  let res = {};
  if (sv_ids && sv_ids.length > 0) {
    res = await getManyStaticTableInfo({
      sv_ids: sv_ids.join(","),
    });
  }
  let { data } = res;
  if (!data) {
    data = {};
  }
  if (!result) {
    global.settingList = settingList = {
      ...JSON.parse(JSON.stringify(global.settingList)),
      ...JSON.parse(JSON.stringify(data)),
    };
  } else {
    global.settingList = settingList = {
      ...JSON.parse(JSON.stringify(global.settingList)),
      ...JSON.parse(JSON.stringify(result)),
      ...JSON.parse(JSON.stringify(data)),
    };
  }
  localStorage.setItem("settingList", JSON.stringify(settingList));
  return settingList[sv_id];
};

//缓存静态表,防止一个页面多次请求
export const getCacheStaticTableInfo = async sv_id => {
  //先查缓存

  let result = localStorage.getItem("settingList");
  let version = localStorage.getItem("settingListVersion");
  let LastTime = localStorage.getItem("webSettingLastTime");
  let nowTime = Date.parse(new Date()) / 1000;
  let settingList;
  let settingListNew;

  let tempObjects =
    result &&
    result != "{}" &&
    result != "undefined" &&
    result != "[object Object]" &&
    JSON.parse(result);
  //获取需要更新的静态表ID

  //若已存在SETTINGLIST，并存储静态表版本信息，则在新加静态表号时会出现读出UNDEFIND新静态表的情况，以下为判断此种情况
  let changeSvidArray = [];
  if (tempObjects && !tempObjects[sv_id]) {
    changeSvidArray.push(sv_id);
  }
  if (
    result &&
    result != "{}" &&
    result != "undefined" &&
    result != "[object Object]" &&
    version == VERSION &&
    nowTime - LastTime > 60 * 1 &&
    tempObjects[sv_id]
  ) {
    let timestamp = Date.parse(new Date()) / 1000;
    localStorage.setItem("webSettingLastTime", timestamp);
    // 需要更新
    // getStaticTableList();
    console.log("更新靜態表");
    changeSvidArray = await getChangeStaticTable();
  } else if (
    result &&
    result != "{}" &&
    result != "undefined" &&
    result != "[object Object]" &&
    version == VERSION &&
    tempObjects[sv_id]
  ) {
    settingList = JSON.parse(result);
    return settingList[sv_id];
  } else if (
    !result ||
    result == "{}" ||
    result == "undefined" ||
    result == "[object Object]"
  ) {
    changeSvidArray = Object.values(SETTING_SV_ID);
  }

  //不需要更新的静态表对象数组
  let noChangeList = {};
  //原先静态表对象
  let oldSettingList = JSON.parse(result);
  //需要更新的静态表ID
  let sv_ids = [];
  //不需要更新的静态表ID
  let sv_no_ids = [];

  // for (var k in SETTING_SV_ID) {
  //   if (changeSvidArray.includes(SETTING_SV_ID[k])) {
  //     sv_ids.push(SETTING_SV_ID[k]);
  //   } else {
  //     sv_no_ids.push(SETTING_SV_ID[k]);
  //   }
  // }
  for (var k in SETTING_SV_ID) {
    if (!changeSvidArray.includes(SETTING_SV_ID[k])) {
      sv_no_ids.push(SETTING_SV_ID[k]);
    }
  }
  sv_ids = changeSvidArray;

  for (let svid of sv_no_ids) {
    noChangeList[svid] = oldSettingList[svid];
  }

  if (Btn) {
    let tempList = localStorage.getItem(
      "settingList",
      JSON.stringify(settingList)
    );
    if (tempList) {
      return tempList[sv_id];
    }
  }

  if (changeSvidArray && changeSvidArray.length == 0 && sv_ids.length == 0) {
    return oldSettingList[sv_id];
  }
  //如果没有缓存
  let res = {};
  if (sv_ids && sv_ids.length > 0) {
    res = await getManyStaticTableInfo({
      sv_ids: sv_ids.join(","),
    });
  }
  const { data } = res;
  if (!data) {
    return {};
  }

  settingListNew = data;
  settingList = { ...noChangeList, ...settingListNew };
  localStorage.setItem("settingList", JSON.stringify(settingList));
  localStorage.setItem("settingListVersion", VERSION);
  let timestamp = Date.parse(new Date()) / 1000;
  localStorage.setItem("webSettingLastTime", timestamp);
  Btn = false;
  // localStorage.setItem("webSettingListTime", timestamp);
  return settingList[sv_id];
};

//获取多个静态表信息  params =  1,2,3,4,5,6,
export function getManyStaticTableInfo(data = {}) {
  Btn = true;
  return api({
    url: `${VUE_APP_UCENTER}/Settings/getStaticTableInfoList`,
    data,
  });
}

// 获取币种汇率
export function getOtcCoinRate(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Settings/otcCoinRate`,
    data,
  });
}

// 获取产品通道列表
export function apiGetStaticAgentList(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/Settings/getStaticAgentList`,
    data,
  });
}

// 商户搜索条件
export function apiGetSearchChannelIds(data = {}) {
  return api({
    url: `${VUE_APP_UCENTER}/UCenter/ChannelAgent/getSearchChannelIds`,
    data,
  });
}
