"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/assets/js/i18n");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["label", "placeholder", "verificate"],
  data() {
    let message = this.translateTitle(this.label);
    return {
      message,
      formData: {
        google_code: ""
      },
      formRules: {
        google_code: [{
          required: true,
          message,
          trigger: "blur"
        }, {
          pattern: /^\d{6}$/,
          message: this.translateTitle(`必须为6位纯数字`)
        }]
      }
    };
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    clearCode() {
      this.formData.google_code = "";
    },
    submitForm() {
      // dBindForm
      let statusData;
      let {
        formData
      } = this;
      this.$refs.dBindForm.validate(valid => {
        if (valid) {
          statusData = formData;
        } else {
          statusData = false;
        }
      });
      return JSON.parse(JSON.stringify(statusData));
    }
  }
};
exports.default = _default;