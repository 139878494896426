"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/assets/js/i18n");
var _foot_logo = _interopRequireDefault(require("@/assets/img/bv/foot_logo.png"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    })
  },
  data() {
    return {
      slogan: "",
      tgUrl: "",
      tipUrl: "",
      qrcodeTitle: "",
      rateUrl: "",
      serviceUrl: "",
      privacyUrl: "",
      statementUrl: "",
      helpUrl: "",
      projectName: "",
      logImg: _foot_logo.default,
      apiUrl: "",
      // is8V: false,
      hasAPI: false
    };
  },
  created() {
    // this.is8V = true;
    // this.logImg = require("../../assets/img/bv/logo_8V_new.png");
    // this.slogan = JSON.parse(sessionStorage.getItem("solgan"));
    // if (!JSON.parse(sessionStorage.getItem("clause"))) {
    //   getEnv();
    // }
    // let clauseList = JSON.parse(sessionStorage.getItem("clause"));
    // let othersList = JSON.parse(sessionStorage.getItem("others"));
    // let noticeList = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    // let qrcode = JSON.parse(sessionStorage.getItem("qrcode"));
    // let about = JSON.parse(sessionStorage.getItem("about"));
    // if (this.is8V) {
    //   this.rateUrl = othersList[0].language[`url_${this.language}`];
    //   this.serviceUrl = clauseList[0].language[`url_${this.language}`];
    //   this.tipUrl = about[0].language[`url_${this.language}`];
    //   this.privacyUrl = clauseList[1].language[`url_${this.language}`];
    //   this.statementUrl = clauseList[2].language[`url_${this.language}`];
    //   this.helpUrl = about[1].language[`url_${this.language}`];
    // } else {
    //   this.privacyUrl = clauseList[1].url;
    //   this.statementUrl = clauseList[2].url;
    //   this.serviceUrl = clauseList[0].url;
    //   this.rateUrl = othersList[0].url;
    //   this.tipUrl = about[0].url;
    //   this.helpUrl = about[1].url;
    // }
    // if (othersList[0].language) {
    //   this.rateUrl = othersList[0].language[`url_${this.language}`];
    // } else {
    //   this.rateUrl = othersList[0].url;
    // }
    // if (othersList[1]) {
    //   this.hasAPI = true;
    //   this.apiUrl = othersList[1].url;
    // } else {
    // }
    // if (clauseList[0].language) {
    //   this.serviceUrl = clauseList[0].language[`url_${this.language}`];
    // } else {
    //   this.serviceUrl = clauseList[0].url;
    // }
    // if (about[0].language) {
    //   this.tipUrl = about[0].language[`url_${this.language}`];
    // } else {
    //   this.tipUrl = about[0].url;
    // }
    // if (clauseList[1].language) {
    //   this.privacyUrl = clauseList[1].language[`url_${this.language}`];
    // } else {
    //   this.privacyUrl = clauseList[1].url;
    // }
    // if (clauseList[2].language) {
    //   this.statementUrl = clauseList[2].language[`url_${this.language}`];
    // } else {
    //   this.statementUrl = clauseList[2].url;
    // }
    // if (about[1].language) {
    //   this.helpUrl = about[1].language[`url_${this.language}`];
    // } else {
    //   this.helpUrl = about[1].url;
    // }
    // this.tgUrl = qrcode.ori_url;
    // this.qrcodeTitle = qrcode.title;
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    tipsDownload() {
      this.$emit("tipsDownload");
    },
    openChat() {
      if (!this.userInfo) {
        this.$message(this.translateTitle("请先登录"));
        return;
      }
      this.$emit("chatOpen");
    }
    // onKycClick() {
    //   this.$router.push({
    //     path: "/kyc-notice"
    //   });
    // },
    // onAPIClick() {
    //   this.$emit("onApiClick");
    // },
    // onClickMore() {
    //   window.open(this.tipUrl);
    // }
  }
};
exports.default = _default;