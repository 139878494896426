"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.timestamp = timestamp;
exports.timestampToDate = timestampToDate;
exports.timestampToDay = timestampToDay;
exports.timestampToYear = timestampToYear;
function timestamp(created, formate = "yyyy-MM-dd HH:mm") {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, formate);
}
function timestampToDate(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, " MM-dd HH:mm");
}
function timestampToDay(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "MM-dd");
}
function timestampToYear(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "yyyy-MM-dd HH:mm");
}

//将秒数转换为时分秒格式
function formatSeconds(value) {
  var theTime = parseInt(value); // 秒
  var middle = 0; // 分
  var hour = 0; // 小时

  if (theTime > 60) {
    middle = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (middle > 60) {
      hour = parseInt(middle / 60);
      middle = parseInt(middle % 60);
    }
  }
  var result = "" + parseInt(theTime) + "秒";
  if (middle > 0) {
    result = "" + parseInt(middle) + "分" + result;
  }
  if (hour > 0) {
    result = "" + parseInt(hour) + "小时" + result;
  }
  return result;
}