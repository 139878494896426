"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getUserInfo = exports.getStaticTableList = exports.getStaticTableInfo = exports.editUserInfo = void 0;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _stationSetting = require("@/assets/js/stationSetting");
// 获取用户信息
const getUserInfo = (data = {}) => {
  if (!_jsCookie.default.get("topcredit_exchange_t")) return;
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/getUserInfo`,
    data,
    type: "POST"
  });
};

// 修改用户信息
exports.getUserInfo = getUserInfo;
const editUserInfo = (data = {}) => {
  if (!_jsCookie.default.get("topcredit_exchange_t")) return;
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/editUserInfo`,
    data,
    type: "POST"
  });
};
// 获取静态表
exports.editUserInfo = editUserInfo;
const getStaticTableList = () => {
  return (0, _api.default)(_stationSetting.VUE_APP_UCENTER + "/Settings/getStaticTableList");
};
// 获取静态表详情
exports.getStaticTableList = getStaticTableList;
const getStaticTableInfo = data => {
  return (0, _api.default)(_stationSetting.VUE_APP_UCENTER + "/Settings/getStaticTableInfo", data);
};
exports.getStaticTableInfo = getStaticTableInfo;