"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _market = require("@/model/exchange/market.js");
var _index = require("@/model/exchange/index");
var _order = require("@/model/contractExchange/order.js");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _index2 = require("@/utils/index.js");
var _vuex = require("vuex");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _message = require("@/components/dialog/message.js");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _settings = require("@/model/settings");
var _keymap = require("@/assets/js/keymap.js");
var _digital = require("@/assets/js/digital");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//FIXME
var _default = {
  mixins: [_commonMixin.default],
  props: {
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    swap_symbol_id: {
      type: [String, Number],
      default: function () {
        return 100901;
      }
    },
    assetsInfo: {
      type: Object,
      default: function () {
        return {
          remain: {
            all_u_valuation: {
              usdt: "--"
            },
            list: []
          }
        };
      }
    },
    from_symbol: {
      type: [String, Number]
    },
    to_symbol: {
      type: [String, Number]
    },
    from_item_id: {
      type: [String, Number]
    },
    to_item_id: {
      type: [String, Number]
    }
  },
  components: {
    NoData: _NoData.default,
    InputNumber: _InputNumber.default
  },
  data() {
    return {
      closePositionTypeMaps: _keymap.closePositionTypeMaps,
      parseTime: _index2.parseTime,
      ws: null,
      tableData: [],
      confirmMessage: "是否确认平仓此订单？",
      tableDataComputed: [],
      orderLoading: true,
      dialogVisible: false,
      appendMoneydialog: false,
      setProfitPricedialog: false,
      row_swap_symbol_id: this.swap_symbol_id,
      beforeBailNumber: "",
      afterBailNumber: "--",
      inputProfitPrice: "",
      inputLossPrice: "",
      price_precision: "",
      contract_plan_num: 0,
      contract_position_num: 0,
      contract_multiple_num: "",
      beforeLiquidationPrice: "",
      plan_order_id: "",
      afterLiquidationPrice: "--",
      rowData: {},
      hidenCancelChecked: false,
      checked: false,
      layout: "total, prev, pager, next",
      symbolId: "",
      position_order_id: "",
      bail_number: "",
      contractMultipleNum: {},
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 6,
        pageTotal: 6
      },
      apiAssetsInfo: {},
      tabList: [{
        value: "1",
        label: "持仓"
      }, {
        value: "2",
        label: "当前委托"
      },
      // {
      //   value: "3",
      //   label: "历史委托",
      // },
      {
        value: "4",
        label: "成交记录"
      }],
      currIndex: "1",
      symbolList: {},
      coinInfoMap: {},
      triggerSideMap: {
        1: "大于触发价",
        2: "小于触发价"
      },
      tradeMap: {
        1: "市价",
        2: "限价"
      },
      sideMap: {
        1: "买涨",
        2: "买跌"
      },
      statusMap: {
        0: "未成交",
        1: "待成交",
        2: "完全成交",
        3: "已撤销",
        4: "失败"
      }
    };
  },
  async created() {
    await this.getCoinInfoMap();
    await this.getTradePairList();
    this.getOrderNumber();
    this.getAssets();
    let {
      userInfo
    } = await Account.getUserInfo();
    if (userInfo) {
      this.initData(this.checked);
      this.startTimer();
    }
  },
  beforeDestroy() {
    clearTimeout(timer);
  },
  mounted() {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    marketInfoShow() {
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    rowMarketInfoShow() {
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.row_swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (JSON.stringify(this.assetsInfo) != "{}" && this.assetsInfo.remain.list.length > 0) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (JSON.stringify(this.apiAssetsInfo) != "{}" && this.apiAssetsInfo.remain.list.length > 0) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    afterLiquidationPriceShow() {
      // 强平价 = 开仓价 - (1 做多 | -1做空)) ×(保证金 × (1 − 强平⻛险率) - 平仓手续费-开仓手续费)/持仓数量
      let tempValue = "";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.bail_number != "") {
        tempValue = this.rowData.open_price - tempSide * (this.afterBailNumberShow * (1 - this.symbolList[this.swap_symbol_id].liquidation_risk_rate / 10000) - this.rowData.close_position_fee - this.rowData.open_position_fee) / this.rowData.open_interest;
        if (this.rowData.open_side == 1) {
          //做多 做多的时候小数位用全进
          tempValue = (Math.ceil(tempValue * Math.pow(10, this.price_precision)) / Math.pow(10, this.price_precision)).toFixed(this.price_precision);
        } else {
          // 做空的时候用截断
          tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.price_precision + 1);
        }
      } else {
        tempValue = this.afterLiquidationPrice;
      }
      if (Number(tempValue) < 0) {
        tempValue = "0";
      }
      return tempValue;
    },
    afterBailNumberShow() {
      return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(2);
    },
    // 平仓盈亏 = (inputProfitPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量  - 平仓手续费
    expectedProfit() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }
        tempValue = (Number(this.inputProfitPrice) - Number(temp_open_price)) * tempSide * Number(this.rowData.open_interest) - Number(this.rowData.close_position_fee);
        tempValue = (0, _digital.floorNumber)(tempValue, 2);
        if (Number(tempValue) <= 0) {
          tempValue = "--";
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }
        tempValue = ((this.inputProfitPrice - temp_open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee) / this.rowData.bail_number * 100;
        tempValue = (0, _digital.floorNumber)(tempValue, 2);
        if (Number(tempValue) <= 0) {
          tempValue = "--";
        } else {
          tempValue = "+" + tempValue;
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏 = (inputLossPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量  - 平仓手续费
    expectedLoss() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }
        tempValue = (this.inputLossPrice - temp_open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee;
        tempValue = (0, _digital.ceilNumber)(tempValue, 2);
        // tempValue = Math.ceil(tempValue * 100) / 100;
        if (Number(tempValue) < this.rowData.bail_number * -1) {
          tempValue = this.rowData.bail_number * -1;
        }
        if (Number(tempValue) > 0) {
          tempValue = "--";
        }
      }
      // inputLossPrice
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        let temp_open_price = "";
        if (this.currIndex == 1) {
          temp_open_price = this.rowData.open_price;
        } else {
          temp_open_price = this.rowData.trigger_price;
        }
        tempValue = ((this.inputLossPrice - temp_open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee) / this.rowData.bail_number * 100;
        tempValue = (0, _digital.ceilNumber)(tempValue, 2);
        if (Number(tempValue) < -100) {
          tempValue = -100;
        }
        if (Number(tempValue) > 0) {
          tempValue = "--";
        }
      }
      // inputLossPrice
      return tempValue;
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    onOK() {
      if (this.dialogType == 1) {
        this.onClosePosition();
      } else if (this.dialogType == 2) {
        this.onCancelOrder();
      } else if (this.dialogType == 3) {
        this.onRevokeAllOrder();
      } else if (this.dialogType == 4) {
        this.onRevokePlan();
      }
    },
    goToRecharge() {
      window.open(this.routeSplicing(this.language, "ucenter/assets/recharge/usdt"));
    },
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    reset(type) {
      this.orderLoading = true;
      this.currIndex = type.toString();
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
      this.getOrderNumber();
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onProfitPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      this.setProfitPricedialog = true;
    },
    onLossPriceEdit(row) {
      this.rowData = row;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      this.setProfitPricedialog = true;
    },
    async getCoinInfoMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.coinInfoMap = data;
      }
    },
    async getTradePairList() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(49);
      if (status == 200) {
        this.symbolList = data;
      }
    },
    onSymbolChange(item) {
      this.$emit("on-symbol-change", item);
    },
    onSymbolClick(row) {
      let tempName = "";
      if (row.from_symbol_name) {
        tempName = row.from_symbol_name.toLowerCase() + "_" + "usdt";
      } else {
        for (const key in this.coinInfoMap) {
          if (key == row.open_item_id) {
            tempName = this.coinInfoMap[key].item_name.toLowerCase() + "_" + "usdt";
          }
        }
      }
      let params = {
        symbol_id: row.symbol_id,
        name: tempName,
        change_item_id: row.open_item_id,
        base_item_id: row.settlement_item_id
      };
      this.$emit("on-symbol-change", params);
    },
    onOpenConfirm(type, id) {
      this.dialogType = type;
      if (type == 1) {
        this.position_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认平仓此订单？");
      } else if (type == 2) {
        this.confirmMessage = this.translateTitle("是否确认全部平仓？");
      } else if (type == 3) {
        this.confirmMessage = this.translateTitle("是否确认全部撤销？");
      } else if (type == 4) {
        this.plan_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认撤销此委托单？");
      }
      this.dialogVisible = true;
    },
    onOpenAppendMoney(row) {
      this.position_order_id = row.position_order_id;
      this.beforeBailNumber = row.bail_number;
      this.afterBailNumber = row.bail_number;
      this.beforeLiquidationPrice = row.liquidation_price;
      this.afterLiquidationPrice = row.liquidation_price;
      this.rowData = row;
      for (const key in this.symbolList) {
        if (key == row.symbol_id) {
          this.price_precision = this.symbolList[key].price_precision;
        }
      }
      this.appendMoneydialog = true;
    },
    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id
      };
      (0, _order.apiClosePosition)(params).then(res => {
        if (res.status == 200) {
          this.dialogVisible = false;
          (0, _message.showMessage)({
            message: this.translateTitle("平仓成功"),
            type: "success"
            // duration:10000000,
          });

          this.initData(this.checked);
        }
      });
    },
    onAppendMoney() {
      if (Number(this.bail_number) == 0) {
        (0, _message.showMessage)({
          message: this.translateTitle("请输入追加金额"),
          type: "error"
        });
        return;
      }
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number
      };
      (0, _order.apiAppendInterest)(params).then(res => {
        if (res.status == 200) {
          (0, _message.showMessage)({
            message: this.translateTitle("追加成功"),
            type: "success"
          });
          this.appendMoneydialog = false;
          this.initData(this.checked);
        }
      });
    },
    async startTimer() {
      const timer = setTimeout(() => {
        this.initData(this.checked);
        this.getOrderNumber();
        this.getMultipleNum();
        this.startTimer();
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(timer);
      });
      this.$once("hook:destroyed", () => {
        clearTimeout(timer);
      });
    },
    async onCancelOrder() {
      const {
        status,
        data
      } = await (0, _order.apiAllClosePosition)();
      if (status == 200) {
        this.dialogVisible = false;
        (0, _message.showMessage)({
          message: this.translateTitle("平仓成功"),
          type: "success"
        });
        this.initData(this.checked);
      }
    },
    async onRevokeAllOrder() {
      const {
        status,
        data
      } = await (0, _order.apiRevokeAllPlan)();
      if (status == 200) {
        (0, _message.showMessage)({
          message: this.translateTitle("撤销成功"),
          type: "success"
        });
        this.dialogVisible = false;
        this.initData(this.checked);
      }
    },
    async onRevokePlan() {
      const req = {
        plan_order_id: this.plan_order_id
      };
      const {
        status,
        data
      } = await (0, _order.apiRevokePlan)(req);
      if (status == 200) {
        (0, _message.showMessage)({
          message: this.translateTitle("撤销成功"),
          type: "success"
        });
        this.dialogVisible = false;
        this.initData(this.checked);
      }
    },
    onSetPositionProfitLoss() {
      // if (this.inputLossPrice == "" && this.inputProfitPrice == "") {
      //   showMessage({ message: "请输入止盈价或止损价", type: "error" });
      //   return;
      // }
      if (this.currIndex == 1) {
        let params = {
          position_order_id: this.rowData.position_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: this.inputProfitPrice,
          stop_loss_price: this.inputLossPrice
        };
        (0, _order.apiSetPositionProfitLoss)(params).then(res => {
          if (res.status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle("设置成功"),
              type: "success"
            });
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          }
        });
      } else {
        let params = {
          plan_order_id: this.rowData.plan_order_id,
          market_price: this.rowMarketInfoShow.last,
          stop_profit_price: this.inputProfitPrice,
          stop_loss_price: this.inputLossPrice
        };
        (0, _order.apiSetPlanProfitLoss)(params).then(res => {
          if (res.status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle("设置成功"),
              type: "success"
            });
            this.setProfitPricedialogClose();
            this.initData(this.checked);
          }
        });
      }
    },
    onAreaChange(item) {
      this.orderLoading = true;
      this.tableData = [];
      this.currIndex = item.value;
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    getMultipleNum() {
      let params = {
        symbol_id: ""
      };
      (0, _order.apiGetOrderStatistic)(params).then(res => {
        if (res.status == 200) {
          this.contract_multiple_num = JSON.stringify(res.data.contract_multiple_num);
          localStorage.setItem("stock-multiple-num", this.contract_multiple_num);
        }
      });
    },
    getOrderNumber() {
      let params = {
        symbol_id: this.checked ? this.swap_symbol_id : ""
      };
      (0, _order.apiGetOrderStatistic)(params).then(res => {
        if (res.status == 200) {
          this.contract_position_num = res.data.contract_position_info.total_num;
          this.contract_plan_num = res.data.contract_plan_info.total_num;
        }
      });
    },
    async initData(checked = false) {
      if (!this.swap_symbol_id) {
        this.orderLoading = false;
        return;
      }
      let {
        userInfo
      } = await Account.getUserInfo();
      if (!userInfo) {
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      let temp_is_hide = 2;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
        temp_is_hide = 1;
      } else {
        temp_symbol_id = "";
        temp_is_hide = 2;
      }
      if (this.currIndex == 1) {
        const req = {
          symbol_id: temp_symbol_id,
          position_status: 1,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize
          // is_hide: temp_is_hide,
        };

        return (0, _order.apiGetOpenPositionList)(req).then(res => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach(ele => {
              // 存各个币种杠杆倍数

              this.contractMultipleNum[ele.symbol_id] = {
                open_leverage: ele.open_leverage
              };

              // 持仓盈亏 = (市价-开仓价) × (1 做多 | -1做空)) × 持仓数量
              this.marketList.forEach(item => {
                if (item.symbol_id == ele.symbol_id) {
                  ele.last_price = item.last;
                }
              });
              let tempSide = ele.open_side == 1 ? 1 : -1;
              // 未实现盈亏
              ele.position_profit_loss = (ele.last_price - ele.open_price) * tempSide * ele.open_interest;
              ele.position_profit_loss_procent = ((ele.last_price - ele.open_price) * tempSide * ele.open_interest / ele.bail_number * 100).toFixed(2);
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        const req = {
          symbol_id: temp_symbol_id,
          order_status: 1,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize
        };
        return (0, _order.apiGetPlanOrderList)(req).then(res => {
          if (res.status == 200) {
            for (const key in this.exchangeCoinMap) {
              res.data.list.forEach(ele => {
                this.marketList.forEach(item => {
                  if (item.symbol_id == ele.symbol_id) {
                    ele.last_price = item.last;
                  }
                });
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[key].name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 3) {
        let temp_order_status = "2,3,4";
        if (this.hidenCancelChecked) {
          temp_order_status = "2,4";
        } else {
          temp_order_status = "2,3,4";
        }
        const req = {
          symbol_id: temp_symbol_id,
          order_status: temp_order_status,
          is_hide: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize
        };
        return (0, _order.apiGetPlanOrderList)(req).then(res => {
          if (res.status == 200) {
            res.data.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[key].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
                if (key == ele.settlement_item_id) {
                  ele.to_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 4) {
        const req = {
          symbol_id: temp_symbol_id,
          position_status: 2,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize
        };
        return (0, _order.apiGetOpenPositionList)(req).then(res => {
          if (res.status == 200 && res.data.list.length > 0) {
            res.data.list.forEach(ele => {
              for (const key in this.exchangeCoinMap) {
                if (ele.symbol_id == key) {
                  ele.symbol_name = this.exchangeCoinMap[key].name.toUpperCase();
                }
              }
              for (const key in this.coinInfoMap) {
                if (key == ele.open_item_id) {
                  ele.from_symbol_name = this.coinInfoMap[key].item_name.toUpperCase();
                }
              }
            });
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.tableData = [];
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },
    onCurrentPageChange() {
      this.initData(this.checked);
    }
  },
  watch: {
    swap_symbol_id: {
      handler(newVal, oldVal) {
        if (oldVal == undefined) {
          return;
        }
        if (newVal != oldVal) {
          this.symbolId = newVal;
          this.orderLoading = true;
          this.pageInfo.pageCurrent = 1;
          this.initData(this.checked);
        }
      }
    },
    hidenCancelChecked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    checked() {
      this.initData(this.checked);
      this.getOrderNumber();
    },
    marketList() {
      if (this.currIndex == 1 || this.currIndex == 2) {
        this.tableData.forEach(ele => {
          this.marketList.forEach(item => {
            if (item.symbol_id == ele.symbol_id) {
              ele.last_price = item.last;
            }
          });
          let tempSide = ele.open_side == 1 ? 1 : -1;
          // 未实现盈亏
          ele.position_profit_loss = (ele.last_price - ele.open_price) * tempSide * ele.open_interest;
          ele.position_profit_loss_procent = ((ele.last_price - ele.open_price) * tempSide * ele.open_interest / ele.bail_number * 100).toFixed(2);
        });
      }
    }
  }
};
exports.default = _default;