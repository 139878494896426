"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatTime = formatTime;
exports.on = exports.off = void 0;
exports.paramObj = paramObj;
exports.parseTime = parseTime;
exports.random = random;
exports.tenBitTimestamp = tenBitTimestamp;
exports.thirteenBitTimestamp = thirteenBitTimestamp;
exports.timestampToDate = timestampToDate;
exports.timestampToDay = timestampToDay;
exports.timestampToYear = timestampToYear;
exports.translateDataToTree = translateDataToTree;
exports.translateTreeToData = translateTreeToData;
exports.uuid = uuid;
require("core-js/modules/es.array.push.js");
/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
function timestampToDay(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "MM-dd");
}
function timestampToDate(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "MM.dd HH:mm");
}
function timestampToYear(created) {
  const stamp = `${created}000`;
  const date = new Date(Number(stamp));
  return window.DateFormat.format.date(date, "yyyy-MM-dd HH:mm");
}
function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
}

/**
 * @description 格式化时间
 * @param time
 * @param option
 * @returns {string}
 */
function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();
  const diff = (now - d) / 1000;
  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}

/**
 * @description 将url请求参数转为json格式
 * @param url
 * @returns {{}|any}
 */
function paramObj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}

/**
 * @description 父子关系的数组转换成树形结构数据
 * @param data
 * @returns {*}
 */
function translateDataToTree(data) {
  const parent = data.filter(value => value.parentId === "undefined" || value.parentId == null);
  const children = data.filter(value => value.parentId !== "undefined" && value.parentId != null);
  const translator = (parent, children) => {
    parent.forEach(parent => {
      children.forEach((current, index) => {
        if (current.parentId === parent.id) {
          const temp = JSON.parse(JSON.stringify(children));
          temp.splice(index, 1);
          translator([current], temp);
          typeof parent.children !== "undefined" ? parent.children.push(current) : parent.children = [current];
        }
      });
    });
  };
  translator(parent, children);
  return parent;
}

/**
 * @description 树形结构数据转换成父子关系的数组
 * @param data
 * @returns {[]}
 */
function translateTreeToData(data) {
  const result = [];
  data.forEach(item => {
    const loop = data => {
      result.push({
        id: data.id,
        name: data.name,
        parentId: data.parentId
      });
      const child = data.children;
      if (child) {
        for (let i = 0; i < child.length; i++) {
          loop(child[i]);
        }
      }
    };
    loop(item);
  });
  return result;
}

/**
 * @description 10位时间戳转换
 * @param time
 * @returns {string}
 */
function tenBitTimestamp(time) {
  const date = new Date(time * 1000);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "" + m : m;
  let d = date.getDate();
  d = d < 10 ? "" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //组合
}

/**
 * @description 13位时间戳转换
 * @param time
 * @returns {string}
 */
function thirteenBitTimestamp(time) {
  const date = new Date(time / 1);
  const y = date.getFullYear();
  let m = date.getMonth() + 1;
  m = m < 10 ? "" + m : m;
  let d = date.getDate();
  d = d < 10 ? "" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let minute = date.getMinutes();
  let second = date.getSeconds();
  minute = minute < 10 ? "0" + minute : minute;
  second = second < 10 ? "0" + second : second;
  return y + "年" + m + "月" + d + "日 " + h + ":" + minute + ":" + second; //组合
}

/**
 * @description 获取随机id
 * @param length
 * @returns {string}
 */
function uuid(length = 32) {
  const num = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  let str = "";
  for (let i = 0; i < length; i++) {
    str += num.charAt(Math.floor(Math.random() * num.length));
  }
  return str;
}

/**
 * @description m到n的随机数
 * @param m
 * @param n
 * @returns {number}
 */
function random(m, n) {
  return Math.floor(Math.random() * (m - n) + n);
}

/**
 * @description addEventListener
 * @type {function(...[*]=)}
 */
const on = function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event && handler) {
      element.addEventListener(event, handler, useCapture);
    }
  };
}();

/**
 * @description removeEventListener
 * @type {function(...[*]=)}
 */
exports.on = on;
const off = function () {
  return function (element, event, handler, useCapture = false) {
    if (element && event) {
      element.removeEventListener(event, handler, useCapture);
    }
  };
}();
exports.off = off;