"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _stationSetting = require("@/assets/js/stationSetting");
function _default(language, path) {
  if (language == "en" && _stationSetting.VUE_UNION_STATION == "8V") {
    return `/${path}`;
  } else {
    return `/${path}/${language}`;
  }
}