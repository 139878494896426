"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/assets/js/i18n");
//
//
//
var _default = {
  data() {
    return {
      isHideLever: false
    };
  },
  created() {
    if (document.domain.split(".").slice(-2).join(".") === "bitda.tw") {
      // if (
      //   document.domain
      //     .split(".")
      //     .slice(-2)
      //     .join(".") === "localhost"
      // ) {
      this.isHideLever = true;
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle
  }
};
exports.default = _default;