"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _index = require("@/model/exchange/index");
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _message = require("@/components/dialog/message");
var _vuex = require("vuex");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _index2 = require("@/model/assets/index");
var _stationSetting = require("@/assets/js/stationSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const refreshTime = 5000;
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      coinData: {},
      // 币种数据列表
      assetsList: {},
      // 资产数据列表
      symbolList: {},
      // 交易币种表
      active: 1,
      advanced: 1,
      contractMap: {},
      btc_num: "",
      usd_num: "",
      item_name: "",
      assetsShow: "",
      dataList: [],
      isChecked: false,
      oldTableList: [],
      userInfo: {},
      assetsShowCurrency: {},
      digitalCoinList: {
        list: {}
      },
      //加密货币
      legalCoinList: {
        list: {}
      },
      //法币
      listType: [{
        text: "数字货币",
        val: 1
      },
      // { text: "币币详情", val: 2 },
      {
        text: "法币",
        val: 2
      }],
      tableLoading: true,
      canWithdraw: false,
      safetyVerification: false,
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      },
      voucherInterval: null,
      assetsListTimer: null,
      is8V: false,
      defaultPage: false
    };
  },
  components: {
    // AssetSnippet,
    // TopTitle,
    NoData: _NoData.default
    // History
  },

  async created() {
    if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.is8V = true;
    } else {
      this.defaultPage = true;
    }
    this.getUserInfoInit();
    await this.getAssetsData();
    await this.getItemList();
    await this.getUserItems();
    // await this.getAssetsList();
    await this.getStaticTableInfo(55);
    this.getAllItemData(this.active);
  },
  mounted() {
    this.advanced = localStorage.getItem("advanced") ? localStorage.getItem("advanced") : 1;
    this.loopRefreshAssets();
  },
  destroyed() {
    clearInterval(this.voucherInterval);
    clearInterval(this.assetsListTimer);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.assetsListTimer);
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    sortCoinData() {
      return this.sortObj(this.coinData);
    }
  },
  watch: {
    systemCurrency: function (old, newd) {
      this.getAssetsData();
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    // 获取用户信息
    async getUserInfoInit() {
      // const { status, data } = await Account.apiGetUserInfo();
      this.userInfo = JSON.parse(localStorage.getItem("userData"));
      const {
        userInfo,
        authInfo
      } = this.userInfo;
      if (authInfo) {
        if (authInfo.is_bind_security == 1) {
          this.canWithdraw = false;
        } else {
          this.canWithdraw = true;
        }
      }
    },
    onWithdrawClick() {
      this.safetyVerification = true;
    },
    onConfirmV2() {
      this.$router.push(this.routeSplicing(this.language, "ucenter/safe-setting"));
    },
    onClickCurrency(type) {
      if (type == 1) {
        (0, _message.showMessage)({
          message: this.translateTitle(`暂时无法充值，请联系客服`)
        });
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`暂时无法提现，请联系客服`)
        });
      }
    },
    async getItemList() {
      const req = {
        __version: "3.4.3",
        __channel: "2"
      };
      const {
        status,
        data
      } = await (0, _index2.getStaticCoinList)(req);
      if (status == 200) {
        this.coinData = data;
      }
    },
    async getUserItems() {
      const req = {
        type: "withdraw_settings"
      };
      const {
        status,
        data
      } = await (0, _index2.apiGetUserItems)(req);
      if (status == 200) {
        let tempList = data.withdraw_settings;
        for (let item in this.coinData) {
          this.coinData[item].in_status = this.coinData[item].itemDetail.in_status;
          this.coinData[item].out_status = this.coinData[item].itemDetail.out_status;
          tempList.forEach(ele => {
            if (this.coinData[item].item_id == ele.item_id) {
              this.coinData[item].in_status = ele.in_status;
              this.coinData[item].out_status = ele.out_status;
            }
          });
        }
      }
    },
    loopRefreshAssets() {
      this.assetsListTimer = setInterval(() => this.getAssetsData(), refreshTime);
    },
    async getAssetsData() {
      // this.tableLoading = true;
      let params = {
        type: "remain"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.assetsList = data.remain;
        this.usd_num = data.remain.all_u_valuation["usdt"];
        this.btc_num = data.remain.all_u_valuation[this.systemCurrency];
        // this.getAllItemData(this.active); //表格数据定时调用
      }
    },

    // async getAssetsData() {
    //   // this.tableLoading = true;
    //   const { status, data } = await getAssetsList();
    //   if (status == 200) {
    //     this.assetsList = data;
    //     this.usd_num = data.usd;
    //     this.btc_num = data.btc;
    //   }
    // },
    handlePassword(type) {
      this.advanced = type;
      localStorage.setItem("advanced", this.advanced);
    },
    rechargeClick(row) {
      if (row.in_status === 0) {
        let coinName = row.item_name.toUpperCase();
        (0, _message.showMessage)({
          message: coinName + this.translateTitle(`暂不支持充币`)
        });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(row));
        localStorage.setItem("currentCoin", JSON.stringify(row));
        this.$router.push({
          path: this.routeSplicing(this.language, "ucenter/assets/recharge/" + row.item_name.toLowerCase())
        });
      }
    },
    // 提币
    withdrawalClick(row) {
      if (row.out_status === 0) {
        let coinName = row.item_name.toUpperCase();
        (0, _message.showMessage)({
          message: coinName + this.translateTitle(`暂不支持提币`)
        });
      } else {
        // sessionStorage.setItem("currentCoin", JSON.stringify(row));
        localStorage.setItem("currentCoin", JSON.stringify(row));
        this.$router.push({
          path: this.routeSplicing(this.language, "ucenter/assets/withdrawal/" + row.item_name.toLowerCase())
        });
      }
    },
    onTrade(row) {
      let tempUrl = this.routeSplicing(this.language, `exchange/${row.item_name.toLowerCase()}_${"usdt"}`);
      window.open(tempUrl);
    },
    // onChangeAssets() {
    //   if (this.isChecked) {
    //     this.dataList = [];
    //     this.oldTableList.forEach((ele) => {
    //       if (+ele.cny > 0) {
    //         this.dataList.push(ele);
    //       }
    //     });
    //   } else {
    //     this.dataList = this.oldTableList;
    //   }
    // },

    // onChangeItem(val) {
    //   if (val) {
    //     this.dataList = [];
    //     this.oldTableList.forEach((ele) => {
    //       let item_name = ele.item_name.toUpperCase();
    //       if (item_name.indexOf(val.toUpperCase()) != -1) {
    //         this.dataList.push(ele);
    //       }
    //     });
    //   } else {
    //     this.dataList = this.oldTableList;
    //   }
    // },
    // 根据筛选条件返回结果
    onFilter() {
      if (this.item_name && this.isChecked) {
        this.dataList = [];
        this.oldTableList.forEach(ele => {
          let item_name = ele.item_name.toUpperCase();
          if (+ele.all > 0 && item_name.indexOf(this.item_name.toUpperCase()) != -1) {
            this.dataList.push(ele);
          }
        });
      } else if (this.isChecked && !this.item_name) {
        this.dataList = [];
        this.oldTableList.forEach(ele => {
          if (+ele.all > 0) {
            this.dataList.push(ele);
          }
        });
      } else if (!this.isChecked && this.item_name) {
        this.dataList = [];
        this.oldTableList.forEach(ele => {
          let item_name = ele.item_name.toUpperCase();
          if (item_name.indexOf(this.item_name.toUpperCase()) != -1) {
            this.dataList.push(ele);
          }
        });
      } else {
        this.dataList = this.oldTableList;
      }
    },
    /**
      资产管理表数据
       @param null
       @returns list
     */
    getAllItemData(val) {
      // this.tableLoading = true;
      let sortDataMap = {};
      let tableList = [];
      let tableMap = {};
      let tempAssets = {};
      tempAssets = this.assetsList.list;
      sortDataMap = this.sortObj(this.coinData);
      for (let ele in tempAssets) {
        for (let item in sortDataMap) {
          if (sortDataMap[item].item_id == tempAssets[ele].item_id && sortDataMap[item].type == val) {
            tableMap = {
              item_id: sortDataMap[item].item_id,
              item_name: sortDataMap[item].item_name.toUpperCase(),
              coin_url: sortDataMap[item].itemDetail.coin_url,
              in_status: sortDataMap[item].in_status,
              out_status: sortDataMap[item].out_status,
              coin_num: tempAssets[ele].coin_num,
              lock: tempAssets[ele].lock,
              over: tempAssets[ele].over,
              cny: tempAssets[ele].cny,
              usd: tempAssets[ele].usd,
              type: sortDataMap[item].type,
              is_trade: sortDataMap[item].is_trade,
              display_name: sortDataMap[item].itemDetail.language.display_en,
              all: tempAssets[ele].all,
              valuation: tempAssets[ele].valuation,
              orderBy: tempAssets[ele].order_by
            };
            tableList.push(tableMap);
          }
        }
      }
      let tempTableList = tableList.sort((a, b) => {
        let aValue = parseFloat(a.valuation[this.systemCurrency]);
        let bValue = parseFloat(b.valuation[this.systemCurrency]);
        if (aValue == bValue) {
          return parseInt(a.orderBy) > parseInt(b.orderBy) ? 1 : -1;
        } else {
          return aValue < bValue ? 1 : -1;
        }
      });

      // localStorage.setItem("webItemList", JSON.stringify(tableList));
      this.oldTableList = tempTableList;
      this.dataList = tempTableList;
      this.onFilter();
      this.tableLoading = false;

      // this.pageInfo.pageTotal = tableList.length;
      // const pagelength = tableList.length;
      // this.pageInfo.pageCount = Math.ceil(pagelength / this.pageInfo.pageSize);

      // this.dataList = tableList.slice(
      //   (this.pageInfo.pageCurrent - 1) * this.pageInfo.pageSize,
      //   this.pageInfo.pageCurrent * this.pageInfo.pageSize
      // );

      // this.oldTableList = this.dataList;
    },

    // 获取资产列表
    getAssetsList() {
      let params = {
        type: "all"
      };
      (0, _index.apiGetAssets)(params).then(({
        status,
        data
      }) => {
        if (status === 200) {
          this.assetsShow = data.assets.u_valuation;
          this.assetsShowCurrency = data.assets.all_u_valuation;
        }
      });
    },
    // getStaticTableList() {
    //   getStaticTableList().then((res) => {
    //     if (res.status == 200) {
    //     }
    //   });
    // },
    async getStaticTableInfo(sv_id) {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(sv_id);
      if (status == 200) {
        this.symbolList = JSON.parse(JSON.stringify(data));
        for (let item in this.coinData) {
          this.coinData[item].is_trade = false;
          for (let ele in this.symbolList) {
            if (this.coinData[item].item_id == this.symbolList[ele].coin_from) {
              this.coinData[item].is_trade = true;
            }
          }
        }
      }
    },
    sortObj(obj) {
      if (obj[10007]) {
        obj[10007].order_by = -1;
      }
      const keys = Object.keys(obj).sort(function (a, b) {
        return obj[a].order_by - obj[b].order_by;
      });
      var newObj = [];
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        newObj.push(obj[key]);
      }
      return newObj;
    },
    onTypeChange(val) {
      this.active = val;
      this.getAllItemData(this.active);
      this.onFilter(this.item_name);
      // this.onChangeAssets();
      // this.onChangeItem(this.item_name);
    },

    onPageChange() {
      this.getAllItemData();
    }
  }
};
exports.default = _default;