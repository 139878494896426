"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetContractList = apiGetContractList;
exports.apiGetSpotList = apiGetSpotList;
exports.apiGetTradePairList = apiGetTradePairList;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env

function apiGetTradePairList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/TradeCoin/getTradePairList`,
    data,
    type: "POST"
  });
}
function apiGetSpotList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/MarketV2/getContractList`,
    data,
    type: "POST"
  });
}
// U本位
function apiGetContractList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/MarketV2/getContractList`,
    data,
    type: "POST"
  });
}