"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _getAssetsCoin = require("../../common/getAssetsCoin");
var _SocketDispatcher = _interopRequireDefault(require("@/assets/js/SocketDispatcher"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: ["data", "assetsList", "is_recharge"],
  data() {
    return {
      coinData: {},
      oldCoinData: [],
      coinList: []
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      systemCurrency: "user/currency"
    })
  },
  async created() {
    this.coinData = await (0, _getAssetsCoin.getCoinInfo)();
    for (let item in this.coinData) {
      this.coinData[item].capital_name = this.coinData[item].itemDetail.capital_name;
      this.coinData[item].language = this.coinData[item].itemDetail.language["display_" + this.language];
      this.coinList.push(this.coinData[item]);
    }
    this.oldCoinData = this.coinList;
    Object.keys(this.coinData).forEach(item_id => {
      let itemDetail = this.coinData[item_id]["itemDetail"];
      if (itemDetail["in_status"] === 0 || itemDetail["out_status"] === 0) {
        delete this.coinData[item_id];
      }
    });
  },
  methods: {
    onFilterItem(val) {
      if (val) {
        this.coinList = [];
        this.coinList = this.oldCoinData.filter(ele => {
          let capital_name = String(ele.capital_name.toUpperCase());
          let language = String(ele.language);
          if (capital_name.indexOf(val.toUpperCase()) != -1) {
            this.coinList.push(ele);
          }
        });
      } else {
        this.coinList = this.oldCoinData;
      }
    },
    // 选择币种
    async selectChange(val) {
      if (val == this.data.item_id) {
        return;
      }
      // 获取用户资产新数据
      await (0, _getAssetsCoin.getAssetsData)();
      for (let key in this.coinData) {
        if (val == key) {
          const query = JSON.parse(JSON.stringify(this.coinData[key].itemDetail.logic_name));
          this.$router.replace(query);
          _SocketDispatcher.default.dispatchEvent("onChainChangeDispatch", this.coinData[key].itemDetail.default_chain);
          this.$emit("changeAssetData", this.coinData[key]);
        }
      }
    }
  }
};
exports.default = _default;