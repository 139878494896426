"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _kline = _interopRequireDefault(require("./components/kline.vue"));
var _depth = _interopRequireDefault(require("./components/depth.vue"));
var _deal = _interopRequireDefault(require("./components/deal.vue"));
var _market = _interopRequireDefault(require("./components/market.vue"));
var _trade = _interopRequireDefault(require("./components/trade.vue"));
var _panel = _interopRequireDefault(require("./components/panel.vue"));
var _vuex = require("vuex");
var _index = _interopRequireDefault(require("@/store/index"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _historyOrder = _interopRequireDefault(require("./components/history-order.vue"));
var _index2 = require("@/model/exchange/index");
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _stationSetting = require("@/assets/js/stationSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Exchange",
  mixins: [_commonMixin.default],
  components: {
    Deal: _deal.default,
    Kline: _kline.default,
    Depth: _depth.default,
    Market: _market.default,
    Panel: _panel.default,
    Trade: _trade.default,
    HistoryOrder: _historyOrder.default
  },
  data() {
    return {
      tt: {},
      lockReconnect: false,
      tabList: [{
        value: "1",
        label: "盘口"
      }, {
        value: "2",
        label: "实时成交"
      }],
      currIndex: "1",
      wsContentTime: 0,
      symbol: this.$route.params.symbol || "btc_usdt",
      // symbol: "btc_usdt",
      coinFrom: "--",
      coinTo: "--",
      voucherInterval: null,
      coinInfoMap: {},
      exchangeCoinMap: {},
      tradePair: [],
      tradePairArray: [],
      marketList: [],
      tempMarkList: [],
      tempMarkListV2: [],
      apiMarketList: [],
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--"
          },
          list: []
        }
      },
      assetsInfoList: [],
      orderbookList: [{
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }],
      klineList: [],
      depthList: {
        a: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]],
        b: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]]
      },
      orderbookEData: [],
      CoinItemIdMap: {},
      swap_symbol_market: {},
      swap_symbol_id: 0,
      old_swap_symbol_id: 0,
      from_item_id: "",
      to_item_id: "",
      wsUrl: "",
      token: "",
      pageLoading: false,
      onload: false,
      marketInfoShow: {
        last: "",
        currency: {
          cny: ""
        }
      },
      isFullScreen: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    }),
    title() {
      let title = "数字货币银行";
      if (_stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
        title = "数字理财平台";
        // } else if (
        //   VUE_UNION_STATION == "MaskEx" ||
        //   VUE_UNION_STATION == "8V" ||
        //   VUE_UNION_STATION == "5A"
        // ) {
      } else if (_stationSetting.VUE_UNION_STATION == "MaskEx" || _stationSetting.VUE_UNION_STATION == "5A") {
        title = "Cryptocurrency Exchange";
      } else if (_stationSetting.VUE_UNION_STATION == "S6") {
        title = "数字货币私人银行";
      } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
        title = "Bitda Cryptocurrency Exchange｜Bitda｜幣達交易所";
      } else {
        title = "数字货币银行";
      }
      let val = this.marketInfoShow.last + "  " + this.coinFrom.toUpperCase() + "/" + this.coinTo.toUpperCase() + "  " + this.translateTitle(title);
      return val;
    }
    // marketInfoShow() {
    //   let tempMarketInfo = {
    //     last: "",
    //     currency: { cny: "" },
    //   };
    //   this.marketList.forEach((ele) => {
    //     if (ele.symbol_id == this.swap_symbol_id) {
    //       tempMarketInfo = ele;
    //     }
    //   });
    //   return tempMarketInfo;
    // },
  },

  async created() {
    await this.getExchangeItemId();
    await this.getUrlSymbol();
    this.getTradePairList();
    // await this.getTradePairList();
    this.resolveCoin();
    let tempCookies = _jsCookie.default.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.initWs();
    } else {
      this.token = _jsCookie.default.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
  },
  mounted() {
    var meta = null;
    var title = null;
    if (_stationSetting.VUE_UNION_STATION == "bitda") {
      title = document.getElementsByTagName("title");
      title[0].innerHTML = "現貨交易|幣達交易所";
      meta = document.getElementsByTagName("meta");
      $("meta[property='og:description']").attr("content", "加密貨幣現貨交易是指交易雙方以當前市價買賣某種加密貨幣。 以 BTC/USDT 交易對為例，該交易對的價格即為買入/賣出 1 枚 BTC 需要付出/可獲得的 USDT 數額。");
      meta["title"].setAttribute("content", "現貨交易|幣達交易所");
      meta["description"].setAttribute("content", "加密貨幣現貨交易是指交易雙方以當前市價買賣某種加密貨幣。 以 BTC/USDT 交易對為例，該交易對的價格即為買入/賣出 1 枚 BTC 需要付出/可獲得的 USDT 數額。");
    } else if (_stationSetting.VUE_UNION_STATION == "8V") {} else {
      this.voucherInterval = setInterval(() => {
        document.title = this.title;
        // document.getElementById("chat-widget-container").style.display = "none"; //隐藏
      }, 500);
    }
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  watch: {
    async userInfo(newValue, oldValue) {
      if (this.userInfo == null) {
        await this.initURL();
        this.initWs();
      } else {
        let tempUserInfo = sessionStorage.getItem("userInfo");
        if (tempUserInfo) {
          return;
        }
        this.token = _jsCookie.default.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    }
  },
  methods: {
    zoomKline(isFull) {
      this.isFullScreen = isFull;
    },
    onAddOption() {
      this.$refs.marketComponent.getOptionList();
    },
    onMarketAddOption() {
      this.$refs.panelComponent.getOptionList();
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    onTradeSuccess(type) {
      this.$refs.historyOrder.reset(type);
    },
    getTradePairList() {
      (0, _index2.apiGetSpotList)().then(res => {
        if (res.status == 200) {
          let tempArr = res.data;
          tempArr.forEach(ele => {
            ele.lastV2 = Number(ele.last);
            ele.currency.usdV2 = Number(ele.currency.usd);
            ele.price_change_percentV2 = Number(ele.price_change_percent);
            for (const key in this.coinInfoMap) {
              if (ele.symbol_id == key) {
                ele["from_symbol"] = this.coinInfoMap[key].name.split("_")[0].toUpperCase();
                ele["to_symbol"] = this.coinInfoMap[key].name.split("_")[1].toUpperCase();
                ele["info"] = this.coinInfoMap[key];
                this.tempMarkList.push(ele);
              }
            }
          });
          this.marketList = this.tempMarkList;
        }
      });
    },
    async getUrlSymbol() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1 && data[key].is_show == 1) {
            tempObj[key] = data[key];
          }
        }
        this.coinInfoMap = JSON.parse(JSON.stringify(tempObj));
        let tempSymbol = this.$route.params.symbol;
        if (!tempSymbol) {
          tempSymbol = "btc_usdt";
          this.symbol = "btc_usdt";
        }
        for (const key in this.coinInfoMap) {
          this.tradePairArray.push(this.coinInfoMap[key].name.replace("_", "/").toUpperCase());
          if (this.coinInfoMap[key].name.toLowerCase() == tempSymbol.toLowerCase()) {
            this.swap_symbol_id = this.coinInfoMap[key].symbol_id;
            this.from_item_id = this.coinInfoMap[key].coin_from;
            this.to_item_id = this.coinInfoMap[key].coin_to;
          }
        }
      }
    },
    async getExchangeItemId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.CoinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    onSymbolChangeV2(params) {
      this.$refs.marketComponent.onSymbolChange(params, "1");
    },
    onSymbolChange(item) {
      if (this.swap_symbol_id == item.symbol_id) {
        return;
      }
      this.old_swap_symbol_id = this.swap_symbol_id;
      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "order",
          symbol: this.swap_symbol_id
        }
      });
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "order",
          symbol: item.symbol_id
        }
      });
      this.orderbookList = [{
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }];
      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "depth",
          symbol: this.swap_symbol_id
        }
      });
      this.depthList = {
        a: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]],
        b: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]]
      };
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "market",
          symbol: item.symbol_id
        }
      });
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "depth",
          symbol: item.symbol_id
        }
      });
      this.$refs.klineComponent.onChangeSymbol(item);
      this.old_swap_symbol_id = this.swap_symbol_id;
      this.resolveCoin();
      this.swap_symbol_id = item.symbol_id;
      this.from_item_id = item.info.coin_from;
      this.to_item_id = item.info.coin_to;
    },
    uniqueArr(arr) {
      var hash = [];
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].E === arr[j].E) {
            ++i;
          }
        }
        hash.push(arr[i]);
      }
      return hash;
    },
    resolveCoin() {
      this.pageLoading = true;
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        tempSymbol = "btc_usdt";
        this.swap_symbol_id = 22;
      }
      let trade_pair = tempSymbol.replace("_", "/").toUpperCase();
      if (this.tradePairArray.indexOf(trade_pair) == -1) {
        tempSymbol = "btc_usdt";
        this.swap_symbol_id = 22;
      }
      this.symbol = tempSymbol;
      const [coinFrom, coinTo] = tempSymbol.split("_");
      this.coinFrom = coinFrom;
      this.coinTo = coinTo;
      setTimeout(() => {
        this.pageLoading = false;
      }, 300);
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };

      const {
        status,
        data
      } = await (0, _index2.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "kline",
            resolution: "15",
            symbol: me.swap_symbol_id
          }
        },
        callback,
        onConnect,
        mounted
      };
      let wsNew = new _socket.default(option).getInstance();
      me.global.setWs(wsNew);
      // 重新连接
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            app_id: _stationSetting.VUE_APP_ID,
            symbol: me.swap_symbol_id
          }
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: _stationSetting.VUE_APP_ID
            // symbol: me.swap_symbol_id,
          }
        });

        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id
          }
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "order",
            symbol: me.swap_symbol_id
          }
        });
        if (_jsCookie.default.get("topcredit_exchange_t")) {
          me.global.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets"
            }
          });
        }
      }
      function callback(data) {
        const {
          action
        } = data;
        if (action == "Pushdata.marketM") {
          if (data.data) {
            me.tempMarkListV2 = [];
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.currency.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
              for (const key in me.coinInfoMap) {
                if (ele.symbol_id == key) {
                  ele["from_symbol"] = me.coinInfoMap[key].name.split("_")[0].toUpperCase();
                  ele["to_symbol"] = me.coinInfoMap[key].name.split("_")[1].toUpperCase();
                  ele["info"] = me.coinInfoMap[key];
                  me.tempMarkListV2.push(ele);
                }
              }
            });
            me.tempMarkListV2.forEach(ele => {
              if (ele.symbol_id == me.swap_symbol_id) {
                me.marketInfoShow = ele;
              }
            });
            me.marketList = me.tempMarkListV2;
          }
        }
        if (action == "Pushdata.kline") {
          window.resolveKlineData(data);
        }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        if (action == "Pushdata.orderbook") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            me.orderbookList.unshift(...data.data);
            if (me.orderbookList.length > data.data.length) {
              me.orderbookList.splice(27, me.orderbookList.length + 1);
            }
            // if (me.orderbookList.length > 26) {
            //   me.orderbookList = me.orderbookList.slice(0, 26);
            // }
          }
        }

        if (action == "Pushdata.depth") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            let tempObj = data.data;
            if (tempObj.a.length > 0) {
              tempObj.c = 0;
              tempObj.d = 0;
            }
            me.depthList = tempObj;
          }
        }
      }
    }
  }
};
exports.default = _default;