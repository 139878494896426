"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stationSetting = require("@/assets/js/stationSetting");
const md5 = require("md5");
var _default = (params, app_id) => {
  var appkey = _stationSetting.VUE_APP_KEY;
  var timestamp = Math.round(new Date().getTime() / 1000) + "";
  params["nonce"] = timestamp.substr(-6);
  params["app_id"] = app_id;
  for (let key in params) {
    params[key] = params[key] || params[key] === 0 || params[key] === false ? params[key] : "";
  }
  let keys = Object.keys(params);
  let length = keys.length;
  keys = keys.sort();
  let sign = "";
  for (let i = 0; i < length; i++) {
    if (sign != "") sign = sign + "&";
    sign = sign + keys[i] + "=" + params[keys[i]];
  }
  //add appkey
  sign = sign + appkey;
  sign = md5(sign).toString();
  return sign;
};
exports.default = _default;