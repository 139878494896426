<template>
  <header class="web-header">
    <!-- Left buttons -->
    <div class="left-buttons">
      <button class="header-button">MMaskEX</button>
      <button class="header-button">Buy Crypto</button>
      <button class="header-button">Markets</button>
      <div class="dropdown">
        <button class="header-button with-dropdown">Spot</button>
        <div class="dropdown-content">
          <!-- Add dropdown content here -->
        </div>
      </div>
      <button class="header-button">Derivatives</button>
      <button class="header-button">Finance</button>
      <div class="dropdown">
        <button class="header-button with-dropdown">Company</button>
        <div class="dropdown-content">
          <!-- Add dropdown content here -->
        </div>
      </div>
      <button class="header-button new-button">
        Blog <span class="new-icon">NEW</span>
      </button>
      <button class="header-button">MaskEX Card</button>
    </div>

    <!-- Right buttons -->
    <div class="right-buttons">
      <button class="header-button">Log In</button>
      <button class="header-button sign-up-button">Sign Up</button>
      <div class="vertical-separator"></div>
      <button class="header-button">APP Download</button>
      <div class="vertical-separator"></div>
      <div class="dropdown">
        <button class="header-button with-dropdown">USD</button>
        <div class="dropdown-content">
          <!-- Add dropdown content here -->
        </div>
      </div>
      <div class="language-button">
        <img
          src="language-flag.png"
          alt="Language Flag"
          class="language-flag"
        />
        <span class="language-text">Language</span>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  // Header component logic
};
</script>

<style scoped>
/* Header styles */
.web-header {
  background-color: #1a202c;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.left-buttons,
.right-buttons {
  display: flex;
  align-items: center;
}

.header-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
  position: relative;
}

.header-button.with-dropdown::after {
  content: "▼";
  margin-left: 5px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #1a202c;
  min-width: 120px;
  z-index: 1;
}

/* Add more styles for dropdowns, separators, and language button */
</style>
