"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _message = require("@/components/dialog/message.js");
var _index = require("@/model/assets/index");
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
var _settings = require("@/model/settings");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _element = require("@/assets/js/element");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {
    NoData: _NoData.default,
    QrcodeVue: _qrcode.default
  },
  props: ["data"],
  data() {
    return {
      // onSelectBlur,
      // onSelectFilter,
      ua_id: "",
      uaid: "",
      checked: false,
      dialogVisible: false,
      createVisible: false,
      modifyVisible: false,
      delVisible: false,
      modifyLoading: false,
      delLoading: false,
      creatLoading: false,
      dataLoading: true,
      qr_code: "",
      active: "",
      isRecharge: true,
      addressActive: "",
      coinInfo: [],
      chainMap: {},
      address: "",
      addressList: [],
      addressList4Tag: {},
      // addressOptions: [],
      addressData: {
        address: "",
        bak: "",
        qrcode_url: "",
        transactions: "",
        chain_tag: "",
        ua_id: ""
      },
      bak: "",
      new_bak: "",
      chain_tag: "",
      time: "",
      isModify: false,
      isScroll: false,
      qrcodeLoading: false
    };
  },
  created() {
    this.default_chain = this.data.itemDetail.default_chain.toUpperCase();
    // this.chain_tag = this.data.itemDetail.default_chain.toUpperCase();
    this.isRecharge = this.data.itemDetail.in_status == 1 ? true : false;
    this.getStaticChainInfo();
  },
  computed: {
    coinInfoComputed() {
      let tempObj = [];
      for (const key in this.coinInfo) {
        if (this.data.itemDetail.coin_id == this.coinInfo[key].value) {
          tempObj.push({
            key: this.coinInfo[key].value,
            value: this.coinInfo[key].label,
            label: this.coinInfo[key].label.toUpperCase()
          });
        }
      }
      if (tempObj.length > 0) {
        this.chain_tag = tempObj[0].label;
      } else {
        this.chain_tag = this.data.itemDetail.default_chain.toUpperCase();
      }
      let erc20 = {};
      let trc20 = {};
      tempObj.forEach(ele => {
        if (ele.label === "ERC20") erc20 = ele;
        if (ele.label === "TRC20") trc20 = ele;
      });
      let newtempObj = tempObj.filter(ele => {
        return ele.label != "ERC20" && ele.label != "TRC20";
      });
      if (JSON.stringify(erc20) != "{}") {
        newtempObj.push(erc20);
      }
      if (JSON.stringify(trc20) != "{}") {
        newtempObj.unshift(trc20);
      }
      // 默认erc20
      // tempObj.forEach((ele) => {
      //   if (ele.value == "erc20") {
      //     this.active = ele.value;
      //   }
      // });
      return newtempObj;
    },
    // getScroll() {
    //   if (this.addressList.length > 6) {
    //     return { "overflow-y": "auto", "overflow-x": "hidden" };
    //   }
    // },
    getScrollClass() {
      let tempVal = "";
      if (this.addressList.length > 6) {
        tempVal = "scroll-calss";
      } else {
        tempVal = "";
      }
      return tempVal;
    }
  },
  // watch: {
  //   addressData() {
  //     this.qrcodeLoading = true;
  //   }
  // },
  methods: {
    // onQrcodeLoad() {
    //   this.qrcodeLoading = false;
    // },
    // async getStaticCoinInfo() {
    //   await apiGetStaticTableInfo(57).then(({ data }) => {
    //     for (const key in data) {
    //       this.coinInfo.push({
    //         label: data[key].chain.toUpperCase(),
    //         value: data[key].coin_id,
    //       });
    //     }
    //   });
    // },
    //  async getStaticChainInfo() {
    //   await apiGetStaticTableInfo(56).then(({ data }) => {
    //    this.chainMap = data
    //   });
    // },
    // initCoinInfo(){
    //  this.coinInfo.forEach(ele=>{

    //    for(let item in this.chainMap){
    //      if(ele.label == this.chainMap[item].chain_tag.toUpperCase()){
    //        if(this.chainMap[item].status==1)
    //        ele.chain_status = this.chainMap[item].status
    //      }
    //    }
    //  })
    // },
    async getStaticChainInfo() {
      this.coinInfo = [];
      await (0, _settings.apiGetStaticTableInfo)(56).then(({
        data
      }) => {
        this.chainMap = data;
      });
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      await (0, _settings.apiGetStaticTableInfo)(57).then(({
        data
      }) => {
        for (const key in data) {
          for (let item in this.chainMap) {
            if (data[key].chain.toUpperCase() == this.chainMap[item].chain_tag.toUpperCase() && data[key].status == 1 && this.chainMap[item].status == 1) {
              this.coinInfo.push({
                label: data[key].chain.toUpperCase(),
                value: data[key].coin_id,
                rate_out: data[key].fee,
                is_default: data[key].is_default,
                chain_status: this.chainMap[item].status,
                status: data[key].status,
                minout: data[key].minout,
                maxout: data[key].maxout
              });
            }
          }
        }
      });
    },
    selectChange(val) {
      this.addressList.forEach(item => {
        if (val == item.ua_id) {
          this.addressData = item;
          this.ua_id = val;
        }
      });
    },
    scrollAdress() {
      this.isScroll = true;
    },
    onCreate() {
      this.createVisible = true;
      this.time = parseInt(new Date().getTime() / 1000);
      this.bak = this.translateTitle(`新地址`) + this.time;
      this.isModify = false;
    },
    onModify() {
      this.modifyVisible = true;
      this.selectChange(this.ua_id);
      this.new_bak = this.addressData.bak;
    },
    onDelete() {
      this.delVisible = true;
      this.isModify = false;
    },
    //删除确认
    async onDeleteConfirm() {
      this.delLoading = true;
      const req = {
        ua_id: this.ua_id
      };
      const {
        status,
        data
      } = await (0, _index.apiDelFinanceAddress)(req);
      this.delLoading = false;
      if (status == 200) {
        this.getAddress(this.chain_tag);
        this.delVisible = false;
        (0, _message.showMessage)({
          message: "删除成功",
          type: "success"
        });
      }
    },
    //修改确认
    async onModifyConfirm() {
      if (this.new_bak) {
        if (this.new_bak == this.addressData.bak) {
          this.modifyVisible = false;
          return;
        } else {
          const req = {
            ua_id: this.ua_id,
            bak: this.new_bak
          };
          this.modifyLoading = true;
          const {
            status,
            data
          } = await (0, _index.apiSaveFinanceAddress)(req);
          this.modifyLoading = false;
          if (status == 200) {
            (0, _message.showMessage)({
              message: this.translateTitle(`修改成功`),
              type: "success"
            });
            this.getAddress(this.chain_tag);
            this.new_bak = "";
            this.isModify = true;
            this.modifyVisible = false;
          }
        }
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`请输入新备注名`),
          type: "error"
        });
      }
    },
    onModifyClose() {
      this.new_bak = "";
    },
    onCreateClose() {
      this.bak = "";
    },
    mainClose() {
      this.isModify = false;
      this.dialogVisible = false;
      // this.chain_tag = "erc20";
    },

    //新增确认
    async onCreateConfirm() {
      if (this.bak) {
        this.creatLoading = true;
        const req = {
          item_id: this.data.item_id,
          bak: this.bak
        };
        // if (!this.data.itemDetail.multi_chain_status) {
        //   this.active = "";
        // } else {
        //   req.chain_tag = this.chain_tag;
        // }
        req.chain_tag = this.chain_tag;
        const {
          status,
          data
        } = await (0, _index.apiCreateFinanceAddress)(req);
        this.creatLoading = false;
        if (status == 200) {
          (0, _message.showMessage)({
            message: this.translateTitle(`生成新充币地址成功`),
            type: "success"
          });
          this.getAddress(this.chain_tag);
          this.bak = "";
          this.createVisible = false;
        }
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`请输入新地址备注名`),
          type: "error"
        });
      }
    },
    // 选项卡切换
    onTypeChange(val) {
      this.isModify = false;
      this.chain_tag = val;
      // this.getAddress(val);
      this.onChangeTag(val);
    },
    //切换链选项卡
    onChangeTag(tagName) {
      this.active = tagName.toUpperCase();
      this.addressList = this.addressList4Tag[tagName.toLowerCase()];
      // this.addressList = data;  //pangpang注释
      // this.addressOptions = data; //pangpang注释

      if (!this.isModify) {
        if (this.addressList4Tag[tagName.toLowerCase()].length > 0) {
          this.addressData = this.addressList4Tag[tagName.toLowerCase()][0];
          this.ua_id = this.addressData.ua_id;
          this.addressActive = this.addressData.ua_id;
          // this.onAddressChange(this.ua_id);
        } else {
          this.addressList = [];
          this.addressData = [];
        }
      } else {
        this.onAddressChange(this.ua_id);
      }
      this.dialogVisible = true;
    },
    onAddressChange(val) {
      //   this.$refs.scorllRef.scrollTo(0,0)
      // //   window.scrollTo(0,0);   // chrome
      // // document.body.scrollTop = 0
      this.addressActive = val;
      this.addressList.forEach(item => {
        if (val == item.ua_id) {
          this.addressData = item;
          this.ua_id = val;
        }
      });
    },
    onFilterNewBak(val) {
      this.new_bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    onFilterBak(val) {
      this.bak = val.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\$]/g, "");
    },
    copySuccess() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制成功`),
        type: "success"
      });
    },
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制失败`)
      });
    },
    // 获取充币地址
    async getAddress(val) {
      // if (!this.checked) {
      //   showMessage({ message: "请先确认是否要充值" });
      //   return;
      // }  coinInfoComputed

      // this.chain_tag = val;  //pangpang注释
      const item_id = this.data.item_id;
      let params = {
        item_id: item_id
      };
      // if (!this.data.itemDetail.multi_chain_status) {
      //   this.active = "";
      // } else {
      //   params.chain_tag_list = this.chain_tag;
      // }
      let chain_tag_temp = [];
      let chain_tag_strings = "";
      // if (!val) {
      this.coinInfoComputed.forEach(item => {
        chain_tag_temp.push(item.value);
      });
      chain_tag_strings = chain_tag_temp.join(",");
      params.chain_tag_list = chain_tag_strings;
      // } else {
      //   params.chain_tag_list = val;
      // }

      const {
        status,
        msg,
        data
      } = await (0, _index.apiGetFinanceAddressList)(params);
      // apiGetFinanceAddressList(params).then(({ status, msg, data }) => {

      if (status === 200) {
        // if (val) {                          //pangpang 注释
        //   this.active = val.toUpperCase();
        // }
        this.active = this.chain_tag.toUpperCase();
        chain_tag_temp.forEach(tag => {
          this.addressList4Tag[tag.toLowerCase()] = [];
          data.forEach(ele => {
            if (tag.toLowerCase() == ele.chain_tag) {
              this.addressList4Tag[tag.toLowerCase()].push(ele);
            }
          });
        });
        this.addressList = this.addressList4Tag[this.chain_tag.toLowerCase()];
        // this.addressList = data;  //pangpang注释
        // this.addressOptions = data; //pangpang注释

        if (!this.isModify) {
          if (this.addressList4Tag[this.chain_tag.toLowerCase()].length > 0) {
            this.addressData = this.addressList4Tag[this.chain_tag.toLowerCase()][0];
            this.ua_id = this.addressData.ua_id;
            this.addressActive = this.addressData.ua_id;
            // this.onAddressChange(this.ua_id);
          } else {
            this.addressList = [];
            this.addressData = [];
          }
        } else {
          this.onAddressChange(this.ua_id);
        }
        // if (!this.isModify) {
        //   if (data.length > 0) {
        //     this.addressData = data[0];
        //     this.ua_id = this.addressData.ua_id;
        //     this.addressActive = this.addressData.ua_id;
        //     // this.onAddressChange(this.ua_id);
        //   } else {
        //     this.addressList = [];
        //     this.addressData = [];
        //   }
        // } else {
        //   this.onAddressChange(this.ua_id);
        // }
        this.dialogVisible = true;
      } else {
        (0, _message.showMessage)({
          message: msg ? msg : status
        });
      }
    }
  }
};
exports.default = _default;