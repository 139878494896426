"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _Title = _interopRequireDefault(require("./components/Title"));
var _CoinInfo = _interopRequireDefault(require("./components/recharge_withdrawal/CoinInfo"));
var _History = _interopRequireDefault(require("./components/recharge_withdrawal/History"));
var _WithdrawalAddress = _interopRequireDefault(require("./components/withdrawal/WithdrawalAddress"));
var _WithdrawalExplain = _interopRequireDefault(require("./components/withdrawal/WithdrawalExplain"));
var _ChargeExplain = _interopRequireDefault(require("./components/recharge/ChargeExplain"));
var _ChargeFragment = _interopRequireDefault(require("./components/recharge/ChargeFragment"));
var _message = require("@/components/dialog/message.js");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _index = require("@/model/assets/index");
var _getAssetsCoin = require("./common/getAssetsCoin");
var _momentTimezone = require("moment-timezone");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import WithdrawFaq from "./components/withdrawal/withdrawFaq";
// import ChargeMaintainFragment from "./components/recharge/ChargeMaintainFragment";
// 充币页面
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      isPageLoading: true,
      //页面加载loading
      safetyVerification: false,
      activeName: "1",
      data: {},
      // 单项资产数据
      coinName: "",
      exchangeList: [],
      // 充提币记录
      assetsList: {},
      // 资产列表
      isRecharge: true,
      // 是否是提币
      exchangeLimit: {},
      // 提币限额
      accountInfo: {},
      withdrawTip: "",
      multi_chain_status: 1,
      historyLoading: true,
      chainMap: {},
      is_trade: true,
      operationList: [{
        value: 1,
        label: "充值"
      }, {
        value: 2,
        label: "提现"
      }
      // { value: 3, label: "交易" },
      ],

      currActicve: 1,
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      },
      pageNum: 1
    };
  },
  async created() {
    // let currentCoin = JSON.parse(sessionStorage.getItem("currentCoin"));
    let currentCoin = JSON.parse(localStorage.getItem("currentCoin"));
    this.is_trade = currentCoin.is_trade;
    // this.initOperition();
    let routeName = this.$route.name;
    let coin_name = this.$route.params.coin;
    if (coin_name) {
      this.coinName = coin_name;
    }
    this.isRecharge = routeName === "recharge";
    this.currActicve = this.isRecharge ? "1" : "2";
    this.coinData = await (0, _getAssetsCoin.getCoinInfo)();
    this.initialData();
    this.data = (0, _getAssetsCoin.getCurrentCoin)();
    for (let key in this.coinData) {
      if (this.coinData[key].item_name == this.$route.params.coin) {
        this.data = this.coinData[key];
      }
    }
    if (this.isRecharge && !this.data.itemDetail.in_status) {
      (0, _message.showMessage)({
        message: this.translateTitle(`当前币种暂不支持充币`),
        type: "warning",
        duration: 1200
      });
      this.$router.push(this.routeSplicing(this.language, "ucenter/assets"));
    }
    if (!this.isRecharge && !this.data.itemDetail.out_status) {
      (0, _message.showMessage)({
        message: this.translateTitle(`当前币种暂不支持提币`),
        type: "warning",
        duration: 1200
      });
      this.$router.push(this.routeSplicing(this.language, "ucenter/assets"));
    }
    this.assetsList = await (0, _getAssetsCoin.getAssetsData)();
    await this.getExchangeList(this.data.item_id);
    await this.getUserExchangeLimit(this.data.item_id);
    this.isPageLoading = false;
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    handleClick() {},
    // initOperition() {
    //   this.operationList = [];
    //   if (!this.is_trade) {
    //     this.operationList = [
    //       { value: 1, label: "充值" },
    //       { value: 2, label: "提现" },
    //     ];
    //   } else {
    //     this.operationList = [
    //       { value: 1, label: "充值" },
    //       { value: 2, label: "提现" },
    //       { value: 3, label: "交易" },
    //     ];
    //   }
    // },
    async onChangeTab(item) {
      let active = item.value;
      if (active == 1) {
        this.currActicve = item.value;
        this.isRecharge = true;
        this.getExchangeList(this.data.item_id);
        this.$router.push({
          path: this.routeSplicing(this.language, "ucenter/assets/recharge/" + this.coinName.toLowerCase())
        });
      } else if (active == 2) {
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        if (userInfo.authInfo.is_bind_security == 1 && userInfo.authInfo.open_google == 0) {
          this.safetyVerification = true;
        } else {
          this.currActicve = item.value;
          this.isRecharge = false;
          this.getExchangeList(this.data.item_id);
          this.$router.push({
            path: this.routeSplicing(this.language, "ucenter/assets/withdrawal/" + this.coinName.toLowerCase())
          });
        }
      } else {
        let tempUrl = this.routeSplicing(this.language, `exchange/${this.coinName.toLowerCase()}_${"usdt"}`);
        //  location.href(tempUrl)
        window.open(tempUrl);
      }
      this.assetsList = await (0, _getAssetsCoin.getAssetsData)();
    },
    onConfirmV2() {
      this.$router.push(this.routeSplicing(this.language, "ucenter/safe-setting"));
    },
    getChainData(val) {
      this.chainMap = val;
    },
    // 获取 24h 内的提币限制
    getUserExchangeLimit(item_id) {
      let params = {
        item_id
      };
      (0, _index.getUserExchangeLimit)(params).then(({
        status,
        data,
        msg
      }) => {
        if (status === 200) {
          this.exchangeLimit = data;
        } else {
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
        }
      });
    },
    //前往充，提币页面
    goFinance() {
      /* 充币记录 */
      this.$router.push({
        path: "/ucenter/financeManager",
        query: {
          indexItem: this.isRecharge ? 1 : 2,
          coinId: this.data.item_id
        }
      });
    },
    // 提币成功
    withdrawSuccess(list) {
      this.assetsList = list;
      this.getExchangeList(this.data.item_id);
      this.getUserExchangeLimit(this.data.item_id);
    },
    // 初始化数据
    initialData() {
      this.bindMobileModel = {};
      this.bindEmailModel = {
        email: ""
      };
      this.bindGoogleModel = {};
    },
    // 获取页面新数据
    getNewData(data) {
      this.exchangeLimit = {};
      if (!this.isRecharge) {
        this.$refs.withDrawalAddress.$refs.changePwdForm.resetFields();
      }
      this.data = data;
      this.multi_chain_status = data.itemDetail.multi_chain_status;
      this.getExchangeList(data.item_id);
      this.getUserExchangeLimit(this.data.item_id);
    },
    //页数变更
    onPageChange(val) {
      this.pageNum = val;
      this.getExchangeList(this.data.item_id);
    },
    // 获取 充值/提币 记录
    async getExchangeList(item_id) {
      let type = this.isRecharge ? 1 : 2;
      this.historyLoading = true;
      if (type == 1) {
        await (0, _index.getINCoinExchangeListV2)({
          item_id,
          type,
          // page_size: 8,
          // page: 1,
          page: !isNaN(this.pageNum) ? this.pageNum : 1,
          page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10
        }).then(({
          status,
          data,
          msg
        }) => {
          if (status === 200) {
            this.exchangeList = data.list;
            this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
          } else {
            (0, _message.showMessage)({
              message: msg ? msg : status
            });
          }
        });
      } else {
        await (0, _index.getExchangeListV2)({
          item_id,
          type,
          // page_size: 8,
          // page: 1,
          page: !isNaN(this.pageNum) ? this.pageNum : 1,
          page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10
        }).then(({
          status,
          data,
          msg
        }) => {
          if (status === 200) {
            this.exchangeList = data.list;
            this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
          } else {
            (0, _message.showMessage)({
              message: msg ? msg : status
            });
          }
        });
      }
      this.historyLoading = false;
    }
  },
  components: {
    TopTitle: _Title.default,
    CoinInfo: _CoinInfo.default,
    ChargeExplain: _ChargeExplain.default,
    History: _History.default,
    WithdrawalAddress: _WithdrawalAddress.default,
    WithdrawalExplain: _WithdrawalExplain.default,
    ChargeFragment: _ChargeFragment.default
    // WithdrawFaq,
    // ChargeMaintainFragment
  }
};
exports.default = _default;