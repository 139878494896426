"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _kline = require("@/model/exchange/kline.js");
var _settings = require("@/model/settings");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _vuex = require("vuex");
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const subMap = {};
window.PageData = {};
function resolveSymbol(pair) {
  if (pair) {
    pair = pair.toLowerCase();
  }
  return pair.replace(/\//, "_");
}
window.resolveKlineDataV3 = function (body) {
  const {
    data,
    params
  } = body;
  const {
    c,
    h,
    l,
    o,
    t,
    v
  } = data;
  const {
    p: symbol,
    r: resolution
  } = params;
  if (t && t.length) {
    if (t.length > 10) {
      return;
    }
    // 处理后台返回的1天重复数据
    // if (t && t.length == 1 && resolution == "D") {
    //   t[0] = t[0] - 86400;
    // }
    const subMapItem = subMap[`${symbol}_${resolution}`];
    if (!subMapItem) {
      return;
    }
    const {
      lastBar,
      listener
    } = subMapItem;
    for (let i = 0; i < c.length; i++) {
      let moreTime = historyProvider.timeToSec[resolution];
      if (resolution == "M") {
        moreTime = moreTime * 28;
      }
      const item = {};
      item.close = Number(c[i]);
      item.high = Number(h[i]);
      item.low = Number(l[i]);
      item.open = Number(o[i]);
      item.volume = Number(v[i]);
      item.time = Number(t[i] * 1000) + moreTime;
      const langBarTime = lastBar.time;
      if (langBarTime && item.time >= langBarTime) {
        subMap[`${symbol}_${resolution}`].lastBar = Object.assign({}, item);
        listener(item);
      }
    }
  }
};
const historyProvider = {
  getDaysInMonth(milliseconds) {
    const date = new Date(milliseconds);
    const year = date.getFullYear();
    const month = date.getMonth();
    const temp = new Date(year, month, 0);
    return temp.getDate();
  },
  history: {},
  resolveResolutions: {
    1: "1min",
    5: "5min",
    30: "30min",
    60: "60min",
    240: "4hour",
    D: "1day",
    W: "1week",
    M: "1mon"
  },
  timeToSec: {
    1: 0,
    5: 0,
    15: 0,
    30: 0,
    60: 0,
    240: 0,
    D: 0,
    W: 0,
    M: 24 * 60 * 60 * 1000
  },
  resolveResolutionFun(chartResolution) {
    return this.resolveResolutions[chartResolution];
  },
  // getBars执行的次数
  loadTime: 0,
  getBars(symbolInfo, chartResolution, from, to, onHistoryCallback, firstDataRequest) {
    this.loadTime++;
    const symbol = resolveSymbol(symbolInfo.name);
    const req = {
      symbol,
      resolution: chartResolution,
      from,
      to
    };
    if (req.symbol == "aa") {
      req.symbol = 1010;
    }
    const me = this;
    function resolveHistoryData(rs) {
      const newBars = [];
      if (rs && rs.length) {
        const len = rs.length;
        for (let i = 0; i < len; i++) {
          let moreTime = me.timeToSec[chartResolution];
          if (chartResolution == "M") {
            moreTime = moreTime * 28;
          }
          const {
            c,
            h,
            l,
            o,
            t,
            v
          } = rs[i];
          const entry = {};
          entry.close = c;
          entry.high = h;
          entry.low = l;
          entry.open = o;
          entry.volume = v;
          entry.time = t * 1000 + moreTime;
          newBars.push(entry);
        }
        if (firstDataRequest) {
          const lastBar = Object.assign({}, newBars[newBars.length - 1]);
          me.history[symbolInfo.name] = lastBar;
        }
        onHistoryCallback(newBars, {
          noData: false
        });
      } else {
        onHistoryCallback([], {
          noData: true
        });
      }
    }
    (0, _kline.apiHistoryData)(req).then(rs => {
      resolveHistoryData(rs.data.kline);
    });
    // if (this.loadTime == 1) {
    //   resolveHistoryData(window.PageData.firstHistoryData);
    // } else {
    //   apiHistoryData(req).then(rs => {
    //     resolveHistoryData(rs.data.kline);
    //   });
    // }
  }
};

const stream = {
  loadTime: 0,
  subScribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID) {
    this.loadTime++;
    if (this.loadTime != 1) {
      const req = {
        action: "Topic.sub",
        data: {
          type: "kline",
          symbol: symbolInfo.name,
          resolution: resolution
        }
      };
      window.ws.sub(req);
    }
    let lastBar = {};
    if (historyProvider.history[symbolInfo.name]) {
      lastBar = historyProvider.history[symbolInfo.name];
    }
    subMap[subscribeUID] = {
      lastBar,
      listener: onRealtimeCallback,
      resolution: resolution,
      name: symbolInfo.name
    };
  },
  unsubScribeBars(subscribeUID) {
    const req = {
      action: "Topic.unsub",
      data: {
        type: "kline",
        symbol: subMap[subscribeUID].name,
        resolution: subMap[subscribeUID].resolution
      }
    };
    window.ws.sub(req);
    delete subMap[subscribeUID];
  }
};
var _default = {
  mixins: [_commonMixin.default],
  components: {
    "v-chart": _vueEcharts.default
  },
  props: {
    coinFrom: {
      type: String,
      defalut: ""
    },
    coinTo: {
      type: String,
      defalut: ""
    },
    symbol: {
      type: String,
      default: ""
    },
    depthList: {
      type: Object,
      default: function () {
        return {
          a: [],
          b: []
        };
      }
    }
  },
  data() {
    return {
      loading: true,
      arrDeep1: [],
      arrDeep2: [],
      depthListTemp: [],
      deepOptions: {
        backgroundColor: "#fff",
        title: {
          text: ""
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            let cumulative = "0";
            if (params[0].value[1] >= 1000000000) {
              cumulative = (params[0].value[1] / 1000000000).toFixed(2) + "B";
            } else if (params[0].value[1] >= 1000000) {
              cumulative = (params[0].value[1] / 1000000).toFixed(2) + "M";
            } else if (params[0].value[1] >= 1000) {
              cumulative = (params[0].value[1] / 1000).toFixed(2) + "K";
            } else {
              cumulative = parseFloat(params[0].value[1]).toFixed(2) + "";
            }
            let text = `${params[0].value[2]}<br />${params[0].value[0]}<br />${params[0].value[3]}<br />${cumulative}`;
            return text;
          },
          position: function (point, params, dom, rect, size) {
            return [point[0], point[1] - 110];
          },
          axisPointer: {
            type: "line",
            axis: "x"
            // lineStyle: {
            //   color: "#000",
            //   type: "dashed",
            // },
            // crossStyle: {
            //   color: "#333",
            //   // type: "dashed",
            // },
            // label: {
            //   show: true, //坐标指示器
            //   fontSize: 11,
            //   backgroundColor: "#EEEEEE",
            // },
          }
        },

        grid: {
          containLabel: true,
          left: "0%",
          right: "1%",
          bottom: "10%"
        },
        textStyle: {
          color: "#BBBBBB"
        },
        animation: false,
        xAxis: {
          type: "category",
          boundaryGap: false,
          min: "dataMin",
          axisLine: {
            lineStyle: {
              color: "transparent"
            }
          },
          axisLabel: {
            margin: 8,
            align: "center",
            showMaxLabel: false,
            showMinLabel: false //不显示X轴最小刻度
          },

          axisTick: {
            show: true,
            length: 4,
            lineStyle: {
              color: "#CCCCCC",
              type: "solid"
              // width: 1,
            }
          },

          show: true
        },
        yAxis: {
          splitNumber: 2,
          zlevel: -1,
          nameTextStyle: {
            fontSize: 10,
            color: "#CCCCCC"
          },
          offset: 0,
          //min: 'dataMin',
          type: "value",
          splitLine: {
            show: false
          },
          axisTick: {
            show: true,
            inside: false,
            lineStyle: {
              color: "#BBBBBB",
              type: "solid",
              width: 1
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            fontSize: 10,
            color: "#BBBBBB",
            inside: false,
            width: 25,
            formatter: function (value) {
              if (value >= 1000000000) {
                return (value / 1000000000).toFixed(2) + "B";
              } else if (value >= 1000000) {
                return (value / 1000000).toFixed(2) + "M";
              } else if (value >= 1000) {
                return (value / 1000).toFixed(2) + "K";
              } else {
                return value;
              }
            }
          },
          position: "right"
        },
        series: [{
          name: "buy",
          showSymbol: false,
          symbol: "circle",
          zlevel: -21,
          label: {
            show: false,
            distance: 22,
            color: "#000000",
            fontSize: 12,
            align: "center",
            verticalAlign: "middle",
            backgroundColor: "#EEEEEE",
            padding: [10, 15, 10, 15]
          },
          symbolSize: 8,
          connectNulls: true,
          emphasis: {},
          lineStyle: {},
          type: "line",
          itemStyle: {
            normal: {
              color: "rgba(2,204,135,0.8)"
            }
          },
          areaStyle: {
            normal: {
              color: "rgba(2,204,135,0.1)"
            }
          },
          data: []
        }, {
          name: "sell",
          showSymbol: false,
          symbol: "circle",
          label: {
            show: false,
            distance: 22,
            color: "#fff",
            fontSize: 12,
            align: "center",
            verticalAlign: "middle",
            backgroundColor: "#222E5D",
            padding: [10, 15, 10, 15]
          },
          symbolSize: 8,
          connectNulls: true,
          //step:true,
          type: "line",
          stack: "总量",
          itemStyle: {
            normal: {
              color: "rgba(198,27,61,0.8)"
            }
          },
          areaStyle: {
            normal: {
              color: "rgba(198,27,61,0.1)"
            }
          },
          data: []
        }]
      },
      asks: [],
      bids: [],
      option: null,
      chartDom: null,
      myChart: null,
      studies: [],
      widget: null,
      pricescale: 100,
      currResolution: "15",
      currChartType: 1,
      symbol_id: "",
      coinConfig: {},
      pageLoading: true,
      symbolMap: {},
      currViewType: 1,
      viewType: [{
        value: "1",
        label: "K线图"
      }, {
        value: "2",
        label: "深度图"
      }],
      resolutionList: [{
        key: 1,
        type: 3,
        value: "1",
        label: "分时"
      }, {
        key: 2,
        type: 1,
        value: "1",
        label: "1分钟"
      }, {
        key: 3,
        type: 1,
        value: "5",
        label: "5分钟"
      }, {
        key: 4,
        type: 1,
        value: "15",
        label: "15分钟"
      }, {
        key: 5,
        type: 1,
        value: "30",
        label: "30分钟"
      }, {
        key: 6,
        type: 1,
        value: "60",
        label: "1小时"
      }, {
        key: 7,
        type: 1,
        value: "240",
        label: "4小时"
      }, {
        key: 8,
        type: 1,
        value: "D",
        label: "1天"
      }, {
        key: 9,
        type: 1,
        value: "W",
        label: "1周"
      }, {
        key: 10,
        type: 1,
        value: "M",
        label: "1月"
      }]
    };
  },
  async created() {
    await this.getTradePairList();
    this.getSymbolInfo();
    this.pageLoading = true;
    this.initChart();
  },
  mounted() {
    // this.drawLine();
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    })
  },
  watch: {
    depthList() {
      if (this.currViewType == 2) {
        this.depthListTemp = JSON.parse(JSON.stringify(this.depthList));
        this.depthMe(this.depthListTemp.b, this.depthListTemp.a);
        this.deepOptions.series[0].data = this.arrDeep1;
        this.deepOptions.series[1].data = this.arrDeep2;
        this.$refs.deepechart.setOption(this.deepOptions, false, true);
      }
    }
  },
  methods: {
    //数组排序，从大到小
    sortarr(list) {
      function sortNumbersub(a, b) {
        return Number(b[0]) - Number(a[0]);
      }
      list.sort(sortNumbersub);
      return list;
    },
    //数组去重复
    unique(arr) {
      let newArr = [arr[0]];
      for (let i = 1; i < arr.length; i++) {
        let repeat = false;
        for (let j = 0; j < newArr.length; j++) {
          if (arr[i] === newArr[j]) {
            repeat = true;
            break;
          } else {}
        }
        if (!repeat) {
          newArr.push(arr[i]);
        }
      }
      return newArr;
    },
    //引入买卖数据，然后去除暂时无用的第三位，并且通过reverse()方法倒序内容，让数量显示在第一位
    depthMe(arr1, arr2) {
      let bids = [];
      let asks = [];
      for (let i = 0; i < arr1.length; i++) {
        bids.push(arr1[i].splice(0, 2).map(Number).reverse()); //取数组内容前两位，再倒序，变为数量排在前面用来作为排序字段。
      }

      for (let i = 0; i < arr2.length; i++) {
        asks.push(arr2[i].splice(0, 2).map(Number).reverse());
      }
      this.k_chart(bids, asks);
    },
    //在渲染成统计图渲染之前，最后再处理一次
    k_chart(bidsTemp, asksTemp) {
      for (let i = 0; i < bidsTemp.length; i++) {
        let tempB = 0;
        // let tempString = " ";
        for (let j = 0; j < bidsTemp.length; j++) {
          if (bidsTemp[i][1] >= bidsTemp[j][1]) {
            tempB += bidsTemp[j][0];
          }
        }
        // console.log(tempString);
        bidsTemp[i][0] = tempB.toFixed(4);
      }
      asksTemp = asksTemp.reverse();
      for (let i = 0; i < asksTemp.length; i++) {
        let tempB = 0;
        for (let j = 0; j < asksTemp.length; j++) {
          if (asksTemp[i][1] >= asksTemp[j][1]) {
            tempB += asksTemp[j][0];
          }
        }
        asksTemp[i][0] = tempB.toFixed(4);
      }
      asksTemp = asksTemp.reverse();
      let bids = this.sortarr(bidsTemp); //以数量从小到大排序
      let asks = this.sortarr(asksTemp).reverse(); //排序之后再倒过来，变成从大到小
      this.arrDeep1 = [];
      this.arrDeep2 = [];
      for (let i = 0; i < bids.length; i++) {
        this.arrDeep1.push(bids[i].splice(0, 2).reverse()); //将价格重新变为内容数组第一位
      }

      for (let i = 0; i < asks.length; i++) {
        this.arrDeep2.push(asks[i].splice(0, 2).reverse());
      }
      for (let i = 0; i < bids.length; i++) {
        this.arrDeep1[i][2] = this.translateTitle(`委托价`);
        this.arrDeep1[i][3] = this.translateTitle(`累计深度`);
      }
      for (let i = 0; i < asks.length; i++) {
        this.arrDeep2[i][2] = this.translateTitle(`委托价`);
        this.arrDeep2[i][3] = this.translateTitle(`累计深度`);
      }
      // this.arrDeep1 = this.unique(this.arrDeep1);
      // this.arrDeep2 = this.unique(this.arrDeep2);
      //最终得出的arr1和arr2就可以去用来渲染深度图了
    },

    async getTradePairList() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(49);
      if (status == 200) {
        let tempObj = data;
        for (const key in tempObj) {
          this.$set(this.symbolMap, tempObj[key].symbol.toLowerCase(), tempObj[key].symbol_id);
        }
        this.symbol_id = this.symbolMap[this.symbol];
      }
    },
    showStudy(widget) {
      // 添加ma指标
      widget.chart().createStudy("Moving Average", false, false, [5], null, {
        "Plot.color": "#642d92"
      });
      widget.chart().createStudy("Moving Average", false, false, [10], null, {
        "Plot.color": "#5278a3"
      });
      widget.chart().createStudy("Moving Average", false, false, [30], null, {
        "Plot.color": "#238031"
      });
      widget.chart().createStudy("Moving Average", false, false, [60], null, {
        "Plot.color": "#850058"
      });
    },
    async getSymbolInfo() {
      let params = {
        symbol: this.symbol_id
      };
      // 获取26静态表 获取交易对精度
      const {
        status,
        data
      } = await (0, _kline.apiSymbolInfo)(params);
      if (status == 200) {
        this.coinConfig = data;
      }
    },
    async onChangeSymbol(item) {
      this.symbol_id = item.symbol_id;
      await this.getSymbolInfo();
      const symbol = item.symbol_id;
      if (this.widget && this.widget.chart) {
        const interval = this.widget.chart().resolution();
        this.widget.setSymbol(String(symbol), interval, () => {
          // 此种情况服务端无返回数据
          this.widget.chart().getVisibleRange();
        });
      }
    },
    onViewChange(value) {
      this.currViewType = value;
    },
    onResolutionChange(item) {
      this.pageLoading = true;
      const {
        type,
        value
      } = item;
      if (this.currResolution == 1 && value == 1) {
        this.widget.chart().setChartType(type);
      } else {
        this.widget.chart().setResolution(value, () => {
          this.widget.chart().setChartType(type);
        });
      }
      let state = type == 3 ? 0 : 1;
      for (let i = 0; i < this.studies.length; i++) {
        this.widget.chart().getStudyById(this.studies[i]).setVisible(state);
      }
      this.currResolution = value;
      this.currChartType = type;
      this.pageLoading = false;
    },
    //自动判断选择时区
    selectTimeZone() {
      //可能需要进行判断
      return _momentTimezone.default.tz.guess();
    },
    // 初始化
    initChart() {
      this.pageLoading = true;
      const langMap = {
        // zh_CN: "zh",
        // zh_HK: "zh_TW",
        // en: "en",
        // kr: "ko",
        // jp: "ja",
        // ru: "ru",
        zh_CN: "zh",
        zh_HK: "zh_TW",
        en: "en",
        ko: "ko",
        ja: "ja",
        ru: "ru"
      };
      const locale = langMap[this.language] || "en";
      const backgroundColor = "#fff";
      const lineColor = "#fff";
      const gapLineColor = "#f2f3f4";
      const textColor = "#6e86a8";
      const interval = this.currResolution;
      const widget = new window.TradingView.widget({
        debug: false,
        symbol: this.symbol_id,
        interval,
        theme: "light",
        toolbar_bg: "transparent",
        container_id: "tv_chart_container",
        datafeed: {
          onReady: cb => {
            const config = {
              supported_resolutions: ["1", "5", "15", "30", "60", "240", "D", "W", "M"]
            };
            setTimeout(() => cb(config), 0);
          },
          resolveSymbol: (symbolName, onSymbolResolveCallback) => {
            const symbol_stub = {
              // 交易对名称
              name: symbolName,
              ticker: symbolName,
              discription: "",
              exchange: "Topcreditbank",
              has_intraday: true,
              minmov: this.coinConfig.minmov,
              minmove2: this.coinConfig.minmove2,
              pricescale: this.coinConfig.pricescale,
              session: "24x7",
              timezone: this.coinConfig.timezone,
              has_weekly_and_monthly: true,
              // 无数据显示空的k柱
              has_empty_bars: true
            };
            setTimeout(() => {
              onSymbolResolveCallback(symbol_stub);
            }, 0);
          },
          getBars(symbolInfo, resulution, from, to, onHistoryCallback, firstDataRequest) {
            historyProvider.getBars(symbolInfo, resulution, from, to, onHistoryCallback, firstDataRequest);
          },
          subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) {
            stream.subScribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback);
          },
          unsubscribeBars(subscribeUID) {
            stream.unsubScribeBars(subscribeUID);
          }
        },
        // library_path: `https://help.topcreditbank.com/3rd/tradingview/charting_library/`,
        // library_path: `${process.env.VUE_APP_UCENTER}/3rd/tradingview/charting_library/`,
        library_path: `/3rd/tradingview/charting_library/`,
        locale,
        autosize: true,
        timezone: this.selectTimeZone(),
        disabled_features: ["use_localstorage_for_settings", "header_screenshot", "header_symbol_search", "header_undo_redo", "header_compare", "timeframes_toolbar", "header_chart_type",
        // 头部分辨率
        "header_resolutions", "header_widget",
        // kline与成交量是否重合
        "volume_force_overlay",
        // "left_toolbar",
        // 移动端禁用属性
        "property_pages", "show_chart_property_page"
        // 禁用右键菜单
        // "context_menus",
        ],

        overrides: {
          volumePaneSize: "small",
          "paneProperties.topMargin": 15,
          "paneProperties.bottomMargin": 10,
          // 背景颜色
          "paneProperties.background": backgroundColor,
          // // 水平分割线
          "paneProperties.vertGridProperties.color": gapLineColor,
          "paneProperties.vertGridProperties.style": 0,
          // // 竖直分割线
          "paneProperties.horzGridProperties.color": gapLineColor,
          "paneProperties.horzGridProperties.style": 0,
          // // 蜡烛线涨颜色
          "mainSeriesProperties.candleStyle2.wickUpColor": "#00b464",
          "mainSeriesProperties.candleStyle2.upColor": "#00b464",
          "mainSeriesProperties.candleStyle2.borderUpColor": "#00b464",
          // // 蜡烛线跌颜色
          "mainSeriesProperties.candleStyle.wickDownColor": "#fa4d56",
          "mainSeriesProperties.candleStyle.downColor": "#fa4d56",
          "mainSeriesProperties.candleStyle.borderDownColor": "#fa4d56",
          //面积图测试
          "mainSeriesProperties.areaStyle.color1": "rgba(40, 186, 152, 0.25)",
          "mainSeriesProperties.areaStyle.color2": "rgba(255, 255, 255, 0.23)",
          "mainSeriesProperties.areaStyle.linecolor": "#24B391",
          // 横纵坐标的字体大小
          "scalesProperties.fontSize": 12,
          // 横纵坐标线条的颜色
          "scalesProperties.lineColor": lineColor,
          // kline上面字体显示的颜色
          "scalesProperties.textColor": textColor,
          // 默认隐藏左上角标题，可手动打开
          "paneProperties.legendProperties.showLegend": false,
          // 展示开盘价，最高价，最低价，收盘价
          "paneProperties.legendProperties.showSeriesOHLC": true

          // "paneProperties.legendProperties.showStudyArguments": true,
          // "paneProperties.legendProperties.showStudyTitles": true,
          // "paneProperties.legendProperties.showStudyValues": true,
          // "paneProperties.legendProperties.showSeriesTitle": true,
          // "paneProperties.legendProperties.showSeriesOHLC": true,
          // "paneProperties.legendProperties.showLegend": true,
          // "paneProperties.legendProperties.showBarChange": true,
          // "paneProperties.legendProperties.showOnlyPriceSource": true,
        },

        studies_overrides: {
          "volume.volume.color.0": "#DA3D68",
          "volume.volume.color.1": "#24B391",
          "volume.show ma": true,
          "volume.volume ma.plottype": "line",
          "volume.ma length": 5,
          "volume.volume ma.color": "#FF0000",
          //波浪图颜色
          "volume.volume ma.transparency": 100,
          //波浪图透明度
          "volume.volume ma.linewidth": 5,
          "volume.show ma": true //是否显示
        },

        customFormatters: {
          timeFormatter: {
            format(date) {
              const _format_str = "%h:%m";
              return _format_str.replace("%h", date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours(), 2).replace("%m", date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes(), 2);
            }
          },
          dateFormatter: {
            format(date) {
              return `${date.getUTCFullYear()}-${date.getUTCMonth() + 1 < 10 ? `0${date.getUTCMonth() + 1}` : date.getUTCMonth() + 1}-${date.getUTCDate() < 10 ? `0${date.getUTCDate()}` : date.getUTCDate()}`;
            }
          }
        },
        custom_css_url: "trading_view3.css"
      });
      widget.onChartReady(() => {
        //JQG 获取平均值ID
        // 添加ma指标
        let id = widget.chart().createStudy("Moving Average", false, false, [5], null, {
          "Plot.color": "#642d92"
        });
        this.studies.push(id);
        id = widget.chart().createStudy("Moving Average", false, false, [10], null, {
          "Plot.color": "#5278a3"
        });
        this.studies.push(id);
        id = widget.chart().createStudy("Moving Average", false, false, [30], null, {
          "Plot.color": "#238031"
        });
        this.studies.push(id);
        id = widget.chart().createStudy("Moving Average", false, false, [60], null, {
          "Plot.color": "#850058"
        });
        this.studies.push(id);
      });
      this.widget = widget;
    }
  }
};
exports.default = _default;