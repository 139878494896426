"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vue = _interopRequireDefault(require("vue"));
require("element-ui/lib/theme-chalk/index.css");
var _elementUi = require("element-ui");
// // 导入vue语言包,全部导入，需要优化
// import locale from "element-ui/lib/locale";
// import zh_CN from "element-ui/lib/locale/lang/zh-CN";
// import en from "element-ui/lib/locale/lang/en";
// import zh_TW from "element-ui/lib/locale/lang/zh-TW";
// import ko from "element-ui/lib/locale/lang/ko";
// import ja from "element-ui/lib/locale/lang/ja";
// import ru from "element-ui/lib/locale/lang/ru-RU";

// const lang = window.APP_CONFIG.LANG;

// // 默认中文
// if (lang == "en") {
//   locale.use(en);
// } else if (lang == "zh") {
//   locale.use(zh_CN);
// } else if (lang == "zh-HK") {
//   locale.use(zh_TW);
// } else if (lang == "kr") {
//   locale.use(ko);
// } else if (lang == "jp") {
//   locale.use(ja);
// } else if (lang == "ru") {
//   locale.use(ru);
// } else {
//   locale.use(en);
// }
_vue.default.component(_elementUi.CheckboxGroup.name, _elementUi.CheckboxGroup);
_vue.default.component(_elementUi.Checkbox.name, _elementUi.Checkbox);
_vue.default.component(_elementUi.Input.name, _elementUi.Input);
_vue.default.component(_elementUi.InputNumber.name, _elementUi.InputNumber);
_vue.default.component(_elementUi.Switch.name, _elementUi.Switch);
_vue.default.component(_elementUi.Select.name, _elementUi.Select);
_vue.default.component(_elementUi.Option.name, _elementUi.Option);
_vue.default.component(_elementUi.TimeSelect.name, _elementUi.TimeSelect);
_vue.default.component(_elementUi.Form.name, _elementUi.Form);
_vue.default.component(_elementUi.FormItem.name, _elementUi.FormItem);
_vue.default.component(_elementUi.Pagination.name, _elementUi.Pagination);
_vue.default.component(_elementUi.Radio.name, _elementUi.Radio);
_vue.default.component(_elementUi.RadioGroup.name, _elementUi.RadioGroup);
_vue.default.component(_elementUi.DatePicker.name, _elementUi.DatePicker);
_vue.default.component(_elementUi.Progress.name, _elementUi.Progress);
_vue.default.component(_elementUi.Popover.name, _elementUi.Popover);
_vue.default.component(_elementUi.Tabs.name, _elementUi.Tabs);
_vue.default.component(_elementUi.TabPane.name, _elementUi.TabPane);