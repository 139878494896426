"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _settings = require("@/model/settings");
var _vuex = require("vuex");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _broker = require("@/model/broker");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  // components: { Recharge },
  data() {
    return {
      marketing: 0,
      imageUrl: "",
      programForm: {
        name: "",
        email: "",
        contact: "",
        uid: "",
        tid: "",
        marketing: "",
        area: ""
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language"
    })
  },
  watch: {},
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传头像图片只能是 JPG ｜ PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isType && isLt2M;
    },
    routeSplicing: _routeSplicing.default,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex"
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login")
        });
      }
    },
    chooseMarketing(index) {
      this.marketing = index;
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    async submit() {
      const req = {
        name: this.programForm.name,
        email: this.programForm.email,
        mobile: this.programForm.area + "" + this.programForm.contact,
        telegram: this.programForm.tid,
        is_exceeded_1_million: this.marketing,
        pre_exchange_platform: this.programForm.marketing,
        screenshot: this.imageUrl
      };
      this.btnLoading = true;
      const {
        data,
        status
      } = await (0, _broker.apiBrokersProgram)(req);
      if (status == 200) {
        this.$message(this.translateTitle("添加成功"), "success");
      }
      this.btnLoading = false;
    }
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     this.coinData = data;
    //   }
    // },
    // //7-static_currency-法币表
    // async getStaticCurrencyMap() {
    //   const { status, data } = await apiGetStaticTableInfo(7);
    //   if (status == 200) {
    //     this.currencyList = Object.values(JSON.parse(JSON.stringify(data)));
    //   }
    // },
    // async getStaticOtcThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(64);
    //   const { status, data } = await apiGetStaticTableInfo(69);
    //   if (status == 200) {
    //     this.otcThirdPaymentMap = Object.values(
    //       JSON.parse(JSON.stringify(data))
    //     );
    //   }
    // },
    // async getStaticThirdPaymentMap() {
    //   // const { status, data } = await apiGetStaticTableInfo(65);
    //   const { status, data } = await apiGetStaticTableInfo(68);
    //   if (status == 200) {
    //     this.thirdPaymentMap = Object.values(JSON.parse(JSON.stringify(data)));
    //     this.thirdPaymentMap.forEach(item => {
    //       item["img"] = this.$data[item.name.toLowerCase()];
    //     });
    //   }
    // },
    // changeActive(num) {
    //   this.active = num;
    // },
    // selectChange(val) {
    //   this.currencyCurr = val;
    // },
    // selectChangeCoin(val) {
    //   this.coinCurr = val;

    //   this.getAddress();
    //   let tempPaymentArray = [];
    //   this.coinCurr.payment.split(",").forEach(ele => {
    //     this.thirdPaymentMap.forEach(item => {
    //       if (ele == item.name) {
    //         tempPaymentArray.push(item);
    //       }
    //     });
    //   });
    //   tempPaymentArray.push({ name: "Wyre", img: this.wyre });
    //   this.thirdPaymentArray = tempPaymentArray;
    //   // this.radioCurr = this.thirdPaymentArray[0].name;
    //   this.rowCount = Math.ceil(tempPaymentArray.length / 2);
    //   this.thirdPaymentShowArray = tempPaymentArray;
    //   let index = null;
    //   for (let i = 0; i < this.thirdPaymentShowArray.length; i++) {
    //     if (this.thirdPaymentShowArray[i].name.toLowerCase() == "itez") {
    //       index = i;
    //       break;
    //     }
    //   }
    //   if (index !== null) {
    //     this.thirdPaymentShowArray.unshift(
    //       ...this.thirdPaymentShowArray.splice(index, 1)
    //     );
    //   }
    //   this.radioCurr = this.thirdPaymentShowArray[0].name;
    // },
    // async getAddress() {
    //   let params = {
    //     item_id: this.coinCurr.item_id,
    //     chain_tag_list: this.coinCurr.chain_name.toUpperCase(),
    //   };
    //   if (!this.userInfo) return;
    //   const { status, msg, data } = await apiGetFinanceAddressList(params);
    //   if (status == 200) {
    //     this.addressCurr = data[0]["address"];
    //   }
    // },
    // async doBuy() {
    //   if (!this.spendValue) {
    //     this.$message({
    //       message: this.translateTitle("请输入金额"),
    //       type: "warning",
    //       customClass: "zZindex",
    //     });
    //     return;
    //   }
    //   let url = await this.generateUrl(
    //     this.radioCurr,
    //     this.coinCurr.item_name.toUpperCase(),
    //     this.spendValue,
    //     this.currencyCurr.logic_name.toUpperCase(),
    //     this.addressCurr
    //   );
    //   window.open(url);
    // },
    // async generateUrl(paymentCode, symbol, amount, currency, address) {
    //   let resultUrl = "";
    //   if (paymentCode.toLowerCase() != "wyre") {
    //     const { status, data: dataUrl } = await apiFetchOtcUrl({
    //       payment_code: paymentCode.toLowerCase(),
    //       symbol: symbol,
    //       amount: amount,
    //       currency: currency,
    //       address: address,
    //     });
    //     if (status == 200) {
    //       resultUrl = dataUrl;
    //     }
    //   } else {
    //     const destCurrency =
    //       symbol.toUpperCase() == "BTC"
    //         ? "bitcoin:" + address
    //         : "ethereum:" + address;
    //     const { status, data } = await apiWyreUrl({
    //       sourceAmount: amount,
    //       sourceCurrency: currency,
    //       destCurrency: symbol,
    //       dest: destCurrency,
    //       country: "US",
    //     });
    //     if (status == 200) {
    //       resultUrl = data.url;
    //     }
    //   }
    //   return resultUrl;
    // },
  }
};
exports.default = _default;