"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _settings = require("@/model/settings");
var _vuex = require("vuex");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import { getStaticCoinList as getCoinList } from "@/model/assets/index";
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      marketing: 0,
      imageUrl: "",
      programForm: {
        name: "",
        email: "",
        contact: "",
        uid: "",
        tid: "",
        marketing: "",
        area: ""
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language"
    })
  },
  watch: {},
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isType = file.type === "image/jpg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error(this.translateTitle("上传头像图片只能是 JPG ｜ PNG 格式!"));
      }
      if (!isLt2M) {
        this.$message.error(this.translateTitle("上传头像图片大小不能超过 2MB!"));
      }
      return isType && isLt2M;
    },
    routeSplicing: _routeSplicing.default,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex"
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login")
        });
      }
    },
    chooseMarketing(index) {
      this.marketing = index;
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    async submit() {
      const req = {
        name: this.programForm.name,
        email: this.programForm.email,
        mobile: this.programForm.area + "" + this.programForm.contact,
        telegram: this.programForm.tid,
        is_exceeded_1_million: this.marketing,
        pre_exchange_platform: this.programForm.marketing,
        screenshot: this.imageUrl
      };
      this.btnLoading = true;
      const {
        data,
        status
      } = await apiBrokersProgram(req);
      if (status == 200) {
        this.$message(this.translateTitle("添加成功"), "success");
      }
      this.btnLoading = false;
    }
  }
};
exports.default = _default;