<template>
  <div class="maskex-card">
    <!-- <HeaderMaskExCard /> -->
    <FirstSectionMaskExCard />
    <SecondSectionMaskExCard />
    <ThirdSectionMaskExCard />
    <FourthSectionMaskExCard />
    <FifthSectionMaskExCard />
    <!-- <FooterMaskExCard /> -->
  </div>
</template>

<script>
import HeaderMaskExCard from "./components/HeaderMaskExCard";
import FirstSectionMaskExCard from "./components/FirstSectionMaskExCard";
import SecondSectionMaskExCard from "./components/SecondSectionMaskExCard";
import ThirdSectionMaskExCard from "./components/ThirdSectionMaskExCard";
import FourthSectionMaskExCard from "./components/FourthSectionMaskExCard";
import FifthSectionMaskExCard from "./components/FifthSectionMaskExCard";
import FooterMaskExCard from "./components/FooterMaskExCard";

export default {
  components: {
    // HeaderMaskExCard,
    FirstSectionMaskExCard,
    SecondSectionMaskExCard,
    ThirdSectionMaskExCard,
    FourthSectionMaskExCard,
    FifthSectionMaskExCard,
    // FooterMaskExCard,
  },
  data() {
    return {
      isMaskex: true,
    };
  },
};
</script>

<style>
/* Add your custom styles for the MaskExCard component */
.maskex-card {
  /* Add styles here */
}
</style>
