"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _accuracy = require("@/assets/js/accuracy");
var _settings = require("@/model/settings");
var _SocketDispatcher = _interopRequireDefault(require("@/assets/js/SocketDispatcher"));
var _i18n = require("@/assets/js/i18n");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ["data", "assetsList", "exchangeLimit", "chainMap"],
  data() {
    return {
      currChainValue: "ERC20",
      rate_out: "--" // 提币手续费
    };
  },

  created() {
    this.currChainValue = this.data.itemDetail.default_chain.toUpperCase();
    _SocketDispatcher.default.addEventListener("onChainChangeDispatch", this.getStaticChainInfo, this);
    this.getStaticChainInfo(this.currChainValue);
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    getStaticChainInfo(val) {
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      this.currChainValue = val.toUpperCase();
      (0, _settings.apiGetStaticTableInfo)(29).then(({
        data
      }) => {
        for (const key in data) {
          if (this.currChainValue.toLowerCase() == data[key].chain_tag && this.data.item_id == data[key].item_id) {
            this.rate_out = data[key].rate_out || "--";
          }
        }
      });
    }
  },
  computed: {
    hasParam() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        return true;
      } else {
        return false;
      }
    },
    auditNum() {
      if (this.hasParam) {
        let {
          auditNum
        } = this.exchangeLimit;
        return auditNum;
      } else {
        return "--";
      }
    },
    limitNum() {
      if (this.hasParam) {
        let {
          limitNum
        } = this.exchangeLimit;
        let {
          coinOutNum
        } = this.exchangeLimit;
        let remaining = limitNum - coinOutNum;
        return remaining;
      } else {
        return "--";
      }
    },
    coinOutNum() {
      if (this.hasParam) {
        let {
          coinOutNum
        } = this.exchangeLimit;
        return coinOutNum;
      } else {
        return "--";
      }
    },
    usdtAuditNum() {
      if (this.hasParam) {
        let {
          usdtAuditNum
        } = this.exchangeLimit;
        return usdtAuditNum;
      } else {
        return "--";
      }
    },
    usdtLimitNum() {
      if (this.hasParam) {
        let {
          usdtLimitNum
        } = this.exchangeLimit;
        return usdtLimitNum;
      } else {
        return "--";
      }
    },
    usdtCoinOutNum() {
      if (this.hasParam) {
        let {
          usdtCoinOutNum
        } = this.exchangeLimit;
        return usdtCoinOutNum;
      } else {
        return "--";
      }
    }
  }
};
exports.default = _default;