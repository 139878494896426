"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bindEmail = bindEmail;
exports.bindGoogleVerify = bindGoogleVerify;
exports.bindMobile = bindMobile;
exports.bingGoogleVerifyStatus = bingGoogleVerifyStatus;
exports.closeGoogleVerify = closeGoogleVerify;
exports.getGoogleSecret = getGoogleSecret;
exports.loginSendEmailCode = loginSendEmailCode;
exports.loginSendSmsCode = loginSendSmsCode;
exports.openGoogleVerify = openGoogleVerify;
exports.sendEmailCode = sendEmailCode;
exports.sendSignEmailCode = sendSignEmailCode;
exports.sendSignSmsCode = sendSignSmsCode;
exports.sendSmsCode = sendSmsCode;
exports.undataMobile = undataMobile;
exports.updateGoogleVerify = updateGoogleVerify;
exports.updatePassword = updatePassword;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER } = process.env;

// //获取手机验证码 (非登录态)
// export function sendSignSmsCode(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Account/sendSignSmsCode`,
//     data,
//     type: "POST",
//   });
// }
//获取手机验证码 (非登录态)
function sendSignSmsCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/Forget/sms`,
    data,
    type: "POST"
  });
}

// 获取手机验证码（二次校验）

function loginSendSmsCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/LoginVerify/sendSmsCode`,
    data,
    type: "POST"
  });
}

// 获取手机验证码（完全登录态）

function sendSmsCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/sendSmsCode`,
    data,
    type: "POST"
  });
}
// // 获取邮箱验证码（非登录态）
// export function sendSignEmailCode(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Account/sendSignEmailCode`,
//     data,
//     type: "POST",
//   });
// }
// 获取邮箱验证码（非登录态）
function sendSignEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/Forget/email`,
    data,
    type: "POST"
  });
}
// 获取邮箱验证码（二次校验）

function loginSendEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/LoginVerify/sendEmailCode`,
    data,
    type: "POST"
  });
}
// 获取邮箱验证码(完全登录态)

function sendEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/sendEmailCode`,
    data,
    type: "POST"
  });
}

// // 修改密码
// export function updatePassword(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updatePassword`,
//     data,
//     type: "POST",
//   });
// }

// 修改密码
function updatePassword(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/updatePassword`,
    data,
    type: "POST"
  });
}

// // 绑定手机
// export function bindMobile(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindMobile`,
//     data,
//     type: "POST",
//   });
// }

// 绑定手机
function bindMobile(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/bindMobile`,
    data,
    type: "POST"
  });
}

// // 绑定邮箱
// export function bindEmail(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindEmail`,
//     data,
//     type: "POST",
//   });
// }

// 绑定邮箱
function bindEmail(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/bindEmail`,
    data,
    type: "POST"
  });
}

// // 修改绑定的手机
// export function undataMobile(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updateMobile`,
//     data,
//     type: "POST",
//   });
// }

// 修改绑定的手机
function undataMobile(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/changeMobile`,
    data,
    type: "POST"
  });
}

// // 获取谷歌令牌
// export function getGoogleSecret(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/getGoogleSecret`,
//     data,
//     type: "POST",
//   });
// }

// 获取谷歌令牌
function getGoogleSecret(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/bindGoogleCode`,
    data,
    type: "POST"
  });
}

// // 绑定谷歌令牌
// export function bindGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/bindGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }

// 绑定谷歌令牌
function bindGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/bindGoogle`,
    data,
    type: "POST"
  });
}

// // 修改谷歌令牌
// export function updateGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/updateGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 修改谷歌令牌
function updateGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/changeGoogle`,
    data,
    type: "POST"
  });
}
// // 关闭谷歌认证
// export function closeGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/closeGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }

// 关闭谷歌认证
function closeGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/closeGoogle`,
    data,
    type: "POST"
  });
}
// // 开启谷歌认证
// export function openGoogleVerify(data = {}) {
//   return api({
//     url: VUE_APP_UCENTER + `/UCenter/Security/openGoogleVerify`,
//     data,
//     type: "POST",
//   });
// }
// 开启谷歌认证
function openGoogleVerify(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/openGoogle`,
    data,
    type: "POST"
  });
}
// 获取谷歌绑定状态
function bingGoogleVerifyStatus(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Security/bingGoogleVerifyStatus`,
    data,
    type: "POST"
  });
}