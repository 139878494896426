"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAddress = addAddress;
exports.apiCreateFinanceAddress = apiCreateFinanceAddress;
exports.apiDelFinanceAddress = apiDelFinanceAddress;
exports.apiGetFinanceAddressList = apiGetFinanceAddressList;
exports.apiSaveFinanceAddress = apiSaveFinanceAddress;
exports.getAddress = getAddress;
exports.getAddressList = getAddressList;
exports.getAssetsList = getAssetsList;
exports.getCoinList = getCoinList;
exports.getExchangeListV2 = getExchangeListV2;
exports.getINCoinExchangeListV2 = getINCoinExchangeListV2;
exports.getUserExchangeLimit = getUserExchangeLimit;
exports.nickNameCheck = nickNameCheck;
exports.sendEmailCode = sendEmailCode;
exports.sendSmsCode = sendSmsCode;
exports.transfer = transfer;
exports.userLoginLogList = userLoginLogList;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;

function getCoinList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/Settings/itemList`,
    data,
    type: "POST"
  });
}
function getAssetsList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/getAssetsList`,
    data,
    type: "POST"
  });
}
function getExchangeListV2(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST"
  });
}
function getINCoinExchangeListV2(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST"
  });
}
function getUserExchangeLimit(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/getUserExchangeLimit`,
    data,
    type: "POST"
  });
}

//获取充币地址
function getAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/getAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiGetFinanceAddressList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/getFinanceAddressList`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiCreateFinanceAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/createFinanceAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiSaveFinanceAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/saveFinanceAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiDelFinanceAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/delFinanceAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function sendEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/sendEmailCode`,
    data,
    type: "POST"
  });
}
function sendSmsCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/sendSmsCode`,
    data,
    type: "POST"
  });
}
function getAddressList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/addressList`,
    data,
    type: "POST"
  });
}
function addAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/addAddress`,
    data,
    type: "POST"
  });
}
// 二次验证,提币借口
function transfer(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/transfer`,
    data,
    type: "POST"
  });
}

// 获取用户信息

function userLoginLogList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Auth/userLoginLogList`,
    data,
    type: "POST"
  });
}

//验证子帐号昵称有无重复

function nickNameCheck(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Account/nickNameCheck`,
    data,
    type: "POST"
  });
}