"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/esnext.typed-array.to-reversed.js");
require("core-js/modules/esnext.typed-array.to-sorted.js");
require("core-js/modules/esnext.typed-array.with.js");
require("core-js/modules/es.array.push.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _index = require("@/model/exchange/index");
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _menuControlMixin = _interopRequireDefault(require("@/components/common/menuControlMixin.vue"));
var _message = require("@/components/dialog/message");
var _vuex = require("vuex");
var _i18n = require("@/assets/js/i18n");
var _stationSetting = require("@/assets/js/stationSetting");
var _index2 = require("@/model/assets/index");
var _billCenter = require("@/model/ucenter/billCenter");
var _vueCanvasPoster = require("vue-canvas-poster");
var _spreadCenter = require("@/model/ucenter/spreadCenter");
var _b = _interopRequireDefault(require("@/assets/img/siteLogo/b9.png"));
var _mask_new = _interopRequireDefault(require("@/assets/img/siteLogo/mask_new.png"));
var _bxc = _interopRequireDefault(require("@/assets/img/siteLogo/bxc.png"));
var _hx = _interopRequireDefault(require("@/assets/img/siteLogo/hx.png"));
var _logo = _interopRequireDefault(require("@/assets/img/siteLogo/logo100.png"));
var _hxlogo_text = _interopRequireDefault(require("@/assets/img/siteLogo/hxlogo_text.png"));
var _logo100_text = _interopRequireDefault(require("@/assets/img/siteLogo/logo100_text.png"));
var _mask_text = _interopRequireDefault(require("@/assets/img/siteLogo/mask_text.png"));
var _b9_text = _interopRequireDefault(require("@/assets/img/siteLogo/b9_text.png"));
var _qr_code = _interopRequireDefault(require("@/assets/img/qr_code.png"));
var _profit_loss_share_bg = _interopRequireDefault(require("@/assets/img/profit_loss_share_bg.png"));
var _placeholder = _interopRequireDefault(require("@/assets/img/placeholder.png"));
var _expressionBad = _interopRequireDefault(require("@/assets/img/expression/expression-bad.png"));
var _expressionGreat = _interopRequireDefault(require("@/assets/img/expression/expression-great.png"));
var _expressionCool = _interopRequireDefault(require("@/assets/img/expression/expression-cool.png"));
var _orderDetails = _interopRequireDefault(require("./components/orderDetails.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import TopTitle from "../../components/Title";
// import { floorNumber, ceilNumber } from "@/assets/js/digital";
// import Socket from "@/assets/js/socket.js";
// import Cookies from "js-cookie";
// import { apiWsUrl } from "@/model/currencyBasedContract/index";
// import {
//   apiAppendInterest,
//   apiSetPositionProfitLoss,
//   apiSetPlanProfitLoss,
//   apiClosePosition,
// } from "@/model/currencyBasedContract/order.js";
// import {
//   apiAppendInterest as apiAppendInterest4U,
//   apiClosePosition as apiClosePosition4U,
//   apiSetPositionProfitLoss as apiSetPositionProfitLoss4U,
//   apiSetPlanProfitLoss as apiSetPlanProfitLoss4U,
// } from "@/model/contractExchange/order.js";
// import InputNumber from "@/components/common/InputNumber";
// import { getAssetsList } from "@/model/assets/index";
// import {
//   timestampToDate,
//   timestampToDay,
//   timestamp,
// } from "@/assets/js/formatDate";
// import BigNumber from "bignumber.js";
var _default = {
  mixins: [_commonMixin.default, _menuControlMixin.default],
  components: {
    NoData: _NoData.default,
    VueCanvasPoster: _vueCanvasPoster.VueCanvasPoster,
    OrderDetails: _orderDetails.default
  },
  data() {
    return {
      placeholder: _placeholder.default,
      wsUrl: "",
      isDestoryWS: false,
      wsContentTime: 0,
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--"
          },
          list: []
        }
      },
      marketList: [],
      appendMoneydialog: false,
      staticChainMap: {},
      //静态表1
      staticCurrencyMap: {},
      sotcStaticPaymentMap: {},
      sotcStaticProtectMap: {},
      sotcStaticGuaranteeMap: {},
      copyAccountMsg: "",
      apiAssetsInfo: {},
      from_item_id: "",
      to_item_id: "",
      from_symbol: "",
      to_symbol: "",
      swap_symbol_id: "",
      beforeBailNumber: "",
      afterBailNumber: "",
      beforeLiquidationPrice: "",
      afterLiquidationPrice: "",
      symbolList: {},
      price_precision: "",
      settlement_precision: "",
      contract_value: "",
      fee_rate: "",
      liquidation_risk_rate: "",
      bail_number: "",
      rowData: {},
      row_swap_symbol_id: "",
      inputProfitPrice: "",
      inputLossPrice: "",
      setProfitPricedialog: false,
      dialogType: "",
      confirmMessage: "",
      plan_order_id: "",
      dialogVisible: false,
      profitLossShareBg: _profit_loss_share_bg.default,
      expressionBad: _expressionBad.default,
      expressionCool: _expressionCool.default,
      expressionGreat: _expressionGreat.default,
      notifyImg: "",
      notifyVisible: false,
      painting: {},
      b9Logo: _b.default,
      bxcLogo: _bxc.default,
      hxLogo: _hx.default,
      logo100: _logo.default,
      maskLogo: _mask_new.default,
      hxlogo_text: _hxlogo_text.default,
      logo100_text: _logo100_text.default,
      mask_text: _mask_text.default,
      b9_text: _b9_text.default,
      qrCode: _qr_code.default,
      projectEnglishName: "",
      projectFullName: "",
      slogan: "",
      projectTitle: "",
      projectLogo: "",
      default_invite_code: {},
      centerHost: "",
      operateTypeMap: {
        // 1: "开仓",
        // 2: "平仓",
        // 3: "设置止损价",
        // 4: "设置止盈价",
        // 5: "增加保证金",
        // 6: "已强平",
        // 7: "同时设置止盈止损",
        // 8: "加仓",
        // 9: "减仓",
        // 10: "资金费率",
        1: this.translateTitle("开仓"),
        2: this.translateTitle("平仓"),
        3: this.translateTitle("设置止损价"),
        4: this.translateTitle("设置止盈价"),
        5: this.translateTitle("追加本金"),
        6: this.translateTitle("已强平"),
        7: this.translateTitle("同时设置止盈止损"),
        8: this.translateTitle("加仓"),
        9: this.translateTitle("减仓"),
        10: this.translateTitle("资金费用")
      },
      position_order_id: "",
      position_order_obj: {
        position_info: {},
        operate_list: []
      },
      user_order_obj: {},
      closePositionTypeMap: {
        1: this.translateTitle("未平仓"),
        2: this.translateTitle("用户平仓"),
        3: this.translateTitle("强制平仓"),
        4: this.translateTitle("止盈平仓"),
        5: this.translateTitle("止损平仓"),
        6: this.translateTitle("自动减仓")
      },
      //平仓类型
      openPositionTypeMap: {
        1: this.translateTitle("手动开仓"),
        2: this.translateTitle("触发价开仓")
      },
      //开仓类型
      liquidation_symbol_name: "",
      // 27静态表排除选项
      outerSearch: [5, 6
      // 21,
      // 22,
      // 23,
      // 26,
      // 27,
      // 28,
      // 29,
      // 30,
      // 31,
      // 32,
      // 33,
      // 34,
      // 37,
      // 38,
      // 39,
      // 40,
      // 41,
      // 42,
      // 43,
      // 44,
      // 45,
      // 46,
      // 47,
      // 49,
      // 50,
      // 51,
      // 55,
      // 56,
      ],

      coinData: {},
      staticSymbolData: {},
      staticFuturesSymbolData: {},
      searchCoin: [],
      staticAssetsTypeData: [],
      staticStockData: {},
      searchStaticAssetsType: [],
      billStatusMap: [{
        value: 1,
        label: this.translateTitle("进行中")
      }, {
        value: 2,
        label: this.translateTitle("已完成")
      }, {
        value: 3,
        label: this.translateTitle("失败")
      }],
      searchBillStatus: [],
      ctimeAndEtime: [],
      dataList: [],
      opDirectionMap: {
        1: this.translateTitle("收入"),
        2: this.translateTitle("支出")
      },
      tableLoading: true,
      billDialogTitle: "",
      billDialogVisible: false,
      reChargeDialogVisible: false,
      otcDialogVisible: false,
      tabList: [{
        label: this.translateTitle("收款账号"),
        value: 1
      }, {
        label: this.translateTitle("付款截图"),
        value: 2
      }, {
        label: this.translateTitle("订单历史"),
        value: 3
      }],
      currIndex: 1,
      currIndexPosition: 1,
      coinInfoMap: {},
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      },
      isDoBS: false,
      isBuy: true,
      currRow: {}
    };
  },
  async created() {
    if (this.isHideLever) {
      this.outerSearch = [5, 6, 21, 22, 23, 35, 36, 53, 54];
    }
    this.getAppIntialDataV2();
    this.getRebateCommonInfo();
    this.getStaticChainMap();
    this.getStaticCurrencyMap();
    this.getStaticPaymentMap();
    // this.getSotcStaticGuaranteeMap();
    // this.getSotcStaticProtectMap();
    // this.getItemList();
    this.getContractConfigBySymbolId();
    this.getFuturesConfigBySymbolId();
    this.getStockConfigBySymbolId();
    this.getStaticAssetsType();
    this.getAssets();
    await this.getItemList();
    this.getTotalBills();
    if (this.language && _stationSetting.VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin" || _stationSetting.VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin") {
      this.projectFullName = "Bullxcow";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("Bullxcow App下载");
      this.projectLogo = this.bxcLogo;
    } else if (this.language && _stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectFullName = "B9";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("B9 App下载");
      this.projectLogo = this.b9Logo;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.projectFullName = "MaskEX";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("MaskEX App下载");
      this.projectLogo = this.maskLogo;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "100Ex") {
      this.projectFullName = "100Ex";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("100Ex App下载");
      this.projectLogo = this.logo100;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "EverTrust") {
      this.projectFullName = "EverTrust";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("EverTrust App下载");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "8V") {
      this.projectFullName = "8V";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("8V App下载");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "UEEx") {
      this.projectFullName = "UEEx";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("UEEx App");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "Ahex") {
      this.projectFullName = "AHEX";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "AHEX" + this.translateTitle(" App下载");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "5A") {
      this.projectFullName = "5A";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "5A" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
      this.projectFullName = "bitda";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "bitda" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "S6") {
      this.projectFullName = "S6";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "S6" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "easy") {
      this.projectFullName = "ezex";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "ezex" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "spex") {
      this.projectFullName = "SPEX";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "SPEX" + this.translateTitle("App下载");
    } else {
      this.projectFullName = "汇信国际";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("汇信App下载");
      this.projectLogo = this.hxLogo;
    }
  },
  destroyed() {},
  beforeDestroy() {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    })
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    moment: _moment.default,
    goToRecharge() {
      window.open(this.routeSplicing(this.language, "ucenter/assets/recharge/usdt"));
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    getCompleteStatusName(assetsType, completeStatus) {
      let closeType = "";
      if ([19, 20, 21, 22, 23, 24, 25, 35, 36, 38].includes(assetsType)) {
        switch (completeStatus) {
          case 1:
            closeType = this.translateTitle("进行中");
            break;
          case 2:
            closeType = this.translateTitle("已完成");
            break;
          case 3:
            closeType = this.translateTitle("已撤销");
            break;
          default:
        }

        // switch (completeStatus) {
        //     case 1:
        //       closeType = "待成交";
        //       break;
        //     case 2:
        //       closeType = "完全成交";
        //       break;
        //     case 3:
        //       closeType = "已撤销";
        //       break;
        //     case 4:
        //       closeType = "成交失败";
        //       break;
        //     default:
        //   }
      } else if ([5, 6, 8, 9].includes(assetsType)) {
        // } else if ([9].includes(assetsType)) {
        switch (completeStatus) {
          case 1:
            closeType = this.translateTitle("进行中");
            break;
          case 2:
            closeType = this.translateTitle("已完成");
            break;
          case 3:
            closeType = this.translateTitle("已取消");
            break;
          default:
        }
      } else {
        switch (completeStatus) {
          case 1:
            closeType = this.translateTitle("进行中");
            break;
          case 2:
            closeType = this.translateTitle("已完成");
            break;
          case 3:
            closeType = this.translateTitle("失败");
            break;
          default:
        }
      }
      return closeType;
    },
    //Base64 To Blob
    convertBase64ToBlob(base64, type) {
      var imgs = base64.replace(/^data:image\/\w+;base64,/, ""); //去掉base64位头部
      var bytes = window.atob(imgs);
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], {
        type: type
      });
    },
    //写入剪切板
    async writeClipboard(src) {
      try {
        const blob = this.convertBase64ToBlob(src, "image/png");
        await navigator.clipboard.write([new ClipboardItem({
          [blob.type]: blob
        })]);
        // console.log("Image copied.");
      } catch (err) {
        console.error(err.name, err.message);
      }
    },
    //图片生成失败回调函数
    posterFail(err) {
      console.log("fail", err);
    },
    // 复制成功
    copySuccess() {
      // showMessage({
      //   message: this.translateTitle(`复制成功`),
      //   type: "success",
      // });
      this.$message({
        message: this.translateTitle(`复制成功`),
        type: "success",
        customClass: "zZindex"
      });
    },
    // 复制成功
    copyImageSuccess() {
      // showMessage({
      //   message: this.translateTitle(`图片已复制`),
      //   type: "success",
      // });
      this.$message({
        message: this.translateTitle(`图片已复制`),
        type: "success",
        customClass: "zZindex"
      });
    },
    //图片生成成功回调函数
    posterSuccess(src) {
      this.notifyImg = src;
      this.notifyVisible = true;
      this.writeClipboard(src);
      this.copyImageSuccess();
    },
    // 复制海报
    createPoster() {
      setTimeout(() => {
        this.billDialogVisible = false;
      }, 1000);
      this.painting = JSON.parse(JSON.stringify(this.poster()));
    },
    poster() {
      let sendingAddress = ""; //接收地址
      let receivingAddress = ""; //接收地址
      let arrivalQuantity = this.currRow.item_number + " " + this.currRow.coinItemName; //到账数量
      let orderNum = ""; //订单号
      let orderStatus = this.currRow.child_status_name.toLowerCase() == this.translateTitle("已完成").toLowerCase() || this.currRow.child_status_name.toLowerCase() == this.translateTitle("成功").toLowerCase() ? this.translateTitle("转账成功") : this.translateTitle("转账失败"); //订单状态
      let color = this.currRow.child_status_name.toLowerCase() == this.translateTitle("已完成").toLowerCase() || this.currRow.child_status_name.toLowerCase() == this.translateTitle("成功").toLowerCase() ? "rgb(0 188 160)" : "rgb(240 74 90)";
      let createTime = ""; //创建时间
      let remark = ""; //备注
      let chain = ""; //备注
      let subHeight = 54;
      let subHeightNoSR = 54 + 180;
      this.currRow.detailArray.forEach(item => {
        if (item.title == this.translateTitle("发送地址")) {
          sendingAddress = item.content ? item.content : "--";
        }
        if (item.title == this.translateTitle("接收地址")) {
          receivingAddress = item.content ? item.content : "--";
        }
        if (item.title == this.translateTitle("订单号")) {
          orderNum = item.content ? item.content + "" : "--";
        }
        if (item.title == this.translateTitle("转账时间")) {
          createTime = item.content ? item.content : "--";
        }
        if (item.title == this.translateTitle("备注")) {
          remark = item.content ? item.content : "--";
        }
        if (item.title == this.translateTitle("链名称")) {
          chain = item.content;
        }
      });
      let result = {};
      if (chain) {
        result = {
          width: "620px",
          height: "853px",
          background: "#fff",
          views: [{
            type: "image",
            url: this.projectLogo,
            css: {
              top: "31px",
              left: "43px",
              width: "68px",
              height: "68px",
              borderRadius: "100%"
            }
          }, {
            type: "text",
            text: this.projectFullName,
            css: {
              top: "42px",
              left: "130px",
              maxLines: 1,
              fontSize: "33px",
              fontWeight: "600",
              color: "#131f30"
            }
          }, {
            type: "rect",
            css: {
              top: "129px",
              left: "27px",
              color: "#f3f7fc",
              width: "566px",
              height: "557px",
              borderRadius: "4px"
            }
          }, {
            type: "text",
            text: this.translateTitle("链名称"),
            css: {
              top: "165px",
              left: "63px",
              width: "80px",
              maxLines: 2,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: chain,
            css: {
              top: "165px",
              left: "185px",
              width: "300px",
              maxLines: 2,
              fontSize: "21px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: this.translateTitle("发送地址"),
            css: {
              top: "219px",
              left: "63px",
              width: "120px",
              maxLines: 2,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: sendingAddress,
            css: {
              top: "219px",
              left: "185px",
              width: "365px",
              maxLines: 2,
              fontSize: "21px",
              color: "#9aa1b1",
              lineHeight: "36px"
            }
          }, {
            type: "text",
            text: this.translateTitle("接收地址"),
            css: {
              top: "309px",
              left: "63px",
              width: "120px",
              maxLines: 2,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: receivingAddress,
            css: {
              top: "309px",
              left: "185px",
              width: "365px",
              maxLines: 2,
              fontSize: "21px",
              color: "#9aa1b1",
              lineHeight: "36px"
            }
          }, {
            type: "text",
            text: this.translateTitle("到账数量"),
            css: {
              top: "399px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: arrivalQuantity,
            css: {
              top: "399px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              fontWeight: "500",
              color: "#354052"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("订单号"),
            css: {
              top: "453px",
              left: "63px",
              width: "100px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: orderNum,
            css: {
              top: "453px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("订单状态"),
            css: {
              top: "507px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: orderStatus,
            css: {
              top: "507px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: color
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("创建时间"),
            css: {
              top: "561px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: createTime,
            css: {
              top: "561px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("备注"),
            css: {
              top: "615px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: remark,
            css: {
              top: "615px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.projectTitle,
            css: {
              top: "715px",
              left: "54px",
              // width: "200px",
              maxLines: 1,
              fontSize: "29px",
              fontWeight: "600",
              color: "#354052"
            }
          }, {
            type: "text",
            text: this.slogan,
            css: {
              top: "767px",
              left: "54px",
              width: "200px",
              maxLines: 1,
              fontSize: "20.2px",
              color: "#354052"
              // fontWeight: "300",
            }
          }, {
            type: "qrcode",
            content: this.centerHost + this.default_invite_code.invite_url,
            css: {
              top: "702px",
              right: "54px",
              color: "#000",
              width: "100px",
              height: "100px"
            }
          }]
        };
      } else if (!sendingAddress && !receivingAddress) {
        result = {
          width: "620px",
          height: 783 + 90 - 20 - subHeightNoSR + "px",
          background: "#fff",
          views: [{
            type: "image",
            url: this.projectLogo,
            css: {
              top: "31px",
              left: "43px",
              width: "68px",
              height: "68px",
              borderRadius: "100%"
            }
          }, {
            type: "text",
            text: this.projectFullName,
            css: {
              top: "42px",
              left: "130px",
              maxLines: 1,
              fontSize: "33px",
              fontWeight: "600",
              color: "#131f30"
            }
          }, {
            type: "rect",
            css: {
              top: "129px",
              left: "27px",
              color: "#f3f7fc",
              width: "566px",
              height: 447 + 110 - subHeightNoSR + "px",
              borderRadius: "4px"
            }
          },
          // {
          //   type: "text",
          //   text: this.translateTitle("发送地址"),
          //   css: {
          //     top: 219 - subHeightNoSR + "px",
          //     left: "63px",
          //     width: "120px",
          //     maxLines: 2,
          //     fontSize: "25.2px",
          //     color: "#9aa1b1",
          //   },
          // },
          // {
          //   type: "text",
          //   text: sendingAddress,
          //   css: {
          //     top: 219 - subHeightNoSR + "px",
          //     left: "197px",
          //     width: "365px",
          //     maxLines: 2,
          //     fontSize: "25.2px",
          //     color: "#9aa1b1",
          //     lineHeight: "36px",
          //   },
          // },
          // {
          //   type: "text",
          //   text: this.translateTitle("接收地址"),
          //   css: {
          //     top: 219 + 90 - subHeightNoSR + "px",
          //     left: "63px",
          //     width: "120px",
          //     maxLines: 2,
          //     fontSize: "25.2px",
          //     color: "#9aa1b1",
          //   },
          // },
          // {
          //   type: "text",
          //   text: receivingAddress,
          //   css: {
          //     top: 219 + 90 - subHeightNoSR + "px",
          //     left: "197px",
          //     width: "365px",
          //     maxLines: 2,
          //     fontSize: "25.2px",
          //     color: "#9aa1b1",
          //     lineHeight: "36px",
          //   },
          // },
          {
            type: "text",
            text: this.translateTitle("到账数量"),
            css: {
              top: 309 + 90 - subHeightNoSR + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: arrivalQuantity,
            css: {
              top: 309 + 90 - subHeightNoSR + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              fontWeight: "500",
              color: "#354052"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("订单号"),
            css: {
              top: 363 + 90 - subHeightNoSR + "px",
              left: "63px",
              width: "100px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: orderNum,
            css: {
              top: 363 + 90 - subHeightNoSR + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("订单状态"),
            css: {
              top: 417 + 90 - subHeightNoSR + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: orderStatus,
            css: {
              top: 417 + 90 - subHeightNoSR + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: color
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("创建时间"),
            css: {
              top: 471 + 90 - subHeightNoSR + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: createTime,
            css: {
              top: 471 + 90 - subHeightNoSR + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("备注"),
            css: {
              top: 525 + 90 - subHeightNoSR + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: remark,
            css: {
              top: 525 + 90 - subHeightNoSR + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.projectTitle,
            css: {
              top: 645 + 90 - 20 - subHeightNoSR + "px",
              left: "54px",
              // width: "200px",
              maxLines: 1,
              fontSize: "29px",
              fontWeight: "600",
              color: "#354052"
            }
          }, {
            type: "text",
            text: this.slogan,
            css: {
              top: 697 + 90 - 20 - subHeightNoSR + "px",
              left: "54px",
              width: "200px",
              maxLines: 1,
              fontSize: "20.2px",
              color: "#354052"
              // fontWeight: "300",
            }
          }, {
            type: "qrcode",
            content: this.centerHost + this.default_invite_code.invite_url,
            css: {
              top: 632 + 90 - 20 - subHeightNoSR + "px",
              right: "54px",
              color: "#000",
              width: "100px",
              height: "100px"
            }
          }]
        };
      } else {
        result = {
          width: "620px",
          height: 783 + 90 - 20 - subHeight + "px",
          background: "#fff",
          views: [{
            type: "image",
            url: this.projectLogo,
            css: {
              top: "31px",
              left: "43px",
              width: "68px",
              height: "68px",
              borderRadius: "100%"
            }
          }, {
            type: "text",
            text: this.projectFullName,
            css: {
              top: "42px",
              left: "130px",
              maxLines: 1,
              fontSize: "33px",
              fontWeight: "600",
              color: "#131f30"
            }
          }, {
            type: "rect",
            css: {
              top: "120px",
              left: "27px",
              color: "#f3f7fc",
              width: "566px",
              height: 447 + 110 - subHeight + "px",
              borderRadius: "4px" //*
            }
          }, {
            type: "text",
            text: this.translateTitle("发送地址"),
            css: {
              top: 219 - subHeight + "px",
              left: "63px",
              width: "120px",
              maxLines: 2,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: sendingAddress,
            css: {
              top: 219 - subHeight + "px",
              left: "185px",
              width: "365px",
              maxLines: 2,
              fontSize: "21px",
              color: "#9aa1b1",
              lineHeight: "36px"
            }
          }, {
            type: "text",
            text: this.translateTitle("接收地址"),
            css: {
              top: 219 + 90 - subHeight + "px",
              left: "63px",
              width: "120px",
              maxLines: 2,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: receivingAddress,
            css: {
              top: 219 + 90 - subHeight + "px",
              left: "185px",
              width: "365px",
              maxLines: 2,
              fontSize: "21px",
              color: "#9aa1b1",
              lineHeight: "36px"
            }
          }, {
            type: "text",
            text: this.translateTitle("到账数量"),
            css: {
              top: 309 + 90 - subHeight + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: arrivalQuantity,
            css: {
              top: 309 + 90 - subHeight + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              fontWeight: "500",
              color: "#354052"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("订单号"),
            css: {
              top: 363 + 90 - subHeight + "px",
              left: "63px",
              width: "100px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: orderNum,
            css: {
              top: 363 + 90 - subHeight + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("订单状态"),
            css: {
              top: 417 + 90 - subHeight + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: orderStatus,
            css: {
              top: 417 + 90 - subHeight + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: color
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("创建时间"),
            css: {
              top: 471 + 90 - subHeight + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: createTime,
            css: {
              top: 471 + 90 - subHeight + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.translateTitle("备注"),
            css: {
              top: 525 + 90 - subHeight + "px",
              left: "63px",
              width: "120px",
              maxLines: 1,
              fontSize: "22px",
              color: "#9aa1b1"
            }
          }, {
            type: "text",
            text: remark,
            css: {
              top: 525 + 90 - subHeight + "px",
              left: "185px",
              width: "400px",
              maxLines: 1,
              fontSize: "21px",
              color: "#9aa1b1"
              // lineHeight: "16px",
            }
          }, {
            type: "text",
            text: this.projectTitle,
            css: {
              top: 645 + 90 - 20 - subHeight + "px",
              left: "54px",
              // width: "200px",
              maxLines: 1,
              fontSize: "29px",
              fontWeight: "600",
              color: "#354052"
            }
          }, {
            type: "text",
            text: this.slogan,
            css: {
              top: 697 + 90 - 20 - subHeight + "px",
              left: "54px",
              width: "200px",
              maxLines: 1,
              fontSize: "20.2px",
              color: "#354052"
              // fontWeight: "300",
            }
          }, {
            type: "qrcode",
            content: this.centerHost + this.default_invite_code.invite_url,
            css: {
              top: 632 + 90 - 20 - subHeight + "px",
              right: "54px",
              color: "#000",
              width: "100px",
              height: "100px"
            }
          }]
        };
      }
      return result;
    },
    // 复制失败
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`复制失败`)
      });
    },
    async getItemList() {
      const {
        status,
        data
      } = await (0, _index2.getStaticCoinList)();
      if (status == 200) {
        this.coinInfoMap = this.coinData = data;
      }
    },
    //获取默认邀请码
    getRebateCommonInfo() {
      (0, _spreadCenter.apiGetRebateCommonInfo)().then(res => {
        if (res.status == 200) {
          this.default_invite_code = res.data.default_invite_code;
        }
      });
    },
    //获取邀请链接 新
    getAppIntialDataV2() {
      (0, _spreadCenter.apiGetInviteUrl)().then(res => {
        if (res.status == 200) {
          this.centerHost = res.data.invite_url;
        }
      });
    },
    //56-static_chain-区块链
    async getStaticChainMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(56);
      if (status == 200) {
        this.staticChainMap = Object.values(JSON.parse(JSON.stringify(data)));
      }
    },
    //7-static_currency-法币表
    async getStaticCurrencyMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(7);
      if (status == 200) {
        this.staticCurrencyMap = JSON.parse(JSON.stringify(data));
      }
    },
    //22-sotc_static_payment-支付方式静态表
    async getStaticPaymentMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(22);
      if (status == 200) {
        this.sotcStaticPaymentMap = JSON.parse(JSON.stringify(data));
      }
    },
    // 保护时间静态表-sotc_static_protect_type-41
    async getSotcStaticProtectMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(41);
      if (status == 200) {
        this.sotcStaticProtectMap = JSON.parse(JSON.stringify(data));
      }
    },
    // 包赔时间静态表 -sotc_static_guarantee_type-42
    async getSotcStaticGuaranteeMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(42);
      if (status == 200) {
        this.sotcStaticGuaranteeMap = JSON.parse(JSON.stringify(data));
      }
    },
    //根据链名称获取链信息
    getChainByName(name) {
      let chainObj = {};
      this.staticChainMap.forEach(item => {
        if (item.chain_tag == name) {
          chainObj = item;
        }
      });
      return chainObj;
    },
    //U本位合约交易对
    async getContractConfigBySymbolId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(49);
      if (status == 200) {
        this.staticSymbolData = JSON.parse(JSON.stringify(data));
      }
    },
    //币本位合约交易对
    async getFuturesConfigBySymbolId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(58);
      if (status == 200) {
        this.staticFuturesSymbolData = JSON.parse(JSON.stringify(data));
      }
    },
    //类型
    async getStaticAssetsType() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(27);
      if (status == 200) {
        let tempTypeData = Object.values(JSON.parse(JSON.stringify(data)));
        this.staticAssetsTypeData = tempTypeData.filter(item => {
          let filterStatus = !this.outerSearch.includes(item.assets_type);
          return filterStatus && item.is_show == 1;
        });
      }
    },
    //股票静态表
    async getStockConfigBySymbolId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(46);
      if (status == 200) {
        this.staticStockData = JSON.parse(JSON.stringify(data));
      }
    },
    async resetTotalBills(pageNum = 1) {
      let defaultSearchStaticAssetsType = "";
      let tempSearchStaticAssetsTypeArray = [];
      this.staticAssetsTypeData.forEach(item => {
        tempSearchStaticAssetsTypeArray.push(item.search_id);
      });
      defaultSearchStaticAssetsType = tempSearchStaticAssetsTypeArray.join(",");
      this.searchCoin = [];
      this.searchStaticAssetsType = [];
      this.searchBillStatus = [];
      this.ctimeAndEtime = [];
      const req = {
        assets_type_list: defaultSearchStaticAssetsType,
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10
      };
      this.tableLoading = true;
      const {
        status,
        data
      } = await (0, _billCenter.apiGetAssetsLog)(req);
      if (status && status == 200) {
        let tempData = data.list;
        tempData.forEach(item => {
          item.numberStr = item.item_number + " " + (this.coinData[item.item_id] ? this.coinData[item.item_id].item_name.toUpperCase() : "未知");
        });
        this.dataList = this.transformListData(JSON.parse(JSON.stringify(tempData)));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
      this.tableLoading = false;
    },
    async getTotalBills(pageNum = 1) {
      let create_time = "";
      let end_time = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_time = parseInt(start / 1000);
        end_time = parseInt(end2 / 1000);
      }
      let defaultSearchStaticAssetsType = "";
      let tempSearchStaticAssetsTypeArray = [];
      this.staticAssetsTypeData.forEach(item => {
        tempSearchStaticAssetsTypeArray.push(item.search_id);
      });
      defaultSearchStaticAssetsType = tempSearchStaticAssetsTypeArray.join(",");
      const req = {
        assets_type_list: this.searchStaticAssetsType.join(",") ? this.searchStaticAssetsType.join(",") : defaultSearchStaticAssetsType,
        item_id_list: this.searchCoin.join(","),
        status_list: this.searchBillStatus.join(","),
        create_time: create_time,
        end_time: end_time,
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10
      };
      this.tableLoading = true;
      const {
        status,
        data
      } = await (0, _billCenter.apiGetAssetsLog)(req);
      if (status && status == 200) {
        let tempData = data.list;
        tempData.forEach(item => {
          item.numberStr = item.item_number + " " + (this.coinData[item.item_id] ? this.coinData[item.item_id].item_name.toUpperCase() : "未知");
        });
        this.dataList = this.transformListData(JSON.parse(JSON.stringify(tempData)));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
      this.tableLoading = false;
    },
    async productInfoClick(row) {
      this.currRow = row;
      // this.currRow.coinImageUrl =
      //   this.coinData[
      //     this.currRow.item_id == 100001 ? 10132 : this.currRow.item_id
      //   ] &&
      //   this.coinData[
      //     this.currRow.item_id == 100001 ? 10132 : this.currRow.item_id
      //   ].itemDetail.coin_url;
      this.currRow.coinImageUrl = this.coinData[this.currRow.item_id] && this.coinData[this.currRow.item_id].itemDetail.coin_url;
      this.currRow.coinItemName = this.coinData[this.currRow.item_id] && this.coinData[this.currRow.item_id].item_name.toUpperCase();
      if ([5, 6, 8, 9].includes(row.assets_type)) {
        this.$refs.orderDetails.productInfoClick(row);
      } else {
        this.billDialogVisible = true;
      }
    },
    //请求U本位合约并打开仓位详情
    async liquidationContractInfoClick() {
      setTimeout(() => {
        this.billDialogVisible = false;
      }, 1000);
      this.$refs.orderDetails.liquidationContractInfoClick(this.currRow);
    },
    //请求币本位合约并打开仓位详情
    async liquidationFuturesInfoClick() {
      setTimeout(() => {
        this.billDialogVisible = false;
      }, 1000);
      this.$refs.orderDetails.liquidationFuturesInfoClick(this.currRow);
    },
    billhandleClose(row) {
      this.billDialogVisible = false;
    },
    reChargehandleClose(row) {
      this.reChargeDialogVisible = false;
    },
    otcHandleClose(row) {
      this.otcDialogVisible = false;
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    onPageChange(val) {
      this.getTotalBills(val);
    },
    openExplor(row) {
      let url = "";
      if (row.order_info.chain_tag) {
        url = this.getChainByName(row.order_info.chain_tag.toLowerCase()).txid_url + row.order_info.txid;
      } else {
        url = this.coinData[row.item_id].itemDetail.txid_url + row.order_info.txid;
      }
      window.open(url);
    },
    transformListData(list = []) {
      list.forEach(item => {
        let detailArray = [];
        let detailObj = {
          title: "",
          content: ""
        };
        let coinName = this.coinData[item.item_id] && this.coinData[item.item_id].item_name.toUpperCase();
        // 是否是进账
        let isAssetsIn = item.op_direction == "1";
        // 根据类型组装字段开始
        if (item.assets_type == "1" || item.assets_type == "2") {
          let model = item.order_info;
          let transferOrderInfo = model;
          let isOnChainTransfer = model.exchange_type == "2";
          let isChanelTransfer = isOnChainTransfer && model.use_type == "2" && item.assets_type == "2";
          let haveTxid = model.txid ? true : false;
          if (isChanelTransfer) {
            //渠道数字支付
            //转账类型
            detailObj.title = this.translateTitle("转账类型");
            detailObj.content = this.translateTitle("数字支付");
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            //转账时间
            detailObj.title = this.translateTitle("转账时间");
            detailObj.content = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            //接收地址
            if (model.to_address) {
              detailObj.title = this.translateTitle("接收地址");
              detailObj.content = model.to_address;
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
          } else if (isOnChainTransfer) {
            //外部地址
            detailObj.title = this.translateTitle(item.show_name) + this.translateTitle("类型");
            detailObj.content = this.translateTitle("外部地址");
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("创建时间");
            detailObj.content = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            //如果是提币的话，显示到账数量和手续费
            if (item.assets_type == "2") {
              detailObj.title = this.translateTitle("到账数量");
              detailObj.content = this.handleCutZero(model.num) + " " + coinName;
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
              detailObj.title = this.translateTitle("手续费");
              detailObj.content = this.handleCutZero(model.network_fee) + " " + coinName;
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
            if (model.chain_tag) {
              detailObj.title = this.translateTitle("链名称");
              detailObj.content = model.chain_tag.toUpperCase();
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
            detailObj.title = this.translateTitle("发送地址");
            detailObj.content = model.from_address;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("接收地址");
            detailObj.content = model.to_address;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            if (isOnChainTransfer) {
              detailObj.title = "Txid";
              detailObj.content = model.txid || "--";
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
              detailObj.title = this.translateTitle("确认数");
              detailObj.content = this.getChainByName(model.chain_tag.toLowerCase()).confirm_num ? `${model.confirm}/${this.getChainByName(model.chain_tag.toLowerCase()).confirm_num}` : `${model.confirm}/${this.coinData[item.item_id].itemDetail.confirm_num}`;
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
          } else {
            // enablePullDown = true;
            //内部地址
            detailObj.title = this.translateTitle(item.show_name) + this.translateTitle("类型");
            detailObj.content = this.translateTitle("内部地址");
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle(item.show_name) + this.translateTitle("时间");
            detailObj.content = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            if (model.from_address) {
              detailObj.title = this.translateTitle("发送地址");
              detailObj.content = model.from_address;
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
            if (model.to_address) {
              detailObj.title = this.translateTitle("接收地址");
              detailObj.content = model.to_address;
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
            if (haveTxid) {
              detailObj.title = "Txid";
              detailObj.content = model.txid || "--";
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
          }
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.uce_id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("备注");
          detailObj.content = model.remark || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "3") {
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle("分发类型");
          detailObj.content = item.client_remark;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("分发时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("备注");
          detailObj.content = model.bak;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "4") {
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = model.exchange_item_name ? model.exchange_item_name.toUpperCase() : this.translateTitle("未知");
          let toItemName = model.item_name ? model.item_name.toUpperCase() : this.translateTitle("未知");
          detailObj.title = this.translateTitle("兑换时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("兑换花费");
          detailObj.content = `-${model.exchange_item_number} ${fromItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("兑换汇率");
          detailObj.content = `1 ${fromItemName} ≈ ${model.item_rate} ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("兑换获得");
          detailObj.content = `+${model.item_number} ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.uie_id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        }
        // else if (item.assets_type == '5') {
        //   //OTC-买入
        // }else if (item.assets_type == '6') {
        //   //OTC-卖出
        // }
        else if (item.assets_type == "7" || item.assets_type == "12") {
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.from_item_id] ? this.coinData[model.from_item_id].item_name.toUpperCase() : this.translateTitle("未知");
          let toItemName = this.coinData[model.to_item_id] ? this.coinData[model.to_item_id].item_name.toUpperCase() : this.translateTitle("未知");
          detailObj.title = this.translateTitle("汇兑时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("支付金额");
          detailObj.content = `${model.from_number} ${fromItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("汇率");
          detailObj.content = `1 ${fromItemName} = ${model.price} ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("得到金额");
          detailObj.content = `${model.to_number} ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.swap_order_id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        }
        // else if (item.assets_type == '8') {
        //   //充值
        // }else if (item.assets_type == '9') {
        //   //提现
        // }
        else if (item.assets_type == "10" || item.assets_type == "11") {
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle(item.show_name) + this.translateTitle("类型");
          detailObj.content = this.translateTitle("内部用户");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle(item.show_name) + this.translateTitle("时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = isAssetsIn ? this.translateTitle("发送用户账号") : this.translateTitle("接收用户账号");
          detailObj.content = model.obj_user_info ? model.obj_user_info : "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("备注");
          detailObj.content = model.remark || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.transfer_id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "13" || item.assets_type == "14" || item.assets_type == "15" || item.assets_type == "16" || item.assets_type == "17" || item.assets_type == "18" || item.assets_type == "48" || item.assets_type == "52") {
          var strs = {
            "13": "发放时间",
            "14": "发放时间",
            "15": "存入时间",
            "16": "存入时间",
            "17": "取出时间",
            "18": "回款时间",
            "48": "购买时间",
            "52": "存入时间"
          };
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle("类型");
          detailObj.content = item.client_remark;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("存款产品");
          detailObj.content = model.remark || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //内部划转
          if (item.assets_type == "48") {
            let baseItemName = this.coinData[model.base_item_id] ? this.coinData[model.base_item_id].item_name.toUpperCase() : "";
            detailObj.title = this.translateTitle("花费");
            detailObj.content = model.base_item_number + baseItemName;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          }
          detailObj.title = strs[item.assets_type];
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.ufat_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "19" || item.assets_type == "20") {
          let model = item.order_info;
          let time = this.moment(model.create_time).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.coin_from] ? this.coinData[model.coin_from].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.coin_to] ? this.coinData[model.coin_to].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = `${fromItemName}/${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //FIXME 增加颜色判断
          detailObj.title = this.translateTitle("委托方向");
          detailObj.content = model.side == "buy" ? this.translateTitle("买入") : this.translateTitle("卖出");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托类型");
          detailObj.content = this.translateTitle("市价委托");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = model.side == "buy" ? this.translateTitle("买入金额") : this.translateTitle("卖出数量");
          detailObj.content = model.side == "buy" ? this.handleCutZero(model.total) + " " + toItemName : this.handleCutZero(model.num) + " " + fromItemName;
          // : model.the_num + " " + fromItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = model.side == "buy" ? this.translateTitle("买入数量") : this.translateTitle("卖出金额");
          detailObj.content = model.side == "buy" ? this.handleCutZero(model.deal_num) + " " + fromItemName : this.handleCutZero(model.total) + " " + toItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交均价");
          detailObj.content = model.deal_avg_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          if (model.item_fee != null) {
            detailObj.title = this.translateTitle("手续费");
            detailObj.content = model.fee + " " + (model.side == "buy" ? fromItemName : toItemName);
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          }
          detailObj.title = this.translateTitle("委托时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.trust_id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //币币交易
        } else if (item.assets_type == "24" || item.assets_type == "25") {
          let model = item.order_info;
          // let time = this.moment(model.create_time * 1000).format(
          //   "YYYY-MM-DD HH:mm:ss"
          // );
          let time = this.moment(model.create_time).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.coin_from] ? this.coinData[model.coin_from].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.coin_to] ? this.coinData[model.coin_to].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = `${fromItemName}/${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托方向");
          detailObj.content = model.side == "buy" ? this.translateTitle("买入") : this.translateTitle("卖出");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托类型");
          detailObj.content = this.translateTitle("限价委托");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托价格");
          detailObj.content = model.price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托量");
          detailObj.content = model.num + " " + fromItemName;
          // detailObj.content = model.the_num + fromItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));

          // let updateTime = this.moment(model.update_time * 1000).format(
          //   "YYYY-MM-DD HH:mm:ss"
          // );
          let updateTime = this.moment(model.update_time).format("YYYY-MM-DD HH:mm:ss");
          if (model.status == "2") {
            detailObj.title = this.translateTitle("成交时间");
            detailObj.content = updateTime;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("成交均价");
            detailObj.content = model.deal_avg_price;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = model.side == "buy" ? this.translateTitle("买入数量") : this.translateTitle("卖出数量");
            detailObj.content = this.handleCutZero(model.dealNum) + fromItemName;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            if (model.item_fee != null) {
              detailObj.title = this.translateTitle("手续费");
              detailObj.content = model.fee + " " + (model.side == "buy" ? fromItemName : toItemName);
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
          } else if (model.status == "3") {
            detailObj.title = this.translateTitle("撤销时间");
            detailObj.content = updateTime;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          }
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.trust_id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //币币交易 触发价
        } else if (item.assets_type == "21") {
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.open_coin_id] ? this.coinData[model.open_coin_id].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.settlement_coin_id] ? this.coinData[model.settlement_coin_id].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("开仓类型");
          detailObj.content = model.open_position_type == "1" ? this.translateTitle("市价开仓") : this.translateTitle("限价开仓");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓方向");
          detailObj.content = model.open_side == "1" ? this.translateTitle("买涨") : this.translateTitle("买跌");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = `${fromItemName}/${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("杠杆倍数");
          detailObj.content = model.open_leverage + "X";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("本金");
          detailObj.content = model.bail_number + " " + toItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交均价");
          detailObj.content = model.open_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交数量");
          detailObj.content = model.open_interest + " " + fromItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交手续费");
          detailObj.content = model.open_position_fee + " " + toItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //杠杆交易 开仓
        } else if (item.assets_type == "22") {
          let model = item.order_info;
          //杠杆交易 平仓
          let closeType = "";
          switch (model.close_position_type) {
            case 1:
              closeType = this.translateTitle("未平仓");
              break;
            case 2:
              closeType = this.translateTitle("用户平仓");
              break;
            case 3:
              closeType = this.translateTitle("强制平仓");
              break;
            case 4:
              closeType = this.translateTitle("止盈平仓");
              break;
            case 5:
              closeType = this.translateTitle("止损平仓");
              break;
            case 6:
              closeType = this.translateTitle("自动减仓");
              break;
            default:
          }
          let time = this.moment(model.close_position_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.open_coin_id] ? this.coinData[model.open_coin_id].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.settlement_coin_id] ? this.coinData[model.settlement_coin_id].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("平仓类型");
          detailObj.content = closeType;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = `${fromItemName}/${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓价格");
          detailObj.content = model.close_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓数量");
          detailObj.content = model.open_interest + " " + fromItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓盈亏");
          detailObj.content = model.profit_loss + " " + toItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓盈亏比");
          detailObj.content = model.profit_loss_ratio + "%";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交手续费");
          detailObj.content = model.close_position_fee + " " + toItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("余额归还");
          detailObj.content = model.balance_change_number + " " + toItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "23") {
          let model = item.order_info;
          let time = this.moment(model.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.open_coin_id] ? this.coinData[model.open_coin_id].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.settlement_coin_id] ? this.coinData[model.settlement_coin_id].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("开仓类型");
          detailObj.content = this.translateTitle("限价开仓");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓方向");
          detailObj.content = model.open_side == "1" ? this.translateTitle("买涨") : this.translateTitle("买跌");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = `${fromItemName}/${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("触发条件");
          detailObj.content = model.trigger_side == "1" ? `≤${model.trigger_price}` : `≥${model.trigger_price}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("杠杆倍数");
          detailObj.content = model.leverage + "X";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("本金");
          detailObj.content = model.bail_number + " " + toItemName;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          let triggerTime = this.moment(model.trigger_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let canncelTime = this.moment(model.update_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          if (model.order_status == "2") {
            detailObj.title = this.translateTitle("成交时间");
            detailObj.content = triggerTime;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("成交均价");
            detailObj.content = model.order_price;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("成交数量");
            detailObj.content = model.trade_number + " " + fromItemName;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("成交手续费");
            detailObj.content = model.trade_fee + " " + toItemName;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          } else if (model.order_status == "3") {
            detailObj.title = this.translateTitle("撤销时间");
            detailObj.content = canncelTime;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          } else if (model.order_status == "4") {
            let reason = "";
            switch (model.trigger_fail_type) {
              case 1:
                reason = this.translateTitle("超过此交易对最大总持仓数量");
                break;
              case 2:
                reason = this.translateTitle("超过账户最大总持仓本金");
                break;
              case 3:
                reason = this.translateTitle("超过此交易对最大总持仓本金");
                break;
              default:
            }
            detailObj.title = this.translateTitle("成交时间");
            detailObj.content = triggerTime;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("失败原因");
            detailObj.content = reason;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          }
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.plan_order_id;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //杠杆交易 平仓
          // final model = AssetsRecordMarginPlanOrderInfo.fromJson(item.order_info);

          // final fromItemName = StaticTableSerivices.getItems()!.items[model.openCoinId!]?.itemName?.toUpperCase() ?? '';
          // final toItemName = StaticTableSerivices.getItems()!.items[model.settlementCoinId!]?.itemName?.toUpperCase() ?? '';
          // final time = Utils.formatDateMs(Utils.safeParesInt(model.createTime ?? '') * 1000, format: 'yyyy-MM-dd HH:mm:ss');
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('开仓类型'), content: localiseString('限价开仓'), copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('开仓方向'), content: model.openSide == '1' ? localiseString('买涨') : localiseString('买跌'), contentColor: model.openSide == '1' ? HXTheme.currentTheme.greenColor : HXTheme.currentTheme.lightRedColor, copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('交易对'), content: '$fromItemName/$toItemName', copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('触发条件'), content: model.triggerSide == '1' ? '≤${model.triggerPrice}' : '≥${model.triggerPrice}', copyable: true));

          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('杠杆倍数'), content: model.leverage! + 'X', copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('本金'), content: model.bailNumber! + ' $toItemName', copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('下单时间'), content: time, copyable: true));

          // // final createTime = Utils.formatDateMs(Utils.safeParesInt(model.createTime ?? '') * 1000, format: 'MM/dd HH:mm:ss');
          // final triggerTime = Utils.formatDateMs(Utils.safeParesInt(model.triggerTime ?? '') * 1000, format: 'MM/dd HH:mm:ss');
          // final canncelTime = Utils.formatDateMs(Utils.safeParesInt(model.updateTime ?? '') * 1000, format: 'MM/dd HH:mm:ss');

          // if (model.orderStatus == '2') {
          //   infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('成交时间'), content: triggerTime, copyable: true));
          //   infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('成交均价'), content: model.orderPrice, copyable: true));
          //   infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('成交数量'), content: (model.tradeNumber ?? '') + ' $fromItemName', copyable: true));
          //   infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('成交手续费'), content: (model.tradeFee ?? '') + ' $toItemName', copyable: true));
          // } else if (model.orderStatus == '3') {
          //   infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('撤销时间'), content: canncelTime, copyable: true));
          // } else if (model.orderStatus == '4') {
          //   String? reason = '';
          //   switch (model.triggerFailType) {
          //     case '1':
          //       reason = localiseString('超过此交易对最大总持仓数量');
          //       break;
          //     case '2':
          //       reason = localiseString('超过账户最大总持仓本金');
          //       break;
          //     case '3':
          //       reason = localiseString('超过此交易对最大总持仓本金');
          //       break;
          //     default:
          //   }
          //   infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('成交时间'), content: triggerTime, copyable: true));
          //   infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('失败原因'), content: reason, copyable: true));
          // }

          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('订单号'), content: model.planOrderId, copyable: true));

          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('平仓数量'), content: (model.openInterest ?? '') + ' $fromItemName', copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('平仓盈亏'), content: model.profitLoss + ' $toItemName', copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('平仓盈亏比'), content: model.profitLossRatio + '%', copyable: true));
          // infoCells.add(_AssetsRecordDetailInfoCell(title: localiseString('成交手续费'), content: (model.closePositionFee ?? '') + ' $toItemName', copyable: true));

          // if (model.orderStatus == '1') {
          //   bottomButtons = SafeArea(
          //     child: Container(
          //       padding: EdgeInsets.symmetric(horizontal: 15, vertical: 15),
          //       child: Row(
          //         children: <Widget>[
          //           Expanded(
          //             child: SubmitButton(
          //               titleColor: HXTheme.currentTheme.titleColor,
          //               height: 44,
          //               color: Colors.white,
          //               showBorder: true,
          //               borderColor: HXTheme.currentTheme.lightGrayColor,
          //               title: localiseString('撤销'),
          //               onPressed: () async {
          //                 final selection = await UIUtils.showSimpleTitleAlert(context, title: localiseString('是否确认撤销此单？'));
          //                 if (selection == true) {
          //                   try {
          //                     UIUtils.showFullScreenLoading(context);
          //                     await RequestServices.revokePlan(planOrderId: model.planOrderId);

          //                     showToast(localiseString('撤销成功'));

          //                     Global.assetsAllV2Bloc!.fetchAssets();
          //                     ; //更新资产
          //                     Navigator.of(context).pop(); //dismiss loading
          //                     Navigator.of(context).pop(); //pop this
          //                     //刷新资产列表
          //                     eventBus.fire(RefreshRecordListScreen());
          //                     //刷新杠杆持仓
          //                     eventBus.fire(WantRefreshMarginPosition());
          //                   } catch (e) {
          //                     Navigator.of(context).pop(); //dismiss loading
          //                     showToast(e.toString());
          //                   }
          //                 }
          //               },
          //             ),
          //           ),
          //         ],
          //       ),
          //     ),
          //   );
          // }
        } else if (item.assets_type == "26" || item.assets_type == "27" || item.assets_type == "28" || item.assets_type == "29" || item.assets_type == "30" || item.assets_type == "31" || item.assets_type == "32" || item.assets_type == "33" || item.assets_type == "34") {
          let operationTitle = "";
          let fundsTitle = "";
          let timeTitle = "";
          //   '26': '借款到账', //借贷
          // '27': '扣除抵押', //借贷
          // '28': '手动结算', //借贷
          // '29': '强平结算', //借贷
          // '30': '部分还款', //借贷
          // '31': '归还抵押', //借贷
          // '32': '全额还款', //借贷
          // '33': '增加抵押', //借贷
          // '34': '减少抵押', //借贷
          switch (item.assets_type) {
            case 34:
              operationTitle = this.translateTitle("借款到账");
              fundsTitle = this.translateTitle("到账金额");
              timeTitle = this.translateTitle("到账时间");
              break;
            case 33:
              operationTitle = this.translateTitle("扣除抵押");
              fundsTitle = this.translateTitle("扣除数量");
              timeTitle = this.translateTitle("扣除时间");
              break;
            case 32:
              operationTitle = this.translateTitle("抵押物还款");
              fundsTitle = this.translateTitle("归还金额");
              timeTitle = this.translateTitle("结算时间");
              break;
            case 31:
              operationTitle = this.translateTitle("强平结算");
              fundsTitle = this.translateTitle("归还金额");
              timeTitle = this.translateTitle("结算时间");
              break;
            case 30:
              operationTitle = this.translateTitle("部分还款");
              fundsTitle = this.translateTitle("还款金额");
              timeTitle = this.translateTitle("还款时间");
              break;
            case 29:
              operationTitle = this.translateTitle("归还抵押");
              fundsTitle = this.translateTitle("归还数量");
              timeTitle = this.translateTitle("归还时间");
              break;
            case 28:
              operationTitle = this.translateTitle("全额还款");
              fundsTitle = this.translateTitle("还款金额");
              timeTitle = this.translateTitle("还款时间");
              break;
            case 27:
              operationTitle = this.translateTitle("增加抵押数量");
              fundsTitle = this.translateTitle("增加数量");
              timeTitle = this.translateTitle("增加时间");
              break;
            case 26:
              operationTitle = this.translateTitle("减少抵押数量");
              fundsTitle = this.translateTitle("减少数量");
              timeTitle = this.translateTitle("减少时间");
              break;
            default:
          }
          let model = item.order_info;
          let time = this.moment(model.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let coinNumber = model.item_number;
          let coinName = this.coinData[model.item_id] ? this.coinData[model.item_id].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("操作类型");
          detailObj.content = operationTitle;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = fundsTitle;
          detailObj.content = coinNumber + ` ${coinName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = timeTitle;
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          // '借款到账', //借贷
        } else if (item.assets_type == "35") {
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.open_item_id] ? this.coinData[model.open_item_id].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.settlement_item_id] ? this.coinData[model.settlement_item_id].item_name.toUpperCase() : "";
          let config = this.staticSymbolData[model.symbol_id];
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = config && config.language[`symbol_name_${this.language}`];
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓类型");
          detailObj.content = model.open_position_type == "1" ? this.translateTitle("市价开仓") : this.translateTitle("限价开仓");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓方向");
          detailObj.content = model.open_side == "1" ? this.translateTitle("买涨") : this.translateTitle("买跌");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("杠杆倍数");
          detailObj.content = model.open_leverage + "X";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("本金");
          detailObj.content = model.bail_number + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交均价");
          detailObj.content = model.open_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交数量");
          detailObj.content = model.open_interest + ` ${fromItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交手续费");
          detailObj.content = model.open_position_fee + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //合约 开仓
        } else if (item.assets_type == "36") {
          let model = item.order_info;
          //合约 平仓
          let closeType = "";
          switch (model.close_position_type) {
            case 1:
              closeType = this.translateTitle("未平仓");
              break;
            case 2:
              closeType = this.translateTitle("用户平仓");
              break;
            case 3:
              closeType = this.translateTitle("强制平仓");
              break;
            case 4:
              closeType = this.translateTitle("止盈平仓");
              break;
            case 5:
              closeType = this.translateTitle("止损平仓");
              break;
            case 6:
              closeType = this.translateTitle("自动减仓");
              break;
            default:
          }
          let time = this.moment(model.close_position_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let fromItemName = this.coinData[model.open_item_id] ? this.coinData[model.open_item_id].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.settlement_item_id] ? this.coinData[model.settlement_item_id].item_name.toUpperCase() : "";
          let config = this.staticSymbolData[model.symbol_id];
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = config && config.language[`symbol_name_${this.language}`];
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓类型");
          detailObj.content = closeType;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓价格");
          detailObj.content = model.close_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓数量");
          detailObj.content = model.open_interest + ` ${fromItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓盈亏");
          detailObj.content = model.profit_loss + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓盈亏比");
          detailObj.content = model.profit_loss_ratio + "%";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交手续费");
          detailObj.content = model.close_position_fee + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("余额归还");
          detailObj.content = model.balance_change_number + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "37") {
          let model = item.order_info;
          let time = this.moment(model.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let stockObj = this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].symbol_name;
          let settleItemName = this.coinData[model.settlement_item_id] ? this.coinData[model.settlement_item_id].item_name.toUpperCase() : "";
          let stockRegionNameCode = [this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].region, this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].language[`symbol_name_${this.language}`], this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].symbol];
          detailObj.title = this.translateTitle("股票");
          // detailObj.content = stockObj;
          detailObj.content = stockRegionNameCode;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托方向");
          detailObj.content = model.order_type == "1" ? this.translateTitle("市价买入") : this.translateTitle("限价买入");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("买入数量");
          detailObj.content = model.volume + " " + "股";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("扣除保证金");
          detailObj.content = item.item_number + ` ${settleItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //股票 买入,扣除保证金
        } else if (item.assets_type == "38") {
          let model = item.order_info;
          let ctime = this.moment(model.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let utime = this.moment(model.update_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let stockObj = this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].symbol_name;
          let feeItemName = this.coinData[model.fee_item_id] ? this.coinData[model.fee_item_id].item_name.toUpperCase() : "";
          let settleItemName = this.coinData[model.settlement_item_id] ? this.coinData[model.settlement_item_id].item_name.toUpperCase() : "";
          let stockRegionNameCode = [this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].region, this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].language[`symbol_name_${this.language}`], this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].symbol];
          detailObj.title = this.translateTitle("股票");
          // detailObj.content = stockObj;
          detailObj.content = stockRegionNameCode;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托方向");
          detailObj.content = model.order_type == "1" ? this.translateTitle("市价买入") : this.translateTitle("限价买入");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //股票 买入
          // 1-进行中|2-待结算|9-已成交|11-撤销中|12-部分待结算|18-部分撤销|19-已撤销
          // String? tradeStatus = '';
          let tradeStatus = "";
          switch (model.order_status) {
            case 9:
              tradeStatus = this.translateTitle("完全成交");
              break;
            case 18:
              tradeStatus = this.translateTitle("部分撤销");
              break;
            case 19:
              tradeStatus = this.translateTitle("已撤销");
              break;
          }
          if (tradeStatus != "") {
            detailObj.title = this.translateTitle("成交状态");
            detailObj.content = tradeStatus;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            if (model.order_status == "19") {
              detailObj.title = this.translateTitle("撤销数量");
              detailObj.content = model.volume + " " + "股";
              detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            }
          }
          if (model.order_status != "19") {
            detailObj.title = this.translateTitle("成交数量");
            detailObj.content = model.real_volume + " " + "股";
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("成交均价");
            detailObj.content = (model.avg_price ? model.avg_price : "--") + ` ${settleItemName}`;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("成交总金额");
            detailObj.content = model.real_amount + ` ${settleItemName}`;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
            detailObj.title = this.translateTitle("成交手续费");
            detailObj.content = model.fee_number + ` ${feeItemName}`;
            detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          }
          detailObj.title = this.translateTitle("返还保证金");
          detailObj.content = item.item_number + ` ${settleItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = ctime;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = model.order_status == "19" ? this.translateTitle("撤销时间") : this.translateTitle("完成时间");
          detailObj.content = utime;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "39") {
          let model = item.order_info;
          let ctime = this.moment(model.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let stockObj = this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].symbol_name;
          let feeItemName = this.coinData[model.fee_item_id] ? this.coinData[model.fee_item_id].item_name.toUpperCase() : "";
          let settleItemName = this.coinData[model.settlement_item_id] ? this.coinData[model.settlement_item_id].item_name.toUpperCase() : "";
          let stockRegionNameCode = [this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].region, this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].language[`symbol_name_${this.language}`], this.staticStockData[model.symbol_id] && this.staticStockData[model.symbol_id].symbol];
          detailObj.title = this.translateTitle("股票");
          // detailObj.content = stockObj;
          detailObj.content = stockRegionNameCode;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("委托方向");
          detailObj.content = model.order_type == "1" ? this.translateTitle("市价卖出") : this.translateTitle("限价卖出");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("卖出价格");
          detailObj.content = model.avg_price + ` ${settleItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("卖出数量");
          detailObj.content = model.real_volume + " " + "股";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交总金额");
          detailObj.content = model.real_amount + ` ${settleItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交手续费");
          detailObj.content = model.fee_number + ` ${feeItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("余额返还");
          detailObj.content = item.item_number + ` ${settleItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = ctime;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //股票 买入
        } else if (item.assets_type == "42" || item.assets_type == "43" || item.assets_type == "44" || item.assets_type == "45" || item.assets_type == "46" || item.assets_type == "47") {
          var strs = {
            "46": this.translateTitle("发放时间"),
            "47": this.translateTitle("发放时间"),
            "42": this.translateTitle("存入时间"),
            "43": this.translateTitle("存入时间"),
            "44": this.translateTitle("取出时间"),
            "45": this.translateTitle("回款时间")
          };
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle("类型");
          detailObj.content = item.client_remark;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("存款产品");
          detailObj.content = model.remark || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = strs[item.assets_type];
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.udat_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //内部划转
        } else if (item.assets_type == "49" || item.assets_type == "50") {
          var strs = {
            "49": "购买时间",
            "50": "发放时间"
          };
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle("类型");
          detailObj.content = item.client_remark;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("云矿机产品");
          detailObj.content = model.remark || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = strs[item.assets_type];
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.ucmat_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //内部划转
        } else if (item.assets_type == "40" || item.assets_type == "41") {
          var strs = {
            "40": this.translateTitle("下单时间"),
            "41": this.translateTitle("结算时间")
          };
          let time = this.moment(item.order_info["create_time"] * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = item.assets_type == "40" ? this.translateTitle("下单金额") : this.translateTitle("结算金额");
          detailObj.content = `${item.item_number} ${coinName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = strs[item.assets_type];
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = item.order_info["game_order_id"];
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "53") {
          let model = item.order_info;
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let config = this.staticFuturesSymbolData[model.symbol_id];
          let fromItemName = this.coinData[model.open_item_id] ? this.coinData[model.open_item_id].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.settlement_item_id] ? this.coinData[model.settlement_item_id].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = config && config.language && config.language[`symbol_name_${this.language}`];
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓类型");
          detailObj.content = model.open_position_type == "1" ? this.translateTitle("市价开仓") : this.translateTitle("限价开仓");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓方向");
          detailObj.content = model.open_side == "1" ? this.translateTitle("买涨") : this.translateTitle("买跌");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("杠杆倍数");
          detailObj.content = model.open_leverage + "X";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("本金");
          detailObj.content = model.bail_number + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交均价");
          detailObj.content = model.open_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交数量");
          detailObj.content = model.open_interest + ` ${fromItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交手续费");
          detailObj.content = model.fee_consumed + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //合约 开仓
        } else if (item.assets_type == "54") {
          let model = item.order_info;

          //合约 平仓
          let closeType = "";
          switch (model.close_position_type) {
            case 1:
              closeType = this.translateTitle("未平仓");
              break;
            case 2:
              closeType = this.translateTitle("用户平仓");
              break;
            case 3:
              closeType = this.translateTitle("强制平仓");
              break;
            case 4:
              closeType = this.translateTitle("止盈平仓");
              break;
            case 5:
              closeType = this.translateTitle("止损平仓");
              break;
            case 6:
              closeType = this.translateTitle("自动减仓");
              break;
            default:
          }
          let time = this.moment(model.close_position_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let config = this.staticFuturesSymbolData[model.symbol_id];
          let fromItemName = this.coinData[model.open_item_id] ? this.coinData[model.open_item_id].item_name.toUpperCase() : "";
          let toItemName = this.coinData[model.settlement_item_id] ? this.coinData[model.settlement_item_id].item_name.toUpperCase() : "";
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = config && config.language && config.language[`symbol_name_${this.language}`];
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓类型");
          detailObj.content = closeType;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓价格");
          detailObj.content = model.close_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓数量");
          detailObj.content = model.open_interest + ` ${fromItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓盈亏");
          detailObj.content = model.profit_loss + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓盈亏比");
          detailObj.content = model.profit_loss_ratio + "%";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("成交手续费");
          detailObj.content = model.fee_consumed + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("余额归还");
          detailObj.content = model.balance_change_number + ` ${toItemName}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("下单时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "55") {
          let model = item.order_info;
          let time = this.moment(model.operate_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let followMsg = model.position_side == "1" ? this.translateTitle(`多·`) + `${model.leverage}X` : this.translateTitle(`空·`) + `${model.leverage}X`;
          let config = this.staticSymbolData[model.symbol_id];
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = [config.language[`symbol_name_${this.language}`], followMsg];
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓均价");
          detailObj.content = model.open_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓价格");
          detailObj.content = model.close_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("本金");
          detailObj.content = model.capital + " USDT";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("合约收益");
          detailObj.content = model.profit_loss + " USDT";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("上交收益");
          detailObj.content = model.profit_rebate + " USDT";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("交易员");
          detailObj.content = model.nick_name;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("上交时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          //上交分润
        } else if (item.assets_type == "56") {
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          let model = item.order_info;
          let followMsg = model.position_side == "1" ? this.translateTitle(`多·`) + `${model.leverage}X` : this.translateTitle(`空·`) + `${model.leverage}X`;
          let config = this.staticSymbolData[model.symbol_id];
          detailObj.title = this.translateTitle("交易对");
          detailObj.content = [config.language[`symbol_name_${this.language}`], followMsg];
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("开仓均价");
          detailObj.content = model.open_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("平仓价格");
          detailObj.content = model.close_price;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("本金");
          detailObj.content = model.capital + " USDT";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("合约收益");
          detailObj.content = model.profit_loss + " USDT";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("接收收益");
          detailObj.content = model.profit_rebate + " USDT";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("接收时间");
          detailObj.content = time;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("订单号");
          detailObj.content = model.order_no;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));

          //接收分润
        } else if (item.assets_type == "57") {
          let time = this.moment(item.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle("备注");
          detailObj.content = item.client_remark == this.translateTitle("退回红包") || item.client_remark == this.translateTitle("发出群红包") ? item.client_remark : this.translateTitle("接收红包");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包金额");
          detailObj.content = `${this.handleCutZero(item.order_info["total_amount"])} ${this.coinData[item.order_info["settlement_item_id"]].item_name.toUpperCase()}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包数量");
          detailObj.content = `${item.order_info["total_num"]} 个`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包类型");
          detailObj.content = item.order_info["packet_type"] == "1" ? this.translateTitle("拼手气红包") : this.translateTitle("普通红包");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("祝福语");
          detailObj.content = item.order_info["content"] || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("群名称");
          detailObj.content = item.order_info["chat_name"] || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("发送时间");
          detailObj.content = time || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "58") {
          let time = this.moment(item.order_info["grab_time"] * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle("备注");
          detailObj.content = item.client_remark == this.translateTitle("退回红包") || item.client_remark == this.translateTitle("发出群红包") ? item.client_remark : this.translateTitle("接收红包");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包金额");
          detailObj.content = `${this.handleCutZero(item.order_info["queue_amount"])} ${this.coinData[item.order_info["settlement_item_id"]] && this.coinData[item.order_info["settlement_item_id"]].item_name.toUpperCase()}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包类型");
          detailObj.content = item.order_info["packet_type"] == "1" ? this.translateTitle("拼手气红包") : this.translateTitle("普通红包");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("祝福语");
          detailObj.content = item.order_info["content"] || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("群名称");
          detailObj.content = item.order_info["chat_name"] || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("发送人");
          detailObj.content = item.order_info["nick_name"] || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("领取时间");
          detailObj.content = time || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        } else if (item.assets_type == "59") {
          let finishTime = this.moment(item.order_info["finish_time"] * 1000).format("YYYY-MM-DD HH:mm:ss");
          let create_time = this.moment(item.order_info["create_time"] * 1000).format("YYYY-MM-DD HH:mm:ss");
          detailObj.title = this.translateTitle("备注");
          detailObj.content = item.client_remark == this.translateTitle("退回红包") || item.client_remark == this.translateTitle("发出群红包") ? item.client_remark : this.translateTitle("接收红包");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包金额");
          detailObj.content = `${this.handleCutZero(item.order_info["total_amount"])} ${this.coinData[item.order_info["settlement_item_id"]].item_name.toUpperCase()}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("退回金额");
          detailObj.content = `${this.handleCutZero(item.order_info["send_back_number"])} ${this.coinData[item.order_info["settlement_item_id"]] && this.coinData[item.order_info["settlement_item_id"]].item_name.toUpperCase()}`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包数量");
          detailObj.content = `${item.order_info["total_num"]} 个`;
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("红包类型");
          detailObj.content = item.order_info["packet_type"] == "1" ? this.translateTitle("拼手气红包") : this.translateTitle("普通红包");
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("祝福语");
          detailObj.content = item.order_info["content"] || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("群名称");
          detailObj.content = item.order_info["chat_name"] || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("发送时间");
          detailObj.content = create_time || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
          detailObj.title = this.translateTitle("过期时间");
          detailObj.content = finishTime || "--";
          detailArray.push(JSON.parse(JSON.stringify(detailObj)));
        }
        item.detailArray = detailArray;
        // 根据类型组装字段结束
      });

      return list;
    },
    handleCutZero(num) {
      if (!num) return 0;
      //拷贝一份 返回去掉零的新串
      let newstr = num;
      //循环变量 小数部分长度
      // console.log(num.indexOf('.') - 1);
      let leng = num.length - num.indexOf(".") - 1;
      //判断是否有效数
      if (num.indexOf(".") > -1) {
        //循环小数部分
        for (let i = leng; i > 0; i--) {
          //如果newstr末尾有0
          if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
            let k = newstr.lastIndexOf("0");
            //如果小数点后只有一个0 去掉小数点
            if (newstr.charAt(k - 1) == ".") {
              return newstr.substring(0, k - 1);
            } else {
              //否则 去掉一个0
              newstr = newstr.substring(0, k);
            }
          } else {
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return num;
    }
  }
};
exports.default = _default;