"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _vuex = require("vuex");
var _markets = require("@/model/markets/markets");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {
    NoData: _NoData.default
  },
  props: {
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data() {
    return {
      ws: null,
      tradePair: [],
      tradePairArray: [],
      selectSymbol: "",
      symbol: "",
      // tabList: [
      //   {
      //     value: "self",
      //     label: this.translateTitle("自选"),
      //   },
      //   {
      //     value: "usdt",
      //     label: "USDT",
      //   },
      //   {
      //     value: "btc",
      //     label: "BTC",
      //   },
      //   {
      //     value: "busd",
      //     label: "BUSD",
      //   },
      // ],
      currIndex: "usdt",
      selfOptionsList: []
    };
  },
  created() {
    let tempSymbol = this.$route.params.symbol || "btc_usdt";
    this.symbol = tempSymbol;
    this.getOptionList();
    // this.getTradePairList();
    const [coinFrom, coinTo] = tempSymbol.split("_");
    if (coinTo.toLowerCase() == "btc") {
      this.currIndex = "btc";
    } else if (coinTo.toLowerCase() == "busd") {
      this.currIndex = "busd";
    } else {
      this.currIndex = "usdt";
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    tableRowClassName({
      row,
      rowIndex
    }) {
      let trade_pair = this.symbol.toLowerCase();
      if (row.info.name.toLowerCase() == trade_pair) {
        return "warning-row";
      } else {
        return "";
      }
    },
    getRowKeys(row) {
      return row.symbol_id;
    },
    addOption(row, type) {
      if (this.userInfo) {
        let params = {
          symbol_id: row.symbol_id,
          option: type,
          swap_type: 2
        };
        (0, _markets.apiAddOption)(params).then(res => {
          if (res.status == 200) {
            this.$emit("onAddOption");
            this.getOptionList();
            this.$message({
              message: params.option == 1 ? this.translateTitle(`添加自选成功`) : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success"
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    onSymbolChange(item, column) {
      if (column != undefined && column.label == "币种") {
        return;
      }
      let trade_pair = item.info.name.toLowerCase();
      // let swap_symbol_id = item.swap_symbol_id;
      this.symbol = trade_pair;
      // this.$router.push(`/exchange/${trade_pair}/${this.language}`);
      this.$router.push(this.routeSplicing(this.language, `exchange/${trade_pair}`));
      this.$emit("on-symbol-change", item);
      let params = {
        row: item
      };
      this.tableRowClassName(params);
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    async getOptionList() {
      let {
        userInfo
      } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      (0, _markets.apiGetOptionList)().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    compareOrderBy(property) {
      return function (a, b) {
        if (a && b && a["info"] && b["info"] && a["info"][property] && b["info"][property]) {
          let value1 = a["info"][property];
          let value2 = b["info"][property];
          if (value1 - value2 != 0) {
            return value1 - value2;
          } else {
            let temp1 = a["from_symbol"];
            let temp2 = b["from_symbol"];
            if (temp1 < temp2) {
              // 按某种排序标准进行比较, a 小于 b
              return -1;
            }
            if (temp1 > temp2) {
              return 1;
            }
            return 0;
          }
        } else {
          return 0;
        }
      };
    }
  },
  watch: {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    }),
    tableLoading() {
      let val = 1;
      if (this.marketList.length > 0) {
        val = 0;
      } else {
        val = 1;
      }
      return val;
    },
    showList() {
      let tempArr = [];
      tempArr = this.marketList.filter(ele => {
        if (this.currIndex == "self") {
          if (this.selectSymbol != "") {
            if (ele.from_symbol.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}/${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 && this.selfOptionsList.indexOf(ele.symbol_id.toString() > -1)) {
              return true;
            }
          } else {
            return this.selfOptionsList.indexOf(ele.symbol_id.toString()) > -1;
          }
        } else if (this.currIndex == "usdt") {
          if (this.selectSymbol != "") {
            if (ele.from_symbol.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}/${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1) {
              return true;
            }
          } else {
            if (ele.info) {
              return ele.info.coin_to == "10007";
            }
          }
        } else if (this.currIndex == "busd") {
          if (this.selectSymbol != "") {
            if (ele.from_symbol.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}/${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1) {
              return true;
            }
          } else {
            if (ele.info) {
              return ele.info.coin_to == "10123";
            }
          }
        } else {
          if (this.selectSymbol != "") {
            if (ele.from_symbol.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1 || `${ele.from_symbol}/${ele.to_symbol}`.toLowerCase().indexOf(this.selectSymbol.trim().toLowerCase()) != -1) {
              return true;
            }
          } else {
            if (ele.info) {
              return ele.info.coin_to == "10005";
            }
          }
        }
      });
      tempArr.sort(this.compareOrderBy("order_by"));
      return tempArr;
    },
    tabList() {
      let tempList = [{
        value: "self",
        label: this.translateTitle("自选")
      }, {
        value: "usdt",
        label: "USDT"
      }, {
        value: "btc",
        label: "BTC"
      }];
      let tempArr = [];
      this.marketList.forEach(ele => {
        tempArr.push(ele.to_symbol);
      });
      if (tempArr.indexOf("BUSD") >= 0) {
        tempList = [{
          value: "self",
          label: this.translateTitle("自选")
        }, {
          value: "usdt",
          label: "USDT"
        }, {
          value: "busd",
          label: "BUSD"
        }, {
          value: "btc",
          label: "BTC"
        }];
      } else {
        tempList = [{
          value: "self",
          label: this.translateTitle("自选")
        }, {
          value: "usdt",
          label: "USDT"
        }, {
          value: "btc",
          label: "BTC"
        }];
      }
      return tempList;
    }
  }
};
exports.default = _default;