"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _i18n = require("@/assets/js/i18n");
var _advantages = _interopRequireDefault(require("@/assets/img/aboutus-8v/advantages.png"));
var _advantages2 = _interopRequireDefault(require("@/assets/img/aboutus-8v/advantages1.png"));
var _advantages3 = _interopRequireDefault(require("@/assets/img/aboutus-8v/advantages2.png"));
var _advantages4 = _interopRequireDefault(require("@/assets/img/aboutus-8v/advantages3.png"));
var _advantages5 = _interopRequireDefault(require("@/assets/img/aboutus-8v/advantages4.png"));
var _advantages6 = _interopRequireDefault(require("@/assets/img/aboutus-8v/advantages5.png"));
var _advantages7 = _interopRequireDefault(require("@/assets/img/aboutus-8v/advantages6.png"));
var _banner = _interopRequireDefault(require("@/assets/img/aboutus-8v/banner.png"));
var _ben = _interopRequireDefault(require("@/assets/img/aboutus-8v/ben.png"));
var _bottomLogo = _interopRequireDefault(require("@/assets/img/aboutus-8v/bottom-logo.png"));
var _bottom1N = _interopRequireDefault(require("@/assets/img/aboutus-8v/bottom1-n.png"));
var _bottom2N = _interopRequireDefault(require("@/assets/img/aboutus-8v/bottom2-n.png"));
var _bottom3N = _interopRequireDefault(require("@/assets/img/aboutus-8v/bottom3-n.png"));
var _customer = _interopRequireDefault(require("@/assets/img/aboutus-8v/customer.png"));
var _logoSlogan = _interopRequireDefault(require("@/assets/img/aboutus-8v/logo-slogan.png"));
var _nick = _interopRequireDefault(require("@/assets/img/aboutus-8v/nick.png"));
var _roland = _interopRequireDefault(require("@/assets/img/aboutus-8v/roland.png"));
var _userCentric = _interopRequireDefault(require("@/assets/img/aboutus-8v/user-centric.png"));
var _vision = _interopRequireDefault(require("@/assets/img/aboutus-8v/vision.png"));
var _vuex = require("vuex");
var _stationSetting = require("@/assets/js/stationSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import bottom1 from "@/assets/img/aboutus-8v/bottom1.png";
// import bottom2 from "@/assets/img/aboutus-8v/bottom2.png";
// import bottom3 from "@/assets/img/aboutus-8v/bottom3.png";
// import bottom4 from "@/assets/img/aboutus-8v/bottom4.png";
var _default = {
  components: {},
  data() {
    return {
      advantages1: _advantages2.default,
      advantages2: _advantages3.default,
      advantages3: _advantages4.default,
      advantages4: _advantages5.default,
      advantages5: _advantages6.default,
      advantages6: _advantages7.default,
      ben: _ben.default,
      nick: _nick.default,
      roland: _roland.default,
      // bottom1,
      // bottom2,
      // bottom3,
      // bottom4,
      bottom1n: _bottom1N.default,
      bottom2n: _bottom2N.default,
      bottom3n: _bottom3N.default,
      isShowIntroduction: false,
      isShowSlogan: false,
      isShowCentric: false,
      isShowItem: 0,
      eco_active: 1,
      currItem: {},
      items: {
        1: {
          name: "Matt",
          job: "Chief Architect",
          zh_CN: "Matt擁有在英國深耕15年的產品與技術架構經驗，並參與了超過50個項目的上線與發佈．在金融及遊戲領域，曾參與匯豐、Tesco Bank、CE、SuperCell的技術架構與產品設計。在加密貨幣及區塊鏈領域，曾任CoinDesk移動端技術負責人並是Qtum量子鏈項目參與者。Matt作為8V交易所技術與安全架構負責人，為平台打下了堅實的基礎。",
          en: "With 15 years of experience in technical architecture, Matt has brought his wizardry to some of the greatest online projects in the UK. From HSBC to Tesco Bank, and even contributing to the likes of SuperCell, he is a master of his craft. Heading up CoinDesk's mobile terminal technology and playing a key role in the enigmatic Qtum quantum chain project, Matt now oversees the technology and security of 8V Exchange. His mastery of the arcane and digital realm is unmatched, making him the ideal guardian of this powerful exchange.",
          link: "https://blinq.me/SuD5OxwID6XN",
          img: require("@/assets/img/aboutus-8v/otf1.png")
        },
        2: {
          name: "Jasper",
          job: "Team Advocate",
          zh_CN: "擁有超過20年以上從Web2到Web3的完整相關經歷，Jasper是遊戲、軟體SaaS與網際網路產業的老兵，深刻了解如何以敏捷管理跨部門團隊快速滿足多元用戶需求，也從零開始一手打造了8V交易所的完整平台架構與用戶體驗。面對加密貨幣的波動週期，Jasper將率領團隊以穩健的策略，重構產品與服務的體質，迎向另一個加密春天。",
          en: "With over two decades of experience in the gaming, e-sports, and tech industries, Jasper is a seasoned veteran of the online world. As the architect of 8V Exchange's software infrastructure and user experience, Jasper leads the team with unwavering determination towards creating the ultimate user-friendly platform. With a steadfast focus on optimizing product features and offering top-notch service, Jasper navigates the ever-changing landscape of the crypto world with ease, always leading the team to success.",
          link: "https://blinq.me/yuJaRaYqu9T0",
          img: require("@/assets/img/aboutus-8v/otf2.png")
        },
        3: {
          name: "Jack",
          job: "Platform Alchemist",
          zh_CN: "Jack在金融科技、資產管理、電子支付、代幣生成活動(TGE)以及去中心化技術(DLT)領域擁有超過13年的產品開發經驗。作為8V交易所的創新策略顧問，Jack主導平台的產品發展策略，與技術合作開發並測試平台新功能，在區塊鏈架構的基礎上，以「用戶為中心」，細細打磨層層把關，淬鍊出最人性化的交易所。",
          en: "Jack is a seasoned alchemist of the 8V Exchange, using his mastery of the arcane arts to develop and test new spells for the platform. With over 13 years of experience in the art of fintech, asset management, and the creation of enchanted tokens, Jack is a valuable member of the 8V team. As the advisor of innovation strategy, Jack works closely with the wizards of technology to brew the most potent elixirs and tonics for the users of the exchange. Jack's ultimate goal is to create a user-friendly realm of magic through a never-ending pursuit of product innovation.",
          link: "https://blinq.me/nRvGdFmS3eew",
          img: require("@/assets/img/aboutus-8v/otf3.png")
        },
        4: {
          name: "Ann",
          job: "Marketing Wiz",
          zh_CN: "Ann擁有超過5年的品牌行銷、公共關係及專案管理經驗，並於2019年取得CMI策略管理及領導認證。Ann統籌8V交易所的品牌策略及行銷專案，致力於透過社群媒體等多元數位渠道推廣8V交易所，並與設計及運營等進行跨部門協作，以「創意+創新」為核心，打造出獨樹一幟的品牌風格，提升8V品牌附加價值．",
          en: "Ann, the Marketing Mage, wields her expertise in branding, PR, and project management to cast powerful spells and promote the image of 8V Exchange. Armed with a CMI Diploma in Strategic Management and Leadership, she collaborates with different departments to spread 8V's brand through multiple digital channels. Her belief in creativity and innovation makes her a fearsome force in enhancing 8V's brand value, leaving a lasting impression in the minds of all who behold it.",
          link: "https://blinq.me/gMuzImkFl6Bh",
          img: require("@/assets/img/aboutus-8v/otf4.png")
        },
        5: {
          name: "David",
          job: "OPS Ninja",
          zh_CN: "David擁有多元化的工程背景和超過10年的區塊鏈經驗。 他領導8V交易所的運營部門，利用其在前端開發、營銷運營、項目啟動以及搜索引擎優化方面的專業知識來提高效率並取得成果。 此外，David精通多國語言並擁有政治學學位，在技術及人文領域都取得傲人成就，賦予了他在運營交易所時別具一格的優勢。",
          en: "David is a versatile adventurer with a diverse background in engineering and over 10 years of experience in blockchain. He leads the operations at 8V Exchange, using his expertise in front-end development, marketing ops, project launch and operations, and search engine optimization to drive efficiency and achieve results. David's multilingualism and degree in Political Science make him a valuable ally, who, with his mastery of operations, strikes fear into the hearts of enemies and earns the respect of allies.",
          link: "https://blinq.me/aJ65Pg8xdzkZ",
          img: require("@/assets/img/aboutus-8v/otf5.png")
        }
      },
      dialogVisible: false
    };
  },
  async created() {},
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    doShowIntroduction() {
      this.isShowIntroduction = !this.isShowIntroduction;
    },
    // doShowItem(num) {
    //   if (num === this.isShowItem) {
    //     this.isShowItem = 0;
    //   } else {
    //     this.isShowItem = num;
    //   }
    // },
    doShowSlogan() {
      this.isShowSlogan = !this.isShowSlogan;
    },
    doShowCentric() {
      this.isShowCentric = !this.isShowCentric;
    },
    chooseEco(num) {
      this.eco_active = num;
    }
  }
};
exports.default = _default;