<template>
  <footer class="web-footer">
    <div class="footer-top">
      <div class="footer-top-column">
        <div class="logo">
          <div class="logo-icon"></div>
          <div class="logo-text">
            <p class="logo-title bold">MaskEX</p>
            <p class="logo-subtitle">Your wallet, Your way!</p>
          </div>
        </div>
      </div>
      <div class="footer-top-column">
        <h3 class="column-title green-blue">About</h3>
        <ul class="column-list">
          <li><a href="#">About Us</a></li>
          <li><a href="#">Law Enforcement</a></li>
          <li><a href="#">Careers</a></li>
          <li><a href="#">Affiliate Program</a></li>
          <li><a href="#">Brokers Program</a></li>
          <li><a href="#">Feedback</a></li>
        </ul>
      </div>
      <div class="footer-top-column">
        <h3 class="column-title green-blue">Terms</h3>
        <ul class="column-list">
          <li><a href="#">Service Agreement</a></li>
          <li><a href="#">Privacy Agreement</a></li>
          <li><a href="#">Disclaimer</a></li>
          <li><a href="#">Risk Notice/Risk Disclosure</a></li>
        </ul>
      </div>
      <div class="footer-top-column">
        <h3 class="column-title green-blue">Support</h3>
        <ul class="column-list">
          <li><a href="#">FAQ</a></li>
          <li><a href="#">Rate standard</a></li>
          <li><a href="#">Information Center</a></li>
          <li><a href="#">Announcement Center</a></li>
          <li><a href="#">Beginner's Guide</a></li>
        </ul>
      </div>
      <div class="footer-top-column">
        <div class="social-icons-row">
          <!-- Add social icons here -->
        </div>
        <div class="social-icons-row">
          <!-- Add more social icons here -->
        </div>
        <div class="compliance-contact">
          <p class="compliance-label pale-gray">Compliance Contact</p>
          <a class="compliance-email white" href="mailto:compliance@example.com"
            >compliance@example.com</a
          >
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p class="copyright grayish">
        @2022. MaskEX All Rights Reserved FINTRAC Registered
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  // Footer component logic
};
</script>

<style scoped>
/* Footer styles */
.web-footer {
  width: 100%;
  height: 477px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: dark;
}

.footer-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.footer-top-column {
  display: flex;
  flex-direction: column;
}

.logo {
  display: flex;
  align-items: flex-start;
}

.logo-icon {
  /* Add logo icon styles */
}

.logo-text {
  margin-left: 10px;
}

.logo-title {
  font-size: 24px;
}

.logo-subtitle {
  font-size: 14px;
  color: gray;
}

.column-title {
  font-size: 18px;
}

.column-list {
  list-style: none;
  padding: 0;
}

.column-list li {
  margin-bottom: 5px;
}

.column-list a {
  text-decoration: none;
  color: black;
}

.social-icons-row {
  display: flex;
}

.compliance-label {
  font-size: 14px;
  color: palegray;
}

.compliance-email {
  text-decoration: none;
  color: white;
}

.footer-bottom {
  text-align: center;
}

.copyight {
  font-size: 12px;
}
</style>
