"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _stationSetting = require("@/assets/js/stationSetting");
var _markets = require("@/model/markets/markets");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _vuex = require("vuex");
var _keymap = require("@/assets/js/keymap.js");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: {
    swap_symbol_id: {
      type: [String, Number],
      default: function () {
        return 22;
      }
    },
    tradePairArray: {
      type: Array,
      default: function () {
        return [];
      }
    },
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    from_symbol: {
      type: [String, Number]
    },
    to_symbol: {
      type: [String, Number]
    }
  },
  data() {
    return {
      VUE_UNION_STATION: _stationSetting.VUE_UNION_STATION,
      systemCurrencyMaps: _keymap.systemCurrencyMaps,
      coinFrom: "--",
      coinTo: "--",
      colorClass: "",
      isMaskex: false,
      selfOptionsList: []
    };
  },
  async created() {
    if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.isMaskex = true;
    }
    this.getOptionList();
    this.getCoin();
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    async getOptionList() {
      let {
        userInfo
      } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      (0, _markets.apiGetOptionList)().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    addOption(type) {
      if (this.userInfo) {
        let params = {
          symbol_id: this.swap_symbol_id,
          option: type,
          swap_type: 2
        };
        (0, _markets.apiAddOption)(params).then(res => {
          if (res.status == 200) {
            this.getOptionList();
            this.$emit("onAddOption");
            this.$message({
              message: params.option == 1 ? this.translateTitle(`添加自选成功`) : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success"
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    getCoin() {
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        tempSymbol = "btc_usdt";
      }
      let trade_pair = tempSymbol.replace("_", "/").toUpperCase();
      if (this.tradePairArray.indexOf(trade_pair) == -1) {
        tempSymbol = "btc_usdt";
      }
      let symbolPair = tempSymbol.split("_");
      this.coinFrom = symbolPair[0].toUpperCase();
      this.coinTo = symbolPair[1].toUpperCase();
    }
  },
  watch: {
    swap_symbol_id() {
      this.getCoin();
    },
    marketInfoShow(newValue, oldValue) {
      if (Number(newValue.last) - Number(oldValue.last) > 0) {
        this.colorClass = "green";
      } else if (Number(newValue.last) - Number(oldValue.last) < 0) {
        this.colorClass = "red";
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    marketInfoShow() {
      // this.pageLoading = true;
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      // this.pageLoading = false;
      return marketInfo;
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.price_change_percent < 0) {
        className = "red";
      }
      return className;
    }
  }
};
exports.default = _default;