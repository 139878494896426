"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiAddOption = apiAddOption;
exports.apiGetCoinInfo = apiGetCoinInfo;
exports.apiGetDeliveryFundingRate = apiGetDeliveryFundingRate;
exports.apiGetFundingRate = apiGetFundingRate;
exports.apiGetOptionList = apiGetOptionList;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER,VUE_APP_APP } = process.env

//获取币种详情
function apiGetCoinInfo(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/App/Product/getCoinInfo`,
    data
  });
}
//增加行情自选
function apiAddOption(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/Common/addOption`,
    data
  });
}
//自选
function apiGetOptionList(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/Common/getOptionList`,
    data
  });
}
//合约获取资金费率
function apiGetFundingRate(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/Contract/getFundingRate`,
    data
  });
}
//币本位合约获取资金费率
function apiGetDeliveryFundingRate(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/Futures/Trace/getFundingRate`,
    data
  });
}