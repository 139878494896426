"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.error.cause.js");
var _DBind = _interopRequireDefault(require("../DBind"));
var _DGoogle = _interopRequireDefault(require("../DGoogle"));
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _message = require("@/components/dialog/message");
var _account = require("@/model/user/account");
var _index = require("@/model/assets/index");
var _getAssetsCoin = require("../../common/getAssetsCoin");
var _accuracy = require("@/assets/js/accuracy");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _settings = require("@/model/settings");
var _SocketDispatcher = _interopRequireDefault(require("@/assets/js/SocketDispatcher"));
var _i18n = require("@/assets/js/i18n");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _vuex = require("vuex");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import GButton from "../Button";
var _default = {
  components: {
    DBind: _DBind.default,
    DGoogle: _DGoogle.default,
    InputNumber: _InputNumber.default
  },
  props: ["data", "assetsList", "exchangeLimit"],
  data() {
    return {
      isAddAddress: false,
      // 是否打开提币地址
      safetyVerification: false,
      delAddressLoading: false,
      delAddressDialog: false,
      addressList: {},
      // 地址列表
      withdrawAddress: "",
      // 提币地址添加时
      remark: "",
      // 备注
      ua_id: "",
      // 备注
      formData: {
        address: "",
        // 提币地址
        withdrawNum: "" // 提币数量
      },

      formRules: {
        // 表单规则
        address: [{
          required: true,
          message: this.translateTitle("请选择提币地址"),
          trigger: ["blur", "change"]
        }],
        withdrawNum: [{
          validator: this.withdrawNumBlur,
          trigger: "blur"
        }, {
          validator: this.withdrawNumBlur,
          trigger: "change"
        }, {
          max: 20,
          message: this.translateTitle("提币数量长度最大20位"),
          trigger: "blur"
        }]
      },
      chainDataMap: {},
      coinInfo: [],
      chainMap: {},
      currChainValue: "ERC20",
      currChainValueV2: "ERC20",
      multi_chain_status: 1,
      verification: false,
      // 验证弹窗,
      mobileStatus: false,
      // 是否手机开启验证
      emailStatus: false,
      // 是否邮箱开启验证
      googleStatus: false,
      // 是否谷歌开启验证
      confirmLoading: false,
      fee_type: 1,
      // 提币手续费 类型  1:固定金额 2:按提币数量百分比收取
      fee_rate: 0,
      // 提币手续费百分比
      rate_out: "",
      // 提币手续费
      rate_out_log: "" // 提币手续费记录
    };
  },

  async created() {
    this.currChainValue = this.data.itemDetail.default_chain.toUpperCase();
    await this.getUserInfo();
    await this.getStaticChainInfo();
    await this.getAddressList();
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    onDel(item) {
      this.ua_id = item.ua_id;
      this.delAddressDialog = true;
    },
    onDelAddress() {
      this.delAddressLoading = true;
      const req = {
        ua_id: this.ua_id
      };
      (0, _index.apiDelAddress)(req).then(res => {
        if (res.status == 200) {
          (0, _message.showMessage)({
            message: this.translateTitle(`删除成功`),
            type: "success"
          });
          this.delAddressLoading = false;
          this.delAddressDialog = false;
          this.getAddressList();
        }
      });
    },
    async getStaticChainInfo() {
      this.coinInfo = [];
      await (0, _settings.apiGetStaticTableInfo)(56).then(({
        data
      }) => {
        this.chainMap = data;
      });
      // 获取29静态表 获取coin 费率 chain名称 item_id信息
      await (0, _settings.apiGetStaticTableInfo)(57).then(({
        data
      }) => {
        for (const key in data) {
          for (let item in this.chainMap) {
            if (data[key].chain.toUpperCase() == this.chainMap[item].chain_tag.toUpperCase()) {
              this.coinInfo.push({
                label: data[key].chain.toUpperCase(),
                value: data[key].coin_id,
                fee_type: data[key].fee_type,
                fee_rate: data[key].fee_rate,
                rate_out: data[key].fee,
                is_default: data[key].is_default,
                chain_status: this.chainMap[item].status,
                status: data[key].status,
                minout: data[key].minout,
                maxout: data[key].maxout
              });
            }
          }
          if (this.currChainValue.toLowerCase() == data[key].chain && this.data.itemDetail.coin_id == data[key].coin_id) {
            this.fee_type = data[key].fee_type;
            this.fee_rate = data[key].fee_rate;
            this.rate_out = data[key].fee;
            this.rate_out_log = data[key].fee;
          }
        }
      });
    },
    onChainChange(item) {
      if (item.status == 1 && item.chain_status == 1) {
        this.chainDataMap = item;
        this.fee_type = item.fee_type;
        this.fee_rate = item.fee_rate;
        this.rate_out = item.rate_out;
        this.rate_out_log = item.rate_out;
        this.currChainValue = item.label;
        this.$emit("getChainData", item);
        this.formData.address = "";
        this.addressList = {};
        _SocketDispatcher.default.dispatchEvent("onChainChangeDispatch", item.label);
        this.$refs.changePwdForm.resetFields();
        this.getAddressList();
      } else {
        return;
      }
    },
    onChainChangeV2(item) {
      if (item.status == 1 && item.chain_status == 1) {
        this.currChainValueV2 = item.label;
        this.withdrawAddress = "";
        this.remark = "";
      } else {
        return;
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let {
        accountInfo
      } = await (0, _account.getUserInfo)();
      this.accountInfo = accountInfo;
    },
    onFilterAddress(val) {
      this.withdrawAddress = val.replace(/[^a-zA-Z\d:]/g, "");
    },
    // 获取地址列表
    async getAddressList() {
      const {
        item_id
      } = this.data;
      // 获取当前选中币种下的地址列表
      if (item_id) {
        let params = {
          item_id: item_id
        };
        if (this.data.itemDetail.multi_chain_status) {
          params.chain_tag = this.currChainValue.toLowerCase();
        }
        const {
          status,
          msg,
          data
        } = await (0, _index.getAddressList)(params);
        if (status === 200) {
          this.addressList = data;
        } else {
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
        }
      }
    },
    // withdrawNumBlur
    withdrawNumBlur(rule, value, callback) {
      let {
        itemDetail: {
          name
        }
      } = this.data;
      let {
        minout,
        maxout
      } = this.chainDataMap;
      let {
        list
      } = this.assetsList;
      let {
        coinOutSingleNum
      } = this.exchangeLimit;
      value = Number(value);
      minout = Number(minout);
      coinOutSingleNum = Number(coinOutSingleNum);
      if (value === "" | !value) {
        callback(new Error(this.translateTitle("请输入提币数量")));
        // 输入值 小于 可用数量
      } else if (value < minout) {
        callback(new Error(this.translateTitle("单笔最少提币数量为") + `${minout} ${name.toUpperCase()}`));
      } else if (value > coinOutSingleNum) {
        callback(new Error(this.translateTitle("单笔最大提币数量为") + `${coinOutSingleNum} ${name.toUpperCase()}`));
      } else if (value < coinOutSingleNum && value > list[name].over) {
        callback(new Error(this.translateTitle("您当前可用提币数量为") + `${list[name].over} ${name.toUpperCase()}`));
      } else {
        callback();
      }
    },
    // 点击可用
    availableClick() {
      let {
        itemDetail: {
          name
        }
      } = this.data;
      let {
        list
      } = this.assetsList;
      this.formData.withdrawNum = list[name].over;
    },
    onConfirmV2() {
      this.$router.push(this.routeSplicing(this.language, "ucenter/safe-setting"));
    },
    // 打开二次验证
    openVerification() {
      this.$refs.changePwdForm.validate(valid => {
        if (valid) {
          this.$store.dispatch("user/getUserInfo").then(res => {
            if (res.authInfo.is_bind_security == 1 && res.authInfo.open_google == 0) {
              this.safetyVerification = true;
            } else {
              this.mobileStatus = res.authInfo.open_mobile === 0 ? false : true;
              this.emailStatus = res.authInfo.open_email === 0 ? false : true;
              this.googleStatus = res.authInfo.open_google === 0 ? false : true;
              this.verification = true;
            }
          });
        }
      });
    },
    selectChange(val) {
      if (typeof val === "boolean") {
        this.isAddAddress = true;
        this.currChainValueV2 = JSON.parse(JSON.stringify(this.currChainValue));
      } else {
        this.formData.address = val;
        this.getUserInfoByAddress(val);
      }
    },
    async getUserInfoByAddress(address) {
      const params = {
        item_id: this.data.item_id,
        address
      };
      const {
        status,
        data
      } = await (0, _index.apiGetUserInfoByAddress)(params);
      if (status == 200) {
        if (data.type != "0") {
          //内部地址
          this.fee_type = 1;
          this.fee_rate = 0;
          this.rate_out = 0;
        } else {
          this.rate_out = this.rate_out_log;
        }
      }
    },
    onCancel() {
      this.isAddAddress = false;
      this.verification = false;
    },
    onConfirmAddress() {
      let {
        item_id
      } = this.data;
      let {
        withdrawAddress,
        remark
      } = this;
      if (/^\s*$/.test(withdrawAddress)) {
        (0, _message.showMessage)({
          message: this.translateTitle("地址不能为空")
        });
        return;
      }
      if (/^\s*$/.test(remark)) {
        (0, _message.showMessage)({
          message: this.translateTitle("备注不能为空")
        });
        return;
      }
      let params = {
        item_id,
        address: withdrawAddress,
        remark
      };
      // if (this.data.itemDetail.multi_chain_status) {
      // }
      params.chain_tag = this.currChainValueV2.toLowerCase();
      (0, _index.addAddress)(params).then(({
        status,
        data,
        msg
      }) => {
        if (status === 200) {
          this.getAddressList();
          (0, _message.showMessage)({
            message: this.translateTitle(`提币地址添加成功`),
            type: "success"
          });
          this.isAddAddress = false;
          this.withdrawAddress = "";
          this.remark = "";
        } else {
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
        }
      });
    },
    // 确认
    async onConfirm() {
      let param = {};
      let emailData, mobileData, googleData;
      let {
        formData: {
          withdrawNum,
          address
        },
        data: {
          item_id
        }
      } = this;
      if (this.$refs.email_ref) {
        emailData = this.$refs.email_ref.submitForm();
        if (emailData) {
          param = Object.assign(param, {
            ...emailData
          });
          delete param.email;
        }
      } else {
        emailData = true;
      }
      if (this.$refs.mobile_ref) {
        mobileData = this.$refs.mobile_ref.submitForm();
        if (mobileData) {
          param = Object.assign(param, {
            ...mobileData
          });
          delete param.mobile;
        }
      } else {
        mobileData = true;
      }
      if (this.$refs.google_ref) {
        googleData = this.$refs.google_ref.submitForm();
        if (googleData) {
          param = Object.assign(param, {
            ...googleData
          });
        }
      } else {
        googleData = true;
      }

      // 发送请求，提币开始
      if (emailData && mobileData && googleData) {
        param.address = address;
        param.number = withdrawNum;
        param.item_id = item_id;
        param.chain_tag = this.currChainValue.toLowerCase(); // chain_tag改为必传 lars
        // if (this.data.itemDetail.multi_chain_status) {
        //   param.chain_tag = this.currChainValue.toLowerCase();
        // }
        this.confirmLoading = true;
        let {
          msg,
          status
        } = await (0, _index.transfer)(param);
        if (status == 200) {
          this.confirmLoading = false;
          (0, _message.showMessage)({
            message: this.translateTitle(`提币成功`),
            type: "success"
          });
          this.$emit("withdrawSuccess", await (0, _getAssetsCoin.getAssetsData)());
          this.formData.withdrawNum = "";
          this.verification = false;
          // this.$refs.changePwdForm.resetFields();
          // this.formData.withdrawNum = '';
        } else {
          this.confirmLoading = false;
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
        }
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    }),
    coinInfoComputed() {
      let tempObj = [];
      for (const key in this.coinInfo) {
        if (this.data.itemDetail.coin_id == this.coinInfo[key].value) {
          tempObj.push({
            value: this.coinInfo[key].value,
            label: this.coinInfo[key].label,
            fee_type: this.coinInfo[key].fee_type,
            fee_rate: this.coinInfo[key].fee_rate,
            rate_out: this.coinInfo[key].rate_out,
            status: this.coinInfo[key].status,
            chain_status: this.coinInfo[key].chain_status,
            minout: this.coinInfo[key].minout,
            maxout: this.coinInfo[key].maxout
          });
        }
      }
      tempObj.forEach(ele => {
        if (ele.label == this.currChainValue.toUpperCase()) {
          this.$emit("getChainData", ele);
          this.chainDataMap = ele;
        }
      });
      let erc20 = {};
      let trc20 = {};
      tempObj.forEach(ele => {
        if (ele.label === "ERC20") erc20 = ele;
        if (ele.label === "TRC20") trc20 = ele;
      });
      let newtempObj = tempObj.filter(ele => {
        return ele.label != "ERC20" && ele.label != "TRC20";
      });
      if (JSON.stringify(erc20) != "{}") {
        newtempObj.push(erc20);
      }
      if (JSON.stringify(trc20) != "{}") {
        newtempObj.unshift(trc20);
      }
      return newtempObj;
    },
    coinOutNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let {
          coinOutNum
        } = this.exchangeLimit;
        return coinOutNum;
        // return accuracy(coinOutNum, 2);
      } else {
        return "--";
      }
    },
    coinOutSingleNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let {
          coinOutSingleNum
        } = this.exchangeLimit;
        return coinOutSingleNum;
      } else {
        return "--";
      }
    },
    limitNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let {
          limitNum
        } = this.exchangeLimit;
        // return accuracy(limitNum, 2);
        return limitNum;
      } else {
        return "--";
      }
    },
    arrivalAccount() {
      let {
        formData: {
          withdrawNum
        },
        data: {
          itemDetail
        },
        rate_out
      } = this;
      let Num = new _bignumber.default(withdrawNum);
      if (Number(rate_out)) {
        return Number(withdrawNum) - Number(rate_out) > 0 ? (0, _accuracy.accuracy)(Num.minus(rate_out), itemDetail.num_precision) : "0" + " " + itemDetail.capital_name;
      } else {
        return Number(withdrawNum) - Number(rate_out) > 0 ? (0, _accuracy.accuracy)(Num, itemDetail.num_precision) : "0" + " " + itemDetail.capital_name;
      }
    }
  },
  watch: {
    data: {
      handler(newVal, oldVal) {
        this.getStaticChainInfo();
        this.currChainValue = this.data.itemDetail.default_chain.toUpperCase();
        this.currChainValueV2 = this.data.itemDetail.default_chain.toUpperCase();
        this.getAddressList();
        this.formData.address = "";
      },
      deep: true,
      immediate: false
    },
    isAddAddress(val) {
      if (!val) {
        this.withdrawAddress = "";
        this.remark = "";
      }
    }
  }
};
exports.default = _default;