"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _stockKline = _interopRequireDefault(require("./components/stock-kline.vue"));
var _stockDeal = _interopRequireDefault(require("./components/stock-deal.vue"));
var _stockMarket = _interopRequireDefault(require("./components/stock-market.vue"));
var _stockTrade = _interopRequireDefault(require("./components/stock-trade.vue"));
var _stockPanel = _interopRequireDefault(require("./components/stock-panel.vue"));
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _vuex = require("vuex");
var _index = _interopRequireDefault(require("@/store/index"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _stockHistoryOrder = _interopRequireDefault(require("./components/stock-history-order.vue"));
var _order = require("@/model/contractExchange/order.js");
var _index2 = require("@/model/contractExchange/index");
var _settings = require("@/model/settings");
var _message = require("@/components/dialog/message");
var _vueEcharts = _interopRequireDefault(require("vue-echarts"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _stationSetting = require("@/assets/js/stationSetting");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import Depth from "./components/stock-depth.vue";
//FIXME
//FIXME
var _default = {
  mixins: [_commonMixin.default],
  name: "StockExchange",
  components: {
    Deal: _stockDeal.default,
    Kline: _stockKline.default,
    // Depth,
    Market: _stockMarket.default,
    Panel: _stockPanel.default,
    Trade: _stockTrade.default,
    InputNumber: _InputNumber.default,
    HistoryOrder: _stockHistoryOrder.default,
    "v-chart": _vueEcharts.default
  },
  data() {
    return {
      isSwitchImg: false,
      isSwitch: false,
      currencyValue: null,
      payValue: null,
      incomeValue: null,
      exchangeSureShow: false,
      //换汇确认
      currencyShow: false,
      //是否显示币种选择
      exchangeShow: false,
      //是否显示汇兑弹框
      beforeSure: false,
      tabList: [{
        value: "1",
        label: "盘口"
      }, {
        value: "2",
        label: "实时成交"
      }],
      currIndex: "1",
      multipleNum: 0,
      mixMultipleNum: 1,
      maxMultipleNum: 300,
      dialogVisible: false,
      sliderValue: 0,
      symbol: this.$route.params.symbol || "btcusdt",
      coinFrom: "--",
      coinTo: "--",
      coinInfoMap: {},
      coinItemIdMap: {},
      tradePair: [],
      tradePairArray: [],
      marketList: [],
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--"
          },
          list: []
        }
      },
      assetsInfoList: [],
      orderbookList: [{
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }],
      klineList: [],
      depthList: {
        a: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]],
        b: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]]
      },
      swap_symbol_market: {},
      swap_symbol_id: "",
      old_swap_symbol_id: "",
      from_item_id: "",
      to_item_id: "",
      wsUrl: "",
      token: "",
      pageLoading: false,
      onload: false,
      voucherInterval: null,
      historyDataLength: 0,
      wsContentTime: 0,
      marks: {},
      maxNumber: {},
      marketInfo: {
        last: "",
        currency: {
          cny: ""
        }
      },
      currencyOptions: {
        grid: {
          containLabel: true,
          left: "0%",
          right: "1%",
          bottom: "3%",
          top: "5%"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: "#c2c7d0",
            fontSize: "10"
          },
          data: ["2020-06-28", "", "2020-07-19", "", "2020-07-27", "", "2020-08-27"]
        },
        yAxis: {
          type: "value",
          min: "dataMin",
          max: "dataMax",
          position: "right",
          axisLabel: {
            fontSize: "10"
          },
          splitLine: {
            lineStyle: {
              color: "#f7f8fa"
            }
          }
        },
        series: [{
          data: [70454.49, 61829.13, 33203.78, 43578.42, 23953.07, 54327.71, 34327.71],
          type: "line",
          symbol: "none",
          itemStyle: {
            normal: {
              color: "rgba(2,204,135,0.8)"
            }
          },
          areaStyle: {
            normal: {
              color: "rgba(2,204,135,0.1)"
            }
          }
        }, {
          data: [80454.49, 71829.13, 43203.78, 53578.42, 33953.07, 64327.71, 44327.71],
          type: "line",
          symbol: "none",
          itemStyle: {
            normal: {
              color: "rgba(198,27,61,0.8)"
            }
          },
          areaStyle: {
            normal: {
              color: "rgba(198,27,61,0.1)"
            }
          }
        }]
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      userInfo: "user/userInfo"
    }),
    title() {
      let title = "数字货币银行";
      if (_stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
        title = "数字理财平台";
      } else {
        title = "数字货币银行";
      }
      let val = this.marketInfoShow.last + "  " + this.coinFrom.toUpperCase() + "  " + this.translateTitle("永续") + "/" + this.coinTo.toUpperCase() + " " + this.translateTitle(title);
      return val;
    },
    marketInfoShow() {
      let tempMarketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          tempMarketInfo = ele;
        }
      });
      return tempMarketInfo;
    }
  },
  async created() {
    // await this.getTradePairList();
    await this.getUrlSymbol();
    await this.getExchangeItemId();
    let tempCookies = _jsCookie.default.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      // await this.getToken();
      await this.initURL();
      this.initWs();
    } else {
      this.token = _jsCookie.default.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
    this.resolveCoin();
    // this.voucherInterval = setInterval(() => {
    //   document.title = this.title;
    // }, 500);
  },

  mounted() {},
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  watch: {
    async userInfo(newValue, oldValue) {
      if (this.userInfo == null) {
        await this.getToken();
        await this.initURL();
        this.initWs();
      } else {
        let tempUserInfo = sessionStorage.getItem("userInfo");
        if (tempUserInfo) {
          return;
        }
        this.token = _jsCookie.default.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    doSwitch() {
      let that = this;
      this.isSwitchImg = !this.isSwitchImg;
      this.isSwitch = true;
      setTimeout(function () {
        that.isSwitch = false;
      }, 2000);
    },
    isShowCurrency() {
      this.currencyShow = true;
    },
    isShowExchange() {
      this.exchangeShow = true;
      this.$nextTick(() => {
        let max_series0 = Math.max(...this.currencyOptions.series[0].data, ...this.currencyOptions.series[1].data);
        let min_series0 = Math.min(...this.currencyOptions.series[0].data, ...this.currencyOptions.series[1].data);
        this.currencyOptions.yAxis.interval = (max_series0 - min_series0) / 5;
        this.$refs.currencychart.setOption(this.currencyOptions);
      });
    },
    onClosePosition() {
      this.$confirm(this.translateTitle("确认平仓该订单?"), this.translateTitle("平仓"), {
        confirmButtonText: this.translateTitle("确定"),
        cancelButtonText: this.translateTitle("取消")
      }).then(() => {});
    },
    onMinClick() {
      this.multipleNum -= 1;
      if (this.multipleNum <= 1) {
        this.multipleNum = 1;
      }
    },
    onPlusClick() {
      this.multipleNum += 1;
      if (this.multipleNum >= 300) {
        this.multipleNum = 300;
      }
    },
    goToLogin() {
      this.$router.push({
        path: this.routeSplicing(this.language, "login"),
        query: {
          redirect: this.$route.fullPath
        }
      });
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    formatTooltip(val) {
      return val + "X";
    },
    onInputChange(value) {
      if (Number(value) > Number(this.maxNumber[this.swap_symbol_id])) {
        value = this.maxNumber[this.swap_symbol_id];
      }
      this.sliderValue = Number(value);
      this.multipleNum = value;
    },
    onSliderChange(value) {
      this.multipleNum = value;
    },
    handleClose() {
      this.multipleNum = 20;
      this.dialogVisible = false;
    },
    async onMultipleNumClick(value) {
      this.sliderValue = Number(value);
      this.multipleNum = value;
      let params = {
        symbol_id: this.swap_symbol_id
      };
      const {
        status,
        data,
        msg
      } = await (0, _order.apiGetOrderStatistic)(params);
      if (status == 200) {
        this.historyDataLength = data.contract_position_info.total_num + data.contract_plan_info.total_num;
        if (this.historyDataLength == 0) {
          this.dialogVisible = true;
        } else {
          (0, _message.showMessage)({
            message: this.translateTitle("当前有进行中的仓位或委托单，不能修改杠杆倍数"),
            type: "error"
          });
        }
      } else {
        (0, _message.showMessage)({
          message: msg,
          type: "error"
        });
      }
    },
    onMultipleNumConfirm() {
      // localStorage.setItem("stock-multiple-num", Number(this.multipleNum));
      // this.resetSetItem("stock-multiple-num", Number(this.multipleNum));
      this.$refs.panelComponent.setMultipleNum(Number(this.multipleNum));
      this.$refs.tradeComponent.setMultipleNum(Number(this.multipleNum));
      this.dialogVisible = false;
    },
    onAddOption() {
      this.$refs.marketComponent.getOptionList();
    },
    onMarketAddOption() {
      this.$refs.panelComponent.getOptionList();
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    onTradeSuccess(type) {
      this.$refs.historyOrder.reset(type);
    },
    async getExchangeItemId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.coinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    async getUrlSymbol() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(49);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
        let tempSymbol = this.$route.params.symbol;
        if (!tempSymbol) {
          tempSymbol = "btcusdt";
          this.symbol = "btcusdt";
        } else {
          this.symbol = tempSymbol;
        }
        for (const key in this.coinInfoMap) {
          let tempArr = [];
          tempArr = this.coinInfoMap[key].leverage_list.split(",");
          this.maxMultipleNum = tempArr[tempArr.length - 1];
          let tempBase = tempArr[tempArr.length - 1] / 5;
          this.marks[key] = {};
          // this.maxNumber[key] = {};
          this.maxNumber[key] = tempArr[tempArr.length - 1];
          tempArr.forEach((ele, index) => {
            this.marks[key][1] = "1X";
            this.marks[key][tempBase * (index + 1)] = tempBase * (index + 1) + "X";
          });
          this.tradePairArray.push(this.coinInfoMap[key].symbol.toLowerCase());
          if (this.coinInfoMap[key].symbol.toLowerCase() == tempSymbol) {
            this.swap_symbol_id = this.coinInfoMap[key].symbol_id;
            this.from_item_id = this.coinInfoMap[key].change_item_id;
            this.to_item_id = this.coinInfoMap[key].base_item_id;
          }
        }
      }
    },
    onSymbolChangeV2(params) {
      this.$refs.marketComponent.onSymbolChange(params, "1");
    },
    onSymbolChange(item) {
      if (this.swap_symbol_id == item.symbol_id) {
        return;
      }
      this.old_swap_symbol_id = this.swap_symbol_id;
      window.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "order",
          symbol: this.swap_symbol_id
        }
      });
      this.orderbookList = [];
      window.ws.sub({
        action: "Topic.sub",
        data: {
          type: "order",
          symbol: item.symbol_id
        }
      });
      window.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "depth",
          symbol: this.swap_symbol_id
        }
      });
      this.depthList = {
        a: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]],
        b: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]]
      };
      window.ws.sub({
        action: "Topic.sub",
        data: {
          type: "depth",
          symbol: item.symbol_id
        }
      });
      this.$refs.klineComponent.onChangeSymbol(item);
      this.swap_symbol_id = item.symbol_id;
      this.from_item_id = item.change_item_id;
      this.to_item_id = item.base_item_id;
      this.resolveCoin();
      // this.$refs.historyOrder.resetV2();
    },

    resolveCoin() {
      this.pageLoading = true;
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        tempSymbol = "btcusdt";
        this.swap_symbol_id = 100901;
      }
      let trade_pair = tempSymbol;
      if (this.tradePairArray.indexOf(trade_pair.toLowerCase()) == -1) {
        tempSymbol = "btcusdt";
        this.swap_symbol_id = 100901;
      }
      this.coinFrom = this.coinItemIdMap[this.from_item_id].item_name.toUpperCase();
      this.coinTo = this.coinItemIdMap[this.to_item_id].item_name.toUpperCase();
      // const [coinFrom, coinTo] = tempSymbol.split("_");
      // this.coinFrom = coinFrom;
      // this.coinTo = coinTo;
      setTimeout(() => {
        this.pageLoading = false;
      }, 300);
    },
    async getToken() {
      const {
        status,
        data
      } = await (0, _index2.apiGetDefaultToken)();
      if (status == 200) {
        this.token = data.token;
      }
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };

      const {
        status,
        data
      } = await (0, _index2.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "kline",
            resolution: "15",
            symbol: me.swap_symbol_id
          }
        },
        callback,
        onConnect,
        mounted
      };
      window.ws = new _socket.default(option).getInstance();
      // 重新连接
      function onConnect() {
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        setTimeout(() => {
          me.wsContentTime++;
          me.initWs();
        }, 3000);
      }
      function mounted() {
        window.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            symbol: me.swap_symbol_id
          }
        });
        window.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id
            // resolution: "D",
          }
        });

        window.ws.sub({
          action: "Topic.sub",
          data: {
            type: "order",
            symbol: me.swap_symbol_id
          }
        });
        window.ws.sub({
          action: "Topic.sub",
          data: {
            // type: "contractM",
            type: "stock",
            app_id: _stationSetting.VUE_APP_ID
          }
        });
        if (_jsCookie.default.get("topcredit_exchange_t")) {
          window.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets"
            }
          });
        }
      }
      function callback(data) {
        const {
          action
        } = data;
        if (action == "Pushdata.stock") {
          // if (action == "Pushdata.market") {
          if (data.data) {
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              if (!ele["currency"]) {
                ele["currency"] = {};
              }
              ele.currency.usdV2 = JSON.stringify(ele.currency) != "{}" ? Number(ele.currency.usd) : 0;
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }
        if (action == "Pushdata.kline") {
          window.resolveKlineDataV3(data);
        }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        if (action == "Pushdata.orderbook") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            me.orderbookList.unshift(...data.data);
            if (me.orderbookList.length > 28) {
              me.orderbookList = me.orderbookList.slice(0, 28);
            }
          }
        }
        if (action == "Pushdata.depth") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            let tempObj = data.data;
            if (tempObj.a.length > 0) {
              tempObj.c = 0;
              tempObj.d = 0;
              tempObj.a.forEach(ele => {
                tempObj.c += Number(ele[1]);
              });
              tempObj.b.forEach(ele => {
                tempObj.d += Number(ele[1]);
              });
            }
            me.depthList = tempObj;
          }
        }
      }
    }
  }
};
exports.default = _default;