"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAddress = addAddress;
exports.apiCreateFinanceAddress = apiCreateFinanceAddress;
exports.apiDelAddress = apiDelAddress;
exports.apiDelFinanceAddress = apiDelFinanceAddress;
exports.apiFetchOtcUrl = apiFetchOtcUrl;
exports.apiGetFinanceAddressList = apiGetFinanceAddressList;
exports.apiGetUserInfoByAddress = apiGetUserInfoByAddress;
exports.apiGetUserItems = apiGetUserItems;
exports.apiSaveFinanceAddress = apiSaveFinanceAddress;
exports.apiWyreUrl = apiWyreUrl;
exports.bindEmailCode = bindEmailCode;
exports.bindMobileCode = bindMobileCode;
exports.changeEmailCode = changeEmailCode;
exports.changeMobileCode = changeMobileCode;
exports.getAddress = getAddress;
exports.getAddressList = getAddressList;
exports.getAssetsList = getAssetsList;
exports.getCoinList = getCoinList;
exports.getExchangeListV2 = getExchangeListV2;
exports.getINCoinExchangeListV2 = getINCoinExchangeListV2;
exports.getStaticCoinList = getStaticCoinList;
exports.getUserExchangeLimit = getUserExchangeLimit;
exports.sendEmailCode = sendEmailCode;
exports.sendSmsCode = sendSmsCode;
exports.transfer = transfer;
exports.transferEmailCode = transferEmailCode;
exports.transferMobileCode = transferMobileCode;
exports.verifyEmailCode = verifyEmailCode;
exports.verifyMobileCode = verifyMobileCode;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER } = process.env;

//缓存CoinList,防止多次请求
async function getStaticCoinList() {
  //先查缓存
  let result = localStorage.getItem("coinListInfo");
  let lastTime = localStorage.getItem("coinListLastTime");
  let nowTime = Date.parse(new Date()) / 1000;
  let coinListInfo;
  if (result && result != "undefined" && result != "{}" && result != "[object Object]" && nowTime - lastTime > 1 * 60) {
    let timestamp = Date.parse(new Date()) / 1000;
    localStorage.setItem("coinListLastTime", timestamp);
    console.log("更新CoinList");
  } else if (result && result != "undefined" && result != "{}" && result != "[object Object]") {
    coinListInfo = JSON.parse(result);
    return {
      status: 200,
      data: coinListInfo
    };
  }

  //如果没有缓存
  const {
    status,
    data
  } = await getCoinList();
  // const { data } = res;
  if (!data) {
    return {};
  }
  coinListInfo = data;
  localStorage.setItem("coinListInfo", JSON.stringify(coinListInfo));
  let timestamp = Date.parse(new Date()) / 1000;
  localStorage.setItem("coinListLastTime", timestamp);
  return {
    status: 200,
    data: coinListInfo
  };
}
function getCoinList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/Settings/itemList`,
    data,
    type: "POST"
  });
}
function apiGetUserItems(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/User/userItems`,
    data,
    type: "POST"
  });
}
function getAssetsList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/getAssetsList`,
    data,
    type: "POST"
  });
}
function getExchangeListV2(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST"
  });
}
function getINCoinExchangeListV2(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/getCoinExchangeListV2`,
    data,
    type: "POST"
  });
}
function getUserExchangeLimit(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/getUserExchangeLimit`,
    data,
    type: "POST"
  });
}

//获取充币地址
function getAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/getAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiGetFinanceAddressList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/getFinanceAddressList`,
    data,
    type: "POST"
  });
}

//三方支付wyre地址
function apiWyreUrl(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/OtcPay/wyre`,
    data,
    type: "POST"
  });
}
//三方支付快捷买币地址
function apiFetchOtcUrl(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/ThirdPayment/fetchOtcUrl`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiCreateFinanceAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/createFinanceAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiSaveFinanceAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/saveFinanceAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function apiDelFinanceAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/delFinanceAddress`,
    data,
    type: "POST"
  });
}

//获取财务充币地址列表
function sendEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/sendEmailCode`,
    data,
    type: "POST"
  });
}
function sendSmsCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Users/sendSmsCode`,
    data,
    type: "POST"
  });
}
//发送绑定短信
function bindMobileCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/bindMobileCode`,
    data,
    type: "POST"
  });
}
//发送换绑短信
function changeMobileCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/changeMobileCode`,
    data,
    type: "POST"
  });
}
//发送安全项变更短信
function verifyMobileCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/verifyMobileCode`,
    data,
    type: "POST"
  });
}
//发送提币转账短信
function transferMobileCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/transferMobileCode`,
    data,
    type: "POST"
  });
}
//发送绑定邮件
function bindEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/bindEmailCode`,
    data,
    type: "POST"
  });
}
//发送换绑邮件
function changeEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/changeEmailCode`,
    data,
    type: "POST"
  });
}
//发送安全项变更邮件
function verifyEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/verifyEmailCode`,
    data,
    type: "POST"
  });
}
//发送提币转账邮件
function transferEmailCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/V3/UCenter/User/transferEmailCode`,
    data,
    type: "POST"
  });
}
function getAddressList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/addressList`,
    data,
    type: "POST"
  });
}
function addAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/addAddress`,
    data,
    type: "POST"
  });
}
// 二次验证,提币借口
function transfer(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Assets/transfer`,
    data,
    type: "POST"
  });
}
//判断地址是否为内部地址
function apiGetUserInfoByAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/getUserInfoByAddress`,
    data,
    type: "POST"
  });
}
//删除提币地址
function apiDelAddress(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/Address/delAddress`,
    data,
    type: "POST"
  });
}