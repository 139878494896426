"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrentCoin = exports.getCoinInfo = exports.getAssetsData = void 0;
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/model/assets/index");
// import {
//   showZMessage
// } from '@/components/dialog/message';
// import {
//   getCoinList,
//   getAssetsList
// } from '@/model/assets/index';
const VERSION = 5;
const __version = "3.4.2";

// 获取币种列表 (请求)
const $getCoinList = async () => {
  const req = {
    __version: "3.4.3",
    __channel: "2"
  };
  const {
    data
  } = await (0, _index.getStaticCoinList)(req);
  for (let key in data) {
    data[key].itemDetail.capital_name = data[key].itemDetail.name.toUpperCase();
  }
  // localStorage.setItem('coinDataVersion', VERSION);
  // sessionStorage.setItem('coinData', JSON.stringify(data));
  return data;
};

// 获取币种列表（本地|请求）
const getCoinInfo = async () => {
  if (!_jsCookie.default.get("topcredit_exchange_t")) return;
  //先查缓存
  // let result = sessionStorage.getItem('coinData');
  // let version = localStorage.getItem('coinDataVersion');
  // let settingList;
  // if (result && result != "[object Object]"&&version==VERSION) {
  //   settingList = JSON.parse(result);
  //   return settingList;
  // }
  let coinData = await $getCoinList();
  // let coinData = sessionStorage.coinData ? JSON.parse(sessionStorage.coinData) : await $getCoinList() ;
  return coinData;
};

// 获取资产列表
exports.getCoinInfo = getCoinInfo;
const getAssetsData = async () => {
  if (!_jsCookie.default.get("topcredit_exchange_t")) return;
  const {
    data
  } = await (0, _index.getAssetsList)({});
  return data;
};

// 获取当前选择的币种
exports.getAssetsData = getAssetsData;
const getCurrentCoin = () => {
  return localStorage.currentCoin ? JSON.parse(localStorage.currentCoin) : "";
};
// const getCurrentCoin = () => {
//   return sessionStorage.currentCoin
//     ? JSON.parse(sessionStorage.currentCoin)
//     : "";
// };
exports.getCurrentCoin = getCurrentCoin;