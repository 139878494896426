"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vuex = require("vuex");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _menuControlMixin = _interopRequireDefault(require("@/components/common/menuControlMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _index = _interopRequireDefault(require("../../store/index"));
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default, _menuControlMixin.default],
  components: {
    // QrcodeVue,
  },
  data() {
    return {
      store: _index.default,
      android_url_new: "https://download.8v.com",
      appstore_url_new: "https://get.8v.com"
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      v8show: "user/v8show",
      language: "user/language",
      environment: "user/environment",
      darkMode: "user/darkMode"
    })
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    ...(0, _vuex.mapActions)({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode"
    }),
    handleClose(done) {
      _index.default.dispatch("user/v8closed");
    }
  }
};
exports.default = _default;