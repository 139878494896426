"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vuex = require("vuex");
var _formatDate = require("@/assets/js/formatDate");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _index = require("@/model/blog/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {},
  data() {
    return {
      searchInput: "",
      tagId: "",
      activeTag: "ALL",
      bannerList: [],
      blogList: [],
      hotList: [],
      detail: {}
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      environment: "user/environment",
      darkMode: "user/darkMode"
    })
  },
  created() {
    this.getBlogDetails();
    this.getBlogList();
    this.getHotList();
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    timestamp: _formatDate.timestamp,
    onBtnClick() {
      this.$router.push({
        path: this.routeSplicing(this.language, "blog")
      });
    },
    onTagClick(item) {
      this.activeTag = item;
    },
    getBlogList() {
      const req = {
        search: this.searchInput,
        tag_id: this.tagId
      };
      (0, _index.apiGetBlogList)(req).then(res => {
        if (res.status == 200) {
          this.blogList = res.data.list;
        }
      });
    },
    getHotList() {
      (0, _index.apiGetHotList)().then(res => {
        if (res.status == 200) {
          this.hotList = res.data;
        }
      });
    },
    getBlogDetails() {
      let pid = this.$route.query.pid;
      (0, _index.apiGetBlogDetails)({
        pid
      }).then(res => {
        if (res.status == 200) {
          this.detail = res.data;
        }
      });
    }
  }
};
exports.default = _default;