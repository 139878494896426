"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/esnext.typed-array.to-reversed.js");
require("core-js/modules/esnext.typed-array.to-sorted.js");
require("core-js/modules/esnext.typed-array.with.js");
require("core-js/modules/es.array.push.js");
var _digital = require("@/assets/js/digital");
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/model/currencyBasedContract/index");
var _order = require("@/model/currencyBasedContract/order.js");
var _order2 = require("@/model/contractExchange/order.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _index2 = require("@/model/exchange/index");
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _message = require("@/components/dialog/message");
var _vuex = require("vuex");
var _stationSetting = require("@/assets/js/stationSetting");
var _index3 = require("@/model/assets/index");
var _billCenter = require("@/model/ucenter/billCenter");
var _vueCanvasPoster = require("vue-canvas-poster");
var _spreadCenter = require("@/model/ucenter/spreadCenter");
var _i18n = require("@/assets/js/i18n");
var _b = _interopRequireDefault(require("@/assets/img/siteLogo/b9.png"));
var _mask = _interopRequireDefault(require("@/assets/img/siteLogo/mask.png"));
var _bxc = _interopRequireDefault(require("@/assets/img/siteLogo/bxc.png"));
var _hx = _interopRequireDefault(require("@/assets/img/siteLogo/hx.png"));
var _Ahex = _interopRequireDefault(require("@/assets/img/siteLogo/Ahex.png"));
var _logo5A = _interopRequireDefault(require("@/assets/img/siteLogo/logo5A.png"));
var _logobitda = _interopRequireDefault(require("@/assets/img/siteLogo/logobitda.png"));
var _logoS = _interopRequireDefault(require("@/assets/img/siteLogo/logoS6.png"));
var _logoEasy = _interopRequireDefault(require("@/assets/img/siteLogo/logoEasy.png"));
var _spex = _interopRequireDefault(require("@/assets/img/siteLogo/spex.png"));
var _coin = _interopRequireDefault(require("@/assets/img/siteLogo/8coin.png"));
var _v = _interopRequireDefault(require("@/assets/img/siteLogo/8v.png"));
var _hxlogo_text = _interopRequireDefault(require("@/assets/img/siteLogo/hxlogo_text.png"));
var _qr_code = _interopRequireDefault(require("@/assets/img/qr_code.png"));
var _profit_loss_share_bg = _interopRequireDefault(require("@/assets/img/profit_loss_share_bg.png"));
var _expressionBad = _interopRequireDefault(require("@/assets/img/expression/expression-bad.png"));
var _expressionGreat = _interopRequireDefault(require("@/assets/img/expression/expression-great.png"));
var _expressionCool = _interopRequireDefault(require("@/assets/img/expression/expression-cool.png"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// import TopTitle from "../../components/Title";
var _default = {
  mixins: [_commonMixin.default],
  components: {
    NoData: _NoData.default,
    VueCanvasPoster: _vueCanvasPoster.VueCanvasPoster,
    InputNumber: _InputNumber.default
  },
  props: {
    isStartWS: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
    marketListTran: {
      type: Array,
      default: function () {
        return [];
      }
    },
    assetsInfoTran: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      tt: {},
      lockReconnect: false,
      sloganColor: "#c61b3e",
      currRow: {},
      wsUrl: "",
      isDestoryWS: false,
      wsContentTime: 0,
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--"
          },
          list: []
        }
      },
      marketList: [],
      appendMoneydialog: false,
      staticChainMap: {},
      //静态表1
      staticCurrencyMap: {},
      sotcStaticPaymentMap: {},
      sotcStaticProtectMap: {},
      sotcStaticGuaranteeMap: {},
      copyAccountMsg: "",
      apiAssetsInfo: {},
      from_item_id: "",
      to_item_id: "",
      from_symbol: "",
      to_symbol: "",
      swap_symbol_id: "",
      beforeBailNumber: "",
      afterBailNumber: "",
      beforeLiquidationPrice: "",
      afterLiquidationPrice: "",
      symbolList: {},
      price_precision: "",
      settlement_precision: "",
      contract_value: "",
      fee_rate: "",
      liquidation_risk_rate: "",
      bail_number: "",
      rowData: {},
      row_swap_symbol_id: "",
      inputProfitPrice: "",
      inputLossPrice: "",
      setProfitPricedialog: false,
      dialogType: "",
      confirmMessage: "",
      dialogVisible: false,
      profitLossShareBg: _profit_loss_share_bg.default,
      expressionBad: _expressionBad.default,
      expressionCool: _expressionCool.default,
      expressionGreat: _expressionGreat.default,
      notifyImg: "",
      notifyVisible: false,
      painting: {},
      b9Logo: _b.default,
      bxcLogo: _bxc.default,
      hxLogo: _hx.default,
      logo5A: _logo5A.default,
      logobitda: _logobitda.default,
      logoS6: _logoS.default,
      logoEasy: _logoEasy.default,
      logoSpex: _spex.default,
      logo8coin: _coin.default,
      logo8V: _v.default,
      AhexLogo: _Ahex.default,
      maskLogo: _mask.default,
      hxlogo_text: _hxlogo_text.default,
      qrCode: _qr_code.default,
      projectEnglishName: "",
      projectFullName: "",
      projectLogo: "",
      default_invite_code: {},
      centerHost: "",
      operateTypeMap: {
        1: this.translateTitle("开仓"),
        2: this.translateTitle("平仓"),
        3: this.translateTitle("设置止损价"),
        4: this.translateTitle("设置止盈价"),
        5: this.translateTitle("追加本金"),
        6: this.translateTitle("已强平"),
        7: this.translateTitle("同时设置止盈止损"),
        8: this.translateTitle("加仓"),
        9: this.translateTitle("减仓"),
        10: this.translateTitle("资金费用")
      },
      position_order_id: "",
      position_order_obj: {
        position_info: {},
        operate_list: []
      },
      user_order_obj: {},
      closePositionTypeMap: {
        1: this.translateTitle("未平仓"),
        2: this.translateTitle("用户平仓"),
        3: this.translateTitle("强制平仓"),
        4: this.translateTitle("止盈平仓"),
        5: this.translateTitle("止损平仓"),
        6: this.translateTitle("自动减仓")
      },
      //平仓类型
      openPositionTypeMap: {
        1: this.translateTitle("手动开仓"),
        2: this.translateTitle("触发价开仓")
      },
      //开仓类型
      liquidation_symbol_name: "",
      outerSearch: [],
      coinData: {},
      staticSymbolData: {},
      staticFuturesSymbolData: {},
      searchCoin: [],
      staticAssetsTypeData: [],
      staticStockData: {},
      searchStaticAssetsType: [],
      reChargeDialogVisible: false,
      otcDialogVisible: false,
      tabList: [{
        label: this.translateTitle("收款账号"),
        value: 1
      }, {
        label: this.translateTitle("付款截图"),
        value: 2
      }, {
        label: this.translateTitle("订单历史"),
        value: 3
      }],
      currIndex: 1,
      currIndexPosition: 1,
      coinInfoMap: {},
      liquidation_dialog_loading: false
    };
  },
  async created() {
    this.getAppIntialDataV2();
    this.getStaticChainMap();
    this.getStaticCurrencyMap();
    this.getStaticPaymentMap();

    // this.getSotcStaticGuaranteeMap();
    // this.getSotcStaticProtectMap();

    this.getItemList();
    this.getContractConfigBySymbolId();
    this.getFuturesConfigBySymbolId();
    // this.getStockConfigBySymbolId();
    this.getStaticAssetsType();
    this.getAssets();
    let tempCookies = _jsCookie.default.get("topcredit_exchange_t");
    if (tempCookies) {
      this.getRebateCommonInfo();
    }
    if (this.isStartWS) {
      if (tempCookies == undefined) {
        await this.initURL();
        this.initWs();
      } else {
        this.getRebateCommonInfo();
        this.token = _jsCookie.default.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    }
    if (this.language && _stationSetting.VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin" || _stationSetting.VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin") {
      this.projectFullName = "Bullxcow";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.bxcLogo;
    } else if (this.language && _stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectFullName = "B9";
      this.sloganColor = "#fdac35";
      this.projectLogo = this.b9Logo;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.projectFullName = "MaskEx";
      this.sloganColor = "#32c8a4";
      this.projectLogo = this.maskLogo;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "100Ex") {
      this.projectFullName = "100Ex";
      this.sloganColor = "#e9393c";
      this.projectLogo = this.logo100;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "EverTrust") {
      this.projectFullName = "EverTrust";
      this.sloganColor = "#fdac35";
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "8V") {
      this.projectFullName = "8V";
      this.sloganColor = "#fdac35";
      this.projectLogo = this.logo8V;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "UEEx") {
      this.projectFullName = "UEEx";
      this.sloganColor = "#f35023";
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "Ahex") {
      this.projectFullName = "Ahex";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.AhexLogo;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "5A") {
      this.projectFullName = "5A";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.logo5A;
    } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
      this.projectFullName = "bitda";
      this.sloganColor = "#702070";
      this.projectLogo = this.logobitda;
    } else if (_stationSetting.VUE_UNION_STATION == "S6") {
      this.projectFullName = "S6";
      this.sloganColor = "#F7b239";
      this.projectLogo = this.logoS6;
    } else if (_stationSetting.VUE_UNION_STATION == "easy") {
      this.projectFullName = "ezex";
      this.sloganColor = "#c61b3d";
      this.projectLogo = this.logoEasy;
    } else if (_stationSetting.VUE_UNION_STATION == "spex") {
      this.projectFullName = "SPEX";
      this.sloganColor = "#e37c30";
      this.projectLogo = this.logoSpex;
    } else if (_stationSetting.VUE_UNION_STATION == "8coin") {
      this.projectFullName = "8coin";
      this.sloganColor = "#3960fb";
      this.projectLogo = this.logo8coin;
    } else {
      this.projectFullName = "汇信国际";
      this.sloganColor = "#c61b3e";
      this.projectLogo = this.hxLogo;
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (JSON.stringify(this.assetsInfo) != "{}" && this.assetsInfo.remain.list.length > 0) {
        this.assetsInfo.remain.list.forEach(ele => {
          if ([35, 36].includes(this.currRow.assets_type)) {
            if (ele.item_id == this.to_item_id) {
              tempObj = ele.over;
            }
          } else {
            if (ele.item_id == this.from_item_id) {
              tempObj = ele.over;
            }
          }
        });
      } else if (JSON.stringify(this.apiAssetsInfo) != "{}" && this.apiAssetsInfo.remain.list.length > 0) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if ([35, 36].includes(this.currRow.assets_type)) {
            if (ele.item_id == this.to_item_id) {
              tempObj = ele.over;
            }
          } else {
            if (ele.item_id == this.from_item_id) {
              tempObj = ele.over;
            }
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    rowMarketInfoShow() {
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.row_swap_symbol_id) {
          marketInfo = ele;
        }
      });
      return marketInfo;
    },
    afterLiquidationPriceShow() {
      //强平价 =  1 / ((持仓张数×单张合约价值 × 1/开仓价 × (1 做多| -1做空) + 保证金 × (1 − 强平风险率))/持仓张数/单张合约价值 / (1 做多 | -1做空))
      let tempValue = "";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.bail_number != "") {
          tempValue = this.rowData.open_price - tempSide * (this.afterBailNumberShow * (1 - this.symbolList[this.swap_symbol_id].liquidation_risk_rate / 10000) - this.rowData.close_position_fee - this.rowData.open_position_fee) / this.rowData.open_interest;
          if (this.rowData.open_side == 1) {
            //做多 做多的时候小数位用全进
            tempValue = (Math.ceil(tempValue * Math.pow(10, this.price_precision)) / Math.pow(10, this.price_precision)).toFixed(this.price_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.price_precision + 1);
          }
        } else {
          tempValue = this.afterLiquidationPrice;
        }
        if (Number(tempValue) < 0) {
          tempValue = "0";
        }
      } else {
        let side = this.rowData.open_side == 1 ? 1 : -1;
        if (this.bail_number != "") {
          // tempValV2 持仓张数
          let tempOpenPrice = "";
          tempOpenPrice = this.rowData.trigger_price ? new _bignumber.default(this.rowData.trigger_price) : new _bignumber.default(this.rowData.open_price);
          // if (this.currIndex == 1) {
          //   tempOpenPrice = new BigNumber(this.rowData.open_price);
          // } else {
          //   tempOpenPrice = new BigNumber(this.rowData.trigger_price);
          // }
          let tempSide = new _bignumber.default(side);
          let bigMarketPrice = new _bignumber.default(this.rowData.open_price);
          let tempMultipleNum = new _bignumber.default(this.rowData.open_leverage);
          let tempConsumerCosts = new _bignumber.default(this.afterBailNumberShow);
          let tempLiquidationRiskRate = new _bignumber.default(Number(this.liquidation_risk_rate) / 10000);
          let tempContractValue = new _bignumber.default(this.contract_value);
          let tempQty = new _bignumber.default(this.rowData.qty);
          let one = new _bignumber.default(1);

          // 强平价 =  1 / { 1 / 开仓价 + [持仓方向 × 保证金 × (1 −强平⻛险率)/ 张数 / 单张合约价值] }

          let tempValV2 = one.div(one.div(tempOpenPrice).plus(tempSide.times(tempConsumerCosts).times(one.minus(tempLiquidationRiskRate)).div(tempQty).div(tempContractValue)));
          tempValue = tempValV2.toString();
          if (this.rowData.open_side == 1) {
            //做多 做多的时候小数位用全进
            tempValue = (Math.ceil(tempValue * Math.pow(10, this.price_precision)) / Math.pow(10, this.price_precision)).toFixed(this.price_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.price_precision + 1);
          }
        } else {
          tempValue = this.afterLiquidationPrice;
        }
        if (Number(tempValue) < 0) {
          tempValue = "0";
        }
      }
      return tempValue;
    },
    //  未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)
    expectedProfit() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.open_price ? this.rowData.open_price : this.rowData.trigger_price;
          // if (this.currIndexPosition == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue = (Number(this.inputProfitPrice) - Number(temp_open_price)) * tempSide * Number(this.rowData.open_interest) - Number(this.rowData.close_position_fee);
          tempValue = (0, _digital.floorNumber)(tempValue, 2);
          if (Number(tempValue) <= 0) {
            tempValue = "--";
          }
        }
      } else {
        let side = this.rowData.open_side == 1 ? 1 : -1;
        let tempOpenPrice = "";
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          // if (this.currIndex == 1) {
          //   tempOpenPrice = new BigNumber(this.rowData.open_price);
          // } else {
          //   tempOpenPrice = new BigNumber(this.rowData.trigger_price);
          // }
          tempOpenPrice = this.rowData.trigger_price ? new _bignumber.default(this.rowData.trigger_price) : new _bignumber.default(this.rowData.open_price);
          let tempInputProfitPrice = new _bignumber.default(this.inputProfitPrice);
          let tempContractValue = new _bignumber.default(this.contract_value);
          let tempSide = new _bignumber.default(side);
          let one = new _bignumber.default(1);
          let tempQty = new _bignumber.default(this.rowData.qty);
          // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

          tempValue = tempQty.times(tempContractValue).times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice))).times(tempSide).toNumber();
          if (this.rowData.open_side == 1) {
            tempValue = Math.ceil(tempValue * Math.pow(10, this.settlement_precision));
            tempValue = tempValue * 1.0 / Math.pow(10, this.settlement_precision);
            //做多 做多的时候小数位用全进
            // tempValue = (
            //   Math.ceil(tempValue * Math.pow(10, this.settlement_precision)) /
            //   Math.pow(10, this.settlement_precision)
            // ).toFixed(this.settlement_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.settlement_precision + 1);
          }

          // 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

          if (Number(tempValue) <= 0) {
            tempValue = "--";
          }
        }
      }
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.open_price ? this.rowData.open_price : this.rowData.trigger_price;
          // if (this.currIndexPosition == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue = ((this.inputProfitPrice - temp_open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee) / this.rowData.bail_number * 100;
          tempValue = (0, _digital.floorNumber)(tempValue, 2);
          if (Number(tempValue) <= 0) {
            tempValue = "--";
          } else {
            tempValue = "+" + tempValue;
          }
        }
      } else {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.open_price ? this.rowData.open_price : this.rowData.trigger_price;
          // if (this.currIndex == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue = this.expectedProfit / this.rowData.bail_number * 100;
          tempValue = (0, _digital.floorNumber)(tempValue, 2);
          if (Number(tempValue) <= 0) {
            tempValue = "--";
          } else {
            tempValue = "+" + tempValue;
          }
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏
    expectedLoss() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          let temp_open_price = "";
          if (this.currIndexPosition == 1) {
            temp_open_price = this.rowData.open_price;
          } else {
            temp_open_price = this.rowData.trigger_price;
          }
          tempValue = (this.inputLossPrice - temp_open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee;
          tempValue = (0, _digital.ceilNumber)(tempValue, 2);
          // tempValue = Math.ceil(tempValue * 100) / 100;
          if (Number(tempValue) < this.rowData.bail_number * -1) {
            tempValue = this.rowData.bail_number * -1;
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          }
        }
      } else {
        let side = this.rowData.open_side == 1 ? 1 : -1;
        let tempOpenPrice = "";
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          // if (this.currIndex == 1) {
          //   tempOpenPrice = new BigNumber(this.rowData.open_price);
          // } else {
          //   tempOpenPrice = new BigNumber(this.rowData.trigger_price);
          // }
          tempOpenPrice = this.rowData.open_price ? new _bignumber.default(this.rowData.open_price) : new _bignumber.default(this.rowData.trigger_price);
          let tempInputProfitPrice = new _bignumber.default(this.inputLossPrice);
          let tempQty = new _bignumber.default(this.rowData.qty);
          let tempContractValue = new _bignumber.default(this.contract_value);
          let tempSide = new _bignumber.default(side);
          let one = new _bignumber.default(1);
          // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价) × (1 做多 | -1做空)

          tempValue = tempQty.times(tempContractValue).times(one.div(tempOpenPrice).minus(one.div(tempInputProfitPrice))).times(tempSide).toNumber();
          if (this.rowData.open_side == 1) {
            if (Number(tempValue) < 0) {
              //做多 做多的时候小数位用全进
              let tempValueABS = Math.abs(tempValue);
              tempValue = Math.ceil(tempValueABS * Math.pow(10, this.settlement_precision));
              tempValue = tempValue * 1.0 / Math.pow(10, this.settlement_precision);
              tempValue = tempValue * -1;
            }
            // tempValue =
            //   Math.round(tempValue * Math.pow(10, this.settlement_precision)) /
            //   Math.pow(10, this.settlement_precision);
          } else {
            // 做空的时候用截断
            tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + this.settlement_precision + 1);
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          } else if (Number(tempValue * -1) > this.rowData.bail_number) {
            tempValue = this.rowData.bail_number * -1;
          }
        }
      }
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      if ([35, 36].includes(this.currRow.assets_type)) {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          let temp_open_price = "";
          if (this.currIndexPosition == 1) {
            temp_open_price = this.rowData.open_price;
          } else {
            temp_open_price = this.rowData.trigger_price;
          }
          tempValue = ((this.inputLossPrice - temp_open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee) / this.rowData.bail_number * 100;
          tempValue = (0, _digital.ceilNumber)(tempValue, 2);
          if (Number(tempValue) < -100) {
            tempValue = -100;
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          }
        }
      } else {
        let tempSide = this.rowData.open_side == 1 ? 1 : -1;
        if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
          let temp_open_price = "";
          temp_open_price = this.rowData.trigger_price ? this.rowData.trigger_price : this.rowData.open_price;
          // if (this.currIndex == 1) {
          //   temp_open_price = this.rowData.open_price;
          // } else {
          //   temp_open_price = this.rowData.trigger_price;
          // }

          tempValue = this.expectedLoss / this.rowData.bail_number * 100;
          tempValue = (0, _digital.ceilNumber)(tempValue, 2);
          if (Number(tempValue) < -100) {
            tempValue = -100;
          }
          if (Number(tempValue) > 0) {
            tempValue = "--";
          }
        }
      }
      // inputLossPrice
      return tempValue;
    },
    //追加本金后
    afterBailNumberShow() {
      if ([35, 36].includes(this.currRow.assets_type)) {
        return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(2);
      } else {
        return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(this.settlement_precision);
      }
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    moment: _moment.default,
    translateTitle: _i18n.translateTitle,
    goToRecharge() {
      window.open(this.routeSplicing(this.language, "ucenter/assets/recharge/usdt"));
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index2.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
      }
    },
    onOpenConfirm(type, id) {
      this.dialogType = type;
      if (type == 1) {
        this.position_order_id = id;
        this.confirmMessage = this.translateTitle("是否确认平仓此订单？");
      }
      // else if (type == 2) {
      //   this.confirmMessage = this.translateTitle("是否确认全部平仓？");
      // } else if (type == 3) {
      //   this.confirmMessage = this.translateTitle("是否确认全部撤销？");
      // } else if (type == 4) {
      //   this.plan_order_id = id;
      //   this.confirmMessage = this.translateTitle("是否确认撤销此委托单？");
      // }
      this.dialogVisible = true;
    },
    onOK() {
      if (this.dialogType == 1) {
        this.onClosePosition();
      }
      // else if (this.dialogType == 2) {
      //   this.onCancelOrder();
      // } else if (this.dialogType == 3) {
      //   this.onRevokeAllOrder();
      // } else if (this.dialogType == 4) {
      //   this.onRevokePlan();
      // }
    },

    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id
      };
      if ([35, 36].includes(this.currRow.assets_type)) {
        (0, _order2.apiClosePosition)(params).then(res => {
          if (res.status == 200) {
            this.dialogVisible = false;
            this.$message({
              message: this.translateTitle("平仓成功"),
              type: "success",
              customClass: "zZindex"
            });
            this.getContractPositionInfo();
          }
        });
      } else {
        (0, _order.apiClosePosition)(params).then(res => {
          if (res.status == 200) {
            this.dialogVisible = false;
            this.$message({
              message: this.translateTitle("平仓成功"),
              type: "success",
              customClass: "zZindex"
            });
            this.getFuturesPositionInfo();
          }
        });
      }
    },
    //设置止盈止损价
    onProfitPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      if (row.stop_profit_price != "--") {
        this.inputProfitPrice = row.stop_profit_price;
      } else {
        this.inputProfitPrice = "";
      }
      if (row.stop_loss_price != "--") {
        this.inputLossPrice = row.stop_loss_price;
      } else {
        this.inputLossPrice = "";
      }
      if ([35, 36].includes(this.currRow.assets_type)) {
        this.symbolList = this.staticSymbolData;
      } else {
        this.symbolList = this.staticFuturesSymbolData;
        for (const key in this.symbolList) {
          if (key == row.symbol_id) {
            this.price_precision = this.symbolList[key].price_precision;
            this.settlement_precision = this.symbolList[key].settlement_precision;
            this.contract_value = this.symbolList[key].contract_value;
            this.fee_rate = this.symbolList[key].fee_rate;
            this.liquidation_risk_rate = this.symbolList[key].liquidation_risk_rate;
          }
        }
      }
      this.setProfitPricedialog = true;
    },
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    onSetPositionProfitLoss() {
      // this.currIndex == 1 //1币本位持仓 2币本位当前委托 3U本位持仓 4U本位当前委托
      if (this.rowData.open_side == 1) {
        if (Number(this.rowMarketInfoShow.last) < Number(this.rowData.open_price)) {
          if (Number(this.inputProfitPrice) != 0 && Number(this.inputProfitPrice) <= Number(this.rowData.open_price)) {
            (0, _message.showMessage)({
              message: this.translateTitle("买涨时，止盈价需大于开仓价"),
              type: "warning"
            });
            return;
          }
        } else {
          if (Number(this.inputProfitPrice) != 0 && Number(this.inputProfitPrice) <= Number(this.rowMarketInfoShow.last)) {
            (0, _message.showMessage)({
              message: this.translateTitle("买涨时，止盈价需大于当前价"),
              type: "warning"
            });
            return;
          }
        }

        // if(止损价 > 强平价){
        // 	if(当前价 < 开仓价){
        // 		止损价 >= 当前价 ? "提示：买涨时，止损价需小于当前价" ：止损价
        // 	}else{
        // 		止损价 >= 开仓价 ? "提示：买涨时，止损价需小于开仓价" ：止损价
        // 	}
        // esle{
        // 	提示：买涨时，止损价需大于强平价
        // }
        if (Number(this.inputLossPrice) != 0 && Number(this.inputLossPrice) >= Number(this.rowData.liquidation_price)) {
          if (Number(this.rowMarketInfoShow.last) < Number(this.rowData.open_price)) {
            if (Number(this.inputLossPrice) >= Number(this.rowMarketInfoShow.last)) {
              (0, _message.showMessage)({
                message: this.translateTitle("买涨时，止损价需小于当前价"),
                type: "warning"
              });
              return;
            }
          } else {
            if (Number(this.inputLossPrice) >= Number(this.rowData.open_price)) {
              (0, _message.showMessage)({
                message: this.translateTitle("买涨时，止损价需小于开仓价"),
                type: "warning"
              });
              return;
            }
          }
        } else if (Number(this.inputLossPrice) != 0) {
          Number(this.inputLossPrice) < Number(this.rowData.liquidation_price);
          (0, _message.showMessage)({
            message: this.translateTitle("买涨时，止损价需大于强平价"),
            type: "warning"
          });
          return;
        }
      } else {
        // 卖跌
        if (Number(this.inputProfitPrice) > 0) {
          if (Number(this.rowMarketInfoShow.last) < Number(this.rowData.open_price)) {
            if (Number(this.inputProfitPrice) >= Number(this.rowMarketInfoShow.last)) {
              (0, _message.showMessage)({
                message: this.translateTitle("买跌时，止盈价需小于当前价"),
                type: "warning"
              });
              return;
            }
          } else {
            if (Number(this.inputProfitPrice) >= Number(this.rowData.open_price)) {
              (0, _message.showMessage)({
                message: this.translateTitle("买跌时，止盈价需小于开仓价"),
                type: "warning"
              });
              return;
            }
          }
        } else if (Number(this.inputProfitPrice) < 0) {
          (0, _message.showMessage)({
            message: this.translateTitle("买跌时，止盈价需大于0"),
            type: "warning"
          });
          return;
        }

        // if(止损价 < 强平价){
        // 	if(当前价 < 开仓价){
        // 		止损价  <= 开仓价 ? "提示：买跌时，止损价需大于开仓价" ：止损价
        // 	}else{
        // 		止损价 <= 当前价 ? "提示：买跌时，止损价需大于当前价" ：止损价
        // 	}
        // esle{
        // 	提示：买跌时，止损价需小于强平价
        // }

        if (Number(this.inputLossPrice) != 0 && Number(this.inputLossPrice) <= Number(this.rowData.liquidation_price)) {
          if (Number(this.rowMarketInfoShow.last) < Number(this.rowData.open_price)) {
            if (Number(this.inputLossPrice) <= Number(this.rowData.open_price)) {
              (0, _message.showMessage)({
                message: this.translateTitle("买跌时，止损价需大于开仓价"),
                type: "warning"
              });
              return;
            }
            if (Number(this.inputLossPrice) <= Number(this.rowMarketInfoShow.last)) {
              (0, _message.showMessage)({
                message: this.translateTitle("买跌时，止损价需大于当前价"),
                type: "warning"
              });
              return;
            }
          }
        } else if (Number(this.inputLossPrice) != 0) {
          (0, _message.showMessage)({
            message: this.translateTitle("买跌时，止损价需小于强平价"),
            type: "warning"
          });
          return;
        }
      }
      let tempInputProfitPrice = "";
      if (Number(this.inputProfitPrice) == 0) {
        tempInputProfitPrice = "";
      } else {
        tempInputProfitPrice = this.inputProfitPrice;
      }
      let tempInputLossPrice = "";
      if (Number(this.inputLossPrice) == 0) {
        tempInputLossPrice = "";
      } else {
        tempInputLossPrice = this.inputLossPrice;
      }
      let params = {
        position_order_id: this.rowData.position_order_id,
        market_price: this.rowMarketInfoShow.last,
        stop_profit_price: tempInputProfitPrice,
        stop_loss_price: tempInputLossPrice
      };
      if ([35, 36].includes(this.currRow.assets_type)) {
        (0, _order2.apiSetPositionProfitLoss)(params).then(res => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("设置成功"),
              type: "success",
              customClass: "zZindex"
            });
            this.getContractPositionInfo();
            this.setProfitPricedialogClose();
          }
        });
      } else {
        (0, _order.apiSetPositionProfitLoss)(params).then(res => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("设置成功"),
              type: "success",
              customClass: "zZindex"
            });
            this.getFuturesPositionInfo();
            this.setProfitPricedialogClose();
          }
        });
      }
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    //追回本金开启
    onOpenAppendMoney(row) {
      this.from_item_id = row.open_item_id;
      this.to_item_id = row.settlement_item_id;
      this.from_symbol = this.coinInfoMap[row.open_item_id].item_name.toUpperCase();
      this.to_symbol = this.coinInfoMap[row.settlement_item_id].item_name.toUpperCase();
      this.swap_symbol_id = row.symbol_id;
      this.position_order_id = row.position_order_id;
      this.beforeBailNumber = row.bail_number;
      this.afterBailNumber = row.bail_number;
      this.beforeLiquidationPrice = row.liquidation_price;
      this.afterLiquidationPrice = row.liquidation_price;
      this.rowData = row;
      if (![35, 36].includes(this.currRow.assets_type)) {
        this.symbolList = this.staticFuturesSymbolData;
        // this.staticSymbolData  assets_type 35,36 [35, 36].includes(this.currRow.assets_type)
        for (const key in this.symbolList) {
          if (key == row.symbol_id) {
            this.price_precision = this.symbolList[key].price_precision;
            this.settlement_precision = this.symbolList[key].settlement_precision;
            this.contract_value = this.symbolList[key].contract_value;
            this.fee_rate = this.symbolList[key].fee_rate;
            this.liquidation_risk_rate = this.symbolList[key].liquidation_risk_rate;
          }
        }
      } else {
        this.symbolList = this.staticSymbolData;
        for (const key in this.symbolList) {
          if (key == row.symbol_id) {
            this.price_precision = this.symbolList[key].price_precision;
          }
        }
      }
      this.appendMoneydialog = true;
    },
    //追加本金关闭
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    //追加本金-点击全部
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    onAppendMoney() {
      if (Number(this.bail_number) == 0) {
        this.$message({
          message: this.translateTitle("请输入追加金额"),
          type: "error",
          customClass: "zZindex"
        });
        return;
      }
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number
      };
      if ([35, 36].includes(this.currRow.assets_type)) {
        (0, _order2.apiAppendInterest)(params).then(res => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("追加成功"),
              type: "success",
              customClass: "zZindex"
            });
            this.getContractPositionInfo();
            this.appendMoneydialog = false;
          }
        });
      } else {
        (0, _order.apiAppendInterest)(params).then(res => {
          if (res.status == 200) {
            this.$message({
              message: this.translateTitle("追加成功"),
              type: "success",
              customClass: "zZindex"
            });
            this.getFuturesPositionInfo();
            this.appendMoneydialog = false;
          }
        });
      }
    },
    //Base64 To Blob
    convertBase64ToBlob(base64, type) {
      var imgs = base64.replace(/^data:image\/\w+;base64,/, ""); //去掉base64位头部
      var bytes = window.atob(imgs);
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], {
        type: type
      });
    },
    //写入剪切板
    async writeClipboard(src) {
      try {
        const blob = this.convertBase64ToBlob(src, "image/png");
        await navigator.clipboard.write([new ClipboardItem({
          [blob.type]: blob
        })]);
      } catch (err) {
        console.error(err.name, err.message);
      }
    },
    //图片生成失败回调函数
    posterFail(err) {
      console.log("fail", err);
    },
    // 复制成功
    copySuccess() {
      this.$message({
        message: this.translateTitle("复制成功"),
        type: "success",
        customClass: "zZindex"
      });
    },
    // 复制成功
    copyImageSuccess() {
      this.$message({
        message: this.translateTitle("图片已复制"),
        type: "success",
        customClass: "zZindex"
      });
    },
    //图片生成成功回调函数
    posterSuccess(src) {
      this.notifyImg = src;
      this.notifyVisible = true;
      this.writeClipboard(src);
      this.copyImageSuccess();
    },
    createProfitLossPoster(obj) {
      let strs = [];
      let index = 0;
      if (obj.position_info.position_profit_loss_procent >= 0 && obj.position_info.position_profit_loss_procent <= 5) {
        strs = [this.translateTitle("小试牛刀，加个鸡腿！"), this.translateTitle("什么是赢，不输为赢！"), this.translateTitle("小赚小赚，谢谢大家！")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent > 5 && obj.position_info.position_profit_loss_procent < 20) {
        strs = [this.translateTitle("我走的慢，但我永不后退！"), this.translateTitle("要啥自行车！"), this.translateTitle("小赚小赚，毛毛雨啦！")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent >= 20 && obj.position_info.position_profit_loss_procent < 40) {
        strs = [this.translateTitle("不求闻达于诸侯"), this.translateTitle("搏一搏，单车变摩托！"), this.translateTitle("承让了！弟弟!")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent >= 40 && obj.position_info.position_profit_loss_procent < 60) {
        strs = [this.translateTitle("币圈索罗斯， 正是在下！"), this.translateTitle("人之所以能，是因为相信")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent >= 60 && obj.position_info.position_profit_loss_procent < 80) {
        strs = [this.translateTitle("再接再厉，勇创辉煌"), this.translateTitle("就问一句，还有谁？")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent >= 80 && obj.position_info.position_profit_loss_procent < 100) {
        strs = [this.translateTitle("会当凌绝顶，一览众山小"), this.translateTitle("高处不胜寒，何似在人间")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent >= 100) {
        strs = [this.translateTitle("荣耀王者"), this.translateTitle("日进斗金，盆满钵满")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      }
      if (obj.position_info.position_profit_loss_procent <= -50) {
        strs = [this.translateTitle("天生我财必有用，千金散尽还复来")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent < -20 && obj.position_info.position_profit_loss_procent >= -50) {
        strs = [this.translateTitle("亏损，是所有成功者的必修课！")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent < -5 && obj.position_info.position_profit_loss_procent >= -20) {
        strs = [this.translateTitle("及时止损，稳字当头")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      } else if (obj.position_info.position_profit_loss_procent < 0) {
        strs = [this.translateTitle("让我静静，谢谢")];
        index = Math.floor(Math.random() * strs.length);
        obj.solgan = strs[index];
      }
      this.painting = JSON.parse(JSON.stringify(this.profitLossPoster(obj)));
    },
    profitLossPoster(obj) {
      const tempObj = JSON.parse(JSON.stringify(obj));
      let result = {
        width: "650px",
        height: "1023px",
        background: "#fff",
        views: [{
          type: "rect",
          css: {
            top: "0px",
            left: "0px",
            width: "650px",
            height: "848px",
            color: this.sloganColor
          }
        },
        //row.show_name
        {
          type: "image",
          url: this.profitLossShareBg,
          css: {
            top: "0px",
            left: "0px",
            width: "650px",
            height: "598px"
          }
        }, {
          type: "text",
          text: this.currRow.show_name,
          css: {
            top: "30px",
            left: "40px",
            maxLines: 1,
            fontSize: "42px",
            color: "#fff"
          }
        }, {
          type: "image",
          url: tempObj.position_info.position_profit_loss_procent >= 0 ? this.expressionCool : this.expressionBad,
          css: {
            top: "147px",
            left: "215px",
            width: "220px",
            height: "220px"
          }
        }, {
          type: "text",
          text: tempObj.solgan,
          css: {
            top: "399px",
            // left: "40px",
            textAlign: "center",
            width: "650px",
            maxLines: 1,
            fontSize: "34px",
            color: "#fff"
          }
        }, {
          type: "text",
          text: this.translateTitle("收益率"),
          css: {
            top: "489px",
            textAlign: "center",
            width: "650px",
            maxLines: 1,
            fontSize: "34px",
            color: "#ffffff66"
          }
        }, {
          type: "text",
          text: tempObj.operate_list[0] && tempObj.operate_list[0].operate_type == 2 ? tempObj.position_info.profit_loss_ratio >= 0 ? "+" + tempObj.position_info.profit_loss_ratio + "%" : tempObj.position_info.profit_loss_ratio + "%" : tempObj.position_info.position_profit_loss_procent >= 0 ? "+" + tempObj.position_info.position_profit_loss_procent + "%" : tempObj.position_info.position_profit_loss_procent + "%",
          css: {
            top: "535px",
            textAlign: "center",
            width: "650px",
            maxLines: 1,
            fontSize: "84px",
            color: "#ffffff",
            fontWeight: "bold"
          }
        }, {
          type: "rect",
          css: {
            top: "661px",
            left: "25px",
            width: "600px",
            height: "148px",
            color: "#00000019",
            borderRadius: "12px"
          }
        }, {
          type: "text",
          text: this.liquidation_symbol_name,
          css: {
            top: "692px",
            left: "69px",
            fontSize: "24px",
            color: "#ffffff",
            fontWeight: "500"
          }
        }, {
          type: "text",
          text: this.translateTitle("开仓价格"),
          css: {
            top: "692px",
            left: "278px",
            fontSize: "24px",
            color: "#ffffff",
            fontWeight: "500"
          }
        }, {
          type: "text",
          text: this.translateTitle("标记价格"),
          css: {
            top: "692px",
            left: "447px",
            fontSize: "24px",
            color: "#ffffff",
            fontWeight: "500"
          }
        }, {
          type: "rect",
          css: {
            top: "738px",
            left: "70px",
            width: "160px",
            height: "43px",
            color: "#fff",
            borderRadius: "5.2px"
          }
        }, {
          type: "text",
          text: tempObj.position_info.open_side == 1 ? this.translateTitle("多") + "·" + parseInt(tempObj.position_info.open_leverage) + "X" : this.translateTitle("空") + "·" + parseInt(tempObj.position_info.open_leverage) + "X",
          css: {
            top: "743px",
            textAlign: "center",
            left: "70px",
            width: "160px",
            fontSize: "26px",
            color: tempObj.position_info.open_side == 1 ? "rgb(0 188 160)" : "rgb(240 74 90)",
            fontWeight: "600"
          }
        }, {
          type: "text",
          text: tempObj.position_info.open_price,
          css: {
            top: "737px",
            left: "278px",
            fontSize: "30px",
            color: "#fff",
            fontWeight: "500"
          }
        }, {
          type: "text",
          text: tempObj.position_info.last_price,
          css: {
            top: "737px",
            left: "447px",
            fontSize: "30px",
            color: "#fff",
            fontWeight: "500"
          }
        }, {
          type: "image",
          url: this.projectLogo,
          css: {
            top: "888px",
            left: "35px",
            width: "60px",
            height: "60px",
            borderRadius: "100%"
          }
        }, {
          type: "text",
          text: this.projectFullName,
          css: {
            top: "895px",
            left: "110px",
            maxLines: 1,
            fontSize: "37px",
            fontWeight: "600",
            color: "#131f30"
          }
        }, {
          type: "text",
          text: this.translateTitle("邀请码") + ":" + (this.default_invite_code && this.default_invite_code.invite_url && this.default_invite_code.invite_url.split("=")[1]),
          css: {
            top: "953px",
            left: "110px",
            fontSize: "26px",
            color: "#354052",
            fontWeight: "500"
          }
        }, {
          type: "qrcode",
          content: this.centerHost + this.default_invite_code.invite_url,
          css: {
            top: "879px",
            left: "503px",
            color: "#000",
            width: "113px",
            height: "113px"
          }
        }]
      };
      return result;
    },
    // 复制失败
    copyError() {
      this.$message({
        message: this.translateTitle("复制失败"),
        type: "error",
        customClass: "zZindex"
      });
    },
    //获取币种
    async getItemList() {
      const {
        status,
        data
      } = await (0, _index3.getStaticCoinList)();
      if (status == 200) {
        this.coinInfoMap = this.coinData = data;
      }
    },
    //获取默认邀请码
    getRebateCommonInfo() {
      (0, _spreadCenter.apiGetRebateCommonInfo)().then(res => {
        if (res.status == 200) {
          this.default_invite_code = res.data.default_invite_code;
        }
      });
    },
    //获取邀请链接 新
    getAppIntialDataV2() {
      (0, _spreadCenter.apiGetInviteUrl)().then(res => {
        if (res.status == 200) {
          this.centerHost = res.data.invite_url;
        }
      });
    },
    //56-static_chain-区块链
    async getStaticChainMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(56);
      if (status == 200) {
        this.staticChainMap = Object.values(JSON.parse(JSON.stringify(data)));
      }
    },
    //7-static_currency-法币表
    async getStaticCurrencyMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(7);
      if (status == 200) {
        this.staticCurrencyMap = JSON.parse(JSON.stringify(data));
      }
    },
    //22-sotc_static_payment-支付方式静态表
    async getStaticPaymentMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(22);
      if (status == 200) {
        this.sotcStaticPaymentMap = JSON.parse(JSON.stringify(data));
      }
    },
    // 保护时间静态表-sotc_static_protect_type-41
    async getSotcStaticProtectMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(41);
      if (status == 200) {
        this.sotcStaticProtectMap = JSON.parse(JSON.stringify(data));
      }
    },
    // 包赔时间静态表 -sotc_static_guarantee_type-42
    async getSotcStaticGuaranteeMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(42);
      if (status == 200) {
        this.sotcStaticGuaranteeMap = JSON.parse(JSON.stringify(data));
      }
    },
    getSotcOrderDetailAccountInfos(orderObj) {
      // this.sotcStaticPaymentMap
      const payment = this.sotcStaticPaymentMap[orderObj.payment_id];
      const fields = JSON.parse(payment.app_client_field);
      let tempObj = {
        title: "",
        content: "",
        isQrCode: ""
      };
      let resultMap = [];
      Object.keys(fields).forEach(field => {
        if (field != "qr_code_url") {
          const content = orderObj.payment_info[field];
          if (content) {
            tempObj.title = fields[field];
            tempObj.content = content;
            tempObj.isQrCode = false;
            resultMap.push(JSON.parse(JSON.stringify(tempObj)));
          }
        }
      });
      if (fields["qr_code_url"]) {
        const qrCodeUrl = orderObj.payment_info["qr_code_url"];
        if (qrCodeUrl) {
          tempObj.title = "qr_code_url";
          tempObj.content = qrCodeUrl;
          tempObj.isQrCode = true;
          resultMap.push(JSON.parse(JSON.stringify(tempObj)));
        }
      }
      const money = this.handleCutZero(orderObj.payment_info["ceiling"]);
      const content = money ? money + (this.staticCurrencyMap[orderObj.currency_id] && this.staticCurrencyMap[orderObj.currency_id].abbreviate) : "--";
      tempObj.title = this.translateTitle("限额");
      tempObj.content = content;
      tempObj.isQrCode = false;
      resultMap.push(JSON.parse(JSON.stringify(tempObj)));
      tempObj.title = this.translateTitle("附言");
      tempObj.content = orderObj.payment_info["postscript"] || "--";
      tempObj.isQrCode = false;
      resultMap.push(JSON.parse(JSON.stringify(tempObj)));
      this.copyAccountMsg = "";
      resultMap.forEach(item => {
        if (!item.isQrCode && item.content && item.content != "--") {
          this.copyAccountMsg = this.copyAccountMsg + item.title + ":" + item.content + "\n";
        }
      });
      return resultMap;
    },
    //根据链名称获取链信息
    getChainByName(name) {
      let chainObj = {};
      this.staticChainMap.forEach(item => {
        if (item.chain_tag == name) {
          chainObj = item;
        }
      });
      return chainObj;
    },
    //U本位合约交易对
    async getContractConfigBySymbolId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(49);
      if (status == 200) {
        this.staticSymbolData = JSON.parse(JSON.stringify(data));
      }
    },
    //币本位合约交易对
    async getFuturesConfigBySymbolId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(58);
      if (status == 200) {
        this.staticFuturesSymbolData = JSON.parse(JSON.stringify(data));
      }
    },
    //账单类型
    async getStaticAssetsType() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(27);
      if (status == 200) {
        let tempTypeData = Object.values(JSON.parse(JSON.stringify(data)));
        this.staticAssetsTypeData = tempTypeData.filter(item => {
          let filterStatus = !this.outerSearch.includes(item.assets_type);
          return filterStatus && item.is_show == 1;
        });
      }
    },
    //股票静态表
    async getStockConfigBySymbolId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(46);
      if (status == 200) {
        this.staticStockData = JSON.parse(JSON.stringify(data));
      }
    },
    //用户获取订单详情-OTC 充值 提现
    async getUserOrderInfo() {
      const req = {
        pay_order_id: this.currRow.order_info.pay_order_id
      };
      const {
        status,
        data
      } = await (0, _billCenter.apiGetUserOrderInfo)(req);
      if (status == 200) {
        let tempObj = JSON.parse(JSON.stringify(data));
        tempObj.isRechargeWithdrawOrder = ![5, 6].includes(this.currRow.assets_type);
        tempObj.isBuy = [5, 8].includes(this.currRow.assets_type);
        if (!tempObj.isRechargeWithdrawOrder) {
          tempObj.lockTimeType = tempObj.isBuy ? this.sotcStaticProtectMap[tempObj.protect_card_type] : this.sotcStaticGuaranteeMap[tempObj.guarantee_type];
          const status = tempObj.isBuy ? tempObj.protect_card_status : tempObj.guarantee_status;
          const unlockString = tempObj.lockTimeType.option + (tempObj.isBuy ? "[" + this.translateTitle("已解锁") + "]" : "[" + this.translateTitle("已过期") + "]");
          let aging = tempObj.lockTimeType.option;
          if (status == 1) {
            aging = tempObj.lockTimeType.option;
          } else if (status == 3) {
            aging = tempObj.lockTimeType.option + "[" + this.translateTitle("暂停") + "]";
          } else if (status == 4) {
            aging = unlockString;
          } else if (status == 3) {
            const now = parseInt(Date.parse(new Date()) / 1000);
            const expire = tempObj.isBuy ? tempObj.protect_card_expire : tempObj.guarantee_expire;
            const seconds = expire - now;
            const leftTime = _moment.default.utc(seconds).format("HH:mm:ss");
            const leftTimeString = this.translateTitle("剩余") + leftTime;
            aging = seconds <= 0 ? unlockString : tempObj.lockTimeType.option + "[" + leftTimeString + "]";
          }
          tempObj.aging = aging;
        }
        tempObj.receivingAccount = this.getSotcOrderDetailAccountInfos(tempObj);
        let timelineObj = {
          title: "",
          time: "",
          curr: false,
          color: "",
          type: "",
          icon: ""
        };
        let timelineArray = [];
        const cardDidGive = tempObj.issuing_card_time > 0;
        const canceled = tempObj.cancel_time > 0;
        const moneyDidPay = tempObj.payment_time > 0;
        const coinDidRelease = tempObj.do_received_time > 0;
        const isOrderCreatedByServer = tempObj.active_type == "2";
        //订单创建
        timelineObj.time = this.moment(tempObj.create_time * 1000).format("YYYY-MM-DD HH:mm:ss");
        timelineObj.title = tempObj.isBuy ? isOrderCreatedByServer ? this.translateTitle("客服已创建订单，待客服给收款账号") : this.translateTitle("您已创建订单，待客服给收款账号") : isOrderCreatedByServer ? this.translateTitle("客服已创建订单,待您接单") : this.translateTitle("您已创建订单，待客服接单");
        timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        //注意取消订单
        //已给卡
        if (!canceled && !coinDidRelease) {
          timelineObj.time = cardDidGive ? this.moment(tempObj.issuing_card_time * 1000).format("YYYY-MM-DD HH:mm:ss") : "";
          timelineObj.title = tempObj.isBuy ? this.translateTitle("客服已给收款账号，待您付款") : isOrderCreatedByServer ? this.translateTitle("您已接单，客服付款中") : this.translateTitle("客服已接单，付款中");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        //已付款
        if (!canceled && !coinDidRelease) {
          timelineObj.time = moneyDidPay ? this.moment(tempObj.payment_time * 1000).format("YYYY-MM-DD HH:mm:ss") : "";
          timelineObj.title = tempObj.isRechargeWithdrawOrder ? tempObj.isBuy ? this.translateTitle("您已付款，待客服充值") : this.translateTitle("客服已付款，待您确认收款") : tempObj.isBuy ? this.translateTitle("您已付款，待客服放币") : this.translateTitle("客服已付款，待您放币");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        //已放币(已完成)
        if (!canceled) {
          timelineObj.time = coinDidRelease ? this.moment(tempObj.do_received_time * 1000).format("YYYY-MM-DD HH:mm:ss") : "";
          timelineObj.title = tempObj.isRechargeWithdrawOrder ? tempObj.isBuy ? this.translateTitle("客服已充值，订单完成") : this.translateTitle("您已确认收款，订单完成") : tempObj.isBuy ? this.translateTitle("客服已放币，订单完成") : this.translateTitle("您已放币，订单完成");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        const cancelTime = canceled ? this.moment(tempObj.cancel_time * 1000).format("YYYY-MM-DD HH:mm:ss") : "";
        if (canceled) {
          const reasons = {
            5: this.translateTitle("您取消订单"),
            6: this.translateTitle("客服取消订单"),
            7: this.translateTitle("系统取消订单")
          };
          timelineObj.time = cancelTime;
          timelineObj.title = reasons[tempObj.order_status] ? reasons[tempObj.order_status] : this.translateTitle("取消订单");
          timelineArray.push(JSON.parse(JSON.stringify(timelineObj)));
        }
        let nextIndex = 0;
        for (let i = 0; i < timelineArray.length; i++) {
          timelineArray[i].color = "#00bca0";
          timelineArray[i].type = "success";
          timelineArray[i].icon = "el-icon-check";
          if (!timelineArray[i].time) {
            timelineArray[i - 1].curr = true;
            timelineArray[i - 1].color = "#00bca0";
            timelineArray[i - 1].type = "primary";
            timelineArray[i - 1].icon = "el-icon-more";
            timelineArray[i].color = "";
            timelineArray[i].type = "";
            timelineArray[i].icon = "";
            break;
          }
        }
        tempObj.timelineArray = timelineArray;
        const haveAccounts = tempObj.payment_info_list.length > 0;
        if (!haveAccounts) {
          let detailEmptyTitle = tempObj.isBuy ? this.translateTitle("客服正在准备收款账号") : this.translateTitle("请您接单");
          if (tempObj.order_status != "1") {
            detailEmptyTitle = tempObj.isBuy ? this.translateTitle("客服未给收款账号") : this.translateTitle("无收款账号");
          }
          tempObj.detailEmptyTitle = detailEmptyTitle;
        }
        tempObj.picEmptyTitle = this.getPicEmptyTitle(tempObj);
        this.user_order_obj = tempObj;
      }
    },
    getPicEmptyTitle(tempObj) {
      let picEmptyTitle = "";
      if (tempObj.isBuy) {
        if (tempObj.order_status == "1") {
          picEmptyTitle = this.translateTitle("请收到客服收款账号后上传付款截图");
          return picEmptyTitle;
        }
        if (!tempObj.pay_voucher_price_list.length) {
          picEmptyTitle = this.translateTitle("您未上传付款截图");
          return picEmptyTitle;
        }
      } else {
        if (tempObj.order_type == "1") {
          if (tempObj.active_type == "2") {
            picEmptyTitle = this.translateTitle("请您接单");
            return picEmptyTitle;
          }
          picEmptyTitle = this.translateTitle("请等待客服接单");
          return picEmptyTitle;
        }
        if (tempObj.order_type == "2") {
          picEmptyTitle = this.translateTitle("请等待客服付款并上传付款截图");
          return picEmptyTitle;
        }
        if (!tempObj.pay_voucher_price_list.length) {
          picEmptyTitle = this.translateTitle("客服未上传付款截图");
          return picEmptyTitle;
        }
      }
    },
    getOtcOrderStatusMaps(isRechargeWithdrawOrder, isBuy, statusCode) {
      let orderStatus = "";
      switch (statusCode) {
        case "1":
          orderStatus = isBuy ? this.translateTitle("待客服给卡") : this.translateTitle("待客服接单");
          break;
        case "2":
          orderStatus = isBuy ? this.translateTitle("待您付款") : this.translateTitle("待客服付款");
          break;
        case "3":
          orderStatus = isRechargeWithdrawOrder ? isBuy ? this.translateTitle("待客服充值") : this.translateTitle("待您确认收款") : isBuy ? this.translateTitle("待客服放币") : this.translateTitle("待您放币");
          break;
        case "4":
          orderStatus = this.translateTitle("订单完成");
          break;
        case "5":
          orderStatus = this.translateTitle("用户取消");
          break;
        case "6":
          orderStatus = this.translateTitle("客服取消");
          break;
        case "7":
          orderStatus = this.translateTitle("系统取消");
          break;
        default:
      }
      return orderStatus;
    },
    //获取U本位合约详情
    async getContractPositionInfo() {
      const req = {
        position_order_id: this.position_order_id
      };
      const {
        status,
        data
      } = await (0, _billCenter.apiGetContractPositionInfo)(req);
      if (status == 200) {
        let position_order_obj_temp = JSON.parse(JSON.stringify(data));
        if ([35, 36].includes(this.currRow.assets_type)) {
          this.symbolList = this.staticSymbolData;
        } else {
          this.symbolList = this.staticFuturesSymbolData;
        }
        for (const key in this.symbolList) {
          if (this.symbolList[key].open_item_id == position_order_obj_temp.position_info.open_item_id) {
            position_order_obj_temp.position_info.settlement_precision = this.symbolList[key].settlement_precision;
            position_order_obj_temp.position_info.contract_value = this.symbolList[key].contract_value;
          }
        }
        this.liquidation_dialog_loading = false;
        this.position_order_obj = position_order_obj_temp;
      }
    },
    //获取币本位合约详情
    async getFuturesPositionInfo() {
      const req = {
        position_order_id: this.position_order_id
      };
      const {
        status,
        data
      } = await (0, _billCenter.apiGetFuturesPositionInfo)(req);
      if (status == 200) {
        let position_order_obj_temp = JSON.parse(JSON.stringify(data));
        if ([35, 36].includes(this.currRow.assets_type)) {
          this.symbolList = this.staticSymbolData;
        } else {
          this.symbolList = this.staticFuturesSymbolData;
        }
        for (const key in this.symbolList) {
          if (this.symbolList[key].open_item_id == position_order_obj_temp.position_info.open_item_id) {
            position_order_obj_temp.position_info.settlement_precision = this.symbolList[key].settlement_precision;
            position_order_obj_temp.position_info.contract_value = this.symbolList[key].contract_value;
          }
        }
        this.liquidation_dialog_loading = false;
        this.position_order_obj = position_order_obj_temp;
      }
    },
    async productInfoClick(row) {
      this.currRow = row;
      this.currRow.coinImageUrl = this.coinData[this.currRow.item_id].itemDetail.coin_url;
      this.currRow.coinItemName = this.coinData[this.currRow.item_id].item_name.toUpperCase();
      await this.getUserOrderInfo();
      this.otcDialogVisible = true;
    },
    //请求U本位合约并打开仓位详情
    async liquidationContractInfoClick(tempRow = "") {
      this.liquidation_dialog_loading = true;
      this.reChargeDialogVisible = true;
      this.currRow = tempRow;
      this.position_order_id = this.currRow.order_info.position_order_id;
      await this.getContractPositionInfo();
      this.liquidation_symbol_name = this.staticSymbolData[this.position_order_obj.position_info.symbol_id] && this.staticSymbolData[this.position_order_obj.position_info.symbol_id].language[`symbol_name_${this.language}`];
    },
    //请求币本位合约并打开仓位详情
    async liquidationFuturesInfoClick(tempRow = "") {
      this.liquidation_dialog_loading = true;
      this.reChargeDialogVisible = true;
      this.currRow = tempRow;
      this.position_order_id = this.currRow.order_info.position_order_id;
      await this.getFuturesPositionInfo();
      this.liquidation_symbol_name = this.staticFuturesSymbolData[this.position_order_obj.position_info.symbol_id] && this.staticFuturesSymbolData[this.position_order_obj.position_info.symbol_id].language[`symbol_name_${this.language}`];
    },
    reChargehandleClose() {
      this.reChargeDialogVisible = false;
    },
    reChargehandleClosed() {
      this.liquidation_symbol_name = "--";
      this.position_order_obj = {
        position_info: {},
        operate_list: []
      };
    },
    otcHandleClose(row) {
      this.otcDialogVisible = false;
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    handleCutZero(num) {
      if (!num) return 0;
      //拷贝一份 返回去掉零的新串
      let newstr = num;
      //循环变量 小数部分长度
      // console.log(num.indexOf('.') - 1);
      let leng = num.length - num.indexOf(".") - 1;
      //判断是否有效数
      if (num.indexOf(".") > -1) {
        //循环小数部分
        for (let i = leng; i > 0; i--) {
          //如果newstr末尾有0
          if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
            let k = newstr.lastIndexOf("0");
            //如果小数点后只有一个0 去掉小数点
            if (newstr.charAt(k - 1) == ".") {
              return newstr.substring(0, k - 1);
            } else {
              //否则 去掉一个0
              newstr = newstr.substring(0, k);
            }
          } else {
            //如果末尾没有0
            return newstr;
          }
        }
      }
      return num;
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };

      const {
        status,
        data
      } = await (0, _index.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "assets"
          }
        },
        callback,
        onConnect,
        mounted
      };
      let wsNew2 = new _socket.default(option).getInstance();
      me.global.setWs2(wsNew2);
      // 重新连接
      function onConnect() {
        if (me.isDestoryWS) return;
        if (me.lockReconnect) return;
        // if (me.wsContentTime > 3) {
        //   window.location.reload();
        // }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        me.global.ws2.sub({
          action: "Topic.sub",
          data: {
            type: "contractM",
            app_id: _stationSetting.VUE_APP_ID
          }
        });
        me.global.ws2.sub({
          action: "Topic.sub",
          data: {
            type: "futureM",
            app_id: _stationSetting.VUE_APP_ID
          }
        });
      }
      function callback(data) {
        const {
          action
        } = data;
        if (action == "Pushdata.futureM") {
          if (data.data) {
            if ([35, 36].includes(me.currRow && me.currRow.assets_type)) return;
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }
        if (action == "Pushdata.contractM") {
          if (data.data) {
            if (![35, 36].includes(me.currRow && me.currRow.assets_type)) {
              return;
            }
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }

        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
      }
    }
  },
  watch: {
    marketListTran: {
      //深度监听，可监听到对象、数组的变化
      handler(newV, oldV) {
        if (!this.isStartWS) {
          this.marketList = newV;
        }
      },
      deep: true
    },
    assetsInfoTran: {
      //深度监听，可监听到对象、数组的变化
      handler(newV, oldV) {
        if (!this.isStartWS) {
          this.assetsInfo = newV;
        }
      },
      deep: true
    },
    marketList() {
      if ([35, 36].includes(this.currRow.assets_type)) {
        this.marketList.forEach(item => {
          if (item.symbol_id == this.position_order_obj.position_info.symbol_id) {
            this.position_order_obj.position_info.last_price = item.last;
          }
        });
        let tempSide = this.position_order_obj.position_info.open_side == 1 ? 1 : -1;
        // 未实现盈亏
        this.position_order_obj.position_info.position_profit_loss = ((this.position_order_obj.position_info.last_price - this.position_order_obj.position_info.open_price) * tempSide * this.position_order_obj.position_info.open_interest).toFixed(2);
        this.position_order_obj.position_info.position_profit_loss_procent = ((this.position_order_obj.position_info.last_price - this.position_order_obj.position_info.open_price) * tempSide * this.position_order_obj.position_info.open_interest / this.position_order_obj.position_info.bail_number * 100).toFixed(2);
      } else {
        this.marketList.forEach(item => {
          if (item.symbol_id == this.position_order_obj.position_info.symbol_id) {
            this.position_order_obj.position_info.last_price = item.last;
          }
        });

        // 未实现盈亏 = 持仓张数 × 单张合约价值 × (1/开仓价 − 1/市场价)
        let tempContractValue = new _bignumber.default(this.position_order_obj.position_info.contract_value);
        let side = this.position_order_obj.position_info.open_side == 1 ? 1 : -1;
        let tempSide = new _bignumber.default(side);
        let one = new _bignumber.default(1);
        let oneHundred = new _bignumber.default(100);
        let tempQty = new _bignumber.default(this.position_order_obj.position_info.qty);
        let tempOpenPrice = new _bignumber.default(this.position_order_obj.position_info.open_price);
        let tempLastPrice = new _bignumber.default(this.position_order_obj.position_info.last_price);
        let tempSettlementPrecision = new _bignumber.default(this.position_order_obj.position_info.settlement_precision);
        // 未实现盈亏
        this.position_order_obj.position_info.position_profit_loss = tempQty.times(tempContractValue).times(one.div(tempOpenPrice).minus(one.div(tempLastPrice))).times(tempSide).toNumber().toFixed(this.position_order_obj.position_info.settlement_precision);
        // .toFixed(this.number_precision);
        let tempValue = new _bignumber.default(this.position_order_obj.position_info.position_profit_loss);
        this.position_order_obj.position_info.position_profit_loss_procent = tempValue.div(this.position_order_obj.position_info.bail_number).times(oneHundred).toFixed(2);
      }
    }
  }
};
exports.default = _default;