"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _trade = require("@/model/ucenter/trade");
var _message = require("@/components/dialog/message.js");
var _index = require("@/model/exchange/index");
var _settings = require("@/model/settings");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _index2 = require("@/utils/index.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _i18n = require("@/assets/js/i18n");
var _order = require("@/model/contractExchange/order.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 4,
        pageCurrent: 1
      },
      cardLoading: true,
      itemList: [],
      bail_number: "",
      position_order_id: "",
      dialogVisible: false,
      appendMoneydialog: false,
      setProfitPricedialog: false,
      contractList: [],
      beforeBailNumber: "",
      afterBailNumber: "--",
      inputProfitPrice: "",
      inputLossPrice: "",
      beforeLiquidationPrice: "",
      afterLiquidationPrice: "--",
      symbolList: {},
      apiAssetsInfo: {},
      rowData: {},
      contractProfitMap: {},
      assetsInfo: {
        type: Object,
        default: function () {
          return {
            remain: {
              all_u_valuation: {
                usdt: "--"
              },
              list: []
            }
          };
        }
      },
      sideMap: {
        1: "买",
        2: "卖"
      }
    };
  },
  components: {
    InputNumber: _InputNumber.default,
    NoData: _NoData.default
  },
  created() {
    this.getItemList();
    this.getTradePairList();
    this.getAssets();
    this.getPositionList();
    this.startTimer();
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    parseTime: _index2.parseTime,
    onClearAll() {
      this.inputProfitPrice = "";
    },
    onClearAllV2() {
      this.inputLossPrice = "";
    },
    onPageChange() {
      this.getPositionList();
    },
    getItemList() {
      let settingList = JSON.parse(localStorage.getItem("settingList"));
      this.itemList = settingList[13];
    },
    startTimer() {
      this.getPositionList().then(() => {
        const timer = setTimeout(() => {
          this.startTimer();
        }, 500);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(timer);
        });
      });
    },
    async getPositionList() {
      const req = {
        position_status: 1,
        page: this.pageInfo.pageCurrent,
        page_size: this.pageInfo.pageSize
      };
      const {
        status,
        data
      } = await (0, _trade.apiGetPositionList)(req);
      this.cardLoading = false;
      if (status == 200) {
        data.list.forEach(ele => {
          for (let item in this.itemList) {
            if (ele.open_item_id == this.itemList[item].item_id) {
              ele.open_item_name = this.itemList[item].item_name.toUpperCase();
            }
            if (ele.settlement_item_id == this.itemList[item].item_id) {
              ele.settlement_item_name = this.itemList[item].item_name.toUpperCase();
            }
          }
        });
        this.contractList = data.list;
        this.pageInfo = data.pageInfo;
      }
    },
    onClosePosition() {
      let params = {
        position_order_id: this.position_order_id
      };
      (0, _order.apiClosePosition)(params).then(res => {
        if (res.status == 200) {
          this.dialogVisible = false;
          (0, _message.showMessage)({
            message: "平仓成功",
            type: "success"
          });
          // this.initData(this.checked);
        }
      });
    },

    onAppendMoney() {
      let params = {
        position_order_id: this.position_order_id,
        bail_number: this.bail_number
      };
      (0, _order.apiAppendInterest)(params).then(res => {
        if (res.status == 200) {
          (0, _message.showMessage)({
            message: "追加成功",
            type: "success"
          });
          this.appendMoneydialog = false;
          this.initData(this.checked);
        }
      });
    },
    onProfitPriceEdit(row) {
      this.rowData = row;
      this.row_swap_symbol_id = row.symbol_id;
      this.setProfitPricedialog = true;
    },
    appendMoneydialogClose() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onCloseAppend() {
      this.bail_number = "";
      this.appendMoneydialog = false;
    },
    onOpenConfirm(position_order_id) {
      this.position_order_id = position_order_id;
      this.dialogVisible = true;
    },
    setProfitPricedialogClose() {
      this.inputLossPrice = "";
      this.inputProfitPrice = "";
      this.setProfitPricedialog = false;
    },
    onOpenAppendMoney(item) {
      this.position_order_id = item.position_order_id;
      this.beforeBailNumber = item.bail_number;
      this.afterBailNumber = item.bail_number;
      this.beforeLiquidationPrice = item.liquidation_price;
      this.afterLiquidationPrice = item.liquidation_price;
      this.rowData = item;
      // this.symbol_id = item.symbol_id
      this.appendMoneydialog = true;
    },
    onClickAll() {
      this.bail_number = this.assetsInfoShow;
    },
    onSetPositionProfitLoss() {
      let params = {
        position_order_id: this.rowData.position_order_id,
        market_price: this.rowMarketInfoShow.last,
        stop_profit_price: this.inputProfitPrice,
        stop_loss_price: this.inputLossPrice
      };
      (0, _order.apiSetPositionProfitLoss)(params).then(res => {
        if (res.status == 200) {
          (0, _message.showMessage)({
            message: "设置成功",
            type: "success"
          });
          this.setProfitPricedialogClose();
          this.initData(this.checked);
        }
      });
    },
    async getAssets() {
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsInfo = data;
        this.contractProfitMap = data.contract;
      }
    },
    async getTradePairList() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(49);
      if (status == 200) {
        this.symbolList = data;
      }
    }
  },
  computed: {
    // 现有U资产
    assetsInfoShow() {
      let tempObj = "0";
      if (JSON.stringify(this.assetsInfo) != "{}" && this.assetsInfo.remain.list.length > 0) {
        this.assetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else if (JSON.stringify(this.apiAssetsInfo) != "{}" && this.apiAssetsInfo.remain.list.length > 0) {
        this.apiAssetsInfo.remain.list.forEach(ele => {
          if (ele.item_id == this.to_item_id) {
            tempObj = ele.over;
          }
        });
      } else {
        tempObj = "--";
      }
      return tempObj;
    },
    afterLiquidationPriceShow() {
      // 强平价 = 开仓价 - (1 做多 | -1做空)) ×(保证金 × (1 − 强平⻛险率) - 平仓手续费-开仓手续费)/持仓数量
      let tempValue = "";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.bail_number != "") {
        tempValue = (this.rowData.open_price - tempSide * (this.afterBailNumberShow * (1 - this.symbolList[this.rowData.symbol_id].liquidation_risk_rate / 10000) - this.rowData.close_position_fee - this.rowData.open_position_fee) / this.rowData.open_interest).toFixed(2);
      } else {
        tempValue = this.afterLiquidationPrice;
      }
      return tempValue;
    },
    afterBailNumberShow() {
      return (Number(this.afterBailNumber) + Number(this.bail_number)).toFixed(2);
    },
    // 平仓盈亏 = (inputProfitPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量  - 平仓手续费
    expectedProfit() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        tempValue = ((this.inputProfitPrice - this.rowData.open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee).toFixed(2);
      }
      // inputProfitPrice
      return tempValue;
    },
    expectedProfitPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputProfitPrice != "" && Number(this.inputProfitPrice) != 0) {
        tempValue = (((this.inputProfitPrice - this.rowData.open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee) / this.rowData.bail_number).toFixed(2);
        if (Number(tempValue) > 0) {
          tempValue = "+" + tempValue;
        }
      }
      // inputProfitPrice
      return tempValue;
    },
    // 平仓盈亏 = (inputLossPrice-开仓价) × (1 做多 | -1做空)) × 持仓数量  - 平仓手续费
    expectedLoss() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        tempValue = ((this.inputLossPrice - this.rowData.open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee).toFixed(2);
      }
      // inputLossPrice
      return tempValue;
    },
    expectedLossPercent() {
      let tempValue = "--";
      let tempSide = this.rowData.open_side == 1 ? 1 : -1;
      if (this.inputLossPrice != "" && Number(this.inputLossPrice) != 0) {
        tempValue = (((this.inputLossPrice - this.rowData.open_price) * tempSide * this.rowData.open_interest - this.rowData.close_position_fee) / this.rowData.bail_number).toFixed(2);
      }

      // inputLossPrice
      return tempValue;
    }
  }
};
exports.default = _default;