"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      queryList: {
        time: "",
        side: "",
        currency: 10007,
        item: ""
      },
      tableList: [],
      sideOptions: [{
        value: "",
        label: "全部方向"
      }, {
        value: "1",
        label: "买涨"
      }, {
        value: "2",
        label: "买跌"
      }],
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 4,
        pageCurrent: 1
      },
      itemList: [],
      timeList: [{
        value: "",
        label: "全部"
      }, {
        value: "1",
        label: "今天"
      }, {
        value: "2",
        label: "7天"
      }, {
        value: "3",
        label: "1月"
      }, {
        value: "4",
        label: "3月"
      }],
      currencyList: [{
        value: 10007,
        label: "USDT"
      }],
      queryLoading: false,
      restLoading: false,
      tableLoading: false
    };
  },
  components: {
    NoData: _NoData.default
  },
  methods: {
    onReset() {},
    onQuery() {},
    onPageChange() {}
  }
};
exports.default = _default;