<template>
  <div class="page-header-hx">
    <div class="left-pages">
      <a :href="homeUrl">
        <img :draggable="false" class="logo" :src="logImg" alt />
      </a>
      <ul class="pages-routes">
        <a :href="routeSplicing(language, `markets`)">
          <li :class="active == 1 ? 'active' : ''">
            <a
              :href="routeSplicing(language, `markets`)"
              style="margin-right: 5px"
              >{{ translateTitle("行情") }}</a
            >
          </li>
        </a>

        <!-- 新菜单开始 -->
        <a @mouseenter="mouseenterTrade" @mouseleave="mouseleaveTrade">
          <li :class="active == 2 ? 'active' : ''">
            <a
              >{{ translateTitle("交易") }}
              <i
                class="iconfont icon-top"
                :class="activeTrade ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeTrade">
            <a class="it" :href="routeSplicing(language, 'exchange/btc_usdt')">
              <img
                src="../../assets/img_n/theme/header_menu/bbjy.png"
                class="i"
              />
              <div class="t-st">
                <span class="t">{{ translateTitle("币币交易") }} </span>
                <span class="st"
                  >{{ translateTitle("便捷交易数字货币") }}
                </span>
              </div>

              <i class="iconfont iconright act"></i>
            </a>
            <div class="it" @click="onDownloadTipsDialog">
              <!-- <div class="it"> -->
              <img
                src="../../assets/img_n/theme/header_menu/otc.png"
                class="i"
              />

              <div class="t-st">
                <span class="t">{{ translateTitle("C2C") }} </span>
                <span class="st"
                  >{{ translateTitle("秒速入金，即刻开始") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it">
              <img
                src="../../assets/img_n/theme/header_menu/gp.png"
                class="i"
              />

              <div class="t-st" @click="onDownloadTipsDialog">
                <span class="t">{{ translateTitle("股票") }} </span>
                <span class="st"
                  >{{ translateTitle("无门槛，快速投资股市") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/hd.png"
                class="i"
              />

              <div class="t-st">
                <span class="t">{{ translateTitle("汇兑") }} </span>
                <span class="st"
                  >{{ translateTitle("法币、数字货币快速兑换") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/cljy.png"
                class="i"
              />
              <div class="t-st">
                <span class="t">{{ translateTitle("策略交易") }} </span>
                <span class="st">{{ translateTitle("定投数字货币") }} </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>

        <a @mouseenter="mouseenterDeri" @mouseleave="mouseleaveDeri">
          <li :class="active == 3 || active == 6 ? 'active' : ''">
            <a
              >{{ translateTitle("衍生品") }}
              <i
                class="iconfont icon-top"
                :class="activeDeri ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeDeri">
            <!-- :href="routeSplicing(language, `contract-exchange/btcusdt`)" -->
            <a class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/ubw.png"
                class="i"
              />
              <div class="t-st">
                <span class="t">{{ translateTitle("U本位合约") }} </span>
                <span class="st"
                  >{{ translateTitle("以USDT结算的永续合约") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </a>

            <!--  <a class="it" :href="routeSplicing(language, `delivery/btcusd`)">
              <img
                src="../../assets/img_n/theme/header_menu/bbw.png"
                class="i"
              />
              <div class="t-st">
                <span class="t">{{ translateTitle("币本位合约") }} </span>
                <span class="st"
                  >{{ translateTitle("以数字货币结算的永续合约") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </a>
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/cjgg.png"
                class="i"
              />
              <div class="t-st">
                <span class="t">{{ translateTitle("超级杠杆") }} </span>
                <span class="st"
                  >{{ translateTitle("最高500倍，放大收益") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div> -->
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/wh.png"
                class="i"
              />

              <div class="t-st" @click="onDownloadTipsDialog">
                <span class="t">{{ translateTitle("外汇") }} </span>
                <span class="st"
                  >{{ translateTitle("全球货币期货交易") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/qh.png"
                class="i"
              />

              <div class="t-st" @click="onDownloadTipsDialog">
                <span class="t">{{ translateTitle("期货") }} </span>
                <span class="st"
                  >{{ translateTitle("商品大宗交易衍生品") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/gz.png"
                class="i"
              />
              <div class="t-st" @click="onDownloadTipsDialog">
                <span class="t">{{ translateTitle("股指") }} </span>
                <span class="st">{{ translateTitle("股市指标衍生品") }} </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>

        <a @mouseenter="mouseenterEarn" @mouseleave="mouseleaveEarn">
          <li :class="active == 4 ? 'active' : ''">
            <a
              >{{ translateTitle("金融理财") }}
              <i
                class="iconfont icon-top"
                :class="activeEarn ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeEarn">
            <a class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/lc.png"
                class="i"
              />

              <div class="t-st">
                <span class="t">{{ translateTitle("理财") }} </span>
                <span class="st">{{ translateTitle("一站式投资服务") }} </span>
              </div>
              <i class="iconfont iconright act"></i>
            </a>

            <div class="it" @click="onDownloadTipsDialog">
              <!-- <div class="it"> -->
              <img
                src="../../assets/img_n/theme/header_menu/defi.png"
                class="i"
              />

              <div class="t-st">
                <span class="t">{{ translateTitle("DeFi") }} </span>
                <span class="st"
                  >{{ translateTitle("轻松参与去中心化金融") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>

            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/dyjd.png"
                class="i"
              />

              <div class="t-st">
                <span class="t">{{ translateTitle("抵押借贷") }} </span>
                <span class="st"
                  >{{ translateTitle("随心借还，高抵押率") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>

        <a @mouseenter="mouseenterMore" @mouseleave="mouseleaveMore">
          <li :class="active == 5 ? 'active' : ''">
            <a
              >{{ translateTitle("更多") }}
              <i
                class="iconfont icon-top"
                :class="activeMore ? 'iconsize16-1' : 'iconsize16-1 trax'"
              ></i>
            </a>
          </li>
          <div class="li-sub-menu" v-if="activeMore">
            <div class="it">
              <img
                src="../../assets/img_n/theme/header_menu/gd.png"
                class="i"
              />
              <div class="t-st" @click="onDownloadTipsDialog">
                <span class="t">{{ translateTitle("跟单") }} </span>
                <span class="st"
                  >{{ translateTitle("实时跟随交易大咖") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
            <div class="it">
              <img
                src="../../assets/img_n/theme/header_menu/mnp.png"
                class="i"
              />

              <div class="t-st" @click="onDownloadTipsDialog">
                <span class="t">{{ translateTitle("模拟盘") }} </span>
                <span class="st"
                  >{{ translateTitle("真实合约交易模拟") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>

            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/xyx.png"
                class="i"
              />

              <div class="t-st">
                <span class="t">{{ translateTitle("小游戏") }} </span>
                <span class="st"
                  >{{ translateTitle("畅玩海量区块链游戏") }}
                </span>
              </div>

              <i class="iconfont iconright act"></i>
            </div>

            <div class="it" @click="onDownloadTipsDialog">
              <img
                src="../../assets/img_n/theme/header_menu/lts.png"
                class="i"
              />
              <div class="t-st">
                <span class="t">{{ translateTitle("聊天室") }} </span>
                <span class="st"
                  >{{ translateTitle("分享交易心得，即时交流") }}
                </span>
              </div>
              <i class="iconfont iconright act"></i>
            </div>
          </div>
        </a>
      </ul>
    </div>
    <div class="right-menus">
      <span
        class="station-name"
        v-if="app_env == 'test' || app_env == undefined"
      >
        {{ envName }}
      </span>
      <span
        class="customer-service"
        v-if="isUEEx && userInfo"
        @click="onCustomerServiceClick"
      >
        {{ translateTitle("咨询客服") }}
      </span>
      <span v-if="userInfoShow" class="quick-access">
        <a :href="routeSplicing(language, `ucenter/assets`)">
          {{ translateTitle("资产") }}
        </a>
      </span>
      <span v-if="userInfoShow" class="quick-access">
        <a :href="routeSplicing(language, `ucenter/tradeManagment`)">
          {{ translateTitle("订单") }}
        </a>
      </span>
      <el-menu class="right-menu" mode="horizontal" @select="handleSelect">
        <template>
          <!-- 个人中心 -->
          <user-menu></user-menu>
        </template>
        <!-- <template>
          <a href="/letter">
            <li class="letter-center">
              <el-badge class="item" is-dot :hidden="true">
                <img src="../../assets/img/letter.png" alt="" />
              </el-badge>
              <div class="driver"></div>
            </li>
          </a>
        </template> -->
        <template>
          <!-- 注册 -->
          <el-menu-item class="nav-menu-item login" v-show="!userInfoShow">
            <router-link
              active-class="none-active"
              :to="routeSplicing(language, 'login')"
              >{{ translateTitle("登录") }}</router-link
            >
          </el-menu-item>
          <!-- 注册 -->
          <el-menu-item
            class="nav-menu-item register theme-btn"
            v-show="!userInfoShow"
          >
            <router-link
              active-class="none-active"
              :to="routeSplicing(language, 'register')"
              >{{ translateTitle("注册") }}</router-link
            >
          </el-menu-item>
        </template>
        <template>
          <li class="vertical-line-li">
            <span class="vertical-line"></span>
          </li>
        </template>
        <template>
          <li
            class="download-app"
            @mouseenter="onShowDownLoad"
            @mouseleave="onHiddenDownLoad"
          >
            <span class="download-app-text">
              <!-- <i class="iconfont iconicon-hd-3"></i> -->
              <img :src="downIcon" class="down_icon" />
              {{ translateTitle("APP下载") }}</span
            >
            <div v-show="showDownLoadImg" class="down-box">
              <div class="down-box-content">
                <p class="down-load-text">
                  {{ translateTitle("手机扫码下载APP，即刻开启交易") }}
                </p>
                <span class="img-content">
                  <div class="down-load-url-outer">
                    <img class="down-load-url" :src="appDownLoadImg" alt="" />
                  </div>
                  <p class="left-item">
                    <span class="down-load-item-text">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img_n/apple_down.png"
                        alt=""
                      />
                      <span class="down-text">iOS</span>
                    </span>
                    <span class="down-load-item-text V2">
                      <img
                        class="icon-title"
                        draggable="false"
                        src="../../assets/img_n/android_down.png"
                        alt=""
                      />
                      <span class="down-text">Android</span>
                    </span>
                  </p>
                </span>
              </div>
            </div>
          </li>
        </template>
        <!-- 法币 -->

        <!-- 语言 -->
        <li
          @click="onMaskLangDialog"
          class="el-submenu sub-item-wrap select-lang-masks"
        >
          <span>
            {{ language_name }}
          </span>
          <span>/</span>
          <span>
            {{ currencys.toUpperCase() }}
          </span>
          <img :src="expandIcon" class="expand" />
        </li>
      </el-menu>
    </div>

    <el-dialog
      custom-class="langVisible_n"
      :visible.sync="langVisible"
      :show-close="false"
    >
      <img
        src="../../assets/img_n/close_icon.png"
        class="close_icon"
        @click="
          () => {
            langVisible = false;
          }
        "
      />
      <el-tabs v-model="activeName">
        <el-tab-pane :label="translateTitle('语言和地区')" name="first">
          <p class="pane-title">{{ translateTitle("选择语言和地区") }}</p>
          <ul>
            <li
              v-for="item in countryList"
              :key="item.value"
              @click="onLangClick(item)"
            >
              <span :class="languageName == item.name ? 'red-lang-curr' : ''">{{
                item.name
              }}</span>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane :label="translateTitle('法币')" name="second">
          <p class="pane-title">{{ translateTitle("选择法币") }}</p>
          <ul>
            <li
              v-for="(item, index) in currencyMaps"
              :key="index"
              @click="onCurrencyChange(item.logic_name)"
            >
              <span :class="currency == item.abbreviate ? 'red-lang-curr' : ''">
                {{ `${item.mark} ${item.abbreviate}` }}
              </span>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <el-dialog
      custom-class="downloadTipsVisible"
      :visible.sync="downloadTipsVisible"
      :show-close="true"
      :before-close="closeDownloadTipsDialog"
    >
      <p class="t">{{ translateTitle("APP下载提示") }}</p>
      <p class="c">
        {{
          translateTitle("您可下载") +
          "APP " +
          translateTitle("使用该功能业务,该WEB功能正在开发中，敬请期待！")
        }}
      </p>
      <div class="d">
        <div class="b">
          <a :href="appstore_url_new" target="_blank">
            <img src="../../assets/img/Apple-store-2.png" class="as" />
          </a>
          <a :href="android_url_new" target="_blank">
            <img src="../../assets/img/apk-day.png" class="apk" />
          </a>
        </div>
        <div class="q">
          <img :src="appDownLoadImg" class="code" />
          <span class="qt">{{
            translateTitle("扫码下载iOS&Android APP")
          }}</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Cookies from "js-cookie";
import commonMixin from "@/components/common/commonMixin.vue";
import menuControlMixin from "@/components/common/menuControlMixin.vue";
import QrcodeVue from "qrcode.vue";
import UserMenu from "./UserMenu";
import { apiGetStaticTableInfo } from "@/model/settings";
// const { VUE_APP_ID, VUE_APP_APP } = process.env;
import zhCN from "../../assets/img/lang/f-cn.png";
import zhHK from "../../assets/img/lang/f-hk.png";
import enUS from "../../assets/img/lang/f-en.png";
import Ja from "../../assets/img/lang/f-ja.png";
import Ko from "../../assets/img/lang/f-ko.png";

import ar from "../../assets/img/lang/f-ar.png";
import fr from "../../assets/img/lang/f-fr.png";
import es from "../../assets/img/lang/f-es.png";
import ru from "../../assets/img/lang/f-ru.png";
import de from "../../assets/img/lang/f-de.png";
import pt from "../../assets/img/lang/f-pt.png";
import hi from "../../assets/img/lang/f-hi.png";
import vi from "../../assets/img/lang/f-vi.png";
import tl from "../../assets/img/lang/f-tl.png";
import th from "../../assets/img/lang/f-th.png";
import tr from "../../assets/img/lang/f-tr.png";
import it from "../../assets/img/lang/f-it.png";
import id from "../../assets/img/lang/f-id.png";
import {
  VUE_APP_ID,
  VUE_CHANNEL_ID,
  VUE_UNION_STATION,
  VUE_APP_ENV,
  WEB_OPEN_COINM,
} from "@/assets/js/stationSetting";
import { apiWsUrl } from "@/model/exchange/index";
import { getLangPackage } from "@/assets/js/lang";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin, menuControlMixin],
  components: {
    UserMenu,
    // QrcodeVue,
  },
  data() {
    return {
      WEB_OPEN_COINM,
      homeUrl: "",
      app_env: "",
      languageName: "English",
      currency: "CNY",
      appDownLoadImg: "",
      appstore_url: "",
      tipUrl: "",
      google_play_url: "",
      langVisible: false,
      showDownLoadImg: false,
      downloadTipsVisible: false,
      isMaskEx: false,
      isHx: false,
      is8V: false,
      isbitda: false,
      isUEEx: false,
      isAhex: false,
      is8Coin: false,
      isB9: false,
      currencyMaps: {},
      logImg: require("../../assets/img/logo.png"),
      imgUrl: require("../../assets/img/en.png"),
      downIcon: require("../../assets/img_n/down_icon.png"),
      expandIcon: require("../../assets/img_n/expand.png"),
      countryList: [
        {
          name: "English",
          value: "en",
          img: enUS,
        },
        {
          name: "日本語",
          value: "ja",
          img: Ja,
        },
        {
          name: "한국인",
          value: "ko",
          img: Ko,
        },

        {
          name: "Français",
          value: "fr",
          img: fr,
        },
        {
          name: "Español",
          value: "es",
          img: es,
        },
        {
          name: "Pусский",
          value: "ru",
          img: ru,
        },
        {
          name: "Deutsch",
          value: "de",
          img: de,
        },
        {
          name: "Português",
          value: "pt",
          img: pt,
        },
        {
          name: "हिंदी",
          value: "hi",
          img: hi,
        },
        {
          name: "Tiếng Việt",
          value: "vi",
          img: vi,
        },
        {
          name: "Pilipino",
          value: "tl",
          img: tl,
        },
        {
          name: "ภาษาไทย",
          value: "th",
          img: th,
        },
        {
          name: "Türk",
          value: "tr",
          img: tr,
        },
        {
          name: "Italiano",
          value: "it",
          img: it,
        },
        {
          name: "bahasa Indonesia",
          value: "id",
          img: id,
        },
        {
          name: "عربي",
          value: "ar",
          img: ar,
        },
        {
          name: "中文简体",
          value: "zh_CN",
          img: zhCN,
        },
        {
          name: "中文繁體",
          value: "zh_HK",
          img: zhHK,
        },
      ],
      activeName: "first",
      android_url_new: "",
      appstore_url_new: "",
      activeTrade: false,
      activeDeri: false,
      activeEarn: false,
      activeMore: false,
    };
  },
  created() {
    this.homeUrl = `/${this.language}`;
    // this.homeUrl = this.language == "en" ? `/` : `/${this.language}`;
    this.app_env = VUE_APP_ENV;
    this.initURL();
    if (
      // 汇信
      VUE_APP_ID == "AwyOTFRlsfQ5mRkqwCNaEd5T" ||
      VUE_APP_ID == "DwyOTFRlsfQ5mRkqwCNaEd5T" ||
      VUE_APP_ID == "J8tstegyx2UjYzJ6J1vNuD3w"
    ) {
      this.isHx = true;
      if (this.language == "zh_CN") {
        this.logImg = require("../../assets/img/logo-zh.png");
      } else if (this.language == "zh_HK") {
        this.logImg = require("../../assets/img/logo-hk.png");
      } else {
        this.logImg = require("../../assets/img/logo.png");
      }
    }

    let tempUrl = JSON.parse(sessionStorage.getItem("qrcode_download"));
    this.appDownLoadImg = tempUrl.url;
    this.appstore_url = tempUrl.appstore_url;
    this.google_play_url = tempUrl.google_play_url;

    let currency = localStorage.getItem("currency") || "usd";
    let tempLang = localStorage.getItem("language");
    if (tempLang) {
      this.countryList.forEach((ele) => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    } else {
      tempLang = "en";
      this.countryList.forEach((ele) => {
        if (ele.value == tempLang) {
          this.imgUrl = require("../../assets/img/lang/f-" +
            ele.value +
            ".png");
          this.languageName = ele.name;
        }
      });
    }

    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));

    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
    }
    this.handleSelect(currency);
  },
  mounted() {
    this.getStaticChainInfo();
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
      currencys: "user/currency",
      environment: "user/environment",
      darkMode: "user/darkMode",
    }),
    language_name() {
      let name = "";
      this.countryList.forEach((item) => {
        if (item.value == this.language) {
          name = item.name;
        }
      });
      return name;
    },
    envName() {
      let tempVal = localStorage.getItem("environment");
      return tempVal;
    },
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = Cookies.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    },
    active() {
      let val = 0;
      if (this.$route.path.includes("/markets")) {
        val = 1;
      } else if (this.$route.path.includes("/exchange")) {
        val = 2;
      } else if (this.$route.path.includes("/contract-exchange")) {
        val = 3;
      } else if (this.$route.path.includes("/finances")) {
        val = 4;
      } else if (this.$route.path.includes("/mining-pool")) {
        val = 5;
      } else if (this.$route.path.includes("/delivery")) {
        val = 6;
      } else if (this.$route.path.includes("/quicklycoins")) {
        val = 7;
      } else {
        val = 0;
      }
      return val;
    },
  },
  methods: {
    routeSplicing,
    ...mapActions({
      changeLanguage: "user/changeLanguage",
      changeCurrency: "user/changeCurrency",
      changeDarkMode: "user/changeDarkMode",
    }),
    //新菜单开始
    mouseenterTrade() {
      this.activeTrade = true;
    },
    mouseleaveTrade() {
      this.activeTrade = false;
    },
    mouseenterDeri() {
      this.activeDeri = true;
    },
    mouseleaveDeri() {
      this.activeDeri = false;
    },
    mouseenterEarn() {
      this.activeEarn = true;
    },
    mouseleaveEarn() {
      this.activeEarn = false;
    },
    mouseenterMore() {
      this.activeMore = true;
    },
    mouseleaveMore() {
      this.activeMore = false;
    },
    // 新菜单结束
    onDownloadTipsDialog() {
      this.downloadTipsVisible = true;
    },
    closeDownloadTipsDialog() {
      this.downloadTipsVisible = false;
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        // this.wsUrl = data.ws_url;
        this.android_url_new = data.android_url;
        this.appstore_url_new = data.appstore_url;
      }
    },
    async getStaticChainInfo() {
      await apiGetStaticTableInfo(7).then(({ data }) => {
        this.currencyMaps = data;
      });
    },
    onChangeStyle() {
      if (this.darkMode == 1) {
        this.changeDarkMode(2);
      } else {
        this.changeDarkMode(1);
      }
    },

    goQuicklycoins() {
      if (!this.userInfo) {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "quicklycoins"),
        });
      }
    },
    onCustomerServiceClick() {
      this.$emit("onApiClick");
    },

    handleSelect(key) {
      let command = "en";
      if (key == "zh_CN") {
        command = "zh_CN";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "zh_HK") {
        command = "zh_HK";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "en") {
        command = "en";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ja") {
        command = "ja";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "ko") {
        command = "ko";
        this.imgUrl = require("../../assets/img/" + key + ".png");
        this.$i18n.locale = command;
        this.changeLanguage(command);
      } else if (key == "cny") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "hkd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "aed") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "jpy") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "krw") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "usd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "try") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "mmk") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "php") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "sgd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "thb") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "vnd") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      } else if (key == "brl") {
        this.currency = key.toUpperCase();
        this.changeCurrency(key);
      }
    },
    onMaskLangDialog() {
      this.langVisible = true;
    },
    onCurrencyChange(key) {
      this.currency = key.toUpperCase();
      this.changeCurrency(key);
      this.langVisible = false;
    },
    onLangClick(item) {
      this.imgUrl = require("../../assets/img/lang/f-" + item.value + ".png");
      // getLangPackage([item.value]);
      const langPackage = getLangPackage([item.value]);
      this.languageName = item.name;
      this.$i18n.setLocaleMessage(item.value, langPackage[item.value]);
      this.changeLanguage(item.value);
      this.$i18n.locale = item.value;
      this.langVisible = false;

      if (this.isHx) {
        if (this.languageName == "中文简体") {
          this.logImg = require("../../assets/img/logo-zh.png");
        } else if (this.languageName == "中文繁體") {
          this.logImg = require("../../assets/img/logo-hk.png");
        } else {
          this.logImg = require("../../assets/img/logo.png");
        }
      }
      window.location.href = `${window.location.protocol}//${window.location.host}/${this.language}`;
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
  },
  watch: {
    language: function (newValue, oldValue) {
      if (this.isHx) {
        if (newValue == "zh_CN") {
          this.logImg = require("../../assets/img/logo-zh.png");
        } else if (newValue == "zh_HK") {
          this.logImg = require("../../assets/img/logo-hk.png");
        } else {
          this.logImg = require("../../assets/img/logo.png");
        }
      }
    },
  },
};
</script>
<style lang="scss">
// .zh_CN {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 120px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .zh_HK {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 120px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .hi {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 145px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .it {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 183px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .de {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 182px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .vi {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 193px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .tr {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 141px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .tl {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 162px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .ru {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 162px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .th {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 162px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .pt {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 162px;
//     }
//     .page-header-hx .right-menus .el-menu .download-app .down-box {
//       width: 265px;
//     }
//   }
// }
// .en {
//   .page-header-hx {
//     min-width: 1468px;
//     .right-menus .el-menu .download-app {
//       width: 170px;
//     }
//   }
//   .page-header-hx .right-menus .el-menu .download-app .down-box {
//     width: 290px;
//   }
//   .page-header-hx
//     .right-menus
//     .el-menu
//     .el-submenu
//     .el-menu--horizontal
//     .el-menu {
//     width: 195px !important;
//   }
// }
// .fr {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 221px;
//     }
//   }
// }
// .es {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 247px;
//     }
//   }
// }
// .ko {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 144px;
//     }
//   }
// }
// .ja {
//   .page-header-hx {
//     .right-menus .el-menu .download-app {
//       width: 202px;
//     }
//   }
//   .page-header-hx .right-menus .el-menu .download-app .down-box {
//     width: 280px;
//     height: 166px;
//   }
//   .page-header-hx .right-menus .el-menu .el-submenu .el-menu--horizontal .el-menu {
//     width: 180px !important;
//   }
// }
.ar {
  .page-header-hx {
    direction: ltr !important;
  }

  .page-header-hx {
    .right-menus .el-menu .download-app {
      width: 130px;
    }
  }
  .user-center-menu.el-menu-item.nav-menu-item.login
    .el-menu--horizontal.sub-item-wrap.select-user-item
    .el-menu
    .el-menu-item {
    text-align: right;
  }
}
</style>
<style lang="scss">
/* yuan language innerBody */
//修改Lang宽度
.select-lang-masks {
  height: 60px;
  // line-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: 0.6;
  cursor: pointer;
  color: #fff;
  &:hover {
    opacity: 1;
    .expand {
      // transform: rotateX(180deg);
    }
  }
  img {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
  span {
    font-size: 14px;
    // margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  .expand {
    width: 16px;
    height: 16px;
    margin-left: 1px;
    user-select: none;
  }
}
.el-menu--horizontal.sub-item-wrap.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
  &.V2 {
    margin-right: 20px;
  }
  top: 59px !important;
  &.merchant-item {
    width: 135px;
  }
  //  修改下拉菜单
  .el-menu {
    background-color: #fff;
    width: 130px !important;
    min-width: 130px !important;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;
      span {
        margin-left: 0px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}

//修改货币宽度
.el-menu--horizontal.sub-item-wrap.select-fiat-currency {
  &.merchant-item {
    width: 135px;
  }
  //  修改下拉菜单
  .el-menu {
    background-color: #fff;
    // width: 130px !important;
    // min-width: 130px !important;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      min-width: auto;
      border-radius: 4px;
      &:hover {
        background-color: #f3f7fc;
      }
      span {
        margin-left: 0px;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
  }
}
.select-fiat-currency {
  top: 59px !important;
  &.el-menu--horizontal {
    width: 140px !important;
  }
  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}

.page-header-hx {
  user-select: none;
  // height: 60px;
  height: 64px;
  background-color: #12161c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .logo {
    // width: 130px;
    // height: 30px;
    width: 150px;
    height: 34px;
    display: block;
    &.isMaskEx {
      width: 122px;
      height: 24px;
    }
  }
  .downloadTipsVisible {
    z-index: 999;
    width: 500px;
    margin-top: 30vh !important;
    min-height: 360px;
    padding: 30px 60px;
    display: flex;
    color: #112132;
    cursor: pointer;
    .el-dialog__header {
      padding: 0;
    }
    .el-dialog__body {
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
    .t {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .c {
      font-size: 14px;
      margin-bottom: 35px;
    }
    .d {
      display: flex;
      justify-content: space-between;
      height: 126px;
      width: 380px;
      .b {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        flex-shrink: 0;
        .as {
          width: 150px;
          height: 50px;
        }
        .apk {
          width: 150px;
          height: 50px;
        }
      }
      .q {
        margin-left: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        .code {
          width: 96px;
          height: 96px;
        }
        .qt {
          font-size: 14px;
          line-height: 1.43;
          word-break: break-word;
        }
      }
    }
    .confirm {
      cursor: pointer;
      margin-top: 40px;
      width: 200px;
      height: 40px;
      border-radius: 3px;
      background-image: linear-gradient(77deg, #ff486d 21%, #c61b3d 82%);
      font-size: 14px;
      font-weight: 500;
      line-height: 1.14;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .langVisible_n {
    z-index: 999;
    width: 840px;
    margin-top: 29vh !important;
    .close_icon {
      position: absolute;
      width: 14px;
      height: 14px;
      top: 28px;
      right: 30px;
      z-index: 10;
      cursor: pointer;
    }
    .el-dialog__header {
      height: 0px;
      padding: 0 !important;
    }
    .el-dialog__body {
      padding-top: 16px;
      .el-tabs__header {
        margin-bottom: 22px;
      }
      .el-tabs__active-bar {
        background-color: #c61b3d;
      }
      .el-tabs__item {
        font-size: 16px;
        font-weight: 500;
        color: #131f30;
        margin-bottom: 12px;
      }
      .pane-title {
        font-size: 16px;
        font-weight: 500;
        color: #131f30;
        margin-bottom: 10px;
      }
    }
    ul {
      // min-width: 700px;
      margin-left: 6px;
    }

    li {
      cursor: pointer;
      // display: inline-block;
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      height: 48px;
      width: 23%;
      margin-right: 1%;
      padding-left: 1%;
      &:hover {
        background-color: #f7f8f9;
        color: black;
      }
      img {
        width: 28px;
        height: 28px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        color: #202630;
        // margin-left: 10px;
      }
      .red-lang-curr {
        color: #c61b3d;
      }
    }
  }
  .el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
    margin-top: 0;
  }
  .left-pages,
  .right-menus,
  .el-submenu__title {
    display: flex;
    align-items: center;
    height: 100%;
  }

  // 左侧
  .left-pages {
    margin-left: 20px;
    display: flex;
    align-items: center;

    .pages-routes {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      margin-left: 35px;
      li {
        color: #fff;
        height: 64px;
        font-size: 14px;
        font-weight: 400;
        line-height: 64px;
        margin-right: 20px;
        box-sizing: border-box;
        cursor: pointer;
        color: #acb9db;
        position: relative;
        a {
          color: #fff;
          // opacity: 0.6;
          font-size: 14px;
          text-decoration: none;
          // display: inline-block;
          height: 100%;
          display: flex;
          align-items: center;
          // margin-right: 20px;
        }

        &.none-active {
          width: 100%;
          border-bottom: 3px solid #c61b3d;
          color: #fff;
          font-weight: 500;
        }
        &:hover {
          // font-weight: 500;
          color: #fff;
          a {
            color: #fff;
            opacity: 1;
          }
        }
        &.active {
          // border-bottom: 3px solid #c61b3d;
          // color: #fff;
          // a {
          //   opacity: 1;
          //   color: #fff;
          // }

          &:after {
            content: "";
            position: absolute;
            background-color: #c61b3d;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 2px;
          }
        }
      }

      .normal-link {
        padding: 0px;

        a {
          padding: 0 20px;
        }
      }
    }

    .li-sub-menu {
      margin-top: -2px;
      position: absolute;
      z-index: 666;
      padding: 15px;

      min-width: 360px;
      border-radius: 2px;
      box-shadow: 0 0 10px 0 rgba(53, 64, 82, 0.2);
      background-color: #fff;
      .it {
        cursor: pointer;
        padding: 20px 20px;
        display: flex;
        align-items: center;

        min-width: 330px;
        min-height: 74px;

        border-radius: 4px;

        .i {
          width: 32px;
          height: 32px;
        }
        .t-st {
          display: flex;
          flex-direction: column;
          .t {
            margin-left: 20px;
            font-size: 16px;
            font-weight: 500;
            color: #000;
          }
          .st {
            margin-left: 20px;
            opacity: 0.52;
            font-size: 12px;
            font-weight: 500;
            color: #202630;
          }
        }

        .act {
          opacity: 0;
          font-size: 16px;
          font-weight: 600;
          margin-left: auto;
          color: #c61b3d;
          transition: all 0.1s linear;
        }
        &:hover {
          background-color: rgba(32, 38, 48, 0.06);
          .act {
            opacity: 1;
          }
        }
      }
    }

    .icon-top {
      margin-left: 2px;
      // margin-right: 10px;
      display: inline-block;
      transition: transform 0.5s;
    }
    .trax {
      // transform: rotate(180deg);
    }
  }

  .app-link {
    font-size: 14px;
  }
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
  .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
    background-color: #12161c !important;
  }
  // 右侧
  .right-menus {
    margin-right: 20px;
    height: 60px;
    .quick-access {
      padding-left: 20px;
      cursor: pointer;
      a {
        color: white;
      }
    }
    .vertical-line-li {
      position: relative;
      float: left;
      .vertical-line {
        height: 22px;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        display: inline-block;
        margin-top: 20px;
        margin-right: 18px;
      }
    }
    .customer-service {
      color: rgba(255, 255, 255, 0.6);
      font-size: 12px;
      cursor: pointer;
      &:hover {
        color: #ffffff;
      }
    }
    .station-name {
      color: red;
      font-size: 24px;
      margin-right: 10px;
    }
    .el-submenu__icon-arrow {
      font-size: 16px;
      color: #acb9db;
    }

    .el-menu {
      background-color: #12161c;
      text-align: left;
      .letter-center {
        float: left;
        width: 40px;
        line-height: 22px;
        margin-top: 20px;
        position: relative;
        img {
          width: 22px;
          height: 22px;
          top: 0px;
          cursor: pointer;
        }
        .driver {
          position: absolute;
          background-color: rgb(172, 185, 219, 0.3);
          right: 0px;
          top: -2px;
          width: 1px;
          height: 30px;
        }
      }
      .download-app {
        float: left;
        position: relative;
        width: fit-content;
        height: 60px;
        outline: none;
        border-radius: 4px;

        .download-app-text {
          // display: block;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          font-size: 14px;
          color: #ffffff;
          // opacity: 0.6;
          // line-height: 60px;
          padding: 0 0;
          margin-right: 20px;
          box-sizing: border-box;
          cursor: pointer;
          &:hover {
            // border-bottom: 4px solid #c61b3d;
            // background-color: rgba(40, 41, 45, 1);
            color: #ffffff;
            opacity: 1;
          }
          .down_icon {
            width: 22px;
            height: 22px;
            margin-right: 5px;
            user-select: none;
          }
        }
        .down-box {
          box-sizing: border-box;
          min-width: 260px;
          height: 200px;
          white-space: nowrap;
          // padding: 18px 30px 18px 19px;
          padding: 20px 20px 30px;
          border-radius: 2px;
          box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
          background-color: #ffffff;
          // background-color: #2a3138;
          display: block;
          // position: relative;
          position: absolute;
          right: 0px;
          z-index: 3000;
          .down-box-content {
            min-width: 260px;
          }
          .down-load-text {
            font-size: 16px;
            font-weight: 400;
            color: #202630;
            overflow: hidden;
            max-width: 380px;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            .icon-title {
              width: 28px;
              height: 28px;
            }
          }
          .down-load-content {
            margin-top: 10px;
            display: flex;
            justify-content: space-around;
            text-align: center;
            .text-title {
              margin-bottom: 10px;
            }
            img {
              width: 70px;
              height: 70px;
            }
          }
          .img-content {
            display: flex;
            margin-top: 20px;
            .down-load-url-outer {
              height: 110px;
              width: 110px;
              background-color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              .down-load-url {
                // margin-top: -20px;
                height: 100px;
                width: 100px;
              }
            }
            .left-item {
              padding-top: 10px;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
            }
            .down-load-item-text {
              // display: inline-block;
              display: flex;
              align-items: center;
              flex-shrink: 0;
              height: 20px;
              margin-left: 15px;
              font-size: 14px;
              color: #202630;
              .icon-title {
                width: 28px;
                height: 28px;
                vertical-align: middle;
                margin-right: 7px;
              }
              &.V2 {
                // margin-top: 17px;
              }
            }
            .quesiton {
              margin-left: 15px;
              padding: 6px 15px;
              border-radius: 16px;
              background-color: rgba(32, 38, 48, 0.1);
              font-size: 12px;
              color: #202630;
            }
            .down-load-img {
              width: 59px;
              height: 59px;
            }
          }
        }
      }
      .select-fiat {
        margin-right: 20px;
      }
      &.el-menu--horizontal {
        border-bottom: none;
      }

      .sub-item-child {
        color: red;
        #sub-item-asset & {
          &:hover {
            a {
              color: #000;
            }
          }
        }
        &.el-submenu {
          &:hover {
            a {
              color: #000;
            }
          }
        }
        .el-submenu__title {
          display: inline-block;
          height: 100%;
          width: 100%;
          box-sizing: border-box;
          padding-left: 10px;
          .sub-item-child-jump:hover {
            color: #000;
          }
          a:hover {
            font-size: 14px;
            font-weight: 400;
            color: #000;
            line-height: 14px;
            display: inline-block;
          }
        }
      }
      .el-submenu {
        &:hover {
          a {
            color: #ffffff;
          }
          i {
            color: #ffffff;
          }
        }
        a {
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          opacity: 0.6;
          line-height: 14px;
          &:hover {
            opacity: 1;
          }
        }
        .el-menu--horizontal {
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            //  padding: 10px 0px;
            // width: 140px;
            background-color: #fff;

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              // padding-left: 10px;
              // width: 140px;
              &:hover {
                background-color: #f3f7fc;
              }
              &.hide-icon {
                i {
                  visibility: hidden;
                }
              }
              i {
                margin-right: 10px;
                position: relative;
                top: -1px;
                color: inherit;
              }

              &.is-active {
                background-color: #fff !important;
                // span {
                //   color: #3a7dff !important;
                // }
              }
            }
          }
        }
        //修改Lang宽度
        .el-menu--horizontal.sub-item-wrap.select-lang {
          top: 59px !important;
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            width: 130px !important;
            min-width: 130px !important;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;
              span {
                margin-left: 0px;
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        //修改货币宽度
        .el-menu--horizontal.sub-item-wrap.select-fiat-currency {
          &.merchant-item {
            width: 135px;
          }
          //  修改下拉菜单
          .el-menu {
            background-color: #fff;
            // width: 130px !important;
            // min-width: 130px !important;
            .el-menu-item {
              height: 40px;
              line-height: 40px;
              min-width: auto;
              border-radius: 4px;
              &:hover {
                background-color: #f3f7fc;
              }
              span {
                margin-left: 0px;
                img {
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }
      .no-arrow {
        .el-submenu__icon-arrow {
          display: none;
        }
      }
      // .el-submenu.is-opend .el-submenu__title {
      //   background-color: #fff !important;
      // }
      .el-submenu__title {
        border-bottom: 0;
        padding: 0;
        a {
          display: block;
          width: 100%;
          height: 100%;
          line-height: 60px;
          // margin-left: 20px;
          box-sizing: border-box;
          &.none-active {
            // width: 40px;
            // border-bottom: 3px solid #c61b3d;
            color: #fff;
            background-color: #12161c;
            &:after {
              content: "";
              height: 3px;
              width: 50px;
              background-color: #c61b3d;
              position: absolute;
              bottom: 0px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
          &:hover {
            color: #fff;
            background-color: #12161c;
          }
        }
      }
      .el-submenu__title:hover {
        background-color: #12161c;
        text-align: left;
        color: #ffffff;
        a {
          text-decoration: none;
        }
      }

      .nav-menu-item {
        color: #fff;
        padding: 0;
        border-bottom: none;
        &.login {
          // margin: 0 10px;
          a {
            color: #ffffff;
            // opacity: 0.6;
            &.none-active {
              color: #fff;
              opacity: 1;
              background-color: #12161c;
            }
            &:hover {
              color: #fff;
              opacity: 1;
              // font-weight: 500;
            }
          }
        }
        .driver {
          position: absolute;
          right: -40px;
          top: 0px;
          width: 1px;
          height: 30px;
          margin: 0 10px 0 9px;
          background-color: transparent;
        }

        &.register {
          position: relative;
          margin-right: 20px;
          background-color: #c61b3d;
          height: 30px;
          margin-top: 15px;
          line-height: 30px;
          border-radius: 2px !important;
          border-right: 1px;
          border-right: 1px soild #acb9db;
          .none-active {
            border-radius: 2px;
          }

          &:hover {
            background: rgba(198, 27, 61, 0.8);
            color: #c61b3d;
          }
          a {
            line-height: 30px;
            color: white;
            border-radius: 4px;
            &.none-active {
              color: #fff;
              background-color: #c61b3d;
            }
          }
        }
        &:hover {
          background-color: #12161c !important;
          color: #c61b3d;
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          // line-height: 58px;
          padding: 0 20px;
          box-sizing: border-box;
          text-decoration: none;
          color: #acb9db;
          &.none-active {
            color: #fff;
            // background-color: rgba(40, 41, 45, 1);
          }
          &:hover {
          }
        }
      }
    }

    .lang {
      img {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 5px;
        border-radius: 10px;
        filter: brightness(200);
        -webkit-filter: brightness(150%);
      }
    }
  }

  .divider {
    width: 1px;
    height: 24px;
    background: rgba(216, 216, 216, 1);
    opacity: 0.18;
    position: relative;
    top: 20px;
  }
}
.select-lang {
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
  &.V2 {
    margin-right: 20px;
  }
}
.select-fiat-currency {
  top: 59px !important;
  &.el-menu--horizontal {
    width: 140px !important;
  }
  .el-menu {
    width: 140px !important;
  }

  margin-right: 20px;
  width: 140px !important;
  span {
    display: flex;
    align-items: center;
    margin-left: 18px;
  }
  img {
    width: 18px;
    height: 18px;
    display: inline-block;
    margin-right: 5px;
    border-radius: 10px;
    vertical-align: text-bottom;
  }
}
</style>
