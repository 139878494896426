"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vuex = require("vuex");
var _markets = require("@/model/markets/markets");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {
    NoData: _NoData.default
  },
  props: {
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    tableDataComputed: {
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  // watch:{
  //   tableData:{
  //     handler:function(){
  //       this.$nextTick(function (){
  //         this.$refs.marketTable.setCurrentRow(this.tableData[0])
  //       })
  //     }
  //   }
  // },
  data() {
    return {
      ws: null,
      tradePair: [],
      tradePairArray: [],
      tableData: [],
      // tableDataComputed: [],
      selectSymbol: "",
      symbol: "",
      coinInfoMap: {},
      CoinItemIdMap: {},
      tabList: [{
        value: "self",
        label: "自选"
      }, {
        value: "usdt",
        label: "美股"
      }, {
        value: "gg",
        label: "港股"
      }
      // {
      //   value: "btc",
      //   label: "BTC",
      // },
      ],

      currIndex: "usdt",
      selfOptionsList: [],
      stockType: "1" //
    };
  },

  async created() {
    // this.initWS();
    await this.getExchangeItemId();
    // await this.getStaticTableInfo(46);
    let tempSymbol = this.$route.params.symbol || "btcusdt";
    this.symbol = tempSymbol;
    this.getOptionList();
    // const [coinFrom, coinTo] = tempSymbol.split("_");
    // if (coinTo.toLowerCase() == "btc") {
    //   this.currIndex = "btc";
    // } else {
    //   this.currIndex = "usdt";
    // }
  },

  mounted() {
    // this.$refs.marketTable.setCurrentRow(this.tableData[0],true)
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    async getExchangeItemId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.CoinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    async getStaticTableInfo(sv_id) {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(sv_id);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
      }
    },
    tableRowClassName({
      row,
      rowIndex
    }) {
      let trade_pair = this.symbol.toLowerCase();
      if (row.symbol.toLowerCase() == trade_pair) {
        return "warning-row";
      }
      return "";
    },
    getRowKeys(row) {
      return row.symbol_id;
    },
    addOption(row, type) {
      if (this.userInfo) {
        let params = {
          symbol_id: row.symbol_id,
          option: type,
          swap_type: 2
        };
        (0, _markets.apiAddOption)(params).then(res => {
          if (res.status == 200) {
            this.$emit("onAddOption");
            this.getOptionList();
            this.$message({
              message: params.option == 1 ? this.translateTitle(`添加自选成功`) : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success"
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    onSymbolChange(item, column) {
      if (column != undefined && column.label == "币种") {
        return;
      }
      let trade_pair = item.name.replace("_", "").toLowerCase();
      this.symbol = trade_pair;
      this.$router.push(`/stock-exchange/${trade_pair}`);
      this.$emit("on-symbol-change", item);
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    async getOptionList() {
      let {
        userInfo
      } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      (0, _markets.apiGetOptionList)().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    }),
    tableLoading() {
      let val = 1;
      if (this.marketList.length > 0) {
        val = 0;
      } else {
        val = 1;
      }
      return val;
    },
    //FIXME 交易对搜索输入框输入ETH 永续搜索不出来内容,原因：使用元素的symbol进行对比，应用使用symbol_name，但国际化时？
    showList() {
      let tempMarket = [];
      this.marketList.forEach(item => {
        for (const key in this.coinInfoMap) {
          if (item.symbol_id == key) {
            tempMarket.push({
              ...item,
              ...this.coinInfoMap[key]
            });
          }
        }
      });
      let tempArr = [];
      tempArr = tempMarket.filter(ele => {
        if (this.currIndex == "self") {
          if (this.selectSymbol != "") {
            if (ele.symbol_name.toLowerCase().indexOf(this.selectSymbol.toLowerCase()) != -1) {
              return true;
            }
          } else {
            return this.selfOptionsList.indexOf(ele.symbol_id.toString()) > -1;
          }
        } else {
          if (this.selectSymbol != "") {
            if (ele.symbol_name.toLowerCase().indexOf(this.selectSymbol.toLowerCase()) != -1) {
              return true;
            }
          } else {
            return ele;
          }
        }
      });
      return tempArr;
    }
  }
};
exports.default = _default;