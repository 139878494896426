"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _store = _interopRequireDefault(require("@/store"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
// 未登录可以访问的页面
const whiteList = ["/", "/login", "/register", "/login/mobile", "/loginSecurity"];
const notLoginList = ["/login", "/ucenter/assets", "/ucenter/security-setting", "/ucenter/situation", "/ucenter/finanManagement", "/ucenter/spread-center", "/ucenter/coupon-center", "/ucenter/safe-setting"];
function _default(router) {
  router.beforeEach((to, from, next) => {
    // 白名单不做登录验证
    if (whiteList.some(path => to.path.includes(path))) {
      next();
    } else {
      // 如果未登录跳转登录页面
      // const token = window.$.cookie('topcredit_exchange_t')

      // if (!token) {
      //   router.push('/login');
      //   next();
      // }
      // 校验是否有token

      let temmToken = _jsCookie.default.get("topcredit_exchange_t");
      if (temmToken) {
        _store.default.dispatch("user/getUserInfo");
      } else {
        if (notLoginList.some(path => to.path.includes(path))) {
          next({
            path: "/login"
          });
        } else {
          next();
        }
      }
      next();
    }
  });
}