<template>
  <section class="web-fourth-section">
    <div class="section-part left-part">
      <img
        src="@/assets/img/maskexcard/14b4e47aad4988246ed05ecd1c9b22c7.png"
        alt="Right Image"
      />
    </div>
    <div class="section-part right-part">
      <div class="labels">
        <div class="label">
          <p class="label-text bold">How to Get Your MaskEX Virtual Card</p>
        </div>
        <div class="label">
          <img
            src="@/assets/img/maskexcard/Group.svg"
            alt="Top Left Image"
            class="checkmark"
          />
          <p class="label-text">
            Once you have a MaskEX Account, select 'Apply Now' on the MaskEX App
            under the ‘Card’ section and follow the application process.
          </p>
        </div>
        <div class="label">
          <img
            src="@/assets/img/maskexcard/Group.svg"
            alt="Top Left Image"
            class="checkmark"
          />
          <p class="label-text">
            Upon approval of your application, your MaskEX Virtual Card is ready
            for immediate top-up and use, eliminating the need for additional
            activation.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  // Fourth section component logic
};
</script>

<style scoped>
/* Fourth section styles */
.web-fourth-section {
  width: 100%;
  height: 693px;
  display: flex;
  background: linear-gradient(white, beige);
}

.section-part {
  flex: 1;
  display: flex;
  align-items: center;
}

.right-part {
  flex: 1;
  display: flex;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  margin-right: 360px;
  margin-left: 360px;
  /* margin-right: 360px; */
}

.labels {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-left: 50px;
}

.label {
  display: flex;
  align-items: center;
  color: black;
}

.checkmark {
  width: 20px;
  height: 20px;
  /* background-color: green; */
  border-radius: 50%;
  margin-right: 10px;
}

.label-text {
  color: black;
  font-family: "DIN Pro";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bold {
  color: black;
  font-family: "DIN Pro";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.left-part img {
  width: 510px;
  height: 629px;
  flex-shrink: 0;
  margin-left: 360px;
  margin-top: 65px;
}
</style>
