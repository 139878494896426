"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _stationSetting = require("@/assets/js/stationSetting");
var _vuex = require("vuex");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/model/exchange/index");
var _index2 = require("@/model/home/index");
var _assets = require("@/model/ucenter/assets");
var _i18n = require("@/assets/js/i18n");
var _cryptoJs = _interopRequireDefault(require("crypto-js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const md5 = require("md5");
var _default = {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // const frameUrl = `https://customer-service.hxexchge.com/?token=${token}`;
    return {
      VUE_UNION_STATION: _stationSetting.VUE_UNION_STATION,
      visible: false,
      frameUrl: "",
      apiAssetsInfo: "",
      ipInfo: "",
      token: "",
      customer_type: 0,
      customer_service_url: "",
      noRead: "",
      frameLoad: true
    };
  },
  async created() {
    this.token = _jsCookie.default.get("topcredit_exchange_t");
    await this.getcustermerUrl();
    //  this.customer_service_url = sessionStorage.getItem("customer_service_url");
    let tempHost = window.location.host;
    // this.customer_type = sessionStorage.getItem("customer_type");

    if (this.customer_type == "1") {
      this.frameUrl = this.customer_service_url;
    } else {
      this.frameUrl = this.customer_service_url + `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_hide`;
    }
    // this.startTimer();
  },

  mounted() {},
  methods: {
    translateTitle: _i18n.translateTitle,
    async getcustermerUrl() {
      const {
        status,
        data
      } = await (0, _index2.apiGetcustermerUrl)();
      if (status == 200) {
        if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
          this.customer_service_url = "https://direct.lc.chat/13885884";
          this.customer_type = 1;
        } else if (_stationSetting.VUE_UNION_STATION == "UEEx") {
          this.customer_type = 1; // 1 第三方客服系统 0 hx 系统
          this.getAssets();
          this.userLoginLogList();
          this.getUeCustomerServer();
        } else {
          this.customer_service_url = data.customer_service_url;
          this.customer_type = data.customer_type;
        }
      }
    },
    onChatClick() {
      this.visible = true;
      // let tempUrl = sessionStorage.getItem("customer_service_url");
      // this.customer_type = sessionStorage.getItem("customer_type");
      let tempHost = window.location.host;
      if (this.customer_type == "1") {
        this.frameUrl = this.customer_service_url;
      } else {
        this.frameUrl = this.customer_service_url + `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_show`;
      }
      setTimeout(() => {
        this.frameLoad = false;
      }, 1500);
    },
    onClose() {
      this.visible = false;
      this.frameLoad = true;
      // let tempUrl = sessionStorage.getItem("customer_service_url");
      // this.customer_type = sessionStorage.getItem("customer_type");
      let tempHost = window.location.host;
      if (this.customer_type == "1") {
        this.frameUrl = this.customer_service_url;
      } else {
        this.frameUrl = this.customer_service_url + `/?token=${this.token}&host=${tempHost}&lang=${this.language}#is_hide`;
      }
    },
    startTimer() {
      this.initStatus().then(rs => {
        setTimeout(() => {
          if (this.destroyed) {
            return;
          }
          this.startTimer();
        }, 10000);
      });
    },
    initStatus() {
      return apiIngOrder().then(rs => {
        const {
          status,
          data
        } = rs;
        if (status == 200) {
          this.noRead = +data.fast_gm_no_read;
          // this.noRead = (+1);
        }
      });
    },

    getUeCustomerServer() {
      let params = {};
      if (this.userInfo) {
        //登陆
        let req = {};
        req.uid = this.userInfo.accountInfo.account_id;
        req.mobile = this.userInfo.accountInfo.mobile;
        req.email = this.userInfo.accountInfo.email;
        req.userName = this.userInfo.accountInfo.username;
        req.userIp = this.ipInfo;
        req.account = this.apiAssetsInfo;
        req.language = localStorage.getItem("language") || "zh_CN";
        // console.log(this.encrypt(this.changStr(req)));
        let tempData = this.encrypt(this.changStr(req));
        let domain = "https://kf.jhdami.com/";
        params.appid = "csc1679554722821";
        params.code = "BD6u7";
        params.channelType = "1";
        params.language = localStorage.getItem("language") || "zh_CN";
        params.nonceStr = "k6ih17s0ppm6esnw02it12";
        params.data = tempData;
        let sign = this.getSign(params);
        this.customer_service_url = domain + "?" + this.changStr(params) + "&sign=" + sign;
        // console.log("----", this.customer_service_url);
      } else {
        //未登录
        this.customer_service_url = "https://kf.jhdami.com?code=BD6u7&channelType=1&tenantPlatCode=youyi";
      }
    },
    changStr(req) {
      let str = "";
      for (let i in req) {
        str += i + "=" + req[i] + "&";
      }
      str = str.substr(0, str.length - 1);
      return str;
    },
    getSign(params) {
      for (let key in params) {
        params[key] = params[key] || params[key] === 0 || params[key] === false ? params[key] : "";
      }
      let keys = Object.keys(params);
      let length = keys.length;
      keys = keys.sort();
      let sign = "";
      for (let i = 0; i < length; i++) {
        if (sign != "") sign = sign + "&";
        sign = sign + keys[i] + "=" + params[keys[i]];
      }
      sign = md5(sign).toString().toUpperCase();
      return sign;
    },
    // 加密
    encrypt(word, keyStr) {
      keyStr = keyStr ? this.sort_ASCII(keyStr) : "PRoxb3f1yTIYX3IgZ6SUWqAcJEI3IDJG";
      let key = _cryptoJs.default.enc.Utf8.parse(keyStr);
      let srcs = _cryptoJs.default.enc.Utf8.parse(word);
      let encrypted = _cryptoJs.default.AES.encrypt(srcs, key, {
        mode: _cryptoJs.default.mode.ECB,
        padding: _cryptoJs.default.pad.Pkcs7
      });
      return encrypted.toString();
    },
    // ascii从小到达排序
    sort_ASCII(arr) {
      arr.sort((a, b) => {
        return a.charCodeAt(0) - b.charCodeAt(0);
      });
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "remain"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.apiAssetsInfo = data.remain.u_valuation;
      }
    },
    async userLoginLogList() {
      if (!this.userInfo) {
        return;
      }
      let pamars = {
        page_size: 1
      };
      await (0, _assets.userLoginLogList)(pamars).then(({
        status,
        data,
        msg
      }) => {
        if (status === 200) {
          this.ipInfo = data.list[0].login_ip;
        }
      });
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      userInfo: "user/userInfo"
    })
    // ...mapState({
    //   userInfo: state => state.user.info,
    // }),
  },

  watch: {
    dialogVisible() {
      this.onChatClick();
    }
  }
};
exports.default = _default;