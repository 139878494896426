"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _menuControlMixin = _interopRequireDefault(require("@/components/common/menuControlMixin.vue"));
var _message = require("@/components/dialog/message");
var _vuex = require("vuex");
var _i18n = require("@/assets/js/i18n");
var _stationSetting = require("@/assets/js/stationSetting");
var _billCenter = require("@/model/ucenter/billCenter");
var _addEditApiSetting = _interopRequireDefault(require("./components/add-edit-api-setting.vue"));
var _b = _interopRequireDefault(require("@/assets/img/siteLogo/b9.png"));
var _mask_new = _interopRequireDefault(require("@/assets/img/siteLogo/mask_new.png"));
var _bxc = _interopRequireDefault(require("@/assets/img/siteLogo/bxc.png"));
var _hx = _interopRequireDefault(require("@/assets/img/siteLogo/hx.png"));
var _logo = _interopRequireDefault(require("@/assets/img/siteLogo/logo100.png"));
var _hxlogo_text = _interopRequireDefault(require("@/assets/img/siteLogo/hxlogo_text.png"));
var _logo100_text = _interopRequireDefault(require("@/assets/img/siteLogo/logo100_text.png"));
var _mask_text = _interopRequireDefault(require("@/assets/img/siteLogo/mask_text.png"));
var _b9_text = _interopRequireDefault(require("@/assets/img/siteLogo/b9_text.png"));
var _qr_code = _interopRequireDefault(require("@/assets/img/qr_code.png"));
var _profit_loss_share_bg = _interopRequireDefault(require("@/assets/img/profit_loss_share_bg.png"));
var _placeholder = _interopRequireDefault(require("@/assets/img/placeholder.png"));
var _expressionBad = _interopRequireDefault(require("@/assets/img/expression/expression-bad.png"));
var _expressionGreat = _interopRequireDefault(require("@/assets/img/expression/expression-great.png"));
var _expressionCool = _interopRequireDefault(require("@/assets/img/expression/expression-cool.png"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default, _menuControlMixin.default],
  components: {
    NoData: _NoData.default,
    addEditApiKey: _addEditApiSetting.default
  },
  data() {
    return {
      currType: "",
      currRow: {},
      delVisible: false,
      addEditVisible: false,
      placeholder: _placeholder.default,
      delLoading: false,
      isDestoryWS: false,
      wsContentTime: 0,
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--"
          },
          list: []
        }
      },
      marketList: [],
      appendMoneydialog: false,
      staticChainMap: {},
      //静态表1
      staticCurrencyMap: {},
      sotcStaticPaymentMap: {},
      sotcStaticProtectMap: {},
      sotcStaticGuaranteeMap: {},
      copyAccountMsg: "",
      googleCode: "",
      apiAssetsInfo: {},
      from_item_id: "",
      to_item_id: "",
      from_symbol: "",
      to_symbol: "",
      swap_symbol_id: "",
      beforeBailNumber: "",
      afterBailNumber: "",
      beforeLiquidationPrice: "",
      afterLiquidationPrice: "",
      symbolList: {},
      price_precision: "",
      settlement_precision: "",
      contract_value: "",
      fee_rate: "",
      liquidation_risk_rate: "",
      bail_number: "",
      rowData: {},
      row_swap_symbol_id: "",
      inputProfitPrice: "",
      inputLossPrice: "",
      setProfitPricedialog: false,
      dialogType: "",
      confirmMessage: "",
      plan_order_id: "",
      dialogVisible: false,
      profitLossShareBg: _profit_loss_share_bg.default,
      expressionBad: _expressionBad.default,
      expressionCool: _expressionCool.default,
      expressionGreat: _expressionGreat.default,
      notifyImg: "",
      notifyVisible: false,
      painting: {},
      b9Logo: _b.default,
      bxcLogo: _bxc.default,
      hxLogo: _hx.default,
      logo100: _logo.default,
      maskLogo: _mask_new.default,
      hxlogo_text: _hxlogo_text.default,
      logo100_text: _logo100_text.default,
      app_id: "",
      mask_text: _mask_text.default,
      b9_text: _b9_text.default,
      qrCode: _qr_code.default,
      projectEnglishName: "",
      projectFullName: "",
      slogan: "",
      projectTitle: "",
      projectLogo: "",
      default_invite_code: {},
      centerHost: "",
      operateTypeMap: {
        1: this.translateTitle("开仓"),
        2: this.translateTitle("平仓"),
        3: this.translateTitle("设置止损价"),
        4: this.translateTitle("设置止盈价"),
        5: this.translateTitle("追加本金"),
        6: this.translateTitle("已强平"),
        7: this.translateTitle("同时设置止盈止损"),
        8: this.translateTitle("加仓"),
        9: this.translateTitle("减仓"),
        10: this.translateTitle("资金费用")
      },
      position_order_id: "",
      position_order_obj: {
        position_info: {},
        operate_list: []
      },
      user_order_obj: {},
      closePositionTypeMap: {
        1: this.translateTitle("未平仓"),
        2: this.translateTitle("用户平仓"),
        3: this.translateTitle("强制平仓"),
        4: this.translateTitle("止盈平仓"),
        5: this.translateTitle("止损平仓"),
        6: this.translateTitle("自动减仓")
      },
      //平仓类型
      openPositionTypeMap: {
        1: this.translateTitle("手动开仓"),
        2: this.translateTitle("触发价开仓")
      },
      //开仓类型
      liquidation_symbol_name: "",
      coinData: {},
      staticSymbolData: {},
      staticFuturesSymbolData: {},
      searchCoin: [],
      staticAssetsTypeData: [],
      staticStockData: {},
      searchStaticAssetsType: [],
      billStatusMap: [{
        value: 1,
        label: this.translateTitle("进行中")
      }, {
        value: 2,
        label: this.translateTitle("已完成")
      }, {
        value: 3,
        label: this.translateTitle("失败")
      }],
      searchBillStatus: [],
      ctimeAndEtime: [],
      dataList: [],
      opDirectionMap: {
        1: this.translateTitle("收入"),
        2: this.translateTitle("支出")
      },
      btnLoading: false,
      tableLoading: true,
      lookmoreVisible: false,
      billDialogTitle: "",
      billDialogVisible: false,
      reChargeDialogVisible: false,
      otcDialogVisible: false,
      tabList: [{
        label: this.translateTitle("收款账号"),
        value: 1
      }, {
        label: this.translateTitle("付款截图"),
        value: 2
      }, {
        label: this.translateTitle("订单历史"),
        value: 3
      }],
      currIndex: 1,
      googleStatus: 1,
      // 谷歌验证状态
      currIndexPosition: 1,
      api_secret: "",
      coinInfoMap: {},
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      },
      isDoBS: false,
      isBuy: true
      //   currRow: {},
    };
  },

  async created() {
    let {
      authInfo
    } = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};
    if (authInfo) {
      this.googleStatus = authInfo.open_google;
    }
    this.initData();
    if (this.language && _stationSetting.VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin" || _stationSetting.VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin") {
      this.projectFullName = "Bullxcow";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("Bullxcow App下载");
      this.projectLogo = this.bxcLogo;
    } else if (this.language && _stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectFullName = "B9";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("B9 App下载");
      this.projectLogo = this.b9Logo;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.projectFullName = "MaskEX";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("MaskEX App下载");
      this.projectLogo = this.maskLogo;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "100Ex") {
      this.projectFullName = "100Ex";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("100Ex App下载");
      this.projectLogo = this.logo100;
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "EverTrust") {
      this.projectFullName = "EverTrust";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("EverTrust App下载");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "8V") {
      this.projectFullName = "8V";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("8V App下载");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "UEEx") {
      this.projectFullName = "UEEx";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("UEEx App");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "Ahex") {
      this.projectFullName = "AHEX";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "AHEX" + this.translateTitle(" App下载");
    } else if (this.language && _stationSetting.VUE_UNION_STATION == "5A") {
      this.projectFullName = "5A";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "5A" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
      this.projectFullName = "bitda";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = "bitda" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "S6") {
      this.projectFullName = "S6";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "S6" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "easy") {
      this.projectFullName = "ezex";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "ezex" + this.translateTitle("App下载");
    } else if (_stationSetting.VUE_UNION_STATION == "spex") {
      this.projectFullName = "SPEX";
      this.slogan = this.translateTitle(`数字理财 赢得未来`);
      this.projectTitle = "SPEX" + this.translateTitle("App下载");
    } else {
      this.projectFullName = "汇信国际";
      this.slogan = this.translateTitle(`数字财富 未来已来`);
      this.projectTitle = this.translateTitle("汇信App下载");
      this.projectLogo = this.hxLogo;
    }
  },
  destroyed() {},
  beforeDestroy() {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    })
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    moment: _moment.default,
    // async getGooglStatus() {
    //   let { status, data, msg } = await bingGoogleVerifyStatus();
    //   if (status !== 200) {
    //     return;
    //   }
    //   this.googleStatus = data.bing_google_status;
    // },
    oncloseGoogle() {
      this.googleCode = "";
      this.api_secret = "";
      //   this.lookmoreVisible = false;
    },

    onlook() {
      if (!this.googleCode) {
        (0, _message.showMessage)({
          message: this.translateTitle(`谷歌码不能为空`)
        });
        return;
      }
      this.btnLoading = true;
      (0, _billCenter.apiApiSecretSecretInfo)({
        google_code: this.googleCode,
        api_key: this.currRow.api_key
      }).then(rs => {
        this.btnLoading = false;
        if (rs.status == 200) {
          var _rs$data;
          console.log("rs", rs);
          if (rs !== null && rs !== void 0 && (_rs$data = rs.data) !== null && _rs$data !== void 0 && _rs$data.api_secret) {
            this.api_secret = rs.data.api_secret;
          }
        }
      });
    },
    onDeleteConfirm() {
      if (!this.googleCode) {
        (0, _message.showMessage)({
          message: this.translateTitle(`谷歌码不能为空`)
        });
        return;
      }
      this.delLoading = true;
      (0, _billCenter.apiApiSecretDelete)({
        id: this.currRow.id,
        google_code: this.googleCode
      }).then(rs => {
        this.delLoading = false;
        if (rs.status == 200) {
          this.delVisible = false;
          (0, _message.showMessage)({
            message: this.translateTitle(`删除成功`),
            type: "success"
          });
          this.initData();
        }
      });
    },
    async deleteRow(row) {
      if (!this.googleStatus) {
        (0, _message.showMessage)({
          message: this.translateTitle(`请在安全设置绑定谷歌码`)
        });
        return;
      }
      this.currRow = row;
      this.delVisible = true;
    },
    formatStatus(row) {
      return row == 1 ? this.translateTitle(`开启`) : this.translateTitle(`禁用`);
    },
    formatDate(row) {
      if (row == 0) {
        return this.translateTitle(`永久`);
      }
      return this.moment(row * 1000).format("YYYY/MM/DD HH:mm:ss");
    },
    lookmore(row) {
      if (!this.googleStatus) {
        (0, _message.showMessage)({
          message: this.translateTitle(`请在安全设置绑定谷歌码`)
        });
        return;
      }
      this.currRow = row;
      this.lookmoreVisible = true;
    },
    addClick() {
      //   if (!this.googleStatus) {
      //     showMessage({
      //       message: this.translateTitle(`请在安全设置绑定谷歌码`),
      //     });
      //     return;
      //   }

      this.currType = 1;
      this.addEditVisible = true;
    },
    editData(row) {
      if (!this.googleStatus) {
        (0, _message.showMessage)({
          message: this.translateTitle(`请在安全设置绑定谷歌码`)
        });
        return;
      }
      this.currType = 2;
      this.currRow = row;
      this.addEditVisible = true;
    },
    onUpdateSuccess() {
      this.addEditVisible = false;
      this.initData();
    },
    onUpdateCancel() {
      this.addEditVisible = false;
    },
    // 复制成功
    copySuccess() {
      this.$message({
        message: this.translateTitle(`复制成功`),
        type: "success",
        customClass: "zZindex"
      });
    },
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`复制失败`)
      });
    },
    async resetData(pageNum = 1) {
      this.tableLoading = true;
      this.app_id = "";
      const {
        status,
        data
      } = await (0, _billCenter.apiApiSecretGetList)({});
      if (status && status == 200) {
        let tempData = data;
        this.dataList = tempData;
      }
      this.tableLoading = false;
    },
    async initData(pageNum = 1) {
      const req = {
        app_id: this.app_id
      };
      this.tableLoading = true;
      const {
        status,
        data
      } = await (0, _billCenter.apiApiSecretGetList)(req);
      if (status && status == 200) {
        data === null || data === void 0 ? void 0 : data.forEach(item => {
          item.visible = false;
        });
        this.dataList = data;
      }
      this.tableLoading = false;
    }
  }
};
exports.default = _default;