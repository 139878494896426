"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vuex = require("vuex");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/model/user/index");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      languageName: "简体中文",
      showDownLoadImg: false
    };
  },
  created() {
    let lang = localStorage.getItem("language");
    // this.handleSelect(lang);
  },

  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = _jsCookie.default.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    async onLogout() {
      const abc = await (0, _index.apiGetLoginOut)();
      this.$store.dispatch("user/logout");
      // 关闭usdt长连接
    },

    goTo(target) {
      this.$router.push(target);
    }
  }
};
exports.default = _default;