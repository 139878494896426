"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkPwd = exports.checkMobileAll = exports.checkMobile = exports.checkEmailAll = exports.checkEmail = exports.checkConfirm = exports.checkAll = void 0;
const checkAll = (e_val, m_val, p_val, c_val, code_val) => {
  let email = checkEmail(e_val);
  let mobile = checkMobile(m_val);
  let pwd = checkPwd(p_val);
  let confirm = checkConfirm(p_val, c_val);
  let code = checkCode(code_val);
  let obj = {
    emailTip: email.tip,
    mobileTip: mobile.tip,
    pwdTip: pwd.tip,
    confirmTip: confirm.tip,
    codeTip: code.tip
  };
  if (!email.check || !mobile.check || !pwd.check || !confirm.check || !code.check) {
    obj.check = false;
    return obj;
  } else {
    obj.check = true;
    return obj;
  }
};

/* 检测手机所有 */
exports.checkAll = checkAll;
const checkMobileAll = (m_val, p_val, c_val, code_val) => {
  let mobile = checkMobile(m_val);
  let pwd = checkPwd(p_val);
  let confirm = checkConfirm(p_val, c_val);
  let code = checkCode(code_val);
  let obj = {
    mobileTip: mobile.tip,
    pwdTip: pwd.tip,
    confirmTip: confirm.tip,
    codeTip: code.tip
  };
  if (!mobile.check || !pwd.check || !confirm.check || !code.check) {
    obj.check = false;
    return obj;
  } else {
    obj.check = true;
    return obj;
  }
};

/* 检测邮箱所有 */
exports.checkMobileAll = checkMobileAll;
const checkEmailAll = (m_val, p_val, c_val) => {
  let email = checkEmail(m_val);
  let pwd = checkPwd(p_val);
  let confirm = checkConfirm(p_val, c_val);
  let obj = {
    emailTip: email.tip,
    pwdTip: pwd.tip,
    confirmTip: confirm.tip
  };
  if (!email.check || !pwd.check || !confirm.check) {
    obj.check = false;
    return obj;
  } else {
    obj.check = true;
    return obj;
  }
};
exports.checkEmailAll = checkEmailAll;
const checkCode = val => {
  if (/^\s*$/.test(val)) {
    return {
      tip: '验证码不能为空',
      check: false
    };
  } else {
    return {
      tip: '',
      check: true
    };
  }
};
const checkEmail = val => {
  if (/^\s*$/.test(val)) {
    return {
      tip: '邮箱账号不能为空',
      check: false
    };
  } else if (!/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/.test(val)) {
    return {
      tip: '邮箱格式不正确',
      check: false
    };
  } else {
    return {
      tip: '',
      check: true
    };
  }
};
exports.checkEmail = checkEmail;
const checkMobile = val => {
  if (/^\s*$/.test(val)) {
    return {
      tip: '手机号不能为空',
      check: false
    };
  }

  // 格式检验
  // if (!/^[0-9]{5,20}$/.test(val)) {
  //   return {
  //     tip: '手机号格式不正确',
  //     check: false
  //   }
  // }

  return {
    tip: '',
    check: true
  };
};
exports.checkMobile = checkMobile;
const checkPwd = val => {
  if (/^\s*$/.test(val)) {
    return {
      tip: '密码不能为空',
      check: false
    };
  } else if (val.length < 6 || val.length > 20) {
    return {
      tip: '密码长度应为6-20位字符',
      check: false
    };
  } else if (!/^(?=.*[a-zA-Z]+)(?=.*[0-9]+)(?=.*[\S]*)[a-zA-Z0-9·~！@#￥%……&*（）——\-+=【】{}、|；‘’：“”《》？，。、`~!#$%^&*()_[\]{}\\|;'':"",./<>?]+$/.test(val)) {
    return {
      tip: '密码必须包含英文和数字，不能包含空格',
      check: false
    };
  } else {
    return {
      tip: '',
      check: true
    };
  }
};
exports.checkPwd = checkPwd;
const checkConfirm = (oldVal, val) => {
  if (/^\s*$/.test(val)) {
    return {
      tip: '确认密码不能为空',
      check: false
    };
  } else if (oldVal !== val) {
    return {
      tip: '两次密码输入不一致',
      check: false
    };
  } else if (oldVal === val) {
    return {
      tip: '',
      check: true
    };
  }
};
exports.checkConfirm = checkConfirm;