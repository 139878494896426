<template>
  <section class="web-fifth-section">
    <div class="title">
      <div class="section-part top-part">
        <h2 class="title-top bold">Frequently Asked Questions</h2>
      </div>
    </div>
    <div class="answers">
      <div
        v-for="(qa, index) in faqData"
        :key="index"
        class="section-part middle-part"
      >
        <div class="question-container" @click="toggleAnswer(index)">
          <h3 class="question bold">{{ qa.question }}</h3>
          <div :class="['expand-icon', { expanded: qa.expanded }]"></div>
        </div>
        <div class="question-line"></div>
        <div class="answer-container" v-if="qa.expanded">
          <p class="answer">{{ qa.answer }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      faqData: [
        {
          question: "Who can apply for the MaskEX Card?",
          answer:
            "Here is answer Here is answerHere is answerHere is answerHere is answerHere is answerHere is answer.",
          expanded: false,
        },
        {
          question: "Who can apply for the MaskEX Card?",
          answer:
            "Here is answer Here is answerHere is answerHere is answerHere is answerHere is answerHere is answer.",
          expanded: false,
        },
        {
          question: "Who can apply for the MaskEX Card?",
          answer:
            "Here is answer Here is answerHere is answerHere is answerHere is answerHere is answerHere is answer.",
          expanded: false,
        },
        // Add more FAQ entries here
      ],
    };
  },
  methods: {
    toggleAnswer(index) {
      this.faqData[index].expanded = !this.faqData[index].expanded;
    },
  },
};
</script>

<style scoped>
/* Fifth section styles */
.web-fifth-section {
  /* width: 100%; */
  height: 693px;
  /* display: flex;
  background: #f5f7fa;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 59px;
  margin-top: 141px;
}
.answers {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 59px;
  margin-top: 50px;
  margin-bottom: 121px;
  margin-left: 356px;
  margin-right: 362px;
}

.section-part {
  width: 100%;
  display: flex;
  justify-content: center;
}

.title-top {
  color: black;
  font-family: "DIN Pro";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.middle-part {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.question-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100%; /* Take up full width */
}

.expand-icon {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}
.expanded {
  transform: rotate(180deg);
}

.question-line {
  width: 100%;
  height: 1px;
  background-color: black;
  margin: 5px 0;
}

.answer-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.answer {
  font-size: 16px;
  color: gray;
}
</style>
