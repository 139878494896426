"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Common = _interopRequireWildcard(require("@/model/common"));
var _ucenter = require("@/model/ucenter/ucenter");
var _assets = require("@/model/ucenter/assets");
var _message = require("@/components/dialog/message.js");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _stationSetting = require("@/assets/js/stationSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const IMAGE_DEFAULT = "../../../assets/img/avatar.png";
var _default = {
  mixins: [_commonMixin.default],
  name: "SettingAccount",
  props: {
    getUserInfoInit: Function,
    isShowDialog: Boolean,
    updateShowDialog: Function,
    userInfo: Object,
    username: [String]
  },
  data() {
    return {
      //上传图片请求参数
      filePostParams: {
        file: "",
        type: 2
      },
      currentDefPermiss: null,
      defPermissList: [],
      currentIsShow: false,
      formData: {
        username: this.userInfo.realname ? this.userInfo.realname : "",
        intro: this.userInfo.intro,
        nick_name: this.userInfo.nick_name ? this.userInfo.nick_name : this.translateTitle(`用户`) + `${this.userInfo.user_id}`,
        avatar: this.userInfo.avatar ? this.userInfo.avatar : IMAGE_DEFAULT
      },
      confirmLoading: false,
      uploadImgLoading: false,
      formRules: {
        nick_name: [{
          required: true,
          message: this.translateTitle("请输入昵称"),
          trigger: "blur"
        }, {
          min: 2,
          max: 10,
          // pattern: /^[\u4e00-\u9fa5_\u0800-\u4e00_a-zA-Z0-9]{2,20}$/,
          // trigger: "change",
          // message: this.translateTitle("请输入昵称"),
          trigger: "blur",
          message: this.translateTitle("请输入昵称,昵称需2-10位字符")
        }],
        intro: [{
          min: 2,
          max: 30,
          required: true,
          message: this.translateTitle("请输入个人签名，签名需2-30字符"),
          trigger: "blur"
        }]
      }
    };
  },
  watch: {
    currentIsShow(val) {
      this.updateShowDialog(val);
    }
  },
  created() {
    if (this.userInfo) {
      this.imageUrl = this.userInfo.avatar;
    }
    this.currentIsShow = this.isShowDialog;
  },
  methods: {
    onCancel() {
      this.currentIsShow = false;
    },
    // 提交表单方法
    async onSubmit() {
      let statusData;
      let {
        formData
      } = this;
      this.$refs.changePwdForm.validate(valid => {
        if (valid) {
          statusData = formData;
        } else {
          statusData = false;
        }
      });
      if (!statusData) {
        return false;
      }
      // 检测昵称是否存在
      let {
        status,
        msg
      } = await (0, _assets.nickNameCheck)({
        subaccount_id: this.userInfo.account_id,
        nick_name: statusData.nick_name
      });
      if (status !== 200) {
        (0, _message.showMessage)({
          message: msg
        });
        return;
      }

      //提交
      this.onCancel();
      this.postApi(this.formData, () => {
        this.getUserInfoInit();
      });
    },
    customerModifiedInfoSuccessfully(data) {
      const customerInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      Object.keys(customerInfo.userInfo).forEach(key => {
        data[key] ? customerInfo.userInfo[key] = data[key] : null;
      });
      sessionStorage.setItem("userInfo", JSON.stringify(customerInfo));
    },
    //设置POST回调
    postApi(params, callback) {
      let data = Object.assign({}, this.formData);
      if (params) {
        data = Object.assign(params, this.formData);
      }
      (0, _ucenter.editUserInfo)(data).then(({
        status,
        data,
        msg
      }) => {
        if (status === 200) {
          (0, _message.showMessage)({
            message: this.translateTitle("修改账号成功"),
            type: "success"
          });
          this.customerModifiedInfoSuccessfully(this.formData);
          this.$emit("onConfirm");
          callback && callback();
        } else {
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
          if (!params) {
            callback && callback();
          }
        }
      });
    },
    //图片上传
    async picUpload({
      file
    }) {
      // this.$refs.uploadFile.type = "text";
      // this.$refs.uploadFile.type = "file";
      this.uploadImgLoading = true;
      if (file.size > 2 * 1024 * 1024) {
        this.uploadImgLoading = false;
        (0, _message.showMessage)({
          message: "上传图片不能大于 2M"
        });
        return;
      }
      if (["image/jpg", "image/jpeg", "image/png"].indexOf(file.type) < 0) {
        this.uploadImgLoading = false;
        (0, _message.showMessage)({
          message: "只支持上传 jpg / jpeg / png 类型图片",
          duration: 2000
        });
        return false;
      }
      const token = window.$.cookie("topcredit_exchange_t") || "";
      let formData = new window.FormData();
      formData.append("type", 1);
      formData.append("app_id", _stationSetting.VUE_APP_ID);
      formData.append("file", file);
      formData.append("token", token);

      // this.filePostParams.type = 1;
      // this.filePostParams.file = file;
      // this.filePostParams.app_id = VUE_APP_ID
      //请求参数
      const {
        status,
        data,
        msg
      } = await Common.fileUpload(formData);
      if (data.status == 200) {
        const url = data.data.url;
        this.formData.avatar = data.data.url;
        (0, _message.showMessage)({
          message: this.translateTitle(`图片上传成功`),
          type: "success"
        });
        this.$emit("setPaymentUrl", url);
        this.uploadImgLoading = false;

        // 貼上圖片url
      } else {
        (0, _message.showMessage)({
          message: data.msg
        });
        this.uploadImgLoading = false;
      }
    }
  }
};
exports.default = _default;