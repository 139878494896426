"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _stationSetting = require("@/assets/js/stationSetting");
var _markets = require("@/model/markets/markets");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _keymap = require("@/assets/js/keymap.js");
var _vuex = require("vuex");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _echarts = require("echarts");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: {
    swap_symbol_id: {
      type: [String, Number],
      default: function () {
        return 1010;
      }
    },
    tradePairArray: {
      type: Array,
      default: function () {
        return [];
      }
    },
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    from_symbol: {
      type: [String, Number]
    },
    to_symbol: {
      type: [String, Number]
    },
    frObj: {
      type: Object
    }
  },
  data() {
    return {
      systemCurrencyMaps: _keymap.systemCurrencyMaps,
      multipleNum: 20,
      timer: null,
      multipleNumBtn: false,
      colorClass: "",
      fundForm: {
        fund_rate: "",
        time: 28800
      },
      TIMER: null,
      TIMER2: null,
      fundTime: 0,
      selfOptionsList: [],
      isMaskex: false
    };
  },
  created() {
    if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.isMaskex = true;
    }
    this.getOptionList();
    this.TIMER2 = setInterval(() => {
      this.getFundingRate();
    }, 1000 * 60 * 60);
  },
  destroyed() {
    clearInterval(this.TIMER);
    clearInterval(this.TIMER2);
  },
  beforeDestroy() {
    clearInterval(this.TIMER);
    clearInterval(this.TIMER2);
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    onMultipleNumClick() {
      this.$emit("onMultipleNumClick", this.multipleNum);
    },
    setMultipleNum(value) {
      this.multipleNum = value;
    },
    async getFundingRate() {
      if (this.swap_symbol_id) {
        let params = {
          symbol_id: this.swap_symbol_id
        };
        const {
          status,
          data
        } = await (0, _markets.apiGetFundingRate)(params);
        if (status == 200) {
          this.fundForm = data;
          this.fundTime = data.time;
          this.TIMER = setInterval(() => {
            this.fundTime--;
          }, 1000);
        }
      }
    },
    async getOptionList() {
      let {
        userInfo
      } = await Account.getUserInfo();
      if (!userInfo) {
        return;
      }
      (0, _markets.apiGetOptionList)().then(res => {
        if (res.status == 200) {
          if (JSON.stringify(res.data) != "{}" && res.data.digital) {
            this.selfOptionsList = res.data.digital;
          }
        }
      });
    },
    addOption(type) {
      if (this.userInfo) {
        let params = {
          symbol_id: this.swap_symbol_id,
          option: type,
          swap_type: 2
        };
        (0, _markets.apiAddOption)(params).then(res => {
          if (res.status == 200) {
            this.getOptionList();
            this.$emit("onAddOption");
            this.$message({
              message: params.option == 1 ? this.translateTitle(`添加自选成功`) : this.translateTitle(`删除自选成功`),
              type: params.option == 1 ? "success" : "success"
            });
          }
        });
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    }
  },
  watch: {
    fundTime(newValue, oldValue) {
      if (newValue < 0) {
        this.fundTime = 28800;
        clearInterval(this.TIMER);
        this.getFundingRate();
      }
    },
    frObj(obj) {
      this.fundForm = obj.fundForm;
      this.fundTime = obj.fundTime;
    },
    swap_symbol_id() {
      clearInterval(this.TIMER);
      let tempMultipleNum = JSON.parse(localStorage.getItem("contract-multiple-num"));
      for (const key in tempMultipleNum) {
        if (key == this.swap_symbol_id) {
          this.multipleNumBtn = true;
          this.multipleNum = Number(tempMultipleNum[key]);
          setTimeout(() => {
            this.multipleNumBtn = false;
          }, 1000);
        }
      }
      if (this.multipleNumBtn == false) {
        this.multipleNum = 20;
      }
      this.getFundingRate();
    },
    marketInfoShow(newValue, oldValue) {
      if (Number(newValue.last) - Number(oldValue.last) > 0) {
        this.colorClass = "green";
      } else if (Number(newValue.last) - Number(oldValue.last) < 0) {
        this.colorClass = "red";
      }
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language"
    }),
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    marketInfoShow() {
      // this.pageLoading = true;
      let marketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          marketInfo = ele;
        }
      });
      // this.pageLoading = false;
      return marketInfo;
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.price_change_percent < 0) {
        className = "red";
      }
      return className;
    },
    countTime() {
      //将秒数转换为时分秒格式
      var t;
      if (this.fundTime > -1) {
        var hour = Math.floor(this.fundTime / 3600);
        var min = Math.floor(this.fundTime / 60) % 60;
        var sec = this.fundTime % 60;
        if (hour < 10) {
          t = "0" + hour + ":";
        } else {
          t = hour + ":";
        }
        if (min < 10) {
          t += "0";
        }
        t += min + ":";
        // t += sec;

        if (sec < 10) {
          t += "0";
        }
        t += sec.toFixed(0);
      }
      if (t == "00:00:00") {
        t = "--";
      }
      return t;
    }
  }
};
exports.default = _default;