"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/model/letter/index.js");
var _index2 = require("@/utils/index.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _i18n = require("@/assets/js/i18n");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      showDisplay: false,
      checked: false,
      pageLoading: false,
      messageList: [],
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 8,
        pageTotal: 6
      }
    };
  },
  components: {
    NoData: _NoData.default
  },
  created() {
    this.getMessageList();
  },
  mounted() {
    this.startTimer();
  },
  computed: {
    textSwitch() {
      if (this.showDisplay == false) {
        return "详情";
      } else {
        return "收起";
      }
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    onClickDetails() {},
    startTimer() {
      this.getMessageList().then(() => {
        const timer = setTimeout(() => {
          this.startTimer();
        }, 5000);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(timer);
        });
      });
    },
    async getMessageList() {
      const req = {
        page: this.pageInfo.pageCurrent,
        pageSize: this.pageInfo.pageSize
      };
      const {
        status,
        data
      } = await (0, _index.apiGetMessageList)(req);
      if (status == 200) {
        data.list.forEach(ele => {
          ele.showDisplay = false;
          ele.create_time = (0, _index2.parseTime)(ele.create_time);
          ele.conLength = ele.content.length;
        });
        this.messageList = data.list;
        this.pageInfo = data.pageInfo;
      }
    },
    async onReadMessage(item) {
      const req = {
        message_id: item.message_id
      };
      const {
        status,
        data
      } = await (0, _index.apiReadMessage)(req);
      if (status == 200) {
        item.status = 2;
      }
    },
    async onReadAll() {
      const req = {
        message_id: -1
      };
      const {
        status,
        data
      } = await (0, _index.apiReadMessage)(req);
      if (status == 200) {
        this.getMessageList();
      }
    },
    onCurrentPageChange() {
      this.getMessageList();
    }
  }
};
exports.default = _default;