"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _Title = _interopRequireDefault(require("./components/Title"));
var _SettingAccount = _interopRequireDefault(require("./components/SettingAccount"));
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _index = require("@/model/assets/index");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _assets = require("@/model/ucenter/assets");
var _message = require("@/components/dialog/message.js");
var _getTime = require("@/assets/js/getTime");
var _accuracy = require("@/assets/js/accuracy");
var _index2 = require("@/model/exchange/index");
var _vuex = require("vuex");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _globalFun = _interopRequireDefault(require("@/assets/js/globalFun.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import EmptyTable from "@/components/EmptyTable";

// import { getUserInfo } from "model/ucenter/ucenter";

const refreshTime = 5000;
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      userInfo: {},
      avatar: "",
      nick_name: "",
      vip_level: "",
      //vip 等级
      vip0: require("@/assets/img/vip-level/vip-0.png"),
      vip1: require("@/assets/img/vip-level/vip-1.png"),
      vip2: require("@/assets/img/vip-level/vip-2.png"),
      currVipImg: "",
      authLevelTotal: 2,
      //设置总安全级别
      authLevelText: {
        1: "低",
        2: "中",
        3: "高"
      },
      exchangeLimit: {},
      // 提币限额
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      },
      assetsList: {},
      tableData: [],
      assetsShow: "",
      assetsShowCurrency: {},
      assetsListTimer: null,
      historyLoading: true,
      settingAccountData: {
        getMobileType: 46,
        getMailType: 45,
        isShowDialog: false,
        updateShowDialog: val => {
          this.settingAccountData.isShowDialog = val;
        },
        getUserInfoInit: this.getUserInfoInit
      },
      username: "",
      voucherInterval: null,
      marginMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--"
        }
      },
      contractMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--"
        }
      },
      stockMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--"
        }
      },
      financeMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--"
        }
      },
      remainMap: {
        u_valuation: "--",
        all_u_valuation: {
          usd: "--"
        }
      },
      tabList: [{
        value: "1",
        label: "余额详情"
      }],
      currIndex: "1",
      advanced: 1,
      globalFun: _globalFun.default
    };
  },
  async created() {
    this.advanced = localStorage.getItem("advanced") ? localStorage.getItem("advanced") : 1;
    await this.getUserInfoInit();
    this.getAssetsList();
    await this.userLoginLogList({
      page: 1
    });
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
    clearInterval(this.assetsListTimer);
  },
  mounted() {
    this.loopRefreshAssets();
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    handlePassword(type) {
      this.advanced = type;
      localStorage.setItem("advanced", this.advanced);
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    onConfirm() {
      this.getUserInfoInit();
    },
    onFinance() {
      this.$router.push(this.routeSplicing(this.language, "ucenter/finanManagement"));
    },
    onAssets() {
      this.$router.push(this.routeSplicing(this.language, "ucenter/assets"));
    },
    async getItemList() {
      const req = {
        __version: "3.4.3",
        __channel: "2"
      };
      const {
        status,
        data
      } = await (0, _index.getCoinList)(req);
      if (status == 200) {
        sessionStorage.setItem("itemList", JSON.stringify(data));
      }
    },
    loopRefreshAssets() {
      this.assetsListTimer = setInterval(() => this.getAssetsList(), refreshTime);
    },
    getStaticLabel(num) {
      return `${(num * 1).toFixed(2)}`;
    },
    login_land() {
      return `-`;
    },
    // 获取资产列表
    getAssetsList() {
      let params = {
        type: "all"
      };
      (0, _index2.apiGetAssets)(params).then(({
        status,
        data
      }) => {
        if (status === 200) {
          this.assetsShow = data.assets.u_valuation;
          this.assetsShowCurrency = data.assets.all_u_valuation;
          this.financeMap = data.finance;
          this.marginMap = data.margin;
          this.stockMap = data.stock;
          this.contractMap = data.contract;
          this.remainMap = data.remain;
        } else if (status === -188) {
          clearInterval(this.assetsListTimer);
        }
      });
    },
    // 获取用户信息
    async getUserInfoInit() {
      // const { status, data } = await Account.apiGetUserInfo();
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      this.settingAccountData.row = {};
      const {
        userInfo,
        accountInfo
      } = this.userInfo;
      if (userInfo) {
        this.settingAccountData.userInfo = userInfo;
        const {
          user_id,
          nick_name,
          avatar,
          vip_level
        } = userInfo;
        // this.nick_name = nick_name ? nick_name : `${user_id}`;
        this.nick_name = nick_name || this.userInfo.accountInfo.username || this.globalFun.userDecryption(this.userInfo.accountInfo.mobile, "mobile") || this.globalFun.userDecryption(this.userInfo.accountInfo.email, "email") || this.translateTitle(`用户`) + `${user_id}`;
        this.avatar = avatar ? avatar : "https://";
        this.vip_level = vip_level;
        this.currVipImg = this.vip_level !== -1 ? this.$data[`vip${this.vip_level}`] : "";
      }
      if (accountInfo) {
        if (accountInfo.username) {
          let str = accountInfo.username || accountInfo.email || accountInfo.mobile;
          this.username = str.length > 2 ? str.substr(0, 2) + "****" + str.substr(-2) : str;
          return;
        } else if (accountInfo.email) {
          var emailReg = /(.{2}).+(@.+)/g;
          let str = accountInfo.email;
          this.username = str.replace(emailReg, "$1****$2");
          return;
        } else if (accountInfo.mobile) {
          let str = accountInfo.mobile;
          this.username = str.length > 2 ? str.substr(0, 3) + "****" + str.substr(-4) : str;
        }
      }
    },
    getInitData() {},
    // 页面数据变化
    onPageChange(val) {
      this.userLoginLogList({
        page: val
      });
    },
    // 获取 24h 内的提币限制
    getUserExchangeLimit() {
      (0, _assets.getUserExchangeLimit)().then(({
        status,
        data,
        msg
      }) => {
        if (status === 200) {
          this.exchangeLimit = data;
        } else {
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
        }
      });
    },
    // 用户登录记录
    async userLoginLogList(pamars) {
      pamars.page_size = this.pageInfo.pageSize;
      this.historyLoading = true;
      await (0, _assets.userLoginLogList)(pamars).then(({
        status,
        data,
        msg
      }) => {
        if (status === 200) {
          this.tableData = data.list;
          this.pageInfo = data.pageInfo;
        } else {
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
        }
      });
      this.historyLoading = false;
    },
    getTimeRefor(row) {
      return (0, _getTime.reformatting)(+row.login_time * 1000);
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    coinOutNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let {
          coinOutNum
        } = this.exchangeLimit;
        return (0, _accuracy.accuracy)(coinOutNum, 2);
      } else {
        return "--";
      }
    },
    limitNum() {
      if (JSON.stringify(this.exchangeLimit) !== "{}") {
        let {
          limitNum
        } = this.exchangeLimit;
        return (0, _accuracy.accuracy)(limitNum, 2);
      } else {
        return "--";
      }
    },
    hasUser() {
      if (JSON.stringify(this.userInfo) !== "{}") {
        return true;
      }
      return false;
    },
    isLevel1() {
      return this.userInfo.userInfo && this.userInfo.userInfo.level >= 1 && this.userInfo.userInfo.status === 2 ? true : false;
    },
    checkAuthLevel() {
      if (this.hasUser) {
        let is_open_mobile = this.userInfo.authInfo.open_mobile;
        let is_open_email = this.userInfo.authInfo.open_email;
        // let is_open_google = this.userInfo.authInfo.open_google;
        if (this.userInfo.accountInfo.type === 4) {
          //子账号
          is_open_mobile = this.userInfo.parentUserInfo.open_mobile;
          is_open_email = this.userInfo.parentUserInfo.open_email;
          // is_open_google = this.userInfo.parentUserInfo.open_google;
        }

        let check_num = 0;
        is_open_mobile === 1 && (check_num += 1);
        is_open_email === 1 && (check_num += 1);
        // is_open_google === 1 && (check_num += 1);

        check_num = check_num / this.authLevelTotal * 3;
        if (check_num <= 1) {
          check_num = 1;
        } else if (check_num > this.authLevelTotal) {
          check_num = 3;
        }
        return parseInt(check_num);
      } else {
        return 0;
      }
    }
  },
  components: {
    TopTitle: _Title.default,
    // EmptyTable,
    SettingAccount: _SettingAccount.default
  }
};
exports.default = _default;