"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DBind = _interopRequireDefault(require("../DBind"));
var _DGoogle = _interopRequireDefault(require("../DGoogle"));
var _encryption = _interopRequireDefault(require("@/assets/js/encryption"));
var _message = require("@/components/dialog/message");
var _security = require("@/model/ucenter/security");
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  components: {
    DBind: _DBind.default,
    DGoogle: _DGoogle.default,
    QrcodeVue: _qrcode.default
  },
  props: ["accountInfo", "emailStatus", "mobileStatus", "googleStatus"],
  data() {
    return {
      secret: "",
      // 谷歌密钥
      gooleLoading: true,
      appDownload: [{
        img: require("./../../../../assets/img/Apple-store.png"),
        apk: require("./../../../../assets/img/Apple-qrCode.png"),
        name: "Apple"
      }, {
        img: require("./../../../../assets/img/Android-market.png"),
        apk: require("./../../../../assets/img/Android-qrCode.png"),
        name: "Android"
      }, {
        img: require("./../../../../assets/img/Google-play.png"),
        apk: require("./../../../../assets/img/Google-qrCode.png"),
        name: "Google"
      }]
    };
  },
  created() {
    this.getGoogleSecret();
  },
  methods: {
    goBack() {
      this.$emit("backClick");
    },
    // 复制成功
    copySuccess() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制成功`),
        type: "success"
      });
    },
    // 复制失败
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制失败`)
      });
    },
    // 获取谷歌验证
    getGoogleSecret() {
      (0, _security.getGoogleSecret)().then(res => {
        if (res.status === 200) {
          this.secret = res.data.secret;
        } else {
          (0, _message.showMessage)({
            message: res.msg
          });
        }
      });
    },
    // 确认提交
    async confirmBtn() {
      let params = {};
      let emailCheck,
        mobileCheck,
        googleCheck,
        newGoogleCheck = false;
      emailCheck = this.$refs.email_ref ? this.$refs.email_ref.submitForm() : true;
      mobileCheck = this.$refs.mobile_ref ? this.$refs.mobile_ref.submitForm() : true;
      googleCheck = this.$refs.google_ref ? this.$refs.google_ref.submitForm() : true;
      newGoogleCheck = this.$refs.new_google_ref ? this.$refs.new_google_ref.submitForm() : true;
      if (!(emailCheck && mobileCheck && googleCheck && newGoogleCheck)) return;

      /* 参数 */
      if (this.$refs.email_ref) {
        let data = this.$refs.email_ref.submitForm();
        delete data.email;
        params = Object.assign({}, {
          ...data
        });
      }
      if (this.$refs.mobile_ref) {
        let data = this.$refs.mobile_ref.submitForm();
        delete data.mobile;
        params = Object.assign(params, {
          ...data
        });
      }
      if (this.$refs.google_ref) {
        let data = this.$refs.google_ref.submitForm();
        params = Object.assign(params, {
          ...data
        });
      }
      if (this.$refs.new_google_ref) {
        let data = this.$refs.new_google_ref.submitForm();
        data.code = data.google_code;
        delete data.google_code;
        params = Object.assign(params, {
          ...data
        });
      }
      params.google_secret = this.secret;

      /* 提交请求 */
      // 未绑定谷歌
      if (this.googleStatus == 0) {
        let res = await (0, _security.bindGoogleVerify)(params);
        this.successType(res, this.translateTitle("绑定谷歌验证成功"));
        // 已绑定谷歌
      } else {
        params["old_google_code"] = params["google_code"];
        params["google_code"] = params["code"];
        let res = await (0, _security.updateGoogleVerify)(params);
        this.successType(res, this.translateTitle("修改谷歌验证成功"));
      }
    },
    successType(res, message) {
      let {
        status,
        msg
      } = res;
      if (status !== 200) {
        (0, _message.showMessage)({
          message: msg ? msg : status
        });
        return;
      }
      (0, _message.showMessage)({
        message: message,
        type: "success"
      });
      // 提交成功
      this.$emit("subSuccess", true);
    }
  },
  watch: {
    googleCode() {
      this.gooleLoading = false;
    }
  },
  computed: {
    emailType() {
      return this.googleStatus != 0 ? "21" : "17";
    },
    mobileType() {
      return this.googleStatus != 0 ? "22" : "18";
    },
    btnText() {
      return this.googleStatus != 0 ? this.translateTitle("修改验证") : this.translateTitle("开启验证");
    },
    googleCode() {
      let name, $encodeURI;
      let {
        mobileStatus,
        emailStatus,
        accountInfo,
        secret,
        gooleLoading
      } = this;
      name = (0, _encryption.default)(accountInfo.username);
      if (mobileStatus) {
        name = (0, _encryption.default)(accountInfo.mobile);
      }
      if (emailStatus) {
        name = name ? " " + (0, _encryption.default)(accountInfo.email) : (0, _encryption.default)(accountInfo.email);
      }
      if (secret) {
        let str = secret.slice(secret.length - 6);
        let val = `otpauth://totp/${this.projectEnglishName}(${name}-${str})?secret=${secret}`;
        val = val.replace(/\s+/g, "");
        return val;
      } else {
        return "";
      }
    }
  }
};
exports.default = _default;