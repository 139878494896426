"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _position = _interopRequireDefault(require("./components/contract/position"));
var _record = _interopRequireDefault(require("./components/contract/record.vue"));
var _index = require("@/model/exchange/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data() {
    return {
      active: 1,
      contractMap: {},
      listType: [{
        text: "当前持仓",
        val: 1
      }, {
        text: "交易记录",
        val: 2
      }]
    };
  },
  components: {
    Position: _position.default,
    Record: _record.default
  },
  created() {
    this.getAssets();
    this.startAssetsTimer();
  },
  methods: {
    onTypeChange(val) {
      this.active = val;
    },
    startAssetsTimer() {
      this.getAssets().then(() => {
        const timer = setTimeout(() => {
          this.startAssetsTimer();
        }, 500);
        this.$once("hook:beforeDestroy", () => {
          clearTimeout(timer);
        });
      });
    },
    async getAssets() {
      // if (!this.userInfo) {
      //   return;
      // }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        this.contractMap = data.contract;
      }
    }
  }
};
exports.default = _default;