"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiCancelOrde = apiCancelOrde;
exports.apiGetOrderList = apiGetOrderList;
exports.apiTrustCancel = apiTrustCancel;
exports.apiTrustDealIndex = apiTrustDealIndex;
exports.apiTrustIndex = apiTrustIndex;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env

function apiGetOrderList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/TradeCoin/getOrderListV2`,
    data,
    type: "POST"
  });
}
function apiCancelOrde(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/TradeCoin/cancelLimitOrder`,
    data,
    type: "POST"
  });
}
// 撤销订单新接口
function apiTrustCancel(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Spot/Trade/trustCancel`,
    data,
    type: "POST"
  });
}
//用户委托列表
function apiTrustIndex(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Spot/Trade/trustIndex`,
    data,
    type: "POST"
  });
}
// 用户成交列表
function apiTrustDealIndex(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Spot/Trade/trustDealIndex`,
    data,
    type: "POST"
  });
}