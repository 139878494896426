"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _AssetSnippet = _interopRequireDefault(require("./components/AssetSnippet"));
var _getAssetsCoin = require("./common/getAssetsCoin");
var _Title = _interopRequireDefault(require("./components/Title"));
var _index = require("@/model/assets/index");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import { getCoinList } from "@/model/assets/index";

const refreshTime = 5000;
var _default = {
  data() {
    return {
      coinData: {},
      // 币种数据列表
      assetsList: {},
      // 资产数据列表
      assetsListTimer: null
    };
  },
  async created() {
    // this.coinData = JSON.parse(sessionStorage.getItem("itemList"));
    await this.getItemList();
    // this.coinData = await getCoinInfo();
    this.assetsList = await (0, _getAssetsCoin.getAssetsData)();
    this.loopRefreshAssets();
  },
  beforeDestroy() {
    clearInterval(this.assetsListTimer);
  },
  destroyed() {
    clearInterval(this.assetsListTimer);
  },
  computed: {
    sortCoinData() {
      return this.sortObj(this.coinData);
      // return this.coinData;
    }
  },

  methods: {
    sortObj(obj) {
      if (obj[10007]) {
        obj[10007].order_by = -1;
      }
      const keys = Object.keys(obj).sort(function (a, b) {
        return obj[a].order_by - obj[b].order_by;
      });
      var newObj = [];
      for (var i = 0; i < keys.length; i++) {
        const key = keys[i];
        newObj.push(obj[key]);
      }
      return newObj;
    },
    async getItemList() {
      const req = {
        __version: "3.4.3",
        __channel: "2"
      };
      const {
        status,
        data
      } = await (0, _index.getStaticCoinList)(req);
      if (status == 200) {
        this.coinData = data;
      }
    },
    loopRefreshAssets() {
      this.assetsListTimer = setInterval(async () => this.assetsList = await (0, _getAssetsCoin.getAssetsData)(), refreshTime);
    }
  },
  components: {
    TopTitle: _Title.default,
    AssetSnippet: _AssetSnippet.default
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.assetsListTimer);
    next();
  }
};
exports.default = _default;