"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: ["data", "capital_name", "exchangeLimit"],
  computed: {
    confirmNum() {
      if (JSON.stringify(this.data) !== "{}") {
        let {
          confirm_num
        } = this.data.itemDetail;
        return confirm_num;
      } else {
        return "--";
      }
    }
  }
};
exports.default = _default;