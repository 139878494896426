"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _settings = require("@/model/settings");
var _vuex = require("vuex");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {};
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      systemCurrency: "user/currency",
      language: "user/language"
    })
  },
  watch: {},
  async created() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    routeSplicing: _routeSplicing.default,
    goLogin() {
      if (!this.userInfo) {
        this.$message({
          message: this.translateTitle("请先登录"),
          type: "warning",
          customClass: "zZindex"
        });
        this.$router.push({
          path: this.routeSplicing(this.language, "login")
        });
      }
    }
  }
};
exports.default = _default;