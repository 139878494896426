import { render, staticRenderFns } from "./bv-home-content.vue?vue&type=template&id=31959f0e&"
import script from "./bv-home-content.vue?vue&type=script&lang=js&"
export * from "./bv-home-content.vue?vue&type=script&lang=js&"
import style0 from "./bv-home-content.vue?vue&type=style&index=0&id=31959f0e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports