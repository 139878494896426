"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _vuePuzzleVcode = _interopRequireDefault(require("vue-puzzle-vcode"));
var _ = _interopRequireDefault(require("../../assets/img/rePhoto/1.png"));
var _index = require("@/model/login/index");
var _message = require("@/components/dialog/message.js");
var _i18n = require("@/assets/js/i18n");
var _vuex = require("vuex");
var _account = require("@/model/user/account");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//页面内引用插件
var _default = {
  data() {
    return {
      isShow: false,
      // 用来控制显示是否显示图片滑动验证框
      photoHua: 0,
      //图片滑动验证的错误次数
      img1: _.default,
      orderStep: 1,
      account: "",
      bindEmail: false,
      bindMobile: false,
      bindGoogle: false,
      isSubmint: false,
      email: "khreui@qq.com",
      mobile: "123klsjflku3o",
      checkList: [],
      checkRadio: "",
      contact: "",
      submitLoading: false
    };
  },
  components: {
    Vcode: _vuePuzzleVcode.default
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    }),
    getIsApply() {
      let isApply = false;
      if (this.checkRadio && this.contact) {
        isApply = true;
        return isApply;
      } else {
        isApply = false;
        return isApply;
      }
    }
  },
  async created() {
    let type = this.$route.query.type;
    if (type) {
      await this.getUserInfoV2();
    } else {
      await this.getUserInfo();
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    translateTitle: _i18n.translateTitle,
    onFilterContact(val) {
      this.contact = val.replace(/[^\a-\z\A-\Z0-9\@\-\.\$]/g, "");
    },
    async getUserInfo() {
      const {
        authInfo
      } = JSON.parse(localStorage.getItem("userData"));
      if (authInfo) {
        this.bindEmail = authInfo.open_email == 0 ? false : true;
        this.bindMobile = authInfo.open_mobile == 0 ? false : true;
        this.bindGoogle = authInfo.open_google == 0 ? false : true;
        if (this.bindEmail) {
          this.checkRadio = 1;
        } else if (this.bindMobile) {
          this.checkRadio = 2;
        } else if (this.bindGoogle) {
          this.checkRadio = 3;
        }
      }
    },
    async getUserInfoV2() {
      const authInfo = JSON.parse(sessionStorage.getItem("user_info"));
      if (authInfo) {
        this.bindEmail = authInfo.validate_email == 0 ? false : true;
        this.bindMobile = authInfo.validate_mobile == 0 ? false : true;
        this.bindGoogle = authInfo.validate_google == 0 ? false : true;
        if (this.bindEmail) {
          this.checkRadio = 1;
        } else if (this.bindMobile) {
          this.checkRadio = 2;
        } else if (this.bindGoogle) {
          this.checkRadio = 3;
        }
      }
    },
    success() {
      this.orderStep = 2;
      this.isShow = false; // 通过验证后，手动隐藏模态框
    },

    async onStartApply() {
      this.submitLoading = true;
      const req = {
        type: this.checkRadio,
        contact: this.contact,
        token: sessionStorage.getItem("topcredit_exchange_t")
      };
      const {
        status,
        data
      } = await (0, _index.apiSafelyLost)(req);
      this.submitLoading = false;
      if (status == 200) {
        (0, _message.showMessage)({
          message: this.translateTitle(`提交安全项丢失成功`),
          type: "success",
          duration: 1200
        });
        this.$router.push(this.routeSplicing(this.language, "login"));
      }
    }
  }
};
exports.default = _default;