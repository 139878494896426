"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLangPackage = getLangPackage;
require("core-js/modules/es.array.push.js");
var _stationSetting = require("@/assets/js/stationSetting");
var _getSign = _interopRequireDefault(require("./getSign"));
let LANG_WEB_VERSION = "";
let LANG_WEB_PACKAGE = "";
let app_id = _stationSetting.VUE_APP_ID;
let option = {
  data: {}
};
if (!app_id) {
  app_id = localStorage.getItem("web-id");
}
function apiGetWebLang(url = "", request = {}) {
  let tempLang = localStorage.getItem("language");
  option.data = request;
  // option.data.lang = tempLang || "zh_CN";
  option.data.app_id = app_id;
  option.data.__platform = 3;
  const token = window.$.cookie("topcredit_exchange_t") || sessionStorage.getItem("topcredit_exchange_t");
  let req = {
    token,
    ...option.data
  };
  req._CDCODE = (0, _getSign.default)(req, app_id);
  let result = "";
  const headers = {};
  headers["content-type"] = "application/x-www-form-urlencoded";
  // headers["accept-encoding"] = "gzip,deflate";
  const arr = [];
  for (const field in req) {
    arr.push(field + "=" + req[field]);
  }
  result = arr.join("&");
  let data = {};
  let status = 0;
  window.$.ajax({
    dataType: "text",
    type: "POST",
    url: _stationSetting.VUE_APP_APP + url,
    //`/Lang/WebVersion`,
    data: result || "",
    headers,
    processData: false,
    contentType: false,
    async: false,
    success: function (result) {
      //请求成功
      result = JSON.parse(result);
      data = result.data;
      status = result.status;
    },
    error: function (e) {
      //请求失败，包含具体的错误信息
    }
  });
  return {
    status,
    data
  };
}
function compare(array1, array2) {
  if (!array1 || !array2 || array1.length != array2.length) {
    return false;
  }
  array1 = array1.slice();
  array1.sort();
  array2 = array2.slice();
  array2.sort();
  for (var i = 0; i < array1.length; i++) {
    if (array1[i] != array2[i]) {
      return false;
    }
  }
  return true;
}
function searchLangPackageChange(keyWordArray = ["zh_CN"]) {
  let resLangVersion = {};
  let langWebVersionTimestamp = JSON.parse(localStorage.getItem("LangWebVersionTimestamp"));
  let unanimous = compare(keyWordArray, langWebVersionTimestamp && langWebVersionTimestamp.keyWordArray);
  if (langWebVersionTimestamp && parseInt(langWebVersionTimestamp.timestamp) + 3 * 60 * 1000 > new Date().valueOf() && unanimous) {
    return "";
  }
  resLangVersion = apiGetWebLang(`/Lang/WebVersion`);
  if (resLangVersion.status == 200) {
    localStorage.setItem("LangWebVersionTimestamp", JSON.stringify({
      timestamp: `${new Date().valueOf()}`,
      keyWordArray: keyWordArray
    }));
    let varLangWebVersion = JSON.parse(localStorage.getItem("LangWebVersion"));
    if (!varLangWebVersion) {
      localStorage.setItem("LangWebVersion", JSON.stringify(resLangVersion.data));
      return keyWordArray;
    } else {
      if (JSON.stringify(varLangWebVersion) != JSON.stringify(resLangVersion.data)) {
        let tempWordArray = [];
        keyWordArray.forEach(keyWord => {
          if (!varLangWebVersion[`${keyWord}`].version || varLangWebVersion[`${keyWord}`].version != resLangVersion.data[`${keyWord}`].version) {
            tempWordArray.push(keyWord);
            varLangWebVersion[`${keyWord}`] = resLangVersion.data[`${keyWord}`];
          }
        });
        localStorage.setItem("LangWebVersion", JSON.stringify(varLangWebVersion));
        // if (
        //   !varLangWebVersion[`${keyWord}`].version ||
        //   varLangWebVersion[`${keyWord}`].version !=
        //     resLangVersion.data[`${keyWord}`].version
        // ) {
        //   tempWord = keyWord;
        // }
        //   localStorage.setItem(
        //     "LangWebVersion",
        //     JSON.stringify(resLangVersion.data)
        //   );
        return tempWordArray;
      } else {
        return "";
      }
    }
  }
  return "";
}
function getLangPackage(keyWordArray = ["zh_CN"]) {
  const varLangWebVersion = JSON.parse(localStorage.getItem("LangWebVersion"));
  if (searchLangPackageChange(keyWordArray)) {
    const resLangPackage = apiGetWebLang(`/Lang/WebLangPackage`, {
      lang: keyWordArray.join(","),
      lang_version: varLangWebVersion ? varLangWebVersion[`${keyWordArray[0]}`] ? varLangWebVersion[`${keyWordArray[0]}`].version : 0 : 0
    });
    Object.keys(resLangPackage.data).forEach(keyWord => {
      let obj = {};
      obj[keyWord] = resLangPackage.data[keyWord];
      localStorage.setItem(`lang_${keyWord}`, JSON.stringify(obj));
    });
    // localStorage.setItem(
    //   `lang_${keyWord}`,
    //   JSON.stringify(resLangPackage.data)
    // );
  } else {
    let empytLangKeyWord = [];
    keyWordArray.forEach(keyWord => {
      let obj = localStorage.getItem(`lang_${keyWord}`);
      if (!obj) empytLangKeyWord.push(keyWord);
    });
    if (empytLangKeyWord.length > 0) {
      const resLangPackage = apiGetWebLang(`/Lang/WebLangPackage`, {
        lang: empytLangKeyWord.join(","),
        lang_version: varLangWebVersion ? varLangWebVersion[`${empytLangKeyWord[0]}`] ? varLangWebVersion[`${empytLangKeyWord[0]}`].version : 0 : 0
      });
      Object.keys(resLangPackage.data).forEach(keyWord => {
        let obj = {};
        obj[keyWord] = resLangPackage.data[keyWord];
        localStorage.setItem(`lang_${keyWord}`, JSON.stringify(obj));
      });
      // localStorage.setItem(
      //   `lang_${keyWord}`,
      //   JSON.stringify(resLangPackage.data)
      // );
    }
  }

  let resultObj = {};
  keyWordArray.forEach(keyWord => {
    resultObj[keyWord] = JSON.parse(localStorage.getItem(`lang_${keyWord}`))[`${keyWord}`];
  });
  return resultObj;
}