"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _accuracy = require("@/assets/js/accuracy");
var _getTime = require("@/assets/js/getTime");
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: ["data", "exchangeList", "isRecharge", "historyLoading", "multiChainStatus"],
  data() {
    return {};
  },
  computed: {
    dataList() {
      if (JSON.stringify(this.data) !== "{}") {
        let {
          confirm_num
        } = this.data.itemDetail;
        return this.exchangeList.filter(item => {
          if (typeof item.create_time === "number") {
            item.create_time = (0, _getTime.reformatting)(+item.create_time * 1000);
          }
          item.chain_tag = item.chain_tag.toUpperCase() || "--";
          // 不截取接口值小数位
          // item.num = accuracy(item.num, 2);
          item.address = this.isRecharge ? item.from_address : item.to_address;
          item.confirm = item.confirm > confirm_num ? confirm_num : item.confirm;
          return true;
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    chargeWithdrawStatus(val) {
      if (["waiting", "pending"].indexOf(val) !== -1) {
        return "等待中";
      } else if (["confirming", "cantrade"].indexOf(val) !== -1) {
        return "确认中";
      } else if (val === "success") {
        return "完成";
      } else if (val === "canceled") {
        return "取消";
      } else if (val === "faild") {
        return "失败";
      }
    },
    getClassName(status) {
      if (status == "inAudit") {
        return "common-progress";
      } else if (status == "waiting" || status == "pending") {
        return "common-progress";
      } else if (status == "confirming" || status == "cantrade") {
        return "common-progress";
      } else if (status == "success") {
        return "common-success";
      } else if (status == "faild") {
        return "common-error";
      } else if (status == "canceled") {
        return "common-cancel";
      }
    },
    seeTxid(row) {
      // this.$refs.refTable.toggleRowExpansion(row);
      this.txidJump(row);
    },
    async txidJump(row) {
      // Three  新增静态表数据支持币种多链
      let txid_url = "";
      // txid_url = this.data.itemDetail.txid_url
      await (0, _settings.apiGetStaticTableInfo)(56).then(({
        data
      }) => {
        for (const key in data) {
          if (row.chain_tag.toLowerCase() == data[key].chain_tag) {
            txid_url = data[key].txid_url;
            break;
          } else {
            txid_url = this.data.itemDetail.txid_url;
          }
        }
      });
      let jumpUrl = txid_url + row.txid;
      window.open(jumpUrl);
    }
  },
  filters: {
    formatNum(val) {
      return (0, _bignumber.default)(val).toFixed(8);
    }
  },
  components: {
    NoData: _NoData.default
  }
};
exports.default = _default;