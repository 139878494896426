"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fileUpload = fileUpload;
exports.videoUpload = videoUpload;
var _axios = _interopRequireDefault(require("axios"));
var _api = _interopRequireDefault(require("@/assets/js/api"));
// import { Message } from 'element-ui'

//图片上传
function fileUpload(url, params, error) {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data;'
    }
  }; //添加请求头
  return new Promise(resolve => {
    _axios.default.post(url, params, config).then(res => {
      resolve(res);
    }, err => {
      err = error ? error : err;
    });
  });
}
function videoUpload(url, params, error) {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }; //添加请求头
  return new Promise(resolve => {
    _api.default.post(url, params, config).then(res => {
      resolve(res);
    }, err => {
      err = error ? error : err;
    });
  });
}