"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _message = require("@/components/dialog/message");
var _i18n = require("@/assets/js/i18n");
var _billCenter = require("@/model/ucenter/billCenter");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "add-edit-api-key",
  props: {
    currRow: {
      type: Object,
      default: function () {
        return {};
      }
    },
    currType: {
      type: [Number]
    }
  },
  data() {
    return {
      form: {
        name: "",
        ip_list: "",
        status: 1
      },
      rules: {
        google_code: [{
          required: true,
          trigger: "blur",
          message: this.translateTitle("谷歌码不能为空")
        }],
        status: [{
          required: true,
          trigger: "blur",
          message: this.translateTitle("状态不能为空")
        }],
        name: [{
          required: true,
          trigger: "blur",
          message: this.translateTitle("名称不能为空")
        }]
      }
    };
  },
  created() {
    if (this.currType != 1) {
      this.form.name = this.currRow.name;
      this.form.ip_list = this.currRow.ip_list.join(",");
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    onCancel() {
      this.$emit("update-cancel");
    },
    onAdd() {
      this.$refs.form.validate(valid => {
        if (!valid) {
          return;
        }
        const req = {
          ...this.form
        };
        if (this.currType == 1) {
          (0, _billCenter.apiApiSecretAdd)(req).then(rs => {
            if (rs.status == 200) {
              //   this.$message("添加成功", "success");
              (0, _message.showMessage)({
                message: this.translateTitle(`添加成功`),
                type: "success"
              });
              this.$emit("update-success");
            }
          });
        } else {
          req.id = this.currRow.id;
          (0, _billCenter.apiApiSecretEdit)(req).then(rs => {
            if (rs.status == 200) {
              (0, _message.showMessage)({
                message: this.translateTitle(`编辑成功`),
                type: "success"
              });
              this.$emit("update-success");
            }
          });
        }
      });
    }
  }
};
exports.default = _default;