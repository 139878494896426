"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _vueAwesomeSwiper = require("vue-awesome-swiper");
var _settings = require("@/model/settings");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = require("@/model/home/index");
var _vuex = require("vuex");
var _index2 = require("@/model/exchange/index");
var _keymap = require("@/assets/js/keymap.js");
var _qrcode = _interopRequireDefault(require("qrcode.vue"));
var _axios = _interopRequireDefault(require("axios"));
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
var _stationSetting = require("@/assets/js/stationSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var vm = null;
var _default = {
  mixins: [_commonMixin.default],
  components: {
    swiper: _vueAwesomeSwiper.swiper,
    swiperSlide: _vueAwesomeSwiper.swiperSlide,
    // QrcodeVue,
    NoData: _NoData.default
  },
  data() {
    return {
      tt: {},
      //WS延时触发器
      lockReconnect: false,
      //避免重复连接
      wsContentTime: 0,
      detailsUrl: "",
      systemCurrencyMaps: _keymap.systemCurrencyMaps,
      token: "",
      wsUrl: "",
      appDownLoadImg: "",
      activeName: "1",
      // realIndex:0,
      isHuiXin: false,
      is8V: false,
      isUeasy: false,
      isS6: false,
      sideLen: 4,
      isResiter: false,
      resiterVal: "",
      resiterValue: "",
      isMobile: false,
      isEmail: false,
      showDownLoadImg: false,
      showBanner: false,
      android_url: "",
      appstore_url: "",
      doanGoogleUrl: "https://www.topcreditbank.com/app_download/",
      doanIosUrl: "https://apps.apple.com/app/id1525081932",
      tipUrl: "",
      firstArticles: {},
      secondArticles: {},
      swiperOption: {
        initialSlide: 0,
        // 箭头配置
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev"
        },
        on: {
          click: function () {
            // 这里有坑，需要注意的是：this 指向的是 swpier 实例，而不是当前的 vue， 因此借助 vm，来调用 methods 里的方法
            // console.log(this); // -> Swiper
            // 当前活动块的索引，与activeIndex不同的是，在loop模式下不会将 复制的块 的数量计算在内。
            const realIndex = this.realIndex;
            vm.handleClickSlide(realIndex);
          }
        },
        // slidesPerView: 1,
        spaceBetween: 24,
        slidesPerView: 4,
        loopedSlides: 8,
        slidesPerGroup: 1,
        // slidesPerView: 'auto',
        // // spaceBetween: 10,
        // // slidesPerView: "auto",
        // loopedSlides: 1,
        // slidesPerGroup: 'auto',
        loopFillGroupWithBlank: true,
        infinite: 1,
        // 无限滚动前后遍历数
        observer: true,
        observeParents: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        },
        // autoplay: {
        //   disableOnInteraction: false,
        //   delay: 4000,
        // },
        autoplay: false,
        followFinger: false,
        autoplayDisableOnInteraction: false,
        // //循环
        loop: false
        // // //每张播放时长3秒，自动播放
        // autoplay: 2000,
        // // //滑动速度
        // speed: 1000,
      },

      oldImgList: [{
        language: {
          img_url_ar: require("../../assets/img/home/bitda/banner-1.png"),
          img_url_en: require("../../assets/img/home/bitda/banner-1.png"),
          img_url_ja: require("../../assets/img/home/bitda/banner-1.png"),
          img_url_ko: require("../../assets/img/home/bitda/banner-1.png"),
          img_url_zh_CN: require("../../assets/img/home/bitda/banner-1.png"),
          img_url_zh_HK: require("../../assets/img/home/bitda/banner-1.png")
        },
        param: {
          url: ""
        }
      }, {
        language: {
          img_url_ar: require("../../assets/img/home/bitda/banner-2.png"),
          img_url_en: require("../../assets/img/home/bitda/banner-2.png"),
          img_url_ja: require("../../assets/img/home/bitda/banner-2.png"),
          img_url_ko: require("../../assets/img/home/bitda/banner-2.png"),
          img_url_zh_CN: require("../../assets/img/home/bitda/banner-2.png"),
          img_url_zh_HK: require("../../assets/img/home/bitda/banner-2.png")
        },
        param: {
          url: ""
        }
      }, {
        language: {
          img_url_ar: require("../../assets/img/home/bitda/banner-3.png"),
          img_url_en: require("../../assets/img/home/bitda/banner-3.png"),
          img_url_ja: require("../../assets/img/home/bitda/banner-3.png"),
          img_url_ko: require("../../assets/img/home/bitda/banner-3.png"),
          img_url_zh_CN: require("../../assets/img/home/bitda/banner-3.png"),
          img_url_zh_HK: require("../../assets/img/home/bitda/banner-3.png")
        },
        param: {
          url: ""
        }
      }, {
        language: {
          img_url_ar: require("../../assets/img/home/bitda/banner-4.png"),
          img_url_en: require("../../assets/img/home/bitda/banner-4.png"),
          img_url_ja: require("../../assets/img/home/bitda/banner-4.png"),
          img_url_ko: require("../../assets/img/home/bitda/banner-4.png"),
          img_url_zh_CN: require("../../assets/img/home/bitda/banner-4.png"),
          img_url_zh_HK: require("../../assets/img/home/bitda/banner-4.png")
        },
        param: {
          url: ""
        }
      }],
      imgList: [],
      articlesList: [],
      tableDataComputed: [],
      exchangeCoinMap: {},
      coinInfoList: {},
      tableLoading: true,
      tableData: [],
      activeSymbol: "1",
      collectSymbol: [22],
      ws: null,
      logoImg: "",
      timer: null
    };
  },
  async created() {
    this.logoImg = require("../../assets/img/logo_bitda.png");
    await this.getBannerData();
    await this.getExchangeCoinMap();
    let qrcode_download = JSON.parse(sessionStorage.getItem("qrcode_download"));
    let about = JSON.parse(sessionStorage.getItem("about"));
    let zendesk_notice = JSON.parse(sessionStorage.getItem("zendesk_notice"));
    this.appDownLoadImg = qrcode_download.url;
    if (zendesk_notice.language) {
      this.tipUrl = zendesk_notice.language[`notice_url_${this.language}`];
      this.detailsUrl = zendesk_notice.language[`articles_url_${this.language}`];
    } else {
      this.tipUrl = zendesk_notice.notice_url;
      this.detailsUrl = zendesk_notice.articles_url;
    }
    await this.getStaticTableInfo(1);
    this.getDeatilsData();
    let tempCookies = _jsCookie.default.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      await this.initURL();
      this.tableLoading = true;
      this.initWS();
    } else {
      this.token = _jsCookie.default.get("topcredit_exchange_t");
      await this.initURL();
      this.tableLoading = true;
      this.initWS();
    }
    vm = this;
    this.voucherInterval = setInterval(() => {
      this.getBannerData();
      this.getDeatilsData();
    }, 5000 * 60 * 6);
  },
  mounted() {
    if (this.language == "zh_CN") {
      let meta = document.getElementsByTagName("meta");
      meta["title"].setAttribute("content", "Bitda Cryptocurrency Exchange 币达加密货币交易所");
      meta["description"].setAttribute("content", "一款区块链数字资产交易平台。主要是为用户提供比特币，莱特币等虚拟货币行情资讯和相关交易服务，使用非常的方便，安全，操作也十分的简单，帮助用户节省了很多不必要的流程。");
    }
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    userInfoShow() {
      let tempVal = true;
      if (this.userInfo) {
        tempVal = true;
      } else {
        let tempCookie = _jsCookie.default.get("topcredit_exchange_t");
        if (tempCookie) {
          tempVal = true;
        } else {
          tempVal = false;
        }
      }
      return tempVal;
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    jumpToUrl(url) {
      if (url != "" && url) {
        window.open(url);
      }
    },
    getMainV2() {
      let params = {
        channel: 0
      };
      (0, _index.apiGetMainV2)(params).then(res => {
        alert(res.status);
        if (res.status == 200) {}
      });
    },
    async getExchangeCoinMap() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(55);
      if (status == 200) {
        let tempObj = {};
        for (const key in data) {
          if (data[key].symbol_type == 1 && data[key].status == 1) {
            tempObj[key] = data[key];
          }
        }
        this.exchangeCoinMap = JSON.parse(JSON.stringify(tempObj));
      }
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };

      const {
        status,
        data
      } = await (0, _index2.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
        this.android_url = data.android_url;
        this.appstore_url = data.super_sign_url;
      }
    },
    handleClick() {},
    // async getItemList() {
    //   const req = {
    //     __version: "3.4.3",
    //     __channel: "2",
    //   };
    //   const { status, data } = await getCoinList(req);
    //   if (status == 200) {
    //     sessionStorage.setItem("itemList", JSON.stringify(data));
    //   }
    // },
    onResiter(type) {
      var name = "";
      var inputVal = "";
      if (type == 1) {
        inputVal = this.resiterValue;
      } else {
        inputVal = this.resiterVal;
      }
      var emailReg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
      var numReg = /^\d{9,}$/;
      if (inputVal) {
        if (emailReg.test(inputVal)) {
          name = "email";
        } else if (numReg.test(inputVal)) {
          name = "phone";
        } else {
          name = "account";
        }
      } else {
        name = "";
        inputVal = "";
      }
      this.$router.push({
        name: "Register",
        params: {
          name: name,
          val: inputVal
        }
      });
    },
    handleClickSlide(index) {
      console.log("當前點擊索引：", index);
    },
    onClickMore() {
      window.open(this.tipUrl);
    },
    onLookMarket() {
      {
        /* this.$router.push(this.routeSplicing(this.language, "markets")); */
      }
    },
    onShowDownLoad() {
      this.showDownLoadImg = true;
    },
    onHiddenDownLoad() {
      this.showDownLoadImg = false;
    },
    async getBannerData() {
      const req = {
        type: 1,
        title: "main"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetBannerList)(req);
      if (status == 200) {
        let len = data.length;
        if (len <= 4) {
          data.forEach((ele, index) => {
            this.oldImgList[index] = ele;
          });
        } else {
          this.imgList = data;
        }
        this.showBanner = true;
        this.sideLen = len >= 4 ? 4 : len;
      }
    },
    async getStaticTableInfo(sv_id) {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(sv_id);
      if (status == 200) {
        this.coinInfoList = JSON.parse(JSON.stringify(data));
      }
    },
    async getDeatilsData() {
      var that = this;
      _axios.default.get(this.detailsUrl).then(function (response) {
        that.articlesList = response.data.articles;
        if (response.data.articles.length >= 1) {
          that.firstArticles = response.data.articles[0];
        } else {
          that.firstArticles.html_url = "";
          that.firstArticles.title = "";
        }
        if (response.data.articles.length >= 2) {
          that.secondArticles = response.data.articles[1];
        } else {
          that.secondArticles.html_url = "";
          that.secondArticles.title = "";
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    initWS() {
      let wsUrl = this.wsUrl;
      let me = this;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "marketM",
            app_id: _stationSetting.VUE_APP_ID
          }
        },
        callback: this.onResponse,
        onConnect
      };
      window.USDT_WS = this.ws = new _socket.default(option).getInstance();
      me.global.setWs(this.ws);
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWS();
          me.lockReconnect = false;
        }, 3000);
      }
      //   setTimeout(() => {}, 1200);
      // this.getSubData();
    },

    onResponse(data) {
      this.tableLoading = false;
      if (data.action == "Pushdata.marketM") {
        this.tableData = data.data;
        if (this.tableData == null || this.tableData.length == 0) {
          return;
        }
        this.tableData.forEach(ele => {
          for (const key in this.exchangeCoinMap) {
            if (key == ele.symbol_id) {
              ele["from_symbol"] = this.exchangeCoinMap[key].name.split("_")[0].toUpperCase();
              ele["to_symbol"] = this.exchangeCoinMap[key].name.split("_")[1].toUpperCase();
              ele["info"] = this.exchangeCoinMap[key];
              ele["web_recommend"] = this.exchangeCoinMap[key].is_recommend;
            }
          }
          for (const key in this.coinInfoList) {
            if (ele.from_symbol) {
              if (this.coinInfoList[key].name == ele.from_symbol.toLowerCase()) {
                ele.coin_url = this.coinInfoList[key].coin_url;
              }
            }
          }
        });
        this.tableDataComputed = this.tableData.filter(ele => {
          return ele.from_symbol != undefined && ele.web_recommend == 1;
        });
      }
    },
    onTrade() {
      let tempUrl = this.routeSplicing(this.language, `exchange/btc_usdt`);
      window.open(tempUrl);
    },
    onSymbolClick(row) {
      let tempUrl = this.routeSplicing(this.language, `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`);
      window.open(tempUrl);
    },
    onClickRow(row, column, event) {
      let tempUrl = this.routeSplicing(this.language, `exchange/${row.from_symbol.toLowerCase()}_${row.to_symbol.toLowerCase()}`);
      window.open(tempUrl);
    }
  }
};
exports.default = _default;