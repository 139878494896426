"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
var _message = require("@/components/dialog/message");
var _settings = require("@/model/settings");
var _index = require("@/model/assets/index");
var _vuex = require("vuex");
var _checkInput = require("@/assets/js/checkInput");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _globalFun = _interopRequireDefault(require("@/assets/js/globalFun.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  // 用户已绑定的账号数据，验证码类型，手机/邮箱，是否禁用，修改数据，验证码位置，背景色
  components: {
    Geetest: () => import("@/components/Geetest.vue")
  },
  props: ["accountInfo", "isChangePwd", "type_num", "type", "disabled", "changeData", "verificate", "bgColor"],
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language"
    })
  },
  data() {
    const isMobile = this.type === "mobile";
    let area = +this.accountInfo.area || 86;
    let mobile, email;
    if (!this.changeData) {
      mobile = this.accountInfo.mobile;
      email = this.accountInfo.email;
    }
    const validatorMobild = (rule, value, callback) => {
      const result = (0, _checkInput.checkMobile)(value);
      if (result && result.tip) {
        callback(new Error(result.tip));
      } else {
        callback();
      }
    };
    return {
      isFetchCode: false,
      countDown: -1,
      // 倒计时
      countDownTimer: null,
      // 倒计时容器
      numLabel: isMobile ? "手机号码" : "邮箱账号",
      codeLabel: isMobile ? "手机验证码" : "邮箱验证码",
      isMobile,
      // 是否用于手机号输入框
      all_areacode: {},
      // 所有区号
      formData: {
        area,
        mobile,
        email,
        mobile_code: "",
        email_code: ""
      },
      formRules: {
        area: [{
          required: true,
          message: this.translateTitle(`请选择区号`),
          trigger: "change"
        }],
        // mobile: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { validator: validatorMobild, trigger: 'blur' }],
        mobile: [{
          required: true,
          message: this.translateTitle(`请输入手机号`),
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: this.translateTitle(`请输入邮箱`),
          trigger: "blur"
        }, {
          pattern: /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,5}$/,
          message: this.translateTitle(`邮箱格式不正确`),
          trigger: "blur"
        }],
        mobile_code: [{
          required: true,
          message: this.translateTitle(`请输入手机验证码`),
          trigger: "blur"
        }, {
          max: 6,
          message: this.translateTitle(`验证码应为 6 位数字`),
          trigger: "blur"
        }],
        email_code: [{
          required: true,
          message: this.translateTitle(`请输入邮箱验证码`),
          trigger: "blur"
        }, {
          max: 6,
          message: this.translateTitle(`验证码应为 6 位数字`),
          trigger: "blur"
        }]
      },
      checkPwdStatus: false,
      isShowGeet: false,
      geetParam: null,
      globalFun: _globalFun.default
    };
  },
  async created() {
    await this.getAreaCode();
  },
  mounted() {
    this.dom();
  },
  methods: {
    dom() {
      if (this.isMobile) {
        const input = document.querySelector("#areaSelect");
        input.setAttribute("maxLength", 5);
      }
    },
    // 清除验证码
    clearCode() {
      this.formData.mobile_code = "";
      this.formData.email_code = "";
    },
    // 获取用户信息
    async getUserInfo() {},
    // 获取手机区号
    async getAreaCode() {
      await (0, _settings.apiGetStaticTableInfo)(_settings.SETTING_SV_ID.static_sms_country).then(res => {
        if (res.status === 200) {
          this.all_areacode = res.data;
        } else {
          (0, _message.showMessage)({
            message: res.msg
          });
        }
      });
    },
    changePwdStatus(status) {
      return status;
    },
    filterData(val) {
      var str = this.$refs.searchSelect.$data.selectedLabel;
      if (str.length > 5) {
        this.$refs.searchSelect.$data.selectedLabel = str.substr(0, 4);
        // this.$refs.searchSelect.$data.selectedLabel = str.subString(0,5)
      }
    },

    getCode4Mobile() {
      if (this.type_num == 3) {
        this.isShowGeet = !this.isShowGeet;
      } else {
        this.getCode();
      }
    },
    success(geetParam) {
      this.geetParam = geetParam;
      this.getCode();
    },
    // 获取验证码
    async getCode() {
      if (this.isFetchCode) return;
      this.isFetchCode = true;
      let type = this.type_num;
      let {
        isChangePwd,
        isMobile,
        formData: {
          mobile,
          area,
          email
        }
      } = this;

      // if (isChangePwd) {
      //   this.$emit("checkPwdStatus", status => (this.checkPwdStatus = status));
      //   if (!this.checkPwdStatus) {
      //     this.isFetchCode = false;
      //     return;
      //   }
      // }
      // 手机

      if (isMobile) {
        if (!mobile || /^\s*$/.test(mobile)) {
          this.isFetchCode = false;
          this.$refs.dBindForm.validateField("mobile");
          return;
        }
        // await sendSmsCode({ type, mobile, area }).then(res => {
        //   this.sendSuccess(res);
        // });
        if (type == 3) {
          await (0, _index.bindMobileCode)({
            type,
            mobile,
            area,
            ...this.geetParam
          }).then(res => {
            this.sendSuccess(res);
          });
        } else if (type == 4) {
          await (0, _index.changeMobileCode)().then(res => {
            this.sendSuccess(res);
          });
        } else if (type == 25) {
          await (0, _index.transferMobileCode)().then(res => {
            this.sendSuccess(res);
          });
        } else {
          await (0, _index.verifyMobileCode)().then(res => {
            this.sendSuccess(res);
          });
        }
      } else {
        if (!email || /^\s*$/.test(email)) {
          this.isFetchCode = false;
          this.$refs.dBindForm.validateField("email");
          return;
        }
        if (type == 6) {
          await (0, _index.bindEmailCode)({
            type,
            email
          }).then(res => {
            this.sendSuccess(res);
          });
        } else if (type == 26) {
          await (0, _index.transferEmailCode)().then(res => {
            this.sendSuccess(res);
          });
        } else {
          await (0, _index.verifyEmailCode)().then(res => {
            this.sendSuccess(res);
          });
        }
        // await sendEmailCode({ type, email }).then(res => {
        //   this.sendSuccess(res);
        // });
      }
    },

    // 发送成功
    sendSuccess(res) {
      if (res.status === 200) {
        this.countDown = 60;
        this.countDownTimer = setInterval(() => {
          if (this.countDown <= 0) {
            clearInterval(this.countDownTimer);
            this.isFetchCode = false;
            this.countDown = -1;
            return;
          }
          this.countDown -= 1;
        }, 1000);
        (0, _message.showMessage)({
          message: this.translateTitle(`发送成功`),
          type: "success"
        });
      } else {
        this.isFetchCode = false;
        (0, _message.showMessage)({
          message: res.msg
        });
      }
    },
    // 登录
    submitForm() {
      // dBindForm
      let statusData;
      let {
        isMobile,
        formData
      } = this;
      this.$refs.dBindForm.validate(valid => {
        if (valid) {
          if (isMobile) {
            delete formData.email;
            delete formData.email_code;
          } else {
            delete formData.area;
            delete formData.mobile;
            delete formData.mobile_code;
          }
          statusData = formData;
        } else {
          statusData = false;
        }
      });
      return JSON.parse(JSON.stringify(statusData));
    }
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.countDownTimer);
    next();
  }
};
exports.default = _default;