"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _situation = _interopRequireDefault(require("./situation"));
var _securitySetting = _interopRequireDefault(require("./security-setting"));
var _kycSetting = _interopRequireDefault(require("./kyc-setting.vue"));
var _recharge_withdrawal = _interopRequireDefault(require("./recharge_withdrawal"));
var _contract = _interopRequireDefault(require("./pages/tradeMoney/contract.vue"));
var _transactionManagment = _interopRequireDefault(require("./pages/tradeMoney/transaction-managment.vue"));
var _coinManager = _interopRequireDefault(require("./pages/tradeManger/coinManager.vue"));
var _financailManagment = _interopRequireDefault(require("./pages/financail-managment/financail-managment"));
var _couponCenter = _interopRequireDefault(require("./pages/coupon-center/coupon-center"));
var _spreadCenter = _interopRequireDefault(require("./pages/spread-center/spread-center"));
var _billCenter = _interopRequireDefault(require("./pages/bill-center/bill-center"));
var _apiSetting = _interopRequireDefault(require("./pages/api-setting/api-setting.vue"));
var _index = _interopRequireDefault(require("./index.vue"));
// import Ucenter from "./index";
// import Assets from "./assets";
// import Exchange from "./exchange";
// import ExchangeDetail from "./exchange-detail";
// import Address from "./address";
// import FinanceManager from "./financeManager";
// import MyExtend from "./myExtend";
// import Auth from "./auth";
// //import Invite from './invite'
//import InviteStats from './invite-stats'
//import InviteDetail from './invite-detail'
// 用户中心路由
var _default = {
  path: "/ucenter",
  redirect: "/ucenter/situation",
  component: _index.default,
  children: [{
    path: "/ucenter/situation/:lang?",
    meta: {
      title: "个人总览"
    },
    component: _situation.default
    //   children: [

    //   ],
  }, {
    path: "/ucenter/safe-setting/:lang?",
    meta: {
      title: "安全设置"
    },
    component: _securitySetting.default
  }, {
    path: "/ucenter/kyc-setting/:lang?",
    meta: {
      title: "KYC认证"
    },
    component: _kycSetting.default
  },
  // 充提币都属于资产下
  {
    name: "recharge",
    meta: {
      title: "充币"
    },
    path: "/ucenter/assets/recharge/:coin/:lang?",
    component: _recharge_withdrawal.default,
    props: true
  }, {
    name: "withdrawal",
    meta: {
      title: "提币"
    },
    path: "/ucenter/assets/withdrawal/:coin/:lang?",
    component: _recharge_withdrawal.default,
    props: true
  }, {
    path: "/ucenter/contract/:lang?",
    meta: {
      title: "合约持仓"
    },
    component: _contract.default
  }, {
    path: "/ucenter/tradeManagment/:lang?",
    meta: {
      title: "交易管理"
    },
    component: _transactionManagment.default
  },
  // 资产管理
  {
    path: "/ucenter/assets/:lang?",
    meta: {
      title: "资产管理"
    },
    component: _coinManager.default
  },
  // 交易管理
  {
    path: "/ucenter/tradeManagment/:lang?",
    meta: {
      title: "交易管理"
    },
    component: _transactionManagment.default
  },
  // 理财管理
  {
    path: "/ucenter/finanManagementTemp/:lang?",
    meta: {
      title: "理财管理"
    },
    component: _financailManagment.default
  },
  // 金融理财
  {
    path: "/ucenter/finanManagement/:lang?",
    meta: {
      title: "金融理财"
    },
    component: _financailManagment.default
  },
  // 推广中心
  {
    path: "/ucenter/spread-center/:lang?",
    meta: {
      title: "推广中心"
    },
    component: _spreadCenter.default
  },
  // 卡券中心
  {
    path: "/ucenter/coupon-center/:lang?",
    meta: {
      title: "卡券中心"
    },
    component: _couponCenter.default
  },
  // 账单
  {
    path: "/ucenter/bill-center/:lang?",
    meta: {
      title: "账单"
    },
    component: _billCenter.default
  },
  // Api管理
  {
    path: "/ucenter/api-setting/:lang?",
    meta: {
      title: "API设置"
    },
    component: _apiSetting.default
  }]
};
exports.default = _default;