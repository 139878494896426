"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = require("@/model/exchange/index");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _vuex = require("vuex");
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  props: {},
  data() {
    return {
      coinFrom: "--",
      coinTo: "--",
      colorClass: "",
      swap_symbol_id: "",
      selfOptionsList: [],
      symbolList: [],
      depthList: {
        a: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]],
        b: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]]
      },
      from_symbol: "",
      to_symbol: "",
      tableLoading: false,
      wsUrl: null
    };
  },
  async created() {
    await this.getTradePairList();
    let tempSymbol = this.$route.query.symbol;
    this.from_symbol = tempSymbol;
    for (const key in this.symbolList) {
      if (this.symbolList[key].name == this.from_symbol) {
        const [coinFrom, coinTo] = this.symbolList[key].name.split("_");
        this.from_symbol = coinFrom;
        this.to_symbol = coinTo;
        this.swap_symbol_id = this.symbolList[key].symbol_id;
      }
    }
    await this.initURL();
    this.initWS();
  },
  methods: {
    goback() {
      window.history.back();
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
      };
      const {
        status,
        data
      } = await (0, _index.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWS() {
      let me = this;
      this.tableLoading = true;
      let wsUrl = this.wsUrl;
      const option = {
        url: wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id
          }
        },
        callback: me.onResponse,
        onConnect,
        mounted
      };
      window.USDT_WS = this.ws = new _socket.default(option).getInstance();
      function mounted() {
        me.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            symbol: me.swap_symbol_id
          }
        });
        me.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id
          }
        });
      }
      // 重新连接
      function onConnect() {
        setTimeout(() => {
          console.log("ws重連");
          me.initWs();
        }, 3000);
      }
    },
    onResponse(data) {
      let me = this;
      if (data.action == "Pushdata.depth") {
        if (data.data) {
          if (!data.params.p) {
            return;
          }
          let tempObj = data.data;
          if (tempObj.a.length > 0) {
            tempObj.c = 0;
            tempObj.d = 0;
            tempObj.a.forEach(ele => {
              tempObj.c += Number(ele[1]);
            });
            tempObj.b.forEach(ele => {
              tempObj.d += Number(ele[1]);
            });
          }
          me.depthList = tempObj;
        }
      }
    },
    async getTradePairList() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(55);
      if (status == 200) {
        this.symbolList = data;
      }
    }
  },
  watch: {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo"
    }),
    from_symbol_show() {
      return this.from_symbol.toUpperCase() || "--";
    },
    to_symbol_show() {
      return this.to_symbol.toUpperCase() || "--";
    },
    totleBuy() {
      return this.depthList.c;
    },
    bigBuy() {
      let temp = Number(this.depthList.a[0][1]);
      for (let i = 0; i < this.depthList.a.length - 1; i++) {
        temp = temp < Number(this.depthList.a[i + 1][1]) ? Number(this.depthList.a[i + 1][1]) : temp;
      }
      return temp;
    },
    bigSell() {
      let temp = Number(this.depthList.b[0][1]);
      for (let i = 0; i < this.depthList.b.length - 1; i++) {
        temp = temp < Number(this.depthList.b[i + 1][1]) ? Number(this.depthList.b[i + 1][1]) : temp;
      }
      return temp;
    },
    totleSell() {
      return this.depthList.d;
    },
    // a卖  b买
    showListSell() {
      return this.depthList.a.slice(0, 50);
    },
    showListBuy() {
      return this.depthList.b.slice(0, 50);
    },
    className() {
      let className = "";
      if (!this.marketInfoShow.info.price_change_percent) {
        className = "";
      } else if (this.marketInfoShow.info.price_change_percent > 0) {
        className = "green";
      } else if (this.marketInfoShow.info.price_change_percent < 0) {
        className = "red";
      }
      return className;
    }
  }
};
exports.default = _default;