"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiAppIntialDataV2 = apiAppIntialDataV2;
exports.apiCommonExport = apiCommonExport;
exports.apiCreateInviteCode = apiCreateInviteCode;
exports.apiDelInviteCode = apiDelInviteCode;
exports.apiGetFriendRebateStats = apiGetFriendRebateStats;
exports.apiGetInviteCodeStats = apiGetInviteCodeStats;
exports.apiGetInviteUrl = apiGetInviteUrl;
exports.apiGetRebateCommonInfo = apiGetRebateCommonInfo;
exports.apiGetRebateList = apiGetRebateList;
exports.apiGetTotalRebateStats = apiGetTotalRebateStats;
exports.apiUpdateInviteCode = apiUpdateInviteCode;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;

//获取数据总览统计信息
function apiGetTotalRebateStats(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/getTotalRebateStats`,
    data,
    type: "POST"
  });
}
//获取推广链接返佣统计
function apiGetInviteCodeStats(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/getInviteCodeStats`,
    data,
    type: "POST"
  });
}
//获取邀请好友返佣统计
function apiGetFriendRebateStats(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/getFriendRebateStats`,
    data,
    type: "POST"
  });
}
//获取返佣流水
function apiGetRebateList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/getRebateList`,
    data,
    type: "POST"
  });
}
//设置默认邀请码、备注
function apiUpdateInviteCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/updateInviteCode`,
    data,
    type: "POST"
  });
}
//生成邀请码
function apiCreateInviteCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/createInviteCode`,
    data,
    type: "POST"
  });
}
//CSV公共导出
function apiCommonExport(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/commonExport`,
    data,
    type: "POST"
  });
}
//获取公共信息
function apiGetRebateCommonInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/getRebateCommonInfo`,
    data,
    type: "POST"
  });
}

//APP初始化API 获取INVITE_URL
function apiAppIntialDataV2(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/Publics/appIntialDataV2`,
    data,
    type: "POST"
  });
}

//新 获取INVITE_URL
function apiGetInviteUrl(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/Publics/getInviteUrl`,
    data,
    type: "POST"
  });
}

//删除邀请码
function apiDelInviteCode(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UserRebate/delInviteCode`,
    data,
    type: "POST"
  });
}