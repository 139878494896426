"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiApiSecretAdd = apiApiSecretAdd;
exports.apiApiSecretDelete = apiApiSecretDelete;
exports.apiApiSecretEdit = apiApiSecretEdit;
exports.apiApiSecretGetList = apiApiSecretGetList;
exports.apiApiSecretSecretInfo = apiApiSecretSecretInfo;
exports.apiGetAssetsLog = apiGetAssetsLog;
exports.apiGetContractPositionInfo = apiGetContractPositionInfo;
exports.apiGetFuturesPositionInfo = apiGetFuturesPositionInfo;
exports.apiGetUserOrderInfo = apiGetUserOrderInfo;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;

//获取账单列表-资产记录
function apiGetAssetsLog(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_SOTC + `/SecretOtc/App/getAssetsLog`,
    data,
    type: "POST"
  });
}
//获取aipkey列表
function apiApiSecretGetList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/ApiSecret/getList`,
    data,
    type: "POST"
  });
}

//新增api Key
function apiApiSecretAdd(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/ApiSecret/create`,
    data,
    type: "POST"
  });
}
//编辑api Key
function apiApiSecretEdit(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/ApiSecret/updateInfo`,
    data,
    type: "POST"
  });
}

//删除 api Key
function apiApiSecretDelete(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/ApiSecret/delete`,
    data,
    type: "POST"
  });
}

//删除 api Key
function apiApiSecretSecretInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/UCenter/ApiSecret/getSecretInfo`,
    data,
    type: "POST"
  });
}

//U本位合约仓位详情
function apiGetContractPositionInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/Contract/positionInfo`,
    data,
    type: "POST"
  });
}
//币本位合约仓位详情
function apiGetFuturesPositionInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Futures/Common/positionInfo`,
    data,
    type: "POST"
  });
}

//用户获取订单详情-OTC 充值 提现
function apiGetUserOrderInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_SOTC + `/SecretOtc/App/getUserOrderInfo`,
    data,
    type: "POST"
  });
}