"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _formatDate = require("@/assets/js/formatDate");
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _i18n = require("@/assets/js/i18n");
var _vuex = require("vuex");
var _index = require("@/model/exchange/index");
var _message = require("@/components/dialog/message");
var _digital = require("@/assets/js/digital");
var _finance = require("@/model/finance/finance.js");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    InputNumber: _InputNumber.default
  },
  props: {
    productId: {
      type: [String, Number],
      default: ""
    },
    productInfo: {
      type: Object,
      default: function () {
        return {
          product_id: 33,
          product_name: "--",
          item_id: 10007,
          channel_id: 101,
          item_type: 1,
          to_item_id: 10069,
          to_item_id_price: "--",
          product_type: 4,
          rate_type: 1,
          quant_tag: 0,
          annual_rate: "--",
          min_fluctuation_annual_rate: "--",
          max_fluctuation_annual_rate: "--",
          day_rate: "--",
          current_rate: "--",
          period: 1,
          start_time: 1620370800,
          stop_time: 1620975600,
          lock_period: "--",
          amount: 0,
          lowest_amount: 0,
          total_amount: "--",
          min_amount: "--",
          max_amount: "--",
          per_min_num: 1,
          per_max_num: 200,
          number_precision: 2,
          price_precision: 2,
          amount_precision: 2,
          trader_profit: 0,
          status: 7,
          union_product_id: 0,
          union_order_by: 1,
          is_union_master: 0,
          public_raise_amount: "--",
          ico_buy_condition: 1,
          ico_user_register_time: 0,
          is_hide: 0,
          is_show_list: 1,
          is_recommand: 1,
          is_delete: 0,
          access_type: 1,
          order_by: 1,
          i18n_lang: "static_finances_product_product_name_330",
          create_time: 1620324487,
          update_time: 1620975363,
          detail: {
            product_id: 33,
            product_intro: "",
            inout_rule: "",
            earnings_rule: "",
            product_code: "--",
            strategy: "",
            ico_url: "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
            ico_eg: "",
            buy_rule: "--",
            risk_tip: "--",
            join_rule: "",
            i18n_lang: "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33"
          },
          item_name: "USDT",
          item_url: require("../../../../assets/img/holder-coin-ico.png"),
          to_item_url: "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
          to_item_name: "--",
          end_time: 1621580400,
          user_profit: 1,
          show_profit: 0
        };
      }
    }
  },
  data() {
    return {
      apiAssetsInfo: "--",
      buyNumber: "",
      dialogForm: {
        min_amount: "",
        max_amount: "",
        item_name: ""
      },
      btnLoading: false,
      apiProductInfo: {
        product_id: 33,
        product_name: "--",
        item_id: 10007,
        channel_id: 101,
        item_type: 1,
        to_item_id: 10069,
        to_item_id_price: "--",
        product_type: 4,
        rate_type: 1,
        quant_tag: 0,
        annual_rate: "--",
        min_fluctuation_annual_rate: "--",
        max_fluctuation_annual_rate: "--",
        day_rate: "--",
        current_rate: "--",
        period: 1,
        start_time: 1620370800,
        stop_time: 1620975600,
        lock_period: "--",
        amount: 0,
        lowest_amount: 0,
        total_amount: "--",
        min_amount: "--",
        max_amount: "--",
        per_min_num: 1,
        per_max_num: 200,
        number_precision: 2,
        price_precision: 2,
        amount_precision: 2,
        trader_profit: 0,
        status: 7,
        union_product_id: 0,
        union_order_by: 1,
        is_union_master: 0,
        public_raise_amount: "--",
        ico_buy_condition: 1,
        ico_user_register_time: 0,
        is_hide: 0,
        is_show_list: 1,
        is_recommand: 1,
        is_delete: 0,
        access_type: 1,
        order_by: 1,
        i18n_lang: "static_finances_product_product_name_330",
        create_time: 1620324487,
        update_time: 1620975363,
        detail: {
          product_id: 33,
          product_intro: "",
          inout_rule: "",
          earnings_rule: "",
          product_code: "--",
          strategy: "",
          ico_url: "https://top6688.zendesk.com/hc/zh-cn/articles/4402147339921-%E8%AE%A4%E8%AF%86-O3",
          ico_eg: "",
          buy_rule: "--",
          risk_tip: "--",
          join_rule: "",
          i18n_lang: "ico_url_31,ico_eg_33,buy_rule_31,risk_tip_31,join_rule_0,product_code_33"
        },
        item_name: "USDT",
        item_url: require("../../../../assets/img/holder-coin-ico.png"),
        to_item_url: "https://imgs-dev.aevwjlae.com/admin/20210505/98506549656.png",
        to_item_name: "--",
        end_time: 1621580400,
        user_profit: 1,
        show_profit: 0
      }
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo"
    }),
    dailyIncome() {
      let tempValue = "--";
      if (this.buyNumber != "") {
        // 年化 *数量*用户分成比例*锁仓天数/365
        tempValue = this.buyNumber * this.productInfo.annual_rate * this.productInfo.user_profit * this.apiProductInfo.quant_real_position_Lock_day / 365 / 100;
        // 截断
        tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
    currentDailyIncome() {
      let tempValue = "--";
      if (this.buyNumber != "") {
        // 存入金额 * 当前年化率 / 365
        tempValue = this.buyNumber * this.productInfo.annual_rate / 100 / 365;
        // 截断
        tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
    fixedDailyIncome() {
      // 定期: 年化 *数量*锁仓天数/365
      let tempValue = "--";
      if (this.buyNumber != "") {
        tempValue = this.productInfo.annual_rate * this.buyNumber * this.productInfo.lock_period / 100 / 365;
        // 截断
        tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    },
    hxDailyIncome() {
      let tempValue = "--";
      if (this.buyNumber != "") {
        //  数量/toItemIdPrice
        tempValue = this.buyNumber / this.productInfo.to_item_id_price;
        // 截断
        tempValue = tempValue.toString().substring(0, tempValue.toString().indexOf(".") + 2 + 1);
      } else {
        tempValue = "--";
      }
      return tempValue;
    }
  },
  created() {
    this.getProductInfo();
    this.getAssets();
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    timestampToYear: _formatDate.timestampToYear,
    onClickAll() {
      if (Number(this.apiAssetsInfo > Number(this.productInfo.max_amount))) {
        this.buyNumber = this.productInfo.max_amount;
      } else {
        this.buyNumber = this.apiAssetsInfo;
      }
    },
    async getAssets() {
      if (!this.userInfo) {
        return;
      }
      let params = {
        type: "all"
      };
      const {
        status,
        data
      } = await (0, _index.apiGetAssets)(params);
      if (status == 200) {
        let tempData = data.remain.list;
        tempData.forEach(ele => {
          if (ele.item_id == this.productInfo.item_id) {
            this.apiAssetsInfo = ele.over;
          }
        });
      }
    },
    getProductInfo() {
      if (this.productInfo.product_type != 3) {
        return;
      }
      let params = {
        product_id: this.productId
      };
      this.pageLoading = true;
      (0, _finance.apiGetFinancesProductInfo)(params).then(res => {
        if (res.status == 200) {
          // this.productInfo = res.data.productInfo;
          this.apiProductInfo = res.data.productInfo;
          const now_time = (new Date().getTime() / 1000).toFixed();
          let tempRate = this.productInfo.total_amount / this.productInfo.amount * 100;
          this.rate = (0, _digital.ceilNumber)(tempRate, 2);

          // this.rate = (
          //   (this.productInfo.total_amount / this.productInfo.amount) *
          //   100
          // ).toFixed(2);
          this.rate1 = (now_time - this.productInfo.start_time) / (this.productInfo.stop_time - this.productInfo.start_time);
          this.rate1 = this.rate1 > 1 ? 1 : this.rate1;
          this.rate2 = (now_time - this.productInfo.stop_time) / (this.productInfo.end_time - this.productInfo.stop_time);
          this.rate2 = this.rate2 > 1 ? 1 : this.rate2;
          if (this.productInfo.status == 3) {
            this.stepActive = 1;
          } else if (this.productInfo.status >= 4 && this.productInfo.status < 7) {
            this.stepActive = 2;
          } else if (this.productInfo.status == 7) {
            this.stepActive = 3;
          }
        } else {
          (0, _message.showMessage)({
            message: res.msg,
            type: "error"
          });
        }
        this.pageLoading = false;
      });
    },
    onCancelClick() {
      this.$emit("onCancel");
    },
    onOK() {
      if (this.buyNumber == "") {
        (0, _message.showMessage)({
          message: this.translateTitle("请输入存入金额"),
          type: "error"
        });
        return;
      }
      if (Number(this.buyNumber) > Number(this.apiAssetsInfo)) {
        (0, _message.showMessage)({
          message: this.translateTitle("可用资产不足"),
          type: "error"
        });
        return;
      }
      if (Number(this.buyNumber) < Number(this.productInfo.min_amount)) {
        (0, _message.showMessage)({
          message: this.translateTitle("存入金额不能小于最小单笔限额"),
          type: "warning"
        });
        return;
      }
      if (Number(this.buyNumber) > Number(this.productInfo.max_amount)) {
        (0, _message.showMessage)({
          message: this.translateTitle("存入金额不能大于最大单笔限额"),
          type: "warning"
        });
        return;
      }
      this.btnLoading = true;
      let params = {
        product_id: this.productInfo.product_id,
        num: this.buyNumber
      };
      (0, _finance.apiBuyFinances)(params).then(res => {
        if (res.status == 200) {
          this.buyNumber = "";
          this.$emit("onConfirm");
        }
        this.btnLoading = false;
      });
    }
  }
};
exports.default = _default;