"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiBuyFinances = apiBuyFinances;
exports.apiGetDeFiProductList = apiGetDeFiProductList;
exports.apiGetFinancesProductInfo = apiGetFinancesProductInfo;
exports.apiGetHistoryPositionList = apiGetHistoryPositionList;
exports.apiGetPositionInfo = apiGetPositionInfo;
exports.apiGetProductInfo = apiGetProductInfo;
exports.apiGetProductListV2 = apiGetProductListV2;
exports.apiSellFinances = apiSellFinances;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// const { VUE_APP_UCENTER, VUE_APP_APP } = process.env;

//理财产品列表
function apiGetProductListV2(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/Publics/getFinanceList`,
    data,
    type: "POST"
  });
}
//获取理财产品详情
function apiGetFinancesProductInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/Finances/getProductInfo`,
    data,
    type: "POST"
  });
}
//获取理财产品详情 - 无登录认证
function apiGetProductInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/Product/getProductInfo`,
    data,
    type: "POST"
  });
}
// 购买理财产品
function apiBuyFinances(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/Finances/buy`,
    data,
    type: "POST"
  });
}
// 赎回理财产品
function apiSellFinances(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/Finances/sell`,
    data,
    type: "POST"
  });
}
// 用户持仓详情
function apiGetPositionInfo(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/Finances/getPositionInfo`,
    data,
    type: "POST"
  });
}
// DeFi产品列表页
function apiGetDeFiProductList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/DeFi/getProductList`,
    data,
    type: "POST"
  });
}

// 理财产品持仓历史列表
function apiGetHistoryPositionList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/Finances/historyPositionList`,
    data,
    type: "POST"
  });
}