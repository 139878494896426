"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Title = _interopRequireDefault(require("./components/Title"));
var _SettingItem = _interopRequireDefault(require("./components/SettingItem"));
var _StatusTag = _interopRequireDefault(require("./components/StatusTag"));
var _DChangePwd = _interopRequireDefault(require("./components/DChangePwd"));
var _DBind = _interopRequireDefault(require("./components/DBind"));
var _DGoogle = _interopRequireDefault(require("./components/DGoogle"));
var _Button = _interopRequireDefault(require("./components/Button"));
var _GoogleBind = _interopRequireDefault(require("./components/google/GoogleBind"));
var _GoogleDialog = _interopRequireDefault(require("./components/google/GoogleDialog"));
var _message = require("@/components/dialog/message");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _ucenter = require("@/model/ucenter/ucenter");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _jsMd = _interopRequireDefault(require("js-md5"));
var _security = require("@/model/ucenter/security");
var _globalFun = _interopRequireDefault(require("@/assets/js/globalFun.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      pwdDialogVisible: false,
      bindDialogVisible: false,
      verificationVisible: false,
      bindType: "mobile",
      // 绑定/修改 类型
      googleVerifyStatus: true,
      accountEmailFlag: true,
      accountMobileFlag: true,
      mobileBind: false,
      // 手机绑定状态
      emailBind: false,
      // 邮箱绑定状态
      mobileStatus: false,
      // 手机验证状态 false:
      emailStatus: false,
      // 邮箱验证状态
      openGoogle: false,
      // 谷歌验证状态
      googleStatus: 1,
      // 谷歌验证状态
      all_areacode: {},
      // 所有区号
      openSetGoogle: false,
      // 打开设置谷歌

      googleValidator: [{
        img: "./../../assets/img/AppleStore.png",
        link: ""
      }, {
        img: "./../../assets/img/AppleStore.png",
        link: ""
      }, {
        img: "./../../assets/img/AppleStore.png",
        link: ""
      }],
      accountInfo: {},
      authInfo: {},
      isBindEmail: false,
      // 是否在绑定邮箱
      changeDBindMobile: false,
      // 是否修改
      confirmLoading: false,
      is_bind_security: 0,
      voucherInterval: null,
      globalFun: _globalFun.default
    };
  },
  async created() {
    // await this.getGooglStatus();
    this.getUserInfo();
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  methods: {
    onDialogOpen() {
      this.getUserInfo();
    },
    onChangePassWord() {
      if (this.is_bind_security == 1) {
        (0, _message.showMessage)({
          message: this.translateTitle(`请先绑定谷歌、手机或者邮箱`),
          type: "warning"
        });
      } else {
        this.pwdDialogVisible = true;
      }
    },
    async getUserInfo() {
      // let { authInfo, accountInfo } = await Account.getUserInfo();
      let {
        authInfo,
        accountInfo
      } = sessionStorage.userInfo ? JSON.parse(sessionStorage.userInfo) : {};
      this.accountInfo = accountInfo;
      this.authInfo = authInfo;
      if (authInfo) {
        this.is_bind_security = authInfo.is_bind_security;
        this.mobileStatus = authInfo.open_mobile === 0 ? false : true;
        this.emailStatus = authInfo.open_email === 0 ? false : true;
        this.openGoogle = authInfo.open_google === 0 ? false : true;
        this.googleStatus = authInfo.open_google;
        if (this.openGoogle == true && this.mobileStatus == false && this.emailStatus == false) {
          this.googleVerifyStatus = false;
        } else {
          this.googleVerifyStatus = true;
        }
      }
      if (accountInfo) {
        this.mobileBind = accountInfo.mobile ? true : false;
        this.emailBind = accountInfo.email ? true : false;
      }
    },
    async getGooglStatus() {
      let {
        status,
        data,
        msg
      } = await (0, _security.bingGoogleVerifyStatus)();
      if (status !== 200) {
        return;
      }
      this.googleStatus = data.bing_google_status;
    },
    // 绑定/修改手机
    onBindMobileClick() {
      let {
        mobileBind
      } = this;
      this.bindType = "mobile";
      let username = this.accountInfo.username;
      let open_email = this.authInfo.open_email;
      if (username && open_email === 0) {
        this.accountEmailFlag = false;
        this.accountMobileFlag = true;
      }
      this.$nextTick(() => this.bindDialogVisible = true);
      if (mobileBind) {
        this.changeDBindMobile = true;
      }
    },
    // 绑定邮箱
    onBindEmailClick() {
      this.bindType = "email";
      let username = this.accountInfo.username;
      let open_mobile = this.authInfo.open_mobile;
      if (username && open_mobile === 0) {
        this.accountMobileFlag = false;
        this.accountEmailFlag = true;
      }
      this.$nextTick(() => this.bindDialogVisible = true);
      this.isBindEmail = true;
    },
    // 打开设置绑定谷歌
    onBindGoogleClick() {
      this.openSetGoogle = true;
    },
    // 关闭谷歌认证
    closeGoogle() {
      this.verificationVisible = true;
    },
    //
    async subGoogleSuccess() {
      this.getGooglStatus();
      await this.newGetUserInfo();
      this.openSetGoogle = false;
    },
    onCancel() {
      if (this.$refs.changePwd_ref) {
        this.$refs.changePwd_ref.clearCode();
      }
      if (this.$refs.google_ref) {
        this.$refs.google_ref.clearCode();
      }
      this.pwdDialogVisible = false;
      this.bindDialogVisible = false;
      this.verificationVisible = false;
      this.changeDBindMobile = false;
      this.isBindEmail = false;
    },
    // 确认提交
    async onOk() {
      let params = {};
      let changePwd_status, mobile_status, change_mobile_status, email_status, google_status;
      changePwd_status = this.$refs.changePwd_ref ? this.$refs.changePwd_ref.submitForm() : true;
      mobile_status = this.$refs.mobile_ref ? this.$refs.mobile_ref.submitForm() : true;
      change_mobile_status = this.$refs.mobileChange_ref ? this.$refs.mobileChange_ref.submitForm() : true;
      email_status = this.$refs.email_ref ? this.$refs.email_ref.submitForm() : true;
      google_status = this.$refs.google_ref ? this.$refs.google_ref.submitForm() : true;
      if (!(changePwd_status && mobile_status && change_mobile_status && email_status && google_status)) return;

      /* 密码 */
      if (this.$refs.changePwd_ref) {
        let data = this.$refs.changePwd_ref.submitForm();
        params = {
          ...data
        };
        delete params.confirmNewPwd;
        params.old_password = (0, _jsMd.default)(params.old_password);
        params.password = (0, _jsMd.default)(params.password);
      }
      /* 手机 */
      if (this.$refs.mobile_ref) {
        let data = this.$refs.mobile_ref.submitForm();
        params = Object.assign(params, {
          ...data
        });
      }
      /* 新手机 */
      if (this.$refs.mobileChange_ref) {
        let data = this.$refs.mobileChange_ref.submitForm();
        data.code = data.mobile_code;
        delete data.mobile_code;
        params = Object.assign(params, {
          ...data
        });
      }
      /* 邮箱 */
      if (this.$refs.email_ref) {
        let data = this.$refs.email_ref.submitForm();
        params = Object.assign(params, {
          ...data
        });
      }
      /* 谷歌 */
      if (this.$refs.google_ref) {
        let data = this.$refs.google_ref.submitForm();
        params = Object.assign(params, {
          ...data
        });
      }

      /* 提交请求 */
      // 修改密码
      if (this.$refs.changePwd_ref) {
        await (0, _security.updatePassword)(params).then(res => this.successTip(res, this.translateTitle(`密码修改成功`)));
        return;
      }
      // 绑定手机
      if (!this.mobileStatus && !this.isBindEmail) {
        //!this.mobileStatus && this.bindDialogVisible
        await (0, _security.bindMobile)(params).then(res => this.successTip(res, this.translateTitle(`手机绑定成功`)));
        return;
      }
      // 修改手机
      if (this.$refs.mobileChange_ref) {
        params["old_mobile_code"] = params["mobile_code"];
        params["mobile_code"] = params["code"];
        await (0, _security.undataMobile)(params).then(res => this.successTip(res, this.translateTitle(`修改手机号成功`)));
        return;
      }
      // 绑定邮箱
      if (this.$refs.email_ref && this.isBindEmail) {
        await (0, _security.bindEmail)(params).then(res => this.successTip(res, this.translateTitle(`邮箱绑定成功`)));
        return;
      }
    },
    // 密码修改状态判断
    checkPwdStatus(callback) {
      let changePwd_status = this.$refs.changePwd_ref ? this.$refs.changePwd_ref.submitForm() : true;
      let status = changePwd_status ? true : false;
      callback(status);
    },
    // 成功提醒
    successTip(res, message) {
      this.confirmLoading = false;
      if (res.status === 200) {
        this.onCancel();
        (0, _message.showMessage)({
          message,
          type: "success"
        });
        this.newGetUserInfo();
      } else if (res.status === -6071004) {
        this.$refs.mobile_ref ? this.$refs.mobile_ref.clearCode() : null;
        this.$refs.mobileChange_ref ? this.$refs.mobileChange_ref.clearCode() : null;
        this.$refs.email_ref ? this.$refs.email_ref.clearCode() : null;
      } else {
        (0, _message.showMessage)({
          message: res.msg ? res.msg : res.status
        });
      }
    },
    // 获取新的用户信息
    async newGetUserInfo() {
      // 从新获取用户信息
      await (0, _ucenter.getUserInfo)().then(({
        data,
        msg,
        status
      }) => {
        if (status === 200) {
          if (data != null) {
            sessionStorage.setItem("userInfo", JSON.stringify(data));
          }
          this.getUserInfo();
        } else {
          (0, _message.showMessage)({
            message: msg ? msg : status
          });
        }
      });
    }
  },
  computed: {
    googleBtnText() {
      let tempVal = "";
      let {
        googleStatus
      } = this;
      switch (googleStatus) {
        case 1:
          tempVal = this.translateTitle(`关闭认证`);
        case 2:
          tempVal = this.translateTitle(`关闭`);
      }
      return tempVal;
    }
  },
  components: {
    TopTitle: _Title.default,
    SettingItem: _SettingItem.default,
    StatusTag: _StatusTag.default,
    ChangePwd: _DChangePwd.default,
    GButton: _Button.default,
    DBind: _DBind.default,
    DGoogle: _DGoogle.default,
    GoogleBind: _GoogleBind.default,
    GoogleDialog: _GoogleDialog.default
  }
};
exports.default = _default;