"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetBanner = apiGetBanner;
exports.apiGetBlogDetails = apiGetBlogDetails;
exports.apiGetBlogList = apiGetBlogList;
exports.apiGetHotList = apiGetHotList;
exports.apiGetTags = apiGetTags;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
// Blog 列表
function apiGetBlogList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Blog/Common/blogList`,
    data,
    type: "POST"
  });
}
// Blog 右侧 列表
function apiGetHotList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Blog/Common/hotList`,
    data,
    type: "POST"
  });
}
// Blog banner 列表
function apiGetBanner(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Blog/Common/banner`,
    data,
    type: "POST"
  });
}
// Blog banner 详情
function apiGetBlogDetails(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Blog/Common/details`,
    data,
    type: "POST"
  });
}
// Blog  TAG 列表
function apiGetTags(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Blog/Common/tags`,
    data,
    type: "POST"
  });
}