"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _DBindNew = _interopRequireDefault(require("../DBindNew"));
var _DGoogle = _interopRequireDefault(require("../DGoogle"));
var _Button = _interopRequireDefault(require("../Button"));
var _message = require("@/components/dialog/message");
var _security = require("@/model/ucenter/security");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    DBind: _DBindNew.default,
    DGoogle: _DGoogle.default,
    GButton: _Button.default
  },
  props: ["accountInfo", "emailStatus", "mobileStatus", "googleStatus"],
  data() {
    return {
      confirmLoading: false
    };
  },
  created() {},
  methods: {
    async onConfirm() {
      let params = {};
      let emailCheck, mobileCheck, googleCheck;
      emailCheck = this.$refs.email_ref ? this.$refs.email_ref.submitForm() : true;
      mobileCheck = this.$refs.mobile_ref ? this.$refs.mobile_ref.submitForm() : true;
      googleCheck = this.$refs.google_ref ? this.$refs.google_ref.submitForm() : true;
      if (!(emailCheck && mobileCheck && googleCheck)) return;
      if (this.$refs.email_ref) {
        let data = this.$refs.email_ref.submitForm();
        delete data.email;
        params = {
          ...data
        };
      }
      if (this.$refs.mobile_ref) {
        let data = this.$refs.mobile_ref.submitForm();
        delete data.area;
        delete data.mobile;
        params = Object.assign(params, {
          ...data
        });
      }
      if (this.$refs.google_ref) {
        let data = this.$refs.google_ref.submitForm();
        params = Object.assign(params, {
          ...data
        });
      }
      let result;
      // 提交，关闭谷歌认证
      if (this.googleStatus == 1) {
        result = await (0, _security.closeGoogleVerify)(params);
        this.subTips(result, "关闭谷歌认证成功");
      } else if (this.googleStatus == 2) {
        result = await (0, _security.openGoogleVerify)(params);
        this.subTips(result, "开启谷歌认证成功");
      }
    },
    subTips(res, msg) {
      if (res.status == 200) {
        (0, _message.showMessage)({
          message: msg,
          type: "success"
        });
        this.$emit("subSuccess");
        this.$emit("onCancel");
      }
    }
  },
  computed: {
    emailType() {
      return this.googleStatus == 2 ? "27" : "29";
    },
    mobileType() {
      return this.googleStatus == 2 ? "28" : "30";
    }
  }
};
exports.default = _default;