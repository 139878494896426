"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showMessage = showMessage;
var _elementUi = require("element-ui");
function showMessage(opts) {
  _elementUi.Message.closeAll();
  (0, _elementUi.Message)({
    type: opts.type || 'warning',
    position: 'top-center',
    title: opts.title || "__I18N(系统通知)__",
    message: opts.message,
    duration: opts.duration || 1500,
    showClose: true
  });
}