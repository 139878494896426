"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _order = require("@/model/exchange/order.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _index = require("@/utils/index.js");
var _vuex = require("vuex");
var _message = require("@/components/dialog/message.js");
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _settings = require("@/model/settings");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    marketList: {
      type: Array,
      default: function () {
        return [];
      }
    },
    exchangeCoinMap: {
      type: Object,
      default: function () {
        return {};
      }
    },
    swap_symbol_id: {
      type: [String, Number],
      default: function () {
        return 22;
      }
    },
    from_symbol: {
      type: [String, Number]
    },
    to_symbol: {
      type: [String, Number]
    }
  },
  mixins: [_commonMixin.default],
  components: {
    NoData: _NoData.default
  },
  data() {
    return {
      parseTime: _index.parseTime,
      ws: null,
      tableData: [],
      tableDataComputed: [],
      orderLoading: true,
      dialogVisible: false,
      hidenCancelChecked: false,
      checked: false,
      layout: "total, prev, pager, next",
      symbolId: "",
      trust_id: "",
      orderNum: 0,
      pageInfo: {
        pageCount: 1,
        pageCurrent: 1,
        pageSize: 6,
        pageTotal: 6
      },
      tabList: [{
        value: "1",
        label: "当前委托"
      }, {
        value: "2",
        label: "历史委托"
      }
      // {
      //   value: "3",
      //   label: "成交明细",
      // },
      ],

      currIndex: "1",
      coinItemIdMap: {},
      tradeMap: {
        1: this.translateTitle("市价"),
        2: this.translateTitle("限价")
      },
      sideMap: {
        buy: this.translateTitle("买入"),
        sell: this.translateTitle("卖出")
      },
      statusMap: {
        0: this.translateTitle("未成交"),
        1: this.translateTitle("部分成交"),
        2: this.translateTitle("完全成交"),
        3: this.translateTitle("已撤销")
      }
    };
  },
  async created() {
    await this.getExchangeItemId();
    let {
      userInfo
    } = await Account.getUserInfo();
    if (userInfo) {
      this.startTimer();
    }
  },
  mounted() {},
  computed: {
    ...(0, _vuex.mapGetters)({
      userInfo: "user/userInfo",
      language: "user/language"
    }),
    tableHeight() {
      let tempHeight = 300;
      tempHeight = this.currIndex == 1 ? 360 : 300;
      return tempHeight;
    }
  },
  methods: {
    onOK() {
      this.onCancelOrder();
      this.dialogVisible = false;
    },
    onOpenConfirm(id) {
      this.trust_id = id;
      this.dialogVisible = true;
    },
    async getExchangeItemId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.coinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    reset(type) {
      this.orderLoading = true;
      this.currIndex = type.toString();
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    onSymbolChange(item) {
      this.$emit("on-symbol-change", item);
    },
    onSymbolClick(row) {
      let params = {
        symbol_id: row.symbol_id,
        info: {
          coin_from: row.coin_from,
          coin_to: row.coin_to,
          name: row.symbol_name_v2
        }
      };
      this.$emit("on-symbol-change", params);
    },
    async startTimer() {
      const timer = setTimeout(() => {
        this.initData(this.checked);
        this.startTimer();
      }, 3000);
      this.$once("hook:beforeDestroy", () => {
        clearTimeout(timer);
      });
    },
    async onCancelOrder() {
      const req = {
        trust_id: this.trust_id
      };
      const {
        status,
        data
      } = await (0, _order.apiTrustCancel)(req);
      if (status == 200) {
        (0, _message.showMessage)({
          message: this.translateTitle("撤销成功"),
          type: "success"
        });
        this.initData(this.checked);
      }
    },
    onAreaChange(item) {
      this.orderLoading = true;
      this.tableData = [];
      this.currIndex = item.value;
      this.pageInfo.pageCurrent = 1;
      this.initData(this.checked);
    },
    async initData(checked = false) {
      if (!this.swap_symbol_id) {
        this.orderLoading = false;
        return;
      }
      let {
        userInfo
      } = await Account.getUserInfo();
      if (!userInfo) {
        this.orderLoading = false;
        return;
      }
      let tempStatus = this.currIndex;
      if (this.currIndex == 3) {
        tempStatus = "";
      }
      let temp_symbol_id = this.swap_symbol_id;
      if (checked) {
        temp_symbol_id = this.swap_symbol_id;
      } else {
        temp_symbol_id = "";
      }
      if (this.currIndex == 1) {
        const req = {
          symbol_id: temp_symbol_id
        };
        return (0, _order.apiTrustIndex)(req).then(res => {
          if (res.status == 200 && res.data.length > 0) {
            this.orderNum = res.data.length;
            for (const key in this.exchangeCoinMap) {
              res.data.forEach(ele => {
                if (ele.symbol_id == key) {
                  ele.symbol_name_v2 = this.exchangeCoinMap[key].name.toUpperCase();
                }
              });
            }
            for (const key in this.coinItemIdMap) {
              res.data.forEach(ele => {
                if (ele.coin_from == key) {
                  ele.coin_name = this.coinItemIdMap[key].item_name.toUpperCase();
                }
                if (ele.coin_to == key) {
                  ele.coin_to_name = this.coinItemIdMap[key].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data;
          } else {
            this.tableData = [];
            this.orderNum = 0;
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      } else if (this.currIndex == 2) {
        let temp_order_status = "1,2,3";
        if (this.hidenCancelChecked) {
          temp_order_status = "1,2";
        } else {
          temp_order_status = "1,2,3";
        }
        const req = {
          symbol_id: temp_symbol_id,
          status: temp_order_status,
          page: this.pageInfo.pageCurrent,
          page_size: this.pageInfo.pageSize
        };
        return (0, _order.apiTrustDealIndex)(req).then(res => {
          if (res.status == 200) {
            for (const key in this.exchangeCoinMap) {
              res.data.list.forEach(ele => {
                if (ele.symbol_id == key) {
                  ele.symbol_name_v2 = this.exchangeCoinMap[key].name.toUpperCase();
                }
              });
            }
            for (const key in this.coinItemIdMap) {
              res.data.list.forEach(ele => {
                if (ele.fee_item_id == key) {
                  ele.fee_item_name = this.coinItemIdMap[key].item_name.toUpperCase();
                }
                if (ele.coin_from == key) {
                  ele.coin_name = this.coinItemIdMap[key].item_name.toUpperCase();
                }
                if (ele.coin_to == key) {
                  ele.coin_to_name = this.coinItemIdMap[key].item_name.toUpperCase();
                }
              });
            }
            this.tableData = res.data.list;
            this.pageInfo = res.data.pageInfo;
          } else {
            this.orderLoading = false;
          }
          this.orderLoading = false;
        });
      }
    },
    onCurrentPageChange() {
      this.orderLoading = true;
      this.initData(this.checked);
    }
  },
  watch: {
    language() {
      this.tradeMap = {
        1: this.translateTitle("市价"),
        2: this.translateTitle("限价")
      };
      this.sideMap = {
        buy: this.translateTitle("买入"),
        sell: this.translateTitle("卖出")
      };
      this.statusMap = {
        0: this.translateTitle("未成交"),
        1: this.translateTitle("待成交"),
        2: this.translateTitle("完全成交"),
        3: this.translateTitle("已撤销")
      };
    },
    swap_symbol_id: {
      handler(newVal, oldVal) {
        if (oldVal == undefined) {
          return;
        }
        if (newVal != oldVal) {
          this.symbolId = newVal;
          this.orderLoading = true;
          this.pageInfo.pageCurrent = 1;
          this.initData(this.checked);
        }
      }
    },
    hidenCancelChecked() {
      this.initData(this.checked);
    },
    checked() {
      this.initData(this.checked);
    }
  },
  computed: {
    // showList() {
    //   return this.marketList;
    // },
  }
};
exports.default = _default;