"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiChannelPartnerList = apiChannelPartnerList;
exports.apiGetBannerList = apiGetBannerList;
exports.apiGetMainV2 = apiGetMainV2;
exports.apiGetcustermerUrl = apiGetcustermerUrl;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
const detailsUrl = "https://top6688.zendesk.com/api/v2/help_center/zh-cn/sections/360005486458/articles";
function apiGetBannerList(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/App/Product/bannerList`,
    data
  });
}
function apiGetcustermerUrl(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Publics/getcustermerUrl`,
    data
  });
}
function apiGetMainV2(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_SOTC}/SecretOtc/App/getMainV2`,
    data
  });
}
function apiChannelPartnerList(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_APP}/App/ChannelPartner/list`,
    data
  });
}