"use strict";

var _interopRequireWildcard = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom-exception.stack.js");
require("core-js/modules/es.typed-array.at.js");
require("core-js/modules/es.typed-array.find-last.js");
require("core-js/modules/es.typed-array.find-last-index.js");
require("core-js/modules/es.typed-array.set.js");
require("core-js/modules/esnext.typed-array.to-reversed.js");
require("core-js/modules/esnext.typed-array.to-sorted.js");
require("core-js/modules/esnext.typed-array.with.js");
require("core-js/modules/es.array.push.js");
var _NoData = _interopRequireDefault(require("@/components/common/NoData"));
var _globalFun = _interopRequireDefault(require("@/assets/js/globalFun.js"));
var _bignumber = _interopRequireDefault(require("bignumber.js"));
var Account = _interopRequireWildcard(require("@/model/user/account"));
var _spreadCenter = require("@/model/ucenter/spreadCenter");
var _settings = require("@/model/settings");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _message = require("@/components/dialog/message");
var _vuex = require("vuex");
var _i18n = require("@/assets/js/i18n");
var _moment = _interopRequireDefault(require("moment"));
var _vueCanvasPoster = require("vue-canvas-poster");
var _ci = _interopRequireDefault(require("@/assets/img/shareInvite/ci.png"));
var _cl = _interopRequireDefault(require("@/assets/img/shareInvite/cl.png"));
var _fb = _interopRequireDefault(require("@/assets/img/shareInvite/fb.png"));
var _si = _interopRequireDefault(require("@/assets/img/shareInvite/si.png"));
var _tg = _interopRequireDefault(require("@/assets/img/shareInvite/tg.png"));
var _tt = _interopRequireDefault(require("@/assets/img/shareInvite/tt.png"));
var _coin = _interopRequireDefault(require("@/assets/img/siteLogo/8coin.png"));
var _b = _interopRequireDefault(require("@/assets/img/siteLogo/b9.png"));
var _bxc = _interopRequireDefault(require("@/assets/img/siteLogo/bxc.png"));
var _hx = _interopRequireDefault(require("@/assets/img/siteLogo/hx.png"));
var _logo = _interopRequireDefault(require("@/assets/img/siteLogo/logo100.png"));
var _mask = _interopRequireDefault(require("@/assets/img/siteLogo/mask.png"));
var _everTrust = _interopRequireDefault(require("@/assets/img/siteLogo/everTrust.png"));
var _v = _interopRequireDefault(require("@/assets/img/siteLogo/8v.png"));
var _ueasy = _interopRequireDefault(require("@/assets/img/siteLogo/ueasy.png"));
var _Ahex = _interopRequireDefault(require("@/assets/img/siteLogo/Ahex.png"));
var _logo5A = _interopRequireDefault(require("@/assets/img/siteLogo/logo5A.png"));
var _logobitda = _interopRequireDefault(require("@/assets/img/siteLogo/logobitda.png"));
var _logoS = _interopRequireDefault(require("@/assets/img/siteLogo/logoS6.png"));
var _spex = _interopRequireDefault(require("@/assets/img/siteLogo/spex.png"));
var _stationSetting = require("@/assets/js/stationSetting");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  data() {
    return {
      moment: _moment.default,
      ci: _ci.default,
      cl: _cl.default,
      fb: _fb.default,
      si: _si.default,
      tg: _tg.default,
      tt: _tt.default,
      mask: _mask.default,
      everTrust: _everTrust.default,
      eightV: _v.default,
      UEEx: _ueasy.default,
      Ahex: _Ahex.default,
      logo5A: _logo5A.default,
      logobitda: _logobitda.default,
      logoS6: _logoS.default,
      logoSpex: _spex.default,
      logo8coin: _coin.default,
      Logo100: _logo.default,
      b9Logo: _b.default,
      bxcLogo: _bxc.default,
      hxLogo: _hx.default,
      avatar: "",
      userInfo: {},
      nick_name: "",
      posterSrc: "",
      painting: {},
      symbolObj: {},
      currIndex: 1,
      item_name: "",
      assetsShow: "",
      dataList: [],
      totalRebateList: [],
      inviteCodeList: [],
      friendRebateList: [],
      rebateList: [],
      tabList: [{
        label: "数据总览",
        value: 1
      }, {
        label: "我的邀请码",
        value: 2
      }, {
        label: "好友列表",
        value: 3
      }, {
        label: "我的返利",
        value: 4
      }
      // { label: "推荐链接", value: 2 },
      // { label: "邀请列表", value: 3 },
      // { label: "返利记录", value: 4 },
      ],

      tableLoading: false,
      totalRebateListLoading: false,
      inviteCodeListLoading: false,
      friendRebateListLoading: false,
      rebateListLoading: false,
      exportLoading: false,
      shareLoading: false,
      inviteImgLoading: false,
      shareImageType: "",
      detailDialogVisible: false,
      editDialogVisible: false,
      inviteDialogVisible: false,
      isDefaultCode: false,
      inviteCodeRemark: "",
      currRate: 0,
      currRateKey: 0,
      initInviteStatus: 0,
      isEditCodeRemark: true,
      //只编辑邀请码备注
      searchCode: "",
      searchRemark: "",
      friendUserId: "",
      friendInviteCode: "",
      projectFullName: "",
      slogan: "",
      projectTitle: "",
      projectLogo: "",
      inviteObj: {},
      editCode: "",
      max_rebate_rate: "",
      poster_conf: [],
      default_invite_code: {},
      rabate_rate_conf: {},
      total_stats: {},
      centerHost: "",
      webHost: "",
      invite_url: "",
      confirmDialogVisible: false,
      delPreInviteCode: "",
      // invite_link_ellipsis: "",
      pageInfo: {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      },
      rebate_type_list_all: [],
      //数据总览统计静态表
      rebate_type_list: [],
      //数据总览统计静态表is_show
      rebate_type_list_item: {},
      //数据总览统交易类型对象
      all_search_id: "",
      //所有返利类型
      ctimeAndEtime: "",
      total_stats_son: {
        total_rebate_amount: "0.00",
        //累计返佣
        total_invite_user_num: 0,
        //累计邀请好友
        total_trade_user_num: 0 //累计好友交易人数
      },

      globalFun: _globalFun.default,
      custRabate: ""
    };
  },
  components: {
    NoData: _NoData.default,
    VueCanvasPoster: _vueCanvasPoster.VueCanvasPoster
  },
  async created() {
    this.webHost = window.location.protocol + "//" + window.location.host + "/register/" + this.language;
    this.getAppIntialDataV2();
    this.getStaticTableInfo(13);
    this.getStaticTableInfo(55);
    this.getRebateCommonInfo();
    await this.getStaticTableInfo(59);
    this.getTotalRebateStats();
    this.getUserInfoInit();
    if (_stationSetting.VUE_APP_ID == "UhkNBRbpeSGTuaf0yuiG1Yin" || _stationSetting.VUE_APP_ID == "OCzy5RbpeSGTuaf0yuiG1Yin") {
      this.projectFullName = "Bullxcow";
      this.projectLogo = this.bxcLogo;
    } else if (_stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
      this.projectFullName = "币久";
      this.projectLogo = this.b9Logo;
    } else if (_stationSetting.VUE_UNION_STATION == "100Ex") {
      this.projectFullName = "100Ex";
      this.projectLogo = this.Logo100;
    } else if (_stationSetting.VUE_UNION_STATION == "MaskEx") {
      this.projectFullName = "MaskEx";
      this.projectLogo = this.mask;
    } else if (_stationSetting.VUE_UNION_STATION == "EverTrust") {
      this.projectFullName = "恒信";
      this.projectLogo = this.everTrust;
    } else if (_stationSetting.VUE_UNION_STATION == "8V") {
      this.projectFullName = "8V";
      this.projectLogo = this.eightV;
    } else if (_stationSetting.VUE_UNION_STATION == "UEEx") {
      this.projectFullName = "UEEx";
      this.projectLogo = this.UEEx;
    } else if (_stationSetting.VUE_UNION_STATION == "Ahex") {
      this.projectFullName = "Ahex";
      this.projectLogo = this.Ahex;
    } else if (_stationSetting.VUE_UNION_STATION == "5A") {
      this.projectFullName = "5A";
      this.projectLogo = this.logo5A;
    } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
      this.projectFullName = "bitda";
      this.projectLogo = this.logobitda;
    } else if (_stationSetting.VUE_UNION_STATION == "S6") {
      this.projectFullName = "S6";
      this.projectLogo = this.logoS6;
    } else if (_stationSetting.VUE_UNION_STATION == "spex") {
      this.projectFullName = "SPEX";
      this.projectLogo = this.logoSpex;
    } else if (_stationSetting.VUE_UNION_STATION == "8coin") {
      this.projectFullName = "8coin";
      this.sloganColor = "#3960fb";
      this.projectLogo = this.logo8coin;
    } else {
      this.projectFullName = "汇信";
      this.projectLogo = this.hxLogo;
    }
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      systemCurrency: "user/currency"
    }),
    slogan4language() {
      if (_stationSetting.VUE_UNION_STATION == "bitda") return "";
      return "高收益，上" + this.projectFullName;
    }
  },
  methods: {
    translateTitle: _i18n.translateTitle,
    clearInput(e) {
      let key = e.key;
      if (key === "e" || key === "E" || key === "+" || key === "-") {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    handleCustChange() {
      this.currRate = [null, null];
      if (this.custRabate === "") {
        return;
      } else {
        this.custRabate = Number(this.custRabate);
      }
      let reg = /(^(\d|[1-9]\d)(\.\d{1,1})?$)|(^100$)/;
      if (!reg.test(parseFloat(this.custRabate))) {
        if (this.custRabate > 100) {
          this.custRabate = 100;
        } else if (this.custRabate < 0) {
          this.custRabate = "";
        } else {
          this.custRabate = (Math.floor(this.custRabate * 10) / 10).toFixed(1);
        }
      }
      this.custRabate = new _bignumber.default(this.custRabate).toNumber();
      this.currRate = [null, new _bignumber.default(this.custRabate).div(100).toNumber()];
    },
    //精度优化
    digData(data) {
      return this.globalFun.handleCutZero(parseFloat(data).toFixed(10));
    },
    //幻灯片切换
    carouselChange(index) {
      this.initInviteStatus = index;
    },
    //邀请图片加载完成
    inviteImgLoaded() {
      this.inviteImgLoading = false;
    },
    // 获取用户信息
    async getUserInfoInit() {
      this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      const {
        userInfo
      } = this.userInfo;
      if (userInfo) {
        const {
          user_id,
          nick_name,
          avatar
        } = userInfo;
        this.nick_name = nick_name || this.userInfo.accountInfo.username || this.globalFun.userDecryption(this.userInfo.accountInfo.mobile, "mobile") || this.globalFun.userDecryption(userInfo.accountInfo.email, "email") || this.translateTitle(`用户`) + `${user_id}`;
        this.avatar = avatar ? avatar : "https://";
      }
    },
    //左移滚动条
    inviteImgScrollLeft() {
      let moveWidthNormal = 222 + 10 * 2;
      this.$refs.inviteImgPosition.scrollLeft = this.$refs.inviteImgPosition.scrollLeft + moveWidthNormal;
      if (this.initInviteStatus < this.poster_conf.length - 1) {
        this.initInviteStatus++;
      }
    },
    //右移滚动条
    inviteImgScrollRight() {
      let moveWidthNormal = 222 + 10 * 2;
      this.$refs.inviteImgPosition.scrollLeft = this.$refs.inviteImgPosition.scrollLeft - moveWidthNormal;
      if (this.initInviteStatus > 0) {
        this.initInviteStatus--;
      }
    },
    //Base64 To Blob
    convertBase64ToBlob(base64, type) {
      var imgs = base64.replace(/^data:image\/\w+;base64,/, ""); //去掉base64位头部
      var bytes = window.atob(imgs);
      var ab = new ArrayBuffer(bytes.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], {
        type: type
      });
    },
    //写入剪切板
    async writeClipboard(src) {
      try {
        const blob = this.convertBase64ToBlob(src, "image/png");
        await navigator.clipboard.write([new ClipboardItem({
          [blob.type]: blob
        })]);
      } catch (err) {
        console.error(err.name, err.message);
      }
    },
    //图片生成成功回调函数
    posterSuccess(src) {
      this.shareLoading = false;
      if (this.shareImageType == "copy") {
        this.writeClipboard(src);
        this.copySuccess();
      } else if (this.shareImageType == "download") {
        const a = document.createElement("a");
        a.href = src;
        a.setAttribute("download", "chart-download");
        a.click();
        (0, _message.showMessage)({
          message: this.translateTitle(`图片下载成功`),
          type: "success"
        });
      }
    },
    //图片生成失败回调函数
    posterFail(err) {
      console.log("fail", err);
    },
    //复制下载链接
    copyPosterLink() {
      let that = this;
      this.$copyText(this.inviteObj.complieUrl).then(function (e) {
        that.copySuccess();
      }, function (e) {
        console.log(e);
      });
    },
    // 下载海报
    downloadPoster() {
      this.shareImageType = "download";
      this.shareLoading = true;
      this.painting = JSON.parse(JSON.stringify(this.poster()));
    },
    // 复制海报
    copyPoster() {
      this.shareImageType = "copy";
      this.shareLoading = true;
      this.painting = JSON.parse(JSON.stringify(this.poster()));
    },
    poster() {
      return {
        width: "222px",
        height: "370px",
        background: "#fff",
        views: [{
          type: "image",
          url: this.poster_conf[this.initInviteStatus].img_url + "?r=" + this.moment().unix(),
          css: {
            top: "0px",
            left: "0px",
            width: "222px",
            height: "310px"
          }
        }, {
          type: "image",
          url: this.projectLogo,
          css: {
            top: "320px",
            left: "5px",
            width: "35px",
            height: "35px",
            borderRadius: "100%"
          }
        }, {
          type: "text",
          text: this.projectFullName,
          css: {
            top: "329px",
            left: "45px",
            width: "150px",
            maxLines: 1,
            fontSize: "13px",
            fontWeight: "500"
          }
        }, {
          type: "text",
          text: this.translateTitle(`邀请码`) + ": " + this.inviteObj.code,
          css: {
            top: "348px",
            left: "45px",
            width: "150px",
            maxLines: 1,
            fontSize: "9px",
            fontWeight: "400",
            color: "#354052"
          }
        }, {
          type: "qrcode",
          content: this.inviteObj.complieUrl,
          css: {
            top: "327px",
            right: "10px",
            color: "#000",
            width: "31px",
            height: "31px"
          }
        }]
      };
    },
    onRateChange(val) {
      this.currRate = val;
      this.currRateKey = val[0];
    },
    onAreaChange(item) {
      this.currIndex = item.value;
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      };
      if (this.currIndex == 1) {
        this.resetTotalRebate();
      }
      if (this.currIndex == 2) {
        this.resetInviteCodeStats();
      }
      if (this.currIndex == 3) {
        this.resetFriendRebateStats();
      }
      if (this.currIndex == 4) {
        this.resetRebate();
      }
    },
    //关闭生成邀请码弹窗
    onHolderDialogClose() {
      this.detailDialogVisible = false;
    },
    //关闭编辑邀请码弹窗
    onEditDialogClose() {
      this.editDialogVisible = false;
    },
    //开启生成邀请码弹窗
    onHolderDialogOpen() {
      this.detailDialogVisible = true;
      this.inviteCodeRemark = "";
      this.currRate = this.rabate_rate_conf[0];
      this.currRateKey = this.rabate_rate_conf[0][0];
      this.custRabate = "";
      this.isDefaultCode = false;
    },
    //开启编辑邀请码弹窗
    onEditDialogOpen(obj, isEditCodeRemark) {
      this.editDialogVisible = true;
      this.isEditCodeRemark = isEditCodeRemark;
      this.editCode = obj.code;
      this.onRateChange([obj.srrc_id, obj.target_rebate_rate]);
      this.custRabate = this.digData(this.currRate[1] * 100);
      this.inviteCodeRemark = obj.remark;
    },
    //关闭邀请分享弹窗
    onInviteDialogClose() {
      this.shareImageType = "";
      this.inviteDialogVisible = false;
      this.initInviteStatus = 0;
    },
    //开启邀请分享弹窗
    onInviteDialogOpen(obj) {
      this.inviteObj = obj;
      this.inviteObj.complieUrl = this.centerHost + this.inviteObj.invite_url;
      this.inviteDialogVisible = true;
    },
    //确认删除邀请码
    onInviteRemove(row) {
      this.confirmDialogVisible = true;
      this.delPreInviteCode = row.code;
    },
    //删除邀请码 apiDelInviteCode
    async delInviteCode() {
      this.confirmDialogVisible = false;
      let params = {
        invite_code: this.delPreInviteCode
      };
      const {
        status,
        data
      } = await (0, _spreadCenter.apiDelInviteCode)(params);
      if (status == 200) {
        this.$message({
          message: this.translateTitle(`删除成功`),
          type: "success"
        });
        this.getInviteCodeStats();
      } else {
        this.$message({
          message: this.translateTitle(`删除失败`),
          type: "error"
        });
      }
    },
    //判断是新增邀请码或修改邀请码备注
    doAddOrEdit() {
      if (this.currRateKey != this.default_invite_code.srrc_id && !this.isEditCodeRemark || Number(this.default_invite_code.target_rebate_rate) != Number(this.currRate[1])) {
        this.createInviteCode(this.currRateKey, this.inviteCodeRemark, true);
      } else {
        this.editInviteCode();
      }
    },
    // 好友ID复制成功
    copyFriendIdSuccess() {
      (0, _message.showMessage)({
        message: this.translateTitle(`复制成功`),
        type: "success"
      });
    },
    // 复制成功
    copySuccess() {
      (0, _message.showMessage)({
        message: this.translateTitle(`复制成功，快分享给好友吧`),
        type: "success"
      });
    },
    // 复制失败
    copyError() {
      (0, _message.showMessage)({
        message: this.translateTitle(`内容复制失败`)
      });
    },
    async getStaticTableInfo(sv_id) {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(sv_id);
      if (status == 200) {
        if (sv_id == 13) {
          this.symbolObj = JSON.parse(JSON.stringify(data));
        }
        if (sv_id == 55) {
          this.symbolList = JSON.parse(JSON.stringify(data));
          for (let item in this.coinData) {
            this.coinData[item].is_trade = false;
            for (let ele in this.symbolList) {
              if (this.coinData[item].item_id == this.symbolList[ele].coin_from) {
                this.coinData[item].is_trade = true;
              }
            }
          }
        }
        if (sv_id == 59) {
          let searchArray = [];
          this.rebate_type_list_all = JSON.parse(JSON.stringify(data));
          for (let key in this.rebate_type_list_all) {
            if (this.rebate_type_list_all[key].is_show == 1) {
              this.rebate_type_list.push(this.rebate_type_list_all[key]);
              searchArray.push(this.rebate_type_list_all[key].search_id);
            }
          }
          this.all_search_id = searchArray.join(",");
          this.rebate_type_list_item = {};
        }
      }
    },
    // 重置数据总览搜索条件
    resetTotalRebate() {
      this.ctimeAndEtime = [];
      this.rebate_type_list_item = {};
      this.totalRebateList = [];
      this.total_stats_son = {};
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      };
      this.getTotalRebateStats();
    },
    // 重置我的邀请码搜索条件
    resetInviteCodeStats() {
      this.searchCode = "";
      this.searchRemark = "";
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      };
      this.getInviteCodeStats();
    },
    // 重置好友列表搜索条件
    resetFriendRebateStats() {
      this.friendInviteCode = "";
      this.friendUserId = "";
      this.ctimeAndEtime = [];
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      };
      this.getFriendRebateStats();
    },
    // 重置返利记录搜索条件
    resetRebate() {
      this.ctimeAndEtime = [];
      this.rebate_type_list_item = {};
      this.rebateList = [];
      this.pageInfo = {
        pageCount: 0,
        pageTotal: 0,
        pageSize: 10,
        pageCurrent: 1
      };
      this.getRebateList();
    },
    //获取数据总览统计信息
    async getTotalRebateStats(pageNum = 1) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        rebate_type_list: this.rebate_type_list_item ? this.rebate_type_list_item.search_id ? this.rebate_type_list_item.search_id : this.all_search_id : this.all_search_id,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : "",
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10
      };
      this.totalRebateListLoading = true;
      const {
        status,
        data
      } = await (0, _spreadCenter.apiGetTotalRebateStats)(params);
      this.totalRebateListLoading = false;
      if (status == 200) {
        this.totalRebateList = JSON.parse(JSON.stringify(data.list));
        this.total_stats_son = JSON.parse(JSON.stringify(data.total_stats));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
    },
    //获取返利记录
    async getRebateList(pageNum = 1) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        rebate_type_list: this.rebate_type_list_item ? this.rebate_type_list_item.search_id ? this.rebate_type_list_item.search_id : this.all_search_id : this.all_search_id,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : "",
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10
      };
      this.rebateListLoading = true;
      const {
        status,
        data
      } = await (0, _spreadCenter.apiGetRebateList)(params);
      this.rebateListLoading = false;
      if (status == 200) {
        let tempArray = [];
        tempArray = JSON.parse(JSON.stringify(data.list));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
        tempArray.forEach(ele => {
          for (const key in this.symbolObj) {
            if (ele.rebate_item_id == key) {
              ele.rebate_item_name = this.symbolObj[key].item_name.toUpperCase();
            }
          }
          for (const key in this.rebate_type_list_all) {
            if (ele.src_id == this.rebate_type_list_all[key].rebate_type) {
              ele.src_name = this.rebate_type_list_all[key].language[`client_remark_${this.language}`];
            }
          }
        });
        this.rebateList = tempArray;
      }
    },
    //获取推广链接返佣统计
    async getInviteCodeStats(pageNum = 1) {
      let params = {
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
        code: this.searchCode ? this.searchCode : "",
        remark: this.searchRemark ? this.searchRemark : ""
      };
      this.inviteCodeListLoading = true;
      const {
        status,
        data
      } = await (0, _spreadCenter.apiGetInviteCodeStats)(params);
      this.inviteCodeListLoading = false;
      if (status == 200) {
        this.inviteCodeList = JSON.parse(JSON.stringify(data.list));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
    },
    //获取邀请好友返佣统计
    async getFriendRebateStats(pageNum = 1) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        code: this.friendInviteCode ? this.friendInviteCode : "",
        rcmd_user_id: this.friendUserId ? this.friendUserId : "",
        page: !isNaN(pageNum) ? pageNum : 1,
        page_size: this.pageInfo.pageSize ? this.pageInfo.pageSize : 10,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : ""
      };
      this.friendRebateListLoading = true;
      const {
        status,
        data
      } = await (0, _spreadCenter.apiGetFriendRebateStats)(params);
      this.friendRebateListLoading = false;
      if (status == 200) {
        this.friendRebateList = JSON.parse(JSON.stringify(data.list));
        this.pageInfo = JSON.parse(JSON.stringify(data.pageInfo));
      }
    },
    //编辑邀请码
    async editInviteCode() {
      let params = {
        invite_code: this.editCode,
        remark: this.inviteCodeRemark
        // srrc_id: this.currRateKey,
      };

      const {
        status,
        data
      } = await (0, _spreadCenter.apiUpdateInviteCode)(params);
      this.onEditDialogClose();
      if (status == 200 && data) {
        (0, _message.showMessage)({
          message: this.translateTitle(`编辑成功`),
          type: "success"
        });
        this.getInviteCodeStats();
        this.getRebateCommonInfo();
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`编辑失败`)
        });
      }
    },
    //设置默认邀请码
    async setDefaultInviteCode(invite_code) {
      let params = {
        invite_code: invite_code,
        is_default: 2
      };
      const {
        status,
        data
      } = await (0, _spreadCenter.apiUpdateInviteCode)(params);
      if (status == 200 && data) {
        (0, _message.showMessage)({
          message: this.translateTitle(`设为默认成功`),
          type: "success"
        });
        this.getInviteCodeStats();
        this.getRebateCommonInfo();
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`设为默认失败`)
        });
      }
    },
    //生成邀请码
    async createInviteCode(currRateKey, inviteCodeRemark, isDefaultCode) {
      if (!inviteCodeRemark.trim()) {
        (0, _message.showMessage)({
          message: this.translateTitle(`请输入备注`)
        });
        return;
      }
      if (!this.currRate[0] && !this.currRate[1] && this.currRate[1] !== 0) {
        (0, _message.showMessage)({
          message: this.translateTitle(`请选择返利比例或填写自定义比例数值`)
        });
        return;
      }
      let params = {};
      if (this.currRate[0]) {
        params = {
          srrc_id: currRateKey,
          remark: inviteCodeRemark,
          is_default: isDefaultCode ? 2 : 1
        };
      } else {
        params = {
          target_rate: this.currRate[1],
          remark: inviteCodeRemark,
          is_default: isDefaultCode ? 2 : 1
        };
      }
      const {
        status,
        data
      } = await (0, _spreadCenter.apiCreateInviteCode)(params);
      this.onHolderDialogClose();
      this.onEditDialogClose();
      if (status == 200 && data) {
        if (this.currRateKey != this.default_invite_code.srrc_id && !this.isEditCodeRemark) {
          (0, _message.showMessage)({
            message: this.translateTitle(`编辑成功,已新增邀请码`),
            type: "success"
          });
        } else {
          (0, _message.showMessage)({
            message: this.translateTitle(`生成邀请码成功`),
            type: "success"
          });
        }
        this.getInviteCodeStats();
        this.getRebateCommonInfo();
      } else {
        (0, _message.showMessage)({
          message: this.translateTitle(`生成邀请码失败`)
        });
      }
    },
    //通过APP初始化API获取邀请链接
    getAppIntialDataV2() {
      (0, _spreadCenter.apiGetInviteUrl)().then(res => {
        if (res.status == 200) {
          this.centerHost = res.data.invite_url;
        }
      });
    },
    getRebateCommonInfo() {
      (0, _spreadCenter.apiGetRebateCommonInfo)().then(res => {
        if (res.status == 200) {
          this.default_invite_code = res.data.default_invite_code;
          this.max_rebate_rate = res.data.max_rebate_rate;
          this.inviteImgLoading = true;
          this.poster_conf = res.data.poster_conf;
          // this.rabate_rate_conf = res.data.rabate_rate_conf;
          let rabate_rate_conf_temp = res.data.rabate_rate_conf;
          let rabate_rate_conf_array = [];
          for (let key in rabate_rate_conf_temp) {
            rabate_rate_conf_array.push([key, rabate_rate_conf_temp[key]]);
          }
          rabate_rate_conf_array.sort((a, b) => {
            return a[1] - b[1];
          });
          this.rabate_rate_conf = rabate_rate_conf_array;
          this.total_stats = res.data.total_stats;
          this.invite_url = res.data.default_invite_code.invite_url;
          this.currRate = this.rabate_rate_conf[0];
        }
      });
    },
    //页数变更
    onPageChange(val) {
      if (this.currIndex == 1) {
        this.getTotalRebateStats(val);
      }
      if (this.currIndex == 2) {
        this.getInviteCodeStats(val);
      }
      if (this.currIndex == 3) {
        this.getFriendRebateStats(val);
      }
      if (this.currIndex == 4) {
        this.getRebateList(val);
      }
    },
    //导出
    async onDownload(type) {
      let create_ctime = "";
      let create_etime = "";
      if (this.ctimeAndEtime) {
        const [start, end] = this.ctimeAndEtime;
        const end2 = end + 86399000;
        create_ctime = parseInt(start / 1000);
        create_etime = parseInt(end2 / 1000);
      }
      let params = {
        rebate_type_list: this.rebate_type_list_item ? this.rebate_type_list_item.search_id ? this.rebate_type_list_item.search_id : this.all_search_id : this.all_search_id,
        ctime: create_ctime ? create_ctime : "",
        etime: create_etime ? create_etime : "",
        type: type
      };
      this.exportLoading = true;
      const result = await (0, _spreadCenter.apiCommonExport)(params);
      this.exportLoading = false;
      if (result.status === 200) {
        this.saveFile(result.data, result.status, result.msg);
      }
    },
    // 下载
    saveFile(data, status, msg) {
      if (status !== 200) {
        this.$message(msg ? msg : status);
        return;
      }
      let link = document.createElement("a");
      link.href = data.url;
      link.download = "";
      link.click();
    }
  }
};
exports.default = _default;