"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiGetAssets = apiGetAssets;
exports.apiGetDefaultToken = apiGetDefaultToken;
exports.apiGetOrderbook = apiGetOrderbook;
exports.apiGetSpotList = apiGetSpotList;
exports.apiGetWebAssets = apiGetWebAssets;
exports.apiGetcontractFuturesList = apiGetcontractFuturesList;
exports.apiLimitOrder = apiLimitOrder;
exports.apiSwitchCoin = apiSwitchCoin;
exports.apiTrustStore = apiTrustStore;
exports.apiWsUrl = apiWsUrl;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
function apiGetDefaultToken(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Publics/getDefaultToken`,
    data,
    type: "POST"
  });
}
// export function apiWsUrl(data = {}) {
//   return api({
//       url: VUE_APP_UCENTER + `/UCenter/Users/getInitInfo`,
//       data,
//       type: "POST"
//   })
// }
function apiWsUrl(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Publics/getWebInitInfo`,
    data,
    type: "POST"
  });
}
// 币币交易
function apiSwitchCoin(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/TradeCoin/switchCoin`,
    data,
    type: "POST"
  });
}
// 限价委托 （触发单）
function apiLimitOrder(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_UCENTER + `/App/TradeCoin/limitOrder`,
    data,
    type: "POST"
  });
}
//获取币币币种
function apiGetSpotList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/MarketV2/getSpotList`,
    data,
    type: "POST"
  });
}
//合约 币本位/U本位
function apiGetcontractFuturesList(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/MarketV2/contractFuturesList`,
    data,
    type: "POST"
  });
}
//委托下单
function apiTrustStore(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Spot/Trade/trustStore`,
    data,
    type: "POST"
  });
}
//获取用户资产
function apiGetAssets(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/AssetsV2/getAssets`,
    data,
    type: "POST"
  });
}
//获取用户资产头信息
function apiGetWebAssets(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/AssetsV2/getWebAssets`,
    data,
    type: "POST"
  });
}
//获取用户资产
function apiGetOrderbook(data = {}) {
  return (0, _api.default)({
    url: _stationSetting.VUE_APP_APP + `/Web/Kline/orderbook`,
    data,
    type: "POST"
  });
}