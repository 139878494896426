"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ShareNetwork", {
  enumerable: true,
  get: function () {
    return _shareNetwork.default;
  }
});
exports.default = void 0;
var _networks = _interopRequireDefault(require("./networks"));
var _shareNetwork = _interopRequireDefault(require("./share-network"));
var _default = {
  install: (Vue, options) => {
    Vue.component(_shareNetwork.default.name, _shareNetwork.default);
    Vue.prototype.$SocialSharing = {
      options: {
        networks: options && options.hasOwnProperty('networks') ? Object.assign(_networks.default, options.networks) : _networks.default
      }
    };
  }
};
exports.default = _default;