"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
var _default = {
  props: {
    text: String,
    plain: Boolean
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
exports.default = _default;