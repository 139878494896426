"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.apiBrokersProgram = apiBrokersProgram;
exports.apiFormSubmitAdvice = apiFormSubmitAdvice;
exports.apiRecruitGetCountryList = apiRecruitGetCountryList;
exports.apiRecruitGetIntroduceInfo = apiRecruitGetIntroduceInfo;
exports.apiRecruitGetIntroduceList = apiRecruitGetIntroduceList;
exports.apiRecruitGetJobList = apiRecruitGetJobList;
var _api = _interopRequireDefault(require("@/assets/js/api"));
var _stationSetting = require("@/assets/js/stationSetting");
const detailsUrl = "https://top6688.zendesk.com/api/v2/help_center/zh-cn/sections/360005486458/articles";
// 获取全部国家
function apiRecruitGetCountryList(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Recruit/getCountryList`,
    data
  });
}
// 获取全部职位
function apiRecruitGetJobList(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Recruit/getJobList`,
    data
  });
}
// 获取岗位
function apiRecruitGetIntroduceList(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Recruit/getIntroduceList`,
    data
  });
}
// 获取岗位详情
function apiRecruitGetIntroduceInfo(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Recruit/getIntroduceInfo`,
    data
  });
}
// 用户建议
function apiFormSubmitAdvice(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Channel/FormSubmit/advice`,
    data
  });
}

// 用户建议
function apiBrokersProgram(data = {}) {
  return (0, _api.default)({
    url: `${_stationSetting.VUE_APP_UCENTER}/Channel/FormSubmit/brokersProgram`,
    data
  });
}