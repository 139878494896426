"use strict";

var _interopRequireDefault = require("/root/.jenkins/workspace/tope-ex/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _socket = _interopRequireDefault(require("@/assets/js/socket.js"));
var _deliveryContractKline = _interopRequireDefault(require("./components/delivery-contract-kline.vue"));
var _deliveryContractDepth = _interopRequireDefault(require("./components/delivery-contract-depth.vue"));
var _deliveryContractDeal = _interopRequireDefault(require("./components/delivery-contract-deal.vue"));
var _deliveryContractMarket = _interopRequireDefault(require("./components/delivery-contract-market.vue"));
var _deliveryContractTrade = _interopRequireDefault(require("./components/delivery-contract-trade.vue"));
var _deliveryContractPanel = _interopRequireDefault(require("./components/delivery-contract-panel.vue"));
var _InputNumber = _interopRequireDefault(require("@/components/common/InputNumber"));
var _vuex = require("vuex");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _deliveryContractHistoryOrder = _interopRequireDefault(require("./components/delivery-contract-history-order.vue"));
var _order = require("@/model/currencyBasedContract/order.js");
var _index = require("@/model/currencyBasedContract/index");
var _settings = require("@/model/settings");
var _message = require("@/components/dialog/message");
var _commonMixin = _interopRequireDefault(require("@/components/common/commonMixin.vue"));
var _stationSetting = require("@/assets/js/stationSetting");
var _routeSplicing = _interopRequireDefault(require("@/assets/js/routeSplicing"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  mixins: [_commonMixin.default],
  name: "ContractExchange",
  components: {
    Deal: _deliveryContractDeal.default,
    Kline: _deliveryContractKline.default,
    Depth: _deliveryContractDepth.default,
    Market: _deliveryContractMarket.default,
    Panel: _deliveryContractPanel.default,
    Trade: _deliveryContractTrade.default,
    InputNumber: _InputNumber.default,
    HistoryOrder: _deliveryContractHistoryOrder.default
  },
  data() {
    return {
      tt: {},
      //WS延时触发器
      lockReconnect: false,
      //避免重复连接
      tabList: [{
        value: "1",
        label: "盘口"
      }, {
        value: "2",
        label: "实时成交"
      }],
      currIndex: "1",
      multipleNum: 0,
      mixMultipleNum: 1,
      maxMultipleNum: {},
      dialogVisible: false,
      sliderValue: 0,
      // symbol: this.$route.params.symbol || "btcusd",
      symbol: this.$route.params.symbol || "ethusd",
      coinFrom: "--",
      coinTo: "--",
      coinInfoMap: {},
      coinItemIdMap: {},
      tradePair: [],
      tradePairArray: [],
      marketList: [],
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--"
          },
          list: []
        }
      },
      assetsInfoList: [],
      orderbookList: [{
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }],
      klineList: [],
      depthList: {
        a: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]],
        b: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]]
      },
      swap_symbol_market: {},
      swap_symbol_id: "",
      old_swap_symbol_id: "",
      from_item_id: "",
      to_item_id: "",
      wsUrl: "",
      token: "",
      pageLoading: false,
      onload: false,
      voucherInterval: null,
      historyDataLength: 0,
      wsContentTime: 0,
      marks: {},
      maxNumber: {},
      marketInfo: {
        last: "",
        currency: {
          cny: ""
        }
      },
      isFullScreen: false
    };
  },
  computed: {
    ...(0, _vuex.mapGetters)({
      language: "user/language",
      userInfo: "user/userInfo"
    }),
    title() {
      let title = "数字货币银行";
      if (_stationSetting.VUE_APP_ID == "9wZvazddcsWrnWtnB7Zhy399") {
        title = "数字理财平台";
      } else if (_stationSetting.VUE_UNION_STATION == "MaskEx" || _stationSetting.VUE_UNION_STATION == "8V" || _stationSetting.VUE_UNION_STATION == "5A") {
        title = "Cryptocurrency Exchange";
      } else if (_stationSetting.VUE_UNION_STATION == "S6") {
        title = "数字货币私人银行";
      } else if (_stationSetting.VUE_UNION_STATION == "bitda") {
        title = "Bitda Cryptocurrency Exchange｜Bitda｜幣達交易所";
      } else {
        title = "数字货币银行";
      }
      let val = this.marketInfoShow.last + "  " + this.coinFrom.toUpperCase() + "  " + this.translateTitle("永续") + "/" + this.coinTo.toUpperCase() + " " + this.translateTitle(title);
      return val;
    },
    marketInfoShow() {
      let tempMarketInfo = {
        last: "",
        currency: {
          cny: ""
        }
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          tempMarketInfo = ele;
        }
      });
      return tempMarketInfo;
    }
  },
  async created() {
    // await this.getTradePairList();
    await this.getExchangeItemId();
    await this.getUrlSymbol();
    let tempCookies = _jsCookie.default.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      // await this.getToken();
      await this.initURL();
      this.initWs();
    } else {
      this.token = _jsCookie.default.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
    this.resolveCoin();
  },
  mounted() {
    var meta = null;
    var title = null;
    if (_stationSetting.VUE_UNION_STATION == "bitda") {
      title = document.getElementsByTagName("title");
      title[0].innerHTML = "幣本位合約|幣達交易所";
      meta = document.getElementsByTagName("meta");
      $("meta[property='og:description']").attr("content", "幣本位合約是一種數字資產衍生產品，對於持倉時間沒有任何限制。稱反向合約，以BTC、ETH等數字資產進行結算。為了保證跟踪標的價格指數，幣本位永續合約通過資金費用(Funding Cost)的機制來保證其價格緊跟標的資產的價格。");
      meta["title"].setAttribute("content", "幣本位合約|幣達交易所");
      meta["description"].setAttribute("content", "幣本位合約是一種數字資產衍生產品，對於持倉時間沒有任何限制。稱反向合約，以BTC、ETH等數字資產進行結算。為了保證跟踪標的價格指數，幣本位永續合約通過資金費用(Funding Cost)的機制來保證其價格緊跟標的資產的價格。");
    } else if (_stationSetting.VUE_UNION_STATION == "8V") {} else {
      this.voucherInterval = setInterval(() => {
        document.title = this.title;
        // document.getElementById("chat-widget-container").style.display = "none"; //隐藏
      }, 500);
    }
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  watch: {
    async userInfo(newValue, oldValue) {
      if (this.userInfo == null) {
        // await this.getToken();
        await this.initURL();
        this.initWs();
      } else {
        let tempUserInfo = sessionStorage.getItem("userInfo");
        if (tempUserInfo) {
          return;
        }
        this.token = _jsCookie.default.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    }
  },
  methods: {
    routeSplicing: _routeSplicing.default,
    zoomKline(isFull) {
      this.isFullScreen = isFull;
    },
    onClosePosition() {
      this.$confirm(this.translateTitle("确认平仓该订单?"), this.translateTitle("平仓"), {
        confirmButtonText: this.translateTitle("确定"),
        cancelButtonText: this.translateTitle("取消")
      }).then(() => {});
    },
    onMinClick() {
      this.multipleNum -= 1;
      if (this.multipleNum <= 1) {
        this.multipleNum = 1;
      }
    },
    onPlusClick() {
      this.multipleNum += 1;
      if (this.multipleNum >= this.maxNumber[this.swap_symbol_id]) {
        this.multipleNum = this.maxNumber[this.swap_symbol_id];
      }
    },
    goToLogin() {
      this.$router.push({
        path: this.routeSplicing(this.language, "login"),
        query: {
          redirect: this.$route.fullPath
        }
      });
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    formatTooltip(val) {
      return val + "X";
    },
    onInputChange(value) {
      if (Number(value) > Number(this.maxNumber[this.swap_symbol_id])) {
        value = this.maxNumber[this.swap_symbol_id];
      }
      this.sliderValue = Number(value);
      this.multipleNum = value;
    },
    onSliderChange(value) {
      this.multipleNum = value;
      if (this.multipleNum >= this.maxNumber[this.swap_symbol_id]) {
        this.multipleNum = this.maxNumber[this.swap_symbol_id];
      }
    },
    handleClose() {
      this.multipleNum = 20;
      this.dialogVisible = false;
    },
    async onMultipleNumClick(value) {
      if (this.userInfo) {
        this.sliderValue = Number(value);
        this.multipleNum = value;
        let params = {
          symbol_id: this.swap_symbol_id
        };
        const {
          status,
          data,
          msg
        } = await (0, _order.apiGetOrderStatistic)(params);
        if (status == 200) {
          this.historyDataLength = data.contract_position_info.total_num + data.contract_plan_info.total_num;
          if (this.historyDataLength == 0) {
            this.dialogVisible = true;
          } else {
            (0, _message.showMessage)({
              message: this.translateTitle("当前有进行中的仓位或委托单，不能修改杠杆倍数"),
              type: "error"
            });
          }
        } else {
          (0, _message.showMessage)({
            message: msg,
            type: "error"
          });
        }
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    onMultipleNumConfirm() {
      this.$refs.panelComponent.setMultipleNum(Number(this.multipleNum));
      this.$refs.tradeComponent.setMultipleNum(Number(this.multipleNum));
      this.dialogVisible = false;
    },
    onAddOption() {
      this.$refs.marketComponent.getOptionList();
    },
    onMarketAddOption() {
      this.$refs.panelComponent.getOptionList();
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },
    onTradeSuccess(type) {
      this.$refs.historyOrder.reset(type);
    },
    async getExchangeItemId() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(13);
      if (status == 200) {
        this.coinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    async getUrlSymbol() {
      const {
        status,
        data
      } = await (0, _settings.apiGetStaticTableInfo)(58);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));
        let symbolArr = [];
        for (const key in this.coinInfoMap) {
          symbolArr.push(this.coinInfoMap[key].symbol.toLowerCase());
        }
        let tempSymbol = this.$route.params.symbol;
        if (symbolArr.indexOf(tempSymbol) > -1) {
          this.symbol = tempSymbol;
        } else {
          // tempSymbol = "btcusd";
          // this.symbol = "btcusd";
          tempSymbol = "ethusd";
          this.symbol = "ethusd";
        }
        for (const key in this.coinInfoMap) {
          let tempArr = [];
          tempArr = this.coinInfoMap[key].leverage_list.split(",");
          this.maxMultipleNum[key] = Number(tempArr[tempArr.length - 1]);
          let tempBase = tempArr[tempArr.length - 1] / 5;
          this.marks[key] = {};
          // this.maxNumber[key] = {};
          this.maxNumber[key] = tempArr[tempArr.length - 1];
          tempArr.forEach((ele, index) => {
            this.marks[key][1] = "1X";
            this.marks[key][tempBase * (index + 1)] = tempBase * (index + 1) + "X";
          });
          this.tradePairArray.push(this.coinInfoMap[key].symbol.toLowerCase());
          if (this.coinInfoMap[key].symbol.toLowerCase() == tempSymbol) {
            this.swap_symbol_id = this.coinInfoMap[key].symbol_id;
            this.from_item_id = this.coinInfoMap[key].change_item_id;
            this.to_item_id = this.coinInfoMap[key].base_item_id;
          }
        }
      }
    },
    onSymbolChangeV2(params) {
      this.$refs.marketComponent.onSymbolChange(params, "1");
    },
    onSymbolChange(item) {
      if (this.swap_symbol_id == item.symbol_id) {
        return;
      }
      this.old_swap_symbol_id = this.swap_symbol_id;
      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "order",
          symbol: this.swap_symbol_id
        }
      });
      this.orderbookList = [{
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }, {
        E: "--",
        T: "--",
        m: "--",
        p: "--",
        q: "--"
      }];
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "order",
          symbol: item.symbol_id
        }
      });
      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "depth",
          symbol: this.swap_symbol_id
        }
      });
      this.depthList = {
        a: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]],
        b: [["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"], ["--", "--"]]
      };
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "market",
          symbol: item.symbol_id
        }
      });
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "depth",
          symbol: item.symbol_id
        }
      });
      this.$refs.klineComponent.onChangeSymbol(item);
      this.swap_symbol_id = item.symbol_id;
      this.from_item_id = item.change_item_id;
      this.to_item_id = item.base_item_id;
      this.resolveCoin();
      // this.$refs.historyOrder.resetV2();
    },

    resolveCoin() {
      this.pageLoading = true;
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        // tempSymbol = "btcusd";
        // this.swap_symbol_id = 10001;
        tempSymbol = "ethusd";
        this.swap_symbol_id = 10002;
      }
      let trade_pair = tempSymbol;
      if (this.tradePairArray.indexOf(trade_pair.toLowerCase()) == -1) {
        // tempSymbol = "btcusd";
        // this.swap_symbol_id = 10001;
        tempSymbol = "ethusd";
        this.swap_symbol_id = 10002;
      }
      this.coinFrom = this.coinItemIdMap[this.from_item_id].item_name.toUpperCase();
      this.coinTo = this.coinItemIdMap[this.to_item_id].item_name.toUpperCase();
      // const [coinFrom, coinTo] = tempSymbol.split("_");
      // this.coinFrom = coinFrom;
      // this.coinTo = coinTo;
      setTimeout(() => {
        this.pageLoading = false;
      }, 300);
    },
    async getToken() {
      // 接口废弃不用了;
      const {
        status,
        data
      } = await (0, _index.apiGetDefaultToken)();
      if (status == 200) {
        this.token = data.token;
      }
    },
    async initURL() {
      let params = {
        __version: "3.4.3"
        // token: this.token,
      };

      const {
        status,
        data
      } = await (0, _index.apiWsUrl)(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;
      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "kline",
            resolution: "15",
            symbol: me.swap_symbol_id
          }
        },
        callback,
        onConnect,
        mounted
      };
      let wsNew = new _socket.default(option).getInstance();
      me.global.setWs(wsNew);
      // 重新连接
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            symbol: me.swap_symbol_id
            // resolution: "D",
          }
        });

        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id
            // resolution: "D",
          }
        });

        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "order",
            symbol: me.swap_symbol_id
          }
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "futureM",
            app_id: _stationSetting.VUE_APP_ID
          }
        });
        if (_jsCookie.default.get("topcredit_exchange_t")) {
          me.global.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets"
            }
          });
        }
      }
      function callback(data) {
        const {
          action
        } = data;
        if (action == "Pushdata.futureM") {
          if (data.data) {
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }
        if (action == "Pushdata.kline") {
          window.resolveKlineDataV4(data);
        }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        if (action == "Pushdata.orderbook") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            me.orderbookList.unshift(...data.data);
            if (me.orderbookList.length > 28) {
              me.orderbookList = me.orderbookList.slice(0, 28);
            }
          }
        }
        if (action == "Pushdata.depth") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            let tempObj = data.data;
            if (tempObj.a.length > 0) {
              tempObj.c = 0;
              tempObj.d = 0;
              tempObj.a.forEach(ele => {
                tempObj.c += Number(ele[1]);
              });
              tempObj.b.forEach(ele => {
                tempObj.d += Number(ele[1]);
              });
            }
            me.depthList = tempObj;
          }
        }
      }
    }
  }
};
exports.default = _default;